<template>
    <div><h1>Invalid Webinar</h1></div>
</template>
<script>
export default {
    props: {
		message: String,
	},
    data() {
        return {
            
        };
    }
}
</script>