<template>
  <div v-html="fileIcon"></div>
</template>

<script>
export default {
  props: {
    mimeType: String,
    extension: String,
    size: {
      type: String,
      default: "small"
    }
  },
  computed: {
    fileIcon() {
      const dimensions = this.size === "large"
        ? { width: 28, height: 33 }
        : { width: 17, height: 20 }

      const attrs = `width="${dimensions.width}" height="${dimensions.height}"`

      const extensionMap = {
        pdf: "application/pdf",
        zip: "application/zip",
        txt: "text/plain",
        doc: "application/msword",
        docx: "application/msword",
        jpg: "image/*",
        jpeg: "image/*",
        png: "image/*",
        gif: "image/*",
        mp4: "video/*",
        webm: "video/*",
        mp3: "audio/*",
        wav: "audio/*"
      }

      const resolvedType =
        (this.mimeType && (
          this.mimeType.startsWith("video/") ? "video/*" :
          this.mimeType.startsWith("audio/") ? "audio/*" :
          this.mimeType.startsWith("image/") ? "image/*" :
          this.mimeType
        )) ||
        (this.extension && extensionMap[this.extension.toLowerCase()]) ||
        "default"

      const icons = {
        "application/pdf": `<svg xmlns="http://www.w3.org/2000/svg" ${attrs} viewBox="0 0 512 512" fill="#2D8CFF"><path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"/></svg>`,
        "application/zip": `<svg xmlns="http://www.w3.org/2000/svg" ${attrs} viewBox="0 0 384 512" fill="#2D8CFF"><path d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM96 48c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16zm-6.3 71.8c3.7-14 16.4-23.8 30.9-23.8l14.8 0c14.5 0 27.2 9.7 30.9 23.8l23.5 88.2c1.4 5.4 2.1 10.9 2.1 16.4c0 35.2-28.8 63.7-64 63.7s-64-28.5-64-63.7c0-5.5 .7-11.1 2.1-16.4l23.5-88.2zM112 336c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0z"/></svg>`,
        "image/*": `<svg xmlns="http://www.w3.org/2000/svg" ${attrs} viewBox="0 0 512 512" fill="#2D8CFF"><path d="M464 32H48A48 48 0 0 0 0 80V432a48 48 0 0 0 48 48H464a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zM128 128a48 48 0 1 1-48 48 48 48 0 0 1 48-48zm336 256H48L192 208l64 96 64-64z"/></svg>`,
        "video/*": `<svg xmlns="http://www.w3.org/2000/svg" ${attrs} viewBox="0 0 576 512" fill="#2D8CFF"><path d="M336 64H48A48 48 0 0 0 0 112V400a48 48 0 0 0 48 48H336a48 48 0 0 0 48-48V112a48 48 0 0 0-48-48zm-16 240-112 64V192l112 64zM576 96v320a16 16 0 0 1-16 16H416V80H560a16 16 0 0 1 16 16z"/></svg>`,
        "audio/*": `<svg xmlns="http://www.w3.org/2000/svg" ${attrs} viewBox="0 0 640 512" fill="#2D8CFF"><path d="M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64l0 384c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352 64 352c-35.3 0-64-28.7-64-64l0-64c0-35.3 28.7-64 64-64l67.8 0L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"/></svg>`,
        "application/msword": `<svg xmlns="http://www.w3.org/2000/svg" ${attrs} viewBox="0 0 384 512" fill="#2D8CFF"><path d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM112 256l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>`,
        "text/plain": `<svg xmlns="http://www.w3.org/2000/svg" ${attrs} viewBox="0 0 384 512" fill="#2D8CFF"><path d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM112 256l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>`,
        "default": `<svg ${attrs} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.792969 19.4735H16.2095V3.40786L12.8016 0H0.792969" fill="#3980FB" /><path d="M12.8027 3.40786H16.2106L12.8027 0" fill="#9EC2FF" /><path d="M16.2106 6.81607V3.4082H12.8027" fill="#1F53B1" /><path d="M4.44141 8.01633H12.5554V7.15625H4.44141M4.44141 9.87443H12.5554V9.0184H4.44141M4.44141 11.7366H12.5554V10.8765H4.44141M4.44141 13.4527H9.30978V12.7387H4.44141" fill="white" /></svg>`
      }

      return icons[resolvedType] || icons.default
    }
  }
}
</script>
