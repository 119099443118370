<template>
  <div class="modal fade bd-example-modal-xl" id="exampleModal" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="border-dotted">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <h2 class="text-white weight-700">Add New Webinar</h2>
              </div>
              <div class="col-md-6">
                <ul class="nav nav-tabs nav-tabs-line nav-tabs-inverse new-webinar-rols-tabs">
                  <li class="nav-item">
                    <button class="nav-link" :class="webinarType == 'ONE_TIME' ? 'active' : ''"
                      @click="changeWebinarType('ONE_TIME')">
                      One Time
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="nav-link" :class="webinarType == 'MULTI_TIME' ? 'active' : ''"
                      @click="changeWebinarType('MULTI_TIME')">
                      Multiple Broadcasts
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="nav-link" :class="webinarType == 'RECURRING' ? 'active' : ''"
                      @click="changeWebinarType('RECURRING')">
                      Recurring
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="nav-link" :class="webinarType == 'OPEN_ROOM' ? 'active' : ''"
                      @click="changeWebinarType('OPEN_ROOM')">
                      Open Room
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="one-time" role="tabpanel" aria-labelledby="kt_tab_pane_2">
                <div class="row">
                  <div class="col-md-6">
                    <div v-if="webinarType == 'OPEN_ROOM'">
                      <div class="url-obj">
                        <h4 class="text-white">URL:</h4>
                        <div>
                          <button v-if="!room_error" class="btn btn-danger" id="copyMyUrl"
                            style="margin-left: 10px; padding: 4px" @click="copyUrl()">
                            Copy Link
                          </button>
                          <button class="btn btn-brand" id="6433a1fe3dd71a31a9620958_COPY"
                            style="margin-left: 10px; padding: 4px" @click="EditUrl()">
                            Edit
                          </button>
                          <button v-if="is_edit" :disabled="s_enable" class="btn btn-primary"
                            id="6433a1fe3dd71a31a9620958_COPY" style="margin-left: 10px; padding: 4px" @click="SaveUrl()">
                            Save
                          </button>
                        </div>
                      </div>
                      <div class="form-group d-flex" :class="is_loading">
                        <input type="text" class="form-control" disabled placeholder="url" v-model="url" />
                        <input style="text-transform: lowercase;" v-if="is_edit" type="email"
                          class="form-control url-input" placeholder="URL" v-model="room_name" />
                      </div>
                      <div :class="[isUrlValid()]" style="color: #ff8b00; font-weight: bold">
                        <em>{{ room_error }}</em>
                      </div>
                    </div>
                    <form v-modal="test">
                      <div v-if="webinarType == 'ONE_TIME'">
                        <h4 class="text-white">Title</h4>
                        <div class="form-group">
                          <input type="text" class="text-center blue-border dark-bg form-control"
                            id="exampleInputPassword1" placeholder="Title" v-model="title" />
                        </div>

                        <div class="form-group">
                          <h4 class="text-white">Date</h4>
                          <div class="input-group date">
                            <Datepicker class="form-control blue-border date-picker-custom" v-model="date"
                              inputFormat="MM/dd/yyyy" placeholder="MM / DD /YYYY" :disabledTime="false" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <h4 class="text-white">Time</h4>
                            <div class="form-group">
                              <vue-timepicker v-model="time" format="hh:mm A" hide-clear-button></vue-timepicker>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <h4 class="text-white">Duration</h4>
                            <div class="">
                              <vue-timepicker v-model="duration" hide-clear-button></vue-timepicker>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <h4 class="text-white">Timezone</h4>
                            <div class="">
                              <v-select v-model="timezone" placeholder="Timezone"
                                class="select-picker blue-border dark-bg"
                                :options="Intl.supportedValuesOf('timeZone')"></v-select>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <h4 class="text-white">Description</h4>
                            <div class="form-group">
                              <textarea class="blue-border dark-bg form-control large-textarea" placeholder="Description"
                                v-model="description"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="webinarType == 'MULTI_TIME'">
                        <div class="form-group">
                          <h4 class="text-white">Title</h4>
                          <input type="text" class="text-center blue-border dark-bg form-control"
                            id="exampleInputPassword1" placeholder="Title" v-model="title" />
                        </div>
                        <div class="form-group">
                          <h4 class="text-white">Date</h4>
                          <div class="input-group date">
                            <Datepicker class="form-control blue-border date-picker-custom" v-model="date"
                              inputFormat="MM/dd/yyyy" placeholder="MM / DD /YYYY" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <h4 class="text-white">Time</h4>
                            <div class="form-group">
                              <vue-timepicker v-model="time" format="hh:mm A" hide-clear-button></vue-timepicker>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <h4 class="text-white">Duration</h4>
                            <div class="form-group">
                              <vue-timepicker v-model="duration" hide-clear-button></vue-timepicker>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <h4 class="text-white">Timezone</h4>
                            <div class="form-group">
                              <v-select v-model="timezone" placeholder="Timezone"
                                class="select-picker blue-border dark-bg"
                                :options="Intl.supportedValuesOf('timeZone')"></v-select>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <h4 class="text-white">Description</h4>
                            <div class="form-group">
                              <textarea class="blue-border dark-bg form-control" placeholder="Description"
                                v-model="Description"></textarea>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <button type="button" class="btn border-radius" style="background: #2d8cff; color: #ffff"
                              data-toggle="dropdown" @click="addMultiWebinar()">
                              Add
                            </button>
                          </div>
                        </div>
                        <div class="table-responsive mt-5">
                          <table class="table no-border">
                            <tbody>
                              <tr>
                                <td>
                                  <h3 class="text-blue weight-700 mb-3">
                                    Date
                                  </h3>
                                </td>
                                <td>
                                  <h3 class="text-blue weight-700 mb-3">
                                    Time
                                  </h3>
                                </td>
                                <td>
                                  <h3 class="text-blue weight-700 mb-3">
                                    Duration
                                  </h3>
                                </td>
                                <td>
                                  <h3 class="text-blue weight-700 mb-3">
                                    Timezone
                                  </h3>
                                </td>
                              </tr>
                              <tr v-for="(key, i) in webinars" :key="i">
                                <td>
                                  <h3>
                                    {{ new Date(key.time * 1000).getMonth() + 1 }}/{{ new Date(key.time *
                                      1000).getDate()
                                    }}/{{ new Date(key.time * 1000).getFullYear() }}
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    {{ formatAMPM(new Date(key.time * 1000)) }}
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    {{ durationformateDuration(key.duration) }}
                                  </h3>
                                </td>
                                <td>
                                  <h3>{{ key.timezone }}</h3>
                                </td>

                                <td>
                                  <button type="button" class="add-btn" @click="deleteTableRow(i)">
                                    X
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div v-if="webinarType == 'RECURRING'">
                        <div class="form-group">
                          <h4 class="text-white">Title</h4>
                          <input type="text" class="text-center blue-border dark-bg form-control"
                            id="exampleInputPassword1" placeholder="Title" v-model="title" />
                        </div>
                        <div class="form-group"></div>

                        <div class="row">
                          <div class="col-md-4" v-if="frequency == 'week'">
                            <h4 class="text-white">Day</h4>
                            <div class="form-group">
                              <select class="blue-border dark-bg form-control" v-model="dayName">
                                <option value="Sunday">Sunday</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="frequency == 'month'">
                            <h4 class="text-white">Date</h4>
                            <div class="form-group">
                              <select class="blue-border dark-bg form-control" v-model="date">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <h4 class="text-white">At</h4>
                            <div class="form-group">
                              <vue-timepicker v-model="time" format="hh:mm A" hide-clear-button></vue-timepicker>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <h4 class="text-white">Duration</h4>
                            <div class="form-group">
                              <vue-timepicker v-model="duration" hide-clear-button></vue-timepicker>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <h4 class="text-white">Timezone</h4>
                            <div class="form-group">
                              <v-select v-model="timezone" placeholder="Timezone"
                                class="select-picker blue-border dark-bg"
                                :options="Intl.supportedValuesOf('timeZone')"></v-select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <h4 class="text-white">For</h4>
                            <div class="form-group">
                              <select class="blue-border dark-bg form-control" v-model="this.for" placeholder="Time Zone"
                                v-on:change="addPeriod(this.for)">
                                <option value="true">Limited</option>
                                <option value="false" selected>
                                  Unlimited
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="this.for == 'true'">
                            <h4 class="text-white">Occurrence</h4>
                            <div class="form-group">
                              <input type="number" min="1" class="text-center blue-border dark-bg form-control"
                                id="exampleInputPassword1" placeholder="Occurrence" v-model="occurrence" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="responsive-hide" style="height: 23px"></div>
                          </div>
                          <div class="col-md-5 text-right" style="margin-bottom: 2rem">
                            <div class="responsive-hide" style="height: 23px"></div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <h4 class="text-white">Description</h4>
                              <textarea class="blue-border dark-bg form-control" placeholder="Description"
                                spellcheck="false"></textarea>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <button type="button" class="btn border-radius" style="background: #2d8cff; color: #ffff"
                              data-toggle="dropdown" @click="addMultiRecurring()">
                              Add
                            </button>
                          </div>
                        </div>
                        <div class="table-responsive mt-5">
                          <table class="table no-border">
                            <tbody>
                              <ul class="number-list">
                                <li v-for="(key, i) in recurringWebinar" :key="i">
                                  <div class="d-flex">
                                    <div>
                                      <span>{{ i + 1 }}</span>Every {{ key.day }} at {{ key.time }} {{ key.timezone }}
                                      <p v-if="key.for == 'false'">
                                        For Unlimited
                                      </p>
                                      <p v-else>
                                        For Limited {{ key.occurrence }} Occurrence
                                      </p>
                                    </div>
                                    <div>
                                      <button type="button" class="add-btn-recurring add-btn" @click="deleterecuring(i)">
                                        X
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <h3 class="weight: 700;">
                          Participants ({{ users.length }})
                        </h3>
                      </div>
                    </div>
                    <div class="row mt-3 mb-3">
                      <div class="col-md-4">
                        <div class="input-group mb-3">
                          <input type="text" required class="form-control bg-white text-black" v-model="invitefirstName"
                            placeholder="First Name" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="input-group mb-3">
                          <input type="text" required class="form-control bg-white text-black" v-model="invitelastName"
                            placeholder="Last Name" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <button type="button" class="btn border-radius add-new-video-link width-100"
                          style="background: #ff2600; color: #ffff" data-toggle="dropdown" @click="
                            contactList()">
                          + Add From Contacts
                        </button>
                      </div>
                    </div>
                    <div class="row mt-3 mb-3">
                      <div class="col-md-8">
                        <div class="input-group mb-3 formM">
                          <input type="email" @input="isEmailValid" required
                            class="form-control bg-white text-black inputM" v-model="inviteUser" placeholder="Email..." />
                          <button v-on:click="addToContacts()" v-if="is_contact" class="buttonM">Add to contact</button>
                          <button v-if="is_sucess_loader" style="background: white;" class="buttonM">
                            <span v-if="is_sucess_loader" class="spinner spinner-white spinner-right"></span>
                          </button>
                          <button v-if="is_sucess" style="background: white;" class="buttonM">
                            <img alt="Logo" v-if="is_sucess" style="padding: 5px;" width="35" height="35"
                              src="../../assets/media/check.png" />
                          </button>
                        </div>

                        <div style="color: #ff8b00; font-weight: bold">
                          <em>{{ validateError }}</em>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <button type="button" class="btn border-radius add-new-video-link width-100"
                          style="background: #2d8cff; color: #ffff" data-toggle="dropdown" @click="addParticipants()"
                          :disabled="disable">
                          + Invite
                        </button>
                      </div>
                    </div>
                    <div style="max-height: 230px; overflow-y: auto">
                      <div v-for="(key, i) in this.users" :key="i" class="user-list-box mb-3">
                        <div class="">
                          <div class="">
                            <div class="" style="display: flex">
                              <input type="hidden" v-model="users[i].Name" />
                              <input type="hidden" v-model="users[i].email" />
                              <div class="w-100 d-flex justify-content-between">
                                <p style="color: #fff ;display: flex">
                                  {{ key.email }} &nbsp;
                                <p v-if="key.firstName"> ({{ key.firstName }} {{ key.lastName == 'lastName' ?
                                  users[i].webinarUserType : key.lastName }})</p>
                                </p>
                                <button v-on:click="() => deleted(users[i])" type="button" class="btn white-btn"
                                  style="background: #b60808; color: #fff" data-toggle="modal"
                                  data-target="#add-new-webinar-calendar">
                                  Delete
                                </button>
                              </div>
                            </div>
                            <div class="row mt-3 ml-1">
                              <label class="custom kt-radio kt-radio--bold kt-radio--brand">
                                <input type="radio" value="Host" v-model="users[i].webinarUserType" />
                                Host
                                <span></span>
                              </label>
                              <label class="custom kt-radio kt-radio--bold kt-radio--brand">
                                <input type="radio" value="Moderator" v-model="users[i].webinarUserType" />
                                Moderator
                                <span></span>
                              </label>
                              <label class="custom kt-radio kt-radio--bold kt-radio--brand">
                                <input type="radio" value="Guest" v-model="users[i].webinarUserType" />
                                Guest
                                <span></span>
                              </label>
                              <label class="custom kt-radio kt-radio--bold kt-radio--brand">
                                <input type="radio" value="Viewer" v-model="users[i].webinarUserType" />
                                Viewer
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 text-center align-items-center justify-content-center">
                  <button v-on:click="Create()" type="button" class="btn white-btn mr-3"
                    style="width: 150px; position: relative" data-toggle="modal" data-target="#add-new-webinar-calendar"
                    :disabled="disabled">
                    <span v-if="loader" class="spinner spinner-white spinner-right"></span>
                    Create
                  </button>
                  <button v-on:click="JoinRoom()" v-if="webinarType == 'OPEN_ROOM'" type="button"
                    class="btn btn-danger mr-3" style="width: 150px; position: relative" data-toggle="modal"
                    data-target="#add-new-webinar-calendar" :disabled="disabled">
                    Join Room
                  </button>
                  <button type="button" class="btn transparent-btn border-blue" style="width: 150px"
                    @click="closeModall()">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade bd-example-modal-xl" id="usersContactModal" style="z-index: 9999;background: rgb(0 0 0 / 92%);"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="border-dotted">
          <div class="modal-body" style=" 
        border-radius: 6px;border: 1px solid;padding: 3rem;">
            <div class="row">
              <div class="col-md-12">
                <h1 class="text-white text-center mb-3">
                  Contacts
                </h1>
                <div class="row">
                  <div class="col-md-6">
                  </div>
                </div>
                <div class="row" style="justify-content: center;">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <input type="text" required class="form-control bg-white text-black" v-model="contactSearch"
                        placeholder="Search Contacts" />
                    </div>
                  </div>
                </div>
                <div class="row mt-3 mb-3" style="display: flex;
    justify-content: center;">
                  <span v-if="filteredList.length == 0">Sorry, no results were found for your contacts</span>
                  <table style="color:white" v-if="filteredList.length">
                    <tr style="font-size:20px">
                      <th></th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Role</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                    <tr v-for="(key, i) in this.filteredList" :key="i" :id="'_ER_TR_' + key._id">
                      <td>
                        <input type="checkbox" checked v-if="userExists(key.email)"
                          @click="addParticipantFromContact(key)" />
                        <input type="checkbox" v-if="!userExists(key.email)" @click="addParticipantFromContact(key)" />
                      </td>
                      <td>{{ key.firstName }}</td>
                      <td>{{ key.lastName }}</td>
                      <td>{{ key.Role }}</td>
                      <td>{{ key.email }}</td>
                      <td>
                        <div class="row">
                          <button v-on:click="
                            editContact(key)
                            " class="btn btn-brand mt-2" :id="'_ER_RIGHT_' + key._id" style="
                                      background: green;
                                      border: green;
                                      margin-left: 10px;padding: 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                              xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" x="0" y="0"
                              viewBox="0 0 24 24" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                              <g>
                                <path
                                  d="M19 12a1 1 0 0 0-1 1v8c0 .551-.448 1-1 1H3c-.552 0-1-.449-1-1V7c0-.551.448-1 1-1h8a1 1 0 1 0 0-2H3C1.346 4 0 5.346 0 7v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-8a1 1 0 0 0-1-1z"
                                  fill="#ffffff" opacity="1" data-original="#000000" class=""></path>
                                <path
                                  d="M9.376 11.089a.506.506 0 0 0-.137.255l-.707 3.536a.501.501 0 0 0 .589.588l3.535-.707a.497.497 0 0 0 .256-.137l7.912-7.912-3.535-3.535zM23.268.732a2.502 2.502 0 0 0-3.535 0l-1.384 1.384 3.535 3.535 1.384-1.384C23.74 3.796 24 3.168 24 2.5s-.26-1.296-.732-1.768z"
                                  fill="#ffffff" opacity="1" data-original="#000000" class=""></path>
                              </g>
                            </svg>
                          </button>
                          <button v-on:click="
                            removeContact(key._id)
                            " class="btn btn-brand mt-2" :id="'_ER_' + key._id" style="
                                        background: #b60808;
                                        border: #b60808;
                                        margin-left: 10px;padding: 5px">
                            <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" version="1.1"
                              xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 320.591 320.591"
                              style="enable-background:new 0 0 512 512;padding: 4px;" xml:space="preserve" class="">
                              <g>
                                <path
                                  d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                                  fill="#ffffff" opacity="1" data-original="#000000" class=""></path>
                                <path
                                  d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                                  fill="#ffffff" opacity="1" data-original="#000000" class=""></path>
                              </g>
                            </svg> 
                          </button>

                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="row mt-3 text-center align-items-center justify-content-center">
              <button v-on:click="AddContactUsers()" type="button" class="btn white-btn mr-3"
                style="width: 150px; position: relative">
                Add Users
              </button>
              <button type="button" class="btn transparent-btn border-blue" style="width: 150px"
                @click="closeContactModal()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade bd-example-modal-xl" id="editContactmodal" style="z-index: 9999;background: rgb(0 0 0 / 92%);"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" style="width: fit-content;" role="document">
      <div class="modal-content">
        <div class="border-dotted">
          <div class="modal-body" style="    border: 1px solid;
        border-radius: 6px;">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <h1 class="text-white">
                      Edit Contact
                    </h1>
                  </div>
                </div>
                <div class="row mt-3 mb-3">
                  <div class="col-md-6">
                    <lable class="text-white mb-1">First Name</lable>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control bg-white text-black" v-model="editFirstName"
                        placeholder="First Name" />
                    </div>
                  </div>
                  <input type="hidden" class="form-control bg-white text-black" v-model="editUserId"
                    placeholder="Last Name " />
                  <div class="col-md-6">
                    <lable class="text-white mb-1">Last Name</lable>
                    <div class="input-group mb-3">

                      <input type="text" class="form-control bg-white text-black" v-model="editLastname"
                        placeholder="Last Name " />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <lable class="text-white mb-1">Email</lable>
                    <div class="input-group mb-3">

                      <input @input="handleInput" type="email" class="form-control bg-white text-black"
                        v-model="editEmail" placeholder="Search..." />
                    </div>

                    <div style="color: #ff8b00; font-weight: bold">
                      <em>{{ email_error }}</em>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <lable class="text-white mb-1">Role</lable>
                    <div class="form-group">
                      <select class="blue-border dark-bg form-control" v-model="editRole" placeholder="Time Zone">
                        <option value="Host" selected>Host</option>
                        <option value="Moderator">Moderator</option>
                        <option value="Guest">Guest</option>
                        <option value="Viewer">Viewer</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 text-center align-items-center justify-content-center">
              <button v-on:click="EditSave()" type="button" class="btn white-btn mr-3"
                style="width: 150px; position: relative" :disabled="edit_contact_enable">
                <span v-if="edit_loader" class="spinner spinner-white spinner-right"></span>
                Save
              </button>
              <button type="button" class="btn transparent-btn border-blue" style="width: 150px"
                @click="closeEditModall()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RRule, RRuleSet, rrulestr } from "rrule";
import VueTimepicker from "vue3-timepicker";
import "vue3-timepicker/dist/VueTimepicker.css";
import { webinaroneTime, CheckContacts, editSaveContacts, checkContactEmail, singlewebinardata, userEmail } from "../services/service";
import Datepicker from "vue3-datepicker";
import {
  webinarmalti,
  checkWebinarUrl,
  getMyContacts,
  lastwebinarlist,
  deleteContacts,
  addToContacts
} from "../services/service";
var moment = require("moment-timezone");
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import axios from "axios";
export default {
  name: "WebinarModel",
  components: {
    RRule,
    RRuleSet,
    rrulestr,
    Datepicker,
    vSelect,
    VueTimepicker,
  },
  props: {
    data: Object,
    mode: String,
  },
  data() {
    return {
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      email: "",
      disable: "",
      disabled: false,
      timezone: "",
      webinarsTemp: [],
      webinarsRecurringTemp: [],
      period: this.period,
      for: this.For,
      occurrence: this.occurrence,
      recurringWebinar: [],
      recurringWebinar: [],
      frequency: "week",
      room_name: "",
      oldRoomName: "",
      webinarType: "ONE_TIME",
      webinarRoomType: "",
      mode: "edit",
      title: "",
      is_edit: false,
      s_enable: false,
      url: "",
      room_error: "",
      dateString: "",
      date: new Date(),
      time: new Date().toLocaleString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      duration: "00:15",
      webinarId: "",
      description: "",
      invitefirstName: "",
      invitelastName: "",
      firstName: "",
      lastName: "",
      inviteUser: "",
      webinars: [],
      selected_contact_users: [],
      conatctUsers: [],
      loader: false,
      is_loading: "",
      users: [],
      date_of_birth: "",
      new_date_of_birth: "",
      is_contact: false,
      is_remove_action: false,
      edit_loader: false,
      edit_contact_enable: false,
      is_contact_add_called: false,
      is_sucess_loader: false,
      validateError: "",
      months: "",
      contactSearch: "",
      dayName: "Monday",
      currentUserEmail: "",
      editFirstName: "",
      editUserId: "",
      editLastname: "",
      editEmail: "",
      editRole: "",
      email_error: "",
      s_enable: "",
      oldEmail: "",
    };

  },
  async created() {
    const lastwebinar = await lastwebinarlist();
    this.time = new Date().toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
    })
    console.log("lastwebinar---------------------", lastwebinar);
    if (localStorage.getItem("isAuthenticated")) {
      var companyname = JSON.parse(
        localStorage.getItem("userdetails")
      ).companyname;

      var loggedUserName = JSON.parse(
        localStorage.getItem("userdetails")
      ).Name.replaceAll(" ", "");

      var loggedUserId = JSON.parse(
        localStorage.getItem("userdetails")
      )._id.replaceAll(" ", "");

      this.firstName = JSON.parse(
        localStorage.getItem("userdetails")
      ).firstName;

      this.currentUserEmail = JSON.parse(
        localStorage.getItem("userdetails")
      ).email;

      this.lastName = JSON.parse(localStorage.getItem("userdetails")).lastName;
      this.timezone = JSON.parse(localStorage.getItem("userdetails")).timezone ? JSON.parse(localStorage.getItem("userdetails")).timezone : "";

      if (lastwebinar.data.result.data != null) {
        var userName = lastwebinar.data.result.data.roomName;
        var createdBy = lastwebinar.data.result.data.createdBy;
        var webinarId = lastwebinar.data.result.data._id;
      } else {
        var userName = loggedUserName;
        var createdBy = loggedUserId;
        var webinarId = "";
      }

      this.webinarId = webinarId;
      this.room_name = userName.toLocaleLowerCase().replaceAll(" ", "");
      // alert(userName)
      this.oldRoomName = userName.toLocaleLowerCase().replaceAll(" ", "");
    } else {
      var companyname = "";
      var roomName = "";
    }

    var old_url = window.location.origin + "/" + companyname + "/";
    this.url = old_url + this.room_name;
    let headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("user"),
    };
    const userMail = await userEmail();
    // this.currentUserEmail = await userEmail();
    if (userMail.response) {
      if (userMail.response.data.code == 401 || userMail.response.status == 401) {
        localStorage.clear();
        swal(userMail.response.data.message);
        this.$router.push("/login");
      }
    }
    this.users.push({
      Name: "",
      email: this.currentUserEmail,
      firstName: this.firstName ? this.firstName : "",
      lastName: this.lastName ? this.lastName : "",
      webinarUserType: "Host",
    });

    if (lastwebinar.data.result.data == null) {
      // this.createDefaultUrl();
    }
  },
  computed: {

    filteredList() {
      if (this.contactSearch == "") {
        return this.conatctUsers;
      } else {
        this.filteredUsers = this.conatctUsers.filter((users) => {
          return users.email.toLowerCase().includes(this.contactSearch.toLowerCase()) ||
            users.lastName.toLowerCase().includes(this.contactSearch.toLowerCase()) ||
            users.firstName.toLowerCase().includes(this.contactSearch.toLowerCase());
        });
        return this.filteredUsers;
      }
    },
  },
  methods: {


    JoinRoom() {
      window.open(this.url, "_blank");
    },
    deleted(id) {
      this.users = this.users.filter((name) => {
        return name.email != id.email;
      });
    },

    isUrlValid: async function async() {
      var body = await checkWebinarUrl(this.room_name);
      console.log("this.room_name:::::::::::::", body);
      if (body.data.result == 1) {
        this.room_error =
          "The url is already in use by someone at your company. Please try a different url.";
        this.s_enable = true;
      } else {
        this.room_error = "";
        this.s_enable = false;
      }

      return this.room_name == "" ?
        " d-none" :
        this.reg.test(this.room_name) ?
          " d-none" :
          " ";
    },
    async isEmailValid() {
      // this.is_contact_add_called = false;
      // if (this.invitefirstName == "") {
      //   this.validateError = "Please enter first name"
      //   this.is_contact_add_called = false;
      //   this.disable = true;
      // } else if (this.invitelastName == "") {
      //   this.validateError = "Please enter last name";
      //   this.is_contact_add_called = false;
      //   this.disable = true;
      // } else {

      if (this.reg.test(this.inviteUser) == false) {
        this.validateError = "Please enter valid Email";
        this.is_contact = false;
        this.is_contact_add_called = false;
        this.disable = true;
      } else {
        var loggedEmail = JSON.parse(localStorage.getItem("userdetails")).email;

        if (this.inviteUser != loggedEmail) {
          console.log('loggedEmail--------', loggedEmail);
          console.log('this.inviteUser--------', this.inviteUser);
          const CheckContactsGet = await CheckContacts(this.inviteUser);
          console.log('CheckContactsGet--------', CheckContactsGet);
          if (CheckContactsGet == 2) {
            this.is_contact = true;
            this.is_sucess_loader = false;
            this.is_sucess = false;
            this.disable = false;
          } else {
            this.is_contact = false;
            this.is_sucess_loader = false;
            this.is_sucess = true;
            this.disable = false;
          }
        } else {
          this.is_contact = false;
        }
        this.validateError = ""
      }
      // }


    },
    async isEditEmailValid() {
      console.log('this.editEmail------------', this.editEmail)

      if (this.edit_loader == false) {

        if (this.editEmail || this.editEmail == "") {
          if (this.reg.test(this.editEmail) == false) {
            this.email_error = "Please enter valid Email";
            this.s_enable = true;
          } else {
            var body_params = {
              email: this.editEmail
            }
            if (this.editEmail) {

              var body = await checkContactEmail(body_params);

              if (this.editUserId != this.editEmail) {
                console.log('body.data.result------------', body.data.result)
                if (body.data.result == 1) {
                  this.email_error = "Email already exits.";
                  this.s_enable = true;
                } else {
                  this.email_error = "";
                  this.s_enable = false;
                }

              } else {
                this.email_error = "";
                this.s_enable = false;
              }

            }
          }
        } else {
          this.email_error = "";
        }
      }
      return this.editEmail == "" ?
        " d-none" :
        this.reg.test(this.editEmail) ?
          "d-none" :
          " ";
    },


    disableBTN: function () {
      return this.validateError == "" ?
        (this.disable = false) :
        (this.disable = true);
    },
    async handleInput() {
      console.log('this.editEmail------------', this.editEmail)

      if (this.edit_loader == false) {

        if (this.editEmail || this.editEmail == "") {
          if (this.reg.test(this.editEmail) == false) {
            this.email_error = "Please enter valid Email";
            this.edit_contact_enable = true;
          } else {
            var body_params = {
              email: this.editEmail
            }
            if (this.editEmail) {

              var body = await checkContactEmail(body_params);

              if (this.oldEmail != this.editEmail) {
                console.log('body.data.result------------', body.data.result)
                if (body.data.result == 1) {
                  console.log('1111111111')
                  this.email_error = "Email already exits.";
                  this.edit_contact_enable = true;
                } else {
                  this.email_error = "";
                  this.edit_contact_enable = false;
                }

              } else {
                this.email_error = "";
                this.edit_contact_enable = false;
              }

            }
          }
        } else {
          this.email_error = "";
          this.edit_contact_enable = false;
        }
      }
    },
    async addToContacts() {
      this.is_contact = false;
      this.is_contact_add_called = true;
      this.is_sucess_loader = true;
      var body = {
        firstName: this.invitefirstName ? this.invitefirstName : 'UnityWebinar',
        email: this.inviteUser,
        lastName: this.invitelastName,
        Role: 'Host'
      }
      const added_contact = await addToContacts(body);
      console.log('added_contact--------------', added_contact)

      if (added_contact.data.code == 200) {
        this.is_sucess_loader = false;
        this.is_contact_add_called = true;
        this.is_sucess = true;
      }

      // this.invitefirstName = "";
      // this.invitelastName = "";
      // this.inviteUser = "";



    },
    async getUpdatedUrl() {
      const lastwebinar = await lastwebinarlist();
      if (lastwebinar.response) {
        if (lastwebinar.response.data.code == 401 || lastwebinar.response.status == 401) {
          localStorage.clear();
          swal(lastwebinar.response.data.message);
          this.$router.push("/login");
        }
      }

      var loggedUserName = JSON.parse(
        localStorage.getItem("userdetails")
      ).Name.replaceAll(" ", "");
      if (lastwebinar.data.result.data != null) {
        var userName = lastwebinar.data.result.data.roomName;
        var webinarId = lastwebinar.data.result.data._id;
      } else {
        var userName = loggedUserName;
        var webinarId = "";
      }

      this.webinarId = webinarId;
      this.room_name = userName.toLocaleLowerCase();
      // alert(userName)
      this.oldRoomName = userName.toLocaleLowerCase();
    },
    async SaveUrl() {
      this.room_name = this.room_name.toLocaleLowerCase().replaceAll(" ", "");
      var old_url =
        window.location.origin +
        "/" +
        JSON.parse(localStorage.getItem("userdetails")).companyname +
        "/";
      this.url = old_url + this.room_name;
      this.is_edit = false;
      this.disabled = false;

      if (this.oldRoomName != this.room_name) {

        let loggedInUser = JSON.parse(localStorage.getItem('userdetails'));
        loggedInUser.Name = this.room_name.toLocaleLowerCase();

        localStorage.setItem('userdetails', JSON.stringify(loggedInUser));


        console.log(JSON.parse(localStorage.getItem('userdetails')));
        this.Create(1);
      }
    },
    async EditSave() {
      this.edit_loader = true;
      const body = {
        id: this.editUserId,
        firstName: this.editFirstName,
        email: this.editUserId,
        lastName: this.editLastname,
        Role: this.editRole,
        email: this.editEmail,
      }
      console.log('body-----------------', body)
      const editSaveCo = await editSaveContacts(body);
      console.log('editSaveCo-----------------', editSaveCo)
      const get_contact = await getMyContacts();
      this.conatctUsers = get_contact.data.result;
      this.edit_loader = false;
      $("#editContactmodal").modal("hide");
    },
    closeEditModall(id) {
      $("#editContactmodal").modal("hide");
    },
    removeContact(id) {
      swal({
        text: "Are you sure want to delete this contact.",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.removeFinallyContact(id)
        } else {
        }
      });
    },
    async removeFinallyContact(id) {
      const body = {
        id: id
      }
      await deleteContacts(body);
      document.getElementById('_ER_TR_' + id).style.background = 'grey';
      const get_contact = await getMyContacts();
      this.conatctUsers = get_contact.data.result;
      console.log('get_contact--------------', get_contact)
      document.getElementById('_ER_TR_' + id).style.background = '';
    },
    EditUrl() {
      if (this.is_edit) {
        this.is_edit = false;
      } else {
        this.is_edit = true;
      }
      this.disabled = true;

    },
    to24Hr(time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes;
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    async createDefaultUrl() {
      var time = new Date();
      this.time = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      // alert('ok')
      let dateString =
        (String(this.date.getMonth() + 1).length == 1 ?
          "0" + String(this.date.getMonth() + 1) :
          String(this.date.getMonth() + 1)) +
        "/" +
        (String(this.date.getDate()).length == 1 ?
          "0" + String(this.date.getDate()) :
          String(this.date.getDate())) +
        "/" +
        this.date.getFullYear();
      let datetimeString =
        this.date.getFullYear() +
        "-" +
        (String(this.date.getMonth() + 1).length == 1 ?
          "0" + String(this.date.getMonth() + 1) :
          String(this.date.getMonth() + 1)) +
        "-" +
        (String(this.date.getDate()).length == 1 ?
          "0" + String(this.date.getDate()) :
          String(this.date.getDate())) +
        " " +
        this.to24Hr(this.time);
      this.is_loading = "box";
      console.log('datetimeString----', datetimeString)
      let unixTime = moment.tz(datetimeString, this.timezone);
      var companyname = JSON.parse(
        localStorage.getItem("userdetails")
      ).companyname;

      unixTime = unixTime.valueOf() / 1000;
      var body = {
        title: this.room_name,
        oldRoomName: this.room_name,
        companyname: companyname,
        roomName: this.room_name.toLocaleLowerCase(),
        webinarType: "OPEN_ROOM",
        newUser: this.users,
        oneTime: {
          time: unixTime,
          duration: "24:00",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          sendTime: this.time,
          date: dateString,
        },
      };
      console.log(":body--->", body);
      try {
        body = await webinaroneTime(body);

        if (body.response) {
          if (body.response.data.code == 401 || body.response.status == 401) {
            localStorage.clear();
            swal(body.response.data.message);
            this.$router.push("/login");
          }
        }
      } catch (error) {
        console.log(":body--->", error);

      }
      this.getUpdatedUrl();
      this.is_loading = false;
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    durationformateDuration(a) {
      let duration = "";
      if (a.split(":")[0] != "00") {
        duration += parseInt(a.split(":")[0]) + " Hour ";
      }
      if (a.split(":")[1] != "00") {
        duration += parseInt(a.split(":")[1]) + " Min";
      }
      return duration;
    },
    async Create(type) {
      let body = {};
      let host = this.users.find(
        (element) => element.webinarUserType == "Host"
      );

      if (this.webinarType == "ONE_TIME") {
        let dateString =
          (String(this.date.getMonth() + 1).length == 1 ?
            "0" + String(this.date.getMonth() + 1) :
            String(this.date.getMonth() + 1)) +
          "/" +
          (String(this.date.getDate()).length == 1 ?
            "0" + String(this.date.getDate()) :
            String(this.date.getDate())) +
          "/" +
          this.date.getFullYear();
        let datetimeString =
          this.date.getFullYear() +
          "-" +
          (String(this.date.getMonth() + 1).length == 1 ?
            "0" + String(this.date.getMonth() + 1) :
            String(this.date.getMonth() + 1)) +
          "-" +
          (String(this.date.getDate()).length == 1 ?
            "0" + String(this.date.getDate()) :
            String(this.date.getDate())) +
          " " +
          this.to24Hr(this.time);
        console.log('datetimeString-----------', datetimeString);
        console.log('this.timezone-----------', this.timezone);
        let unixTime = moment.tz(datetimeString, this.timezone);
        console.log('unixTime-----------', unixTime);
        console.log('unixTime.valueOf()-----------', unixTime.valueOf());

        if (this.title == "") {
          swal("Please add proper Title");
        } else if (this.users.length == 0) {
          swal("Please add at least one user");
        } else if (this.date == "") {
          swal("Please select date");
        } else if (this.time == "") {
          swal("Please select time");
        } else if (this.duration == "") {
          swal("Please add duration");
        } else if (this.timezone == "") {
          swal("Please select timezone");
        } else if (Date.now() >= unixTime) {
          swal("Please select future date and time");
        } else if (host == undefined) {
          swal("Please add at least one host");
        } else {
          this.loader = true;
          this.disabled = true;
          unixTime = unixTime.valueOf() / 1000;
          var companyname = JSON.parse(
            localStorage.getItem("userdetails")
          ).companyname;

          body = {
            title: this.title,
            companyname: companyname,
            roomName: (Math.random() + 1).toString(15).substring(2),
            webinarType: "ONE_TIME",
            oneTime: {
              time: unixTime,
              duration: this.duration,
              timezone: this.timezone,
              sendTime: this.time,
              date: dateString,
            },
            newUser: this.users,
          };
          console.log(":body--->", body);
          body = await webinaroneTime(body);
          if (body.response) {
            if (body.response.data.code == 401 || body.response.status == 401) {
              localStorage.clear();
              swal(body.response.data.message);
              this.$router.push("/login");
            }
          }
          this.loader = false;
          this.disabled = false;
          window.location.reload();
        }
      } else if (this.webinarType == "MULTI_TIME") {
        if (this.title == "") {
          swal("Please add proper Title");
        } else if (this.users.length == 0) {
          swal("Please add at least one user");
        } else if (this.duration == "") {
          swal("Please add duration");
        } else if (this.webinars.length <= 0) {
          swal("Please add at least one webinar");
        } else if (host == undefined) {
          swal("Please add at least one host");
        } else {
          this.loader = true;
          this.disabled = true;
          var companyname = JSON.parse(
            localStorage.getItem("userdetails")
          ).companyname;

          body = {
            title: this.title,
            companyname: companyname,
            webinarType: "MULTIPLE_BROADCAST",
            roomName: (Math.random() + 1).toString(15).substring(2),
            multipleBroadcasts: this.webinarsTemp,
            newUser: this.users,
          };
          body = await webinarmalti(body);

          if (body?.response) {
            if (body?.response?.data?.code == 401 || body?.response?.status == 401) {
              localStorage.clear();
              swal(body?.response?.data?.message);
              this.$router.push("/login");
            }
          }
          this.loader = false;
          this.disabled = false;
          window.location.reload();
        }
      } else if (this.webinarType == "RECURRING") {
        if (this.title == "") {
          swal("Please add proper Title");
        } else if (this.users.length == 0) {
          swal("Please add at least one user");
        } else if (this.frequency == "") {
          swal("Please select every params");
        } else if (this.timezone == "") {
          swal("Please select timezone");
        } else if (this.recurringWebinar.length <= 0) {
          swal("Please add at least one webinar");
        } else if (host == undefined) {
          swal("Please add at least one host");
        } else {
          this.loader = true;
          this.disabled = true;

          let recurringWebinar = this.webinarsRecurringTemp;
          recurringWebinar.filter((e) => {
            e.time = this.to24Hr(e.time);
          });
          var companyname = JSON.parse(
            localStorage.getItem("userdetails")
          ).companyname;

          body = {
            title: this.title,
            roomName: (Math.random() + 1).toString(15).substring(2),
            webinarType: "RECURRING",
            recurring: recurringWebinar,
            newUser: this.users,
            roomName: "",
          };
          body = await webinarmalti(body);
          if (body?.response) {
            if (body?.response?.data?.code == 401 || body?.response?.status == 401) {
              localStorage.clear();
              swal(body?.response?.data?.message);
              this.$router.push("/login");
            }
          }
          window.location.reload();
          this.loader = false;
          this.disabled = false;
        }
      } else if (this.webinarType == "OPEN_ROOM") {
        var time = new Date();
        this.time = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        let dateString =
          (String(this.date.getMonth() + 1).length == 1 ?
            "0" + String(this.date.getMonth() + 1) :
            String(this.date.getMonth() + 1)) +
          "/" +
          (String(this.date.getDate()).length == 1 ?
            "0" + String(this.date.getDate()) :
            String(this.date.getDate())) +
          "/" +
          this.date.getFullYear();
        let datetimeString =
          this.date.getFullYear() +
          "-" +
          (String(this.date.getMonth() + 1).length == 1 ?
            "0" + String(this.date.getMonth() + 1) :
            String(this.date.getMonth() + 1)) +
          "-" +
          (String(this.date.getDate()).length == 1 ?
            "0" + String(this.date.getDate()) :
            String(this.date.getDate())) +
          " " +
          this.to24Hr(this.time);
        this.loader = true;
        this.is_loading = "box";

        let unixTime = moment.tz(datetimeString, this.timezone);
        var companyname = JSON.parse(
          localStorage.getItem("userdetails")
        ).companyname;
        var Name = JSON.parse(
          localStorage.getItem("userdetails")
        ).Name;
        if (companyname == "" || Name == "") {
          swal("Please edit your profile.");
        }
        unixTime = unixTime.valueOf() / 1000;


        body = {
          title: this.room_name,
          oldRoomName: this.oldRoomName,
          companyname: companyname,
          roomName: this.room_name.toLocaleLowerCase(),
          webinarType: "OPEN_ROOM",
          newUser: this.users,
          oneTime: {
            time: unixTime,
            duration: "24:00",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            sendTime: this.time,
            date: dateString,
          },
        };
        console.log(":body--->", body);
        body = await webinaroneTime(body);
        if (body.response) {
          if (body.response.data.code == 401 || body.response.status == 401) {
            localStorage.clear();
            swal(body.response.data.message);
            this.$router.push("/login");
          }
        }
        if (type != 1) {
          window.location.reload();
        }

        //   this.is_loading = "box";
        // }
        this.getUpdatedUrl();
        this.is_loading = "";
        this.loader = false;


      }
    },
    removeEmail(arr) {
      return arr.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.email == thing.email &&
              t.Name == thing.Name &&
              t.webinarUserType == thing.webinarUserType
          )
      );
    },
    addParticipants() {
      this.users.push({
        Name: "",
        email: this.inviteUser.toLowerCase(),
        firstName: this.invitefirstName ? this.invitefirstName : "UnityWebinar",
        lastName: this.invitelastName ? this.invitelastName : "lastName",
        webinarUserType: "Viewer",
      });

      console.log('this.users_______', this.users);
      this.invitefirstName = "";
      this.invitelastName = "";
      this.inviteUser = "";
      this.is_sucess_loader = false;
      this.is_contact_add_called = false;
      this.is_sucess = false;
      this.is_contact = false;
      this.users = this.users.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.email == thing.email
          )
      );
    },
    async addParticipantFromContact(key) {

      const exits = this.userExists(key.email)
      console.log('exits---------------', exits);
      if (exits == false) {
        this.selected_contact_users.push({
          Name: "",
          email: key.email.toLowerCase(),
          firstName: key.firstName,
          lastName: key.lastName,
          webinarUserType: key.Role,
        });
      } else {
        const newArr = this.selected_contact_users.filter(object => {
          return object.email !== key.email.toLowerCase();
        });
        console.log('newArr----------', newArr)
        this.selected_contact_users = newArr;
      }
      // const indexOfObject = this.selected_contact_users.findIndex(object => {

      //   return object.email === key.email;

      // });
      // this.selected_contact_users = []
    },
    async AddContactUsers() {
      this.users = [...this.users, ...this.selected_contact_users];
      this.users = this.users.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.email == thing.email
          )
      );
      $("#usersContactModal").modal("hide");
      this.selected_contact_users = []
      // $('#exampleModal').show();
    },
    editContact(data) {
      console.log('data&&&&&&&&&&&---', data)

      this.editFirstName = data.firstName;
      this.editUserId = data._id;
      this.editRole = data.Role;
      this.editLastname = data.lastName;
      this.editEmail = data.email;
      this.oldEmail = data.email;
      $("#editContactmodal").modal("show");
    },
    userExists(email) {
      return this.users.some(function (el) {
        return el.email === email;
      });
    },
    async closeContactModal() {
      this.selected_contact_users = []
      $("#usersContactModal").modal("hide");
      // $('#exampleModal').show();
    },
    async contactList() {
      // $('#exampleModal').hide();
      const get_contact = await getMyContacts();
      this.conatctUsers = get_contact.data.result;
      $("#usersContactModal").modal("show");
    },
    checkUrlValid(value) {
      console.log(value);
    },
    copyUrl(value) {
      var copyText = this.url;

      document.getElementById("copyMyUrl").classList.remove("btn-brand");
      document.getElementById("copyMyUrl").classList.add("btn-success");
      document.getElementById("copyMyUrl").innerHTML = "Copied";

      // Select the text field
      // copyText.select();
      // copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText);
      setInterval(function () {
        document.getElementById("copyMyUrl").classList.remove("btn-success");
        document.getElementById("copyMyUrl").classList.add("btn-danger");
        document.getElementById("copyMyUrl").innerHTML = "Copy Link";
      }, 5000);

      // Alert the copied text
    },
    changeWebinarRoomType(webinarRoomType) {
      if (this.webinarRoomType) {
        this.webinarRoomType = "";
      } else {
        this.webinarType = "ONE_TIME";

        this.webinarRoomType = webinarRoomType;
      }

      console.log(this.webinarRoomType);
    },
    async changeWebinarType(webinarType) {
      this.webinarRoomType = "";
      this.webinarType = webinarType;

      if (this.webinarType == "OPEN_ROOM") {
        const userCheckEmail = await userEmail();
        if (userCheckEmail.response) {
          if (userCheckEmail.response.data.code == 401 || userCheckEmail.response.status == 401) {
            localStorage.clear();
            swal(userCheckEmail.response.data.message);
            this.$router.push("/login");
          }
        }
        const lastwebinar = await lastwebinarlist();
        if (lastwebinar.data) {

          if (lastwebinar.data.result.data == null) {
            this.createDefaultUrl();
          }
        }
        // this.disabled = true;
        var companyname = JSON.parse(
          localStorage.getItem("userdetails")
        ).companyname;
        var lastName = JSON.parse(localStorage.getItem("userdetails")).lastName;
        var firstName = JSON.parse(
          localStorage.getItem("userdetails")
        ).firstName;
        console.log("firstName---", firstName);
        console.log("webinarId---", this.webinarId);

        const singlewebinar = await singlewebinardata(this.webinarId);
        console.log("singlewebinardata---", singlewebinar);

        if (singlewebinar.response) {
          if (singlewebinar.response.data.code == 401 || singlewebinar.response.status == 401) {
            localStorage.clear();
            swal(singlewebinar.response.data.message);
            this.$router.push("/login");
          }
        }
        if (singlewebinar.data) {
          if (singlewebinar.data.result) {
            this.users = singlewebinar.data.result.participate;
          }

        }
        if (companyname == undefined) {
          swal("Please Fill your profile.");
        } else {
          // this.Create(1);
        }
      } else {
        this.users = [];
        this.users.push({
          Name: "",
          email: this.currentUserEmail,
          firstName: this.firstName ? this.firstName : "UnityWebinar",
          lastName: this.lastName ? this.lastName : "lastName",
          webinarUserType: "Host",
        });
        this.disabled = false;
      }
      console.log(this.webinarType);
    },
    addMultiWebinar() {
      let datetimeString =
        this.date.getFullYear() +
        "-" +
        (String(this.date.getMonth() + 1).length == 1 ?
          "0" + String(this.date.getMonth() + 1) :
          String(this.date.getMonth() + 1)) +
        "-" +
        (String(this.date.getDate()).length == 1 ?
          "0" + String(this.date.getDate()) :
          String(this.date.getDate())) +
        " " +
        this.to24Hr(this.time);
      let unixTimeStamp = moment.tz(datetimeString, this.timezone);
      unixTimeStamp = unixTimeStamp.valueOf() / 1000;
      let unixTime = this.date;
      unixTime = Date.parse(this.date.toString());
      unixTime = new Date(unixTime).setHours(
        this.to24Hr(this.time).split(":")[0],
        this.to24Hr(this.time).split(":")[1]
      );
      if (this.date == "") {
        swal("Please select date");
      } else if (this.time == "") {
        swal("Please select time");
      } else if (this.timezone == "") {
        swal("Please select timezone");
      } else if (Date.now() >= unixTime) {
        swal("Please select future date and time");
      } else if (this.timezone == "") {
        swal("Please select timezone");
      } else {
        let dateString =
          (String(this.date.getMonth() + 1).length == 1 ?
            "0" + String(this.date.getMonth() + 1) :
            String(this.date.getMonth() + 1)) +
          "/" +
          (String(this.date.getDate()).length == 1 ?
            "0" + String(this.date.getDate()) :
            String(this.date.getDate())) +
          "/" +
          this.date.getFullYear();
        this.webinars.push({
          time: unixTime / 1000,
          duration: this.duration,
          timezone: this.timezone,
          sendTime: this.time,
          date: dateString,
        });

        this.webinarsTemp.push({
          time: unixTimeStamp,
          duration: this.duration,
          timezone: this.timezone,
          sendTime: this.time,
          date: dateString,
        });
      }
    },
    addPeriod(x) {
      console.log(x);
    },
    addMultiRecurring() {
      let dateString =
        (String(this.date.getMonth() + 1).length == 1 ?
          "0" + String(this.date.getMonth() + 1) :
          String(this.date.getMonth() + 1)) +
        "/" +
        (String(this.date.getDate()).length == 1 ?
          "0" + String(this.date.getDate()) :
          String(this.date.getDate())) +
        "/" +
        this.date.getFullYear();
      if (this.frequency == "") {
        swal("Please select every params");
      } else if (this.timezone == "") {
        swal("Please select timezone");
      } else if (this.time == "") {
        swal("Please select time");
      } else {
        if (this.frequency == "day") {
          if (this.time == "") {
            swal("Please select time.");
          } else {
            this.recurringWebinar.push({
              every: this.frequency,
              timezone: this.timezone,
              duration: this.duration,
              time: this.time,
              day: "/",
              for: this.For,
            });
          }
        } else if (this.frequency == "month") {
          if (this.time == "") {
            swal("Please select time.");
          } else if (this.date == "") {
            swal("Please select date.");
          } else {
            this.recurringWebinar.push({
              every: this.frequency,
              timezone: this.timezone,
              time: this.time,
              date: this.date,
              duration: this.duration,
              day: "/",
              for: this.For,
              type: this.For,
            });
            this.webinarsRecurringTemp.push({
              every: this.frequency,
              timezone: this.timezone,
              time: this.time,
              date: this.date,
              duration: this.duration,
              day: "/",
              type: this.For,
            });
          }
        } else if (this.frequency == "week") {
          if (this.time == "") {
            swal("Please select time.");
          } else if (this.date == "") {
            swal("Please select date.");
          } else {
            this.recurringWebinar.push({
              every: this.frequency,
              timezone: this.timezone,
              time: this.time,
              sendTime: this.time,
              date: dateString,
              duration: this.duration,
              date: "/",
              day: this.dayName,
              for: this.For,
              type: this.For,
              // period:this.period,
              occurrence: this.occurrence,
            });
            this.webinarsRecurringTemp.push({
              every: this.frequency,
              timezone: this.timezone,
              time: this.time,
              sendTime: this.time,
              date: dateString,
              duration: this.duration,
              date: "/",
              day: this.dayName,
              type: this.For,
              // period:this.period,
              occurrence: this.occurrence,
            });
          }
        }
      }
      console.log(this.recurringWebinar);
    },
    updateWebinarDetails(webinarDetails) {
      this.title = webinarDetails.title;
      this.duration = webinarDetails.duration;
      this.users = webinarDetails.participate;
    },
    closeModall() {
      window.location.reload();
      // WebinarViewModel
    },
    ampmTo24Hr(time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes;
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
    deleteTableRow: function (idx) {
      this.counter--;
      this.webinars.splice(idx, 1);
      this.webinarsTemp.splice(idx, 1);
    },
    deleterecuring: function (idx) {
      this.counter--;
      this.recurringWebinar.splice(idx, 1);
      this.webinarsRecurringTemp.splice(idx, 1);
    },
  },
};
</script>

<style>
.vue__time-picker input {
  background: #101826 !important;
  color: #fff !important;
  border: 1px solid #2d8cff !important;
  padding: 8.45px 13px !important;
  height: 100% !important;
  border-radius: 4px !important;
}

.url-obj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.box {
  background-image: linear-gradient(to right, #f5f5f5c4 calc(50% - 100px), #fdfdfd05 50%, #b4b4b4 calc(50% + 100px));
  background-size: 0;
  position: relative;
  overflow: hidden;
  border-radius: 0.3rem;
}

.box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: calc(200% + 200px);
  bottom: 0;
  background-image: inherit;
  animation: move 2s linear infinite;
}

@keyframes move {
  to {
    transform: translateX(calc(50% + 100px));
  }
}

.formM {
  display: flex;
  flex-direction: row;
  padding: 1px;
}

.inputM {
  /* Tell the input to use all the available space */
  flex-grow: 2;
  /* And hide the input's outline, so the form looks like the outline */
  border: none;
}

/* remove the input focus blue box, it will be in the wrong place. */

.inputM:focus {
  outline: none;
}

/* Add the focus effect to the form so it contains the button */

.formM:focus-within {}

.buttonM {
  background: rgb(182, 8, 8);
  color: rgb(255, 255, 255);
  font-weight: bold;
  border: 0;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {}</style>
