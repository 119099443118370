<template>
  <div v-if="isOpen" class="modal-overlay">
    <div class="modal-container">
      <div class="modal-header">Upload {{ sideBarName }}</div>
      <div class="modal-body">
        <div v-if="storageWarning" style="padding: 14px; background: #101826; border-radius: 10px" class="mb-2 d-flex">
          <div class="mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" version="1.1"
              width="24px" height="24px" viewBox="0 0 34.854 34.855" xml:space="preserve">
              <g>
                <path
                  d="M34.653,30.47L18.727,2.884c-0.269-0.464-0.764-0.75-1.299-0.75c-0.537,0-1.031,0.286-1.3,0.75L0.202,30.47   c-0.269,0.464-0.269,1.036,0,1.5s0.763,0.75,1.299,0.75h31.853c0.535,0,1.031-0.286,1.3-0.75   C34.921,31.506,34.921,30.934,34.653,30.47z M4.099,29.72L17.427,6.634L30.756,29.72H4.099z M15.427,11.677h4V23.51h-4V11.677z    M15.427,25.507h4v2.919h-4V25.507z" />
              </g>
            </svg>
          </div>
          <div style="display: flex; flex-direction: column; margin-left: 0.5rem">
            <span class="text-white" style="font-size: large">Your storage is almost full {{ getStorageGB }}%</span>
            <span class="text-white">
              Webinar requires storage to maintain your videos, files,
              recordings and more. To continue maintain your data please upgrade
              your storage or free-up some space from media manager.
            </span>
          </div>
        </div>
        <div v-if="!storageFull" class="drop-zone" :class="{ 'drop-zone-active': isDragActive }"
          @dragover.prevent="onDragOver" @dragleave="onDragLeave" @drop.prevent="onDrop">
          <svg width="82" height="70" viewBox="0 0 82 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_4866_696)">
              <path
                d="M68.5193 7V15.0001H37.5117L52.6601 5.31496C52.9818 5.10929 53.3556 5 53.7374 5H66.5193C67.6239 5 68.5193 5.89543 68.5193 7Z"
                fill="#0576FF" />
              <rect x="14" y="11.333" width="54.5189" height="35.6669" rx="2" fill="#2D8CFF" />
            </g>
            <path
              d="M42.236 19.3636C41.8845 19.0121 41.3147 19.0121 40.9632 19.3636L35.2356 25.0912C34.8842 25.4426 34.8842 26.0125 35.2356 26.364C35.5871 26.7154 36.157 26.7154 36.5084 26.364L41.5996 21.2728L46.6908 26.364C47.0423 26.7154 47.6121 26.7154 47.9636 26.364C48.315 26.0125 48.315 25.4426 47.9636 25.0912L42.236 19.3636ZM42.4996 39L42.4996 20L40.6996 20L40.6996 39L42.4996 39Z"
              fill="white" />
            <defs>
              <filter id="filter0_d_4866_696" x="0.9" y="0.9" width="80.7195" height="68.2" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="9" />
                <feGaussianBlur stdDeviation="6.55" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4866_696" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4866_696" result="shape" />
              </filter>
            </defs>
          </svg>

          <div class="drop-zone-text">
            <a href="#" @click.prevent="onClickSelect">Click</a> or drag &amp;
            drop video(s) here
          </div>
          <button class="select-files-btn" @click="onSelectFilesClick">
            Select {{ sideBarName }}
          </button>
        </div>

        <input ref="fileInput" type="file" :accept="sideBarName === 'Videos' ? 'video/*' : '*'" multiple
          style="display: none" @change="handleFileChange" />

        <div class="row mt-4">
          <h4 class="col-4 text-dark bold" v-if="sideBarName === 'Videos' ? videoData.length : fileData.length">
            File Name
          </h4>

          <div class="col-4"></div>
          <h4 class="col-4 text-dark bold" v-if="sideBarName === 'Videos' ? videoData.length : fileData.length">
            Select Folder
          </h4>
        </div>

        <div style="margin-bottom: 10px" class="row" v-if="
          sideBarName === 'Videos'
            ? videoData.length !== 0
            : fileData.length !== 0
        " v-for="(item, index) in sideBarName === 'Videos'
          ? videoData
          : fileData" :key="index">
          <div class="col-4">
            <input type="text" class="form-control" style="
                border-radius: 8px;
                background: #f0f0f0;
                height: 100%;
                padding-left: 11px;
                color: black;
              " v-model="item.name" @change="updateName(index)" placeholder="Edit File Name" />
          </div>
          <div class="col-4" style="
              display: flex;
              background: #f0f0f0;
              padding: 5px;
              border-radius: 8px;
              align-items: center;
            ">
            <svg style="margin-left: 7px" width="23" height="28" viewBox="0 0 23 28" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.8834 0H8.6842H7.97169L7.46802 0.503665L0.503719 7.46802L0 7.97169V8.6842V23.0421C0 25.6032 2.08345 27.687 4.64493 27.687H17.8834C20.4445 27.687 22.5279 25.6032 22.5279 23.0421V4.64487C22.5279 2.08345 20.4445 0 17.8834 0ZM20.8081 23.0421C20.8081 24.6577 19.4987 25.9671 17.8834 25.9671H4.64493C3.02929 25.9671 1.71989 24.6577 1.71989 23.0421V8.68414H6.24698C7.59262 8.68414 8.6842 7.59294 8.6842 6.24693V1.71984H17.8834C19.4986 1.71984 20.808 3.02924 20.808 4.64487L20.8081 23.0421Z"
                fill="#9CA3AF" />
              <path
                d="M9.76182 12.3926C9.7237 12.3707 9.67763 12.3696 9.6395 12.3907C9.601 12.4119 9.5791 12.4519 9.5791 12.4961V15.5638V18.6327C9.5791 18.6769 9.601 18.7162 9.6395 18.737C9.67763 18.7593 9.7237 18.7581 9.76182 18.737L14.9013 15.6673C14.9379 15.6454 14.9598 15.6061 14.9598 15.5638C14.9598 15.5219 14.938 15.4815 14.9013 15.4607L9.76182 12.3926Z"
                fill="#9CA3AF" />
            </svg>
            <div style="
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                width: -webkit-fill-available;
              ">

              <div style="display: flex; width: 100%; justify-content: space-between; align-items: center;">
                <span style="font-weight: bold; color: black">
                  {{ shortenFilename(item.name, 10, 2) }}.{{ item.ext }}
                </span>
                <div style="display: flex; gap: 0.5rem; align-items: center;">
                  <Pause @click="pauseUpload(item.id)" v-if="item.progress > 0 && !item.error && !item.paused" />
                  <Play @click="resumeUpload(item)" v-if="item.paused && !item.error" />

                  <span v-on:click="removeFileFromArray(item)" style="cursor: pointer;">
                    X
                  </span>
                </div>
              </div>

              <span style="color: #8c929d; font-size: x-small">{{
                item.remainingFileSize == 0
                  ? "0.0"
                  : item.remainingFileSize
              }}
                / {{ item.fileBytesSize }}
                <span v-if="!item.paused">{{
                  item.remainingTime == 0
                    ? ""
                    : " • " + item.remainingTime + " remaining"
                }}
                  {{
                    item.uploadingSpeed == 0
                      ? ""
                      : " • " + item.uploadingSpeed
                  }}</span>
                <span v-if="item.paused">{{ " • " + "Paused" }}</span>
                <span v-if="item.error">{{ " • " + "An error occurred" }}</span>
              </span>

              <div style="display: flex; align-items: center">
                <div id="myProgress" class="mt-1">
                  <div :style="`transition: all ease-out 0.5s; width: ${item.progress}%`" id="myBar"></div>
                </div>

                <span style="margin-left: 8px"> {{ item.progress }}%</span>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="dropdown d-flex selectFolder">
              <div class="folder">
                <svg style="
                    margin-left: 16px;
                    margin-bottom: 4px;
                    margin-right: 8px;
                    transform: scaleX(-1);
                  " data-v-519fcb06="" class="trrr" data-v-4bef2702="" xmlns="http://www.w3.org/2000/svg" width="25"
                  height="21" viewBox="-4 -4 258 205" fill="none">
                  <path data-v-519fcb06="" data-v-4bef2702="" width="249.331" height="166.74"
                    d="M243.658 6.36523V47.1148H101.851L171.886 1.34275C172.862 0.704915 174.002 0.365234 175.168 0.365234H237.658C240.971 0.365234 243.658 3.05152 243.658 6.36523Z"
                    fill="#0576FF"></path>
                  <rect data-v-519fcb06="" class="trrrect" fill="#2D8CFF" data-v-4bef2702="" x="2" y="29.9736"
                    width="241.5" height="166.74" rx="6"></rect>
                </svg>

                <span class="folder-text">{{ item.folderName }}</span>
                <div class="">
                  <a href="javascript:void(0)" data-toggle="dropdown">
                    <svg class="dropBtn" width="15" height="8" viewBox="0 0 15 8" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.2589 0H1.80777C0.911553 0 0.46783 1.08812 1.10847 1.71484L6.83401 7.31591C7.22268 7.69613 7.84393 7.69613 8.2326 7.31591L13.9581 1.71483C14.5988 1.08812 14.1551 0 13.2589 0Z"
                        fill="#2D8CFF" />
                    </svg>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right myLs" style="
                      background: #ffffff;
                      padding: 10px 0px;
                      border-radius: 10px;
                      min-width: 100%;
                    ">
                    <div v-for="(item, i) in folders" :key="index" :value="item.name" class="justify-content-center">
                      <a href="#" class="editUpload" v-on:click="updateRole(index, i)">
                        <svg style="margin-right: 10px; transform: scaleX(-1)" data-v-519fcb06="" class="trrr"
                          data-v-4bef2702="" xmlns="http://www.w3.org/2000/svg" width="20" height="16"
                          viewBox="-4 -4 258 205" fill="none">
                          <path data-v-519fcb06="" data-v-4bef2702="" width="249.331" height="166.74"
                            d="M243.658 6.36523V47.1148H101.851L171.886 1.34275C172.862 0.704915 174.002 0.365234 175.168 0.365234H237.658C240.971 0.365234 243.658 3.05152 243.658 6.36523Z"
                            fill="#0576FF"></path>
                          <rect data-v-519fcb06="" class="trrrect" fill="#2D8CFF" data-v-4bef2702="" x="2" y="29.9736"
                            width="241.5" height="166.74" rx="6"></rect>
                        </svg>

                        {{ item.name }}</a>
                      <hr v-if="i + 1 != folders.length" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!storageFull" class="upload-controls">
          <button class="discard-btn" @click="onDiscard">Discard</button>
          <button :disabled="fileLoader" class="send-btn" @click="onSend">
            <span v-if="fileLoader" class="spinner spinner-white spinner-right"></span>
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pause from "@/Icons/Pause.vue";
import Play from "@/Icons/Play.vue";
import { getMyFolder, initiateMultipartUpload, getMultipartUploadUrl, completeMultipartUpload, abortMultipartUpload, deleteFile } from "@/services/service";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "FilesUpload",
  components: {
    Pause,
    Play,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    sideBarName: {
      type: String,
      default: "",
    },
    selectedFolder: {
      type: Object,
      default: undefined,
    },
    socket: {
      type: Object,
      default: null
    }
  },
  emits: ["update:isOpen", "uploadCompleted"],
  data() {
    return {
      isDragActive: false,
      filesData: [],
      s3: null,
      fileLoader: false,
      videoData: [],
      fileData: [],
      folders: [],
      currentFolderSelected: null,
      BASE_URL:
        window.location.host == "unitywebinar.com"
          ? "https://api.unitywebinar.com/"
          : window.location.host == "demo.unitywebinar.com"
            ? "https://record.unitywebinar.com/"
            : window.location.host == "dev.unitywebinar.com"
              ? "https://dev-api.unitywebinar.com/"
              : "https://dev-api.unitywebinar.com/",
      videoExtensions: ["mp4", "avi", "mov", "mkv", "webm", "flv", "wmv"],
      restrictedExtensions: [
        "exe",
        "bat",
        "sh",
        "cmd",
        "com",
        "msi",
        "js",
        "vbs",
        "scr",
        "pif",
        "jar",
        "wsf",
        "cpl",
        "hta",
        "vb",
        "ps1",
        "reg",
        "lnk",
      ],
      fromDrop: false,
      storageFull: false,
    };
  },
  watch: {
    videoData: {
      async handler(newMedia) {
        if (newMedia?.length === 0) return;
        if (newMedia?.every(media => media?.uploaded)) {
          this.videoData = [];
          this.fileLoader = false;

          this.$emit("update:isOpen", false);
          this.uploadProgress = [];
          this.$emit("uploadCompleted");
          console.log("**************uploaded****************");
        }
      },
      deep: true
    },
    fileData: {
      async handler(newFiles) {
        if (newFiles?.length === 0) return;
        if (newFiles?.every(file => file?.uploaded)) {
          this.fileData = [];
          this.fileLoader = false;

          this.$emit("update:isOpen", false);
          this.uploadProgress = [];
          this.$emit("uploadCompleted");
          console.log("**************uploaded****************");
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["getStorageGB"]),

    storageWarning() {
      console.log("this.getStorageGB----------", this.getStorageGB);
      if (this.getStorageGB === 100) {
        this.storageFull = true
      } else {
        this.storageFull = false
      }
      return this.getStorageGB > 90;
    },
  },
  async created() {
    if (localStorage.getItem("userdetails") !== null) {
      await this.loadData();
    }
  },
  methods: {
    onDragOver() {
      this.isDragActive = true;
    },
    onDragLeave() {
      this.isDragActive = false;
      this.fromDrop = true;
    },
    onDrop(e) {
      this.fromDrop = true;
      this.isDragActive = false;
      if (this.sideBarName === "Videos") {
        this.onFileChange(e.dataTransfer.files);
      } else {
        this.onDocsChange(e.dataTransfer.files);
      }
    },
    onClickSelect() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.click();
      }
    },
    onSelectFilesClick() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.click();
      }
    },
    handleFileChange(event) {
      this.fromDrop = false;
      if (this.sideBarName === "Videos") {
        this.onFileChange(event);
      } else {
        this.onDocsChange(event);
      }
    },
    onFileChange(e) {
      let files;
      if (this.fromDrop) {
        files = [...e];
      } else {
        files = [...e.target.files];
      }

      let filteredFiles = files.filter((file) => {
        let fileExt = file.name.split(".").pop().toLowerCase();
        let isVideo = file.type.startsWith("video/");
        let isRestricted = this.restrictedExtensions.includes(fileExt);

        if (!isVideo) {
          console.warn(`Blocked file (not a video): ${file.name}`);
          return false;
        }

        if (isRestricted) {
          console.warn(`Blocked file (restricted extension): ${file.name}`);
          return false;
        }

        return true;
      });

      if (filteredFiles.length === 0) {
        alert("Invalid file type. Please upload only allowed video files.");
        this.$refs.fileInput.value = null;
        return;
      }
      this.uploadFiles(filteredFiles);
      this.$refs.fileInput.value = null;
    },
    updateInfos(file, hours, minutes, seconds, id) {
      console.log("file--------", file);
      console.log("hours--------", hours);
      console.log("minutes--------", minutes);
      console.log("seconds--------", seconds);
      console.log("id--------", id);
      const firstSpaceIndex = file.name.split(".")[0].indexOf(" ");
      const firstName = file.name.slice(0, firstSpaceIndex);
      const firstSpaceID = this.makeId(10);
      this.videoData.unshift({
        file: file,
        folderName: this.selectedFolder.name,
        folderId: this.selectedFolder._id,
        id: firstSpaceID + "" + file.size + "." + file.name.split(".")[1],
        fileBytesSize: this.bytesToSize(file.size),
        fileOriginalSize: file.size,
        progress: 0,
        remainingFileSize: 0,
        remainingTime: 0,
        uploadedBytes: 0,
        uploadingSpeed: 0,
        error: null,
        ext: file.name.split(".")[1],
        name: file.name.split(".")[0],
        duration:
          hours != 0
            ? +hours + " hours " + minutes + " mins " + seconds + " sec"
            : minutes != 0
              ? +minutes + " mins " + seconds + " sec"
              : +seconds + " sec",
        uploadId: null,
        currentPart: 1,
        uploadedParts: [],
        cancelSource: null,
        uploaded: false,
        paused: false,
      });
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "n/a";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i == 0) return bytes + " " + sizes[i];
      return (
        parseInt((bytes / Math.pow(1024, i)).toFixed(1), 10) + " " + sizes[i]
      );
    },
    onDocsChange(event) {
      // let files = [...event.target.files];
      let files;
      if (this.fromDrop) {
        files = [...event];
      } else {
        files = [...event.target.files];
      }
      let filteredFiles = files.filter((file) => {
        let fileExt = file.name.split(".").pop().toLowerCase();
        let isVideo = file.type.startsWith("video/");
        let isRestricted = this.restrictedExtensions.includes(fileExt);

        if (isVideo || isRestricted) {
          console.warn(`Blocked file: ${file.name}`);
          return false;
        }
        return true;
      });

      if (filteredFiles.length === 0) {
        alert("Invalid file type. Please upload only allowed files.");
        this.$refs.fileInput.value = null;
        return;
      }

      console.log("filteredFiles---------", filteredFiles);

      this.processFiles(filteredFiles);
      this.$refs.fileInput.value = null;
    },
    processFiles(files) {
      files.forEach((file) => {
        let fileExt = file.name.split(".").pop().toLowerCase(); // Ensure lowercase extension

        if (this.restrictedExtensions.includes(fileExt)) {
          alert(`The file "${file.name}" is not allowed.`);
          return;
        }
        let id = this.generateId(10) + "" + file.size + "." + fileExt;
        this.fileData.unshift({
          id,
          file,
          folderName: this.selectedFolder.name,
          folderId: this.selectedFolder._id,
          fileBytesSize: this.bytesToSize(file.size),
          fileOriginalSize: file.size,
          progress: 0,
          remainingFileSize: 0,
          remainingTime: 0,
          uploadedBytes: 0,
          uploadingSpeed: 0,
          error: null,
          ext: fileExt,
          name: file.name.split(".")[0],
          progress: 0,
          uploadId: null,
          currentPart: 1,
          uploadedParts: [],
          cancelSource: null,
          uploaded: false,
          paused: false,
        });
      });
    },
    generateId(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    },
    async uploadFiles(files) {
      files.forEach((file, index) => {
        if (!file) return;

        var video = document.createElement("video");
        video.preload = "metadata";
        console.log("video--------", video, file);

        video.onloadedmetadata = async () => {
          window.URL.revokeObjectURL(video.src);
          var duration = video.duration;

          var hours = Math.floor(duration / 3600);
          var minutes = Math.floor((duration % 3600) / 60);
          var seconds = Math.floor(duration % 60);
          var id = this.makeId(10) + "" + index;

          try {
            this.updateInfos(file, hours, minutes, seconds, id);
          } catch (err) {
            console.log("err----", err);
          }
        };

        video.src = URL.createObjectURL(file);
      });
      console.log("this.videoData----", this.videoData);
    },
    makeId(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    onDiscard() {
      this.videoData = [];
      this.fileData = [];
      if (this.$refs.fileInput) {
        this.$refs.fileInput = "";
      }
      this.$emit("update:isOpen", false);
    },
    updateRole(index, folderDestIndex) {
      if (this.sideBarName === "Videos") {
        var fileData = this.videoData[index];
        console.log("------------", fileData.folderId);

        var fileData1 = this.folders[folderDestIndex];
        console.log("------------", fileData1._id);
        // console.log('------------', this.name);
        if (index >= 0 && index < this.videoData.length) {
          this.videoData[index].folderName = fileData1.name;
          this.videoData[index].folderId = fileData1._id;
        }
        console.log("------------", this.videoData);
      } else {
        var fileData = this.fileData[index];
        console.log("------------", fileData.folderId);

        var fileData1 = this.folders[folderDestIndex];
        console.log("------------", fileData1._id);
        // console.log('------------', this.name);
        if (index >= 0 && index < this.fileData.length) {
          this.fileData[index].folderName = fileData1.name;
          this.fileData[index].folderId = fileData1._id;
        }
        console.log("fileData------------", this.fileData);
      }
    },
    shortenFilename(str, startLength, endLength) {
      // Find the last dot to separate the name from the extension
      if (str.length <= startLength + endLength + 3) {
        return str;
      }

      const start = str.substring(0, startLength);
      const end = str.substring(str.length - endLength);
      console.log(`${start}...${end}`);

      return `${start}...${end}`;
    },
    updateName(index) {
      console.log("------------", this.videoData[index].file);
      if (this.sideBarName === "Videos") {
        if (index >= 0 && index < this.videoData.length) {
          console.log("------------", this.videoData[index]);
          this.videoData[index].error = this.name;
        }
      } else {
        if (index >= 0 && index < this.fileData.length) {
          console.log("------------", this.fileData[index]);
          this.fileData[index].error = this.name;
        }
      }
    },
    pauseUploading(targetId) {
      if (this.sideBarName === "Videos") {
        const index = this.videoData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.videoData.length) {
          this.videoData[index].uploadPaused = true;
        }
      } else {
        const index = this.fileData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.fileData.length) {
          this.fileData[index].uploadPaused = true;
        }
      }
    },
    resumeUploading(targetId) {
      if (this.sideBarName === "Videos") {
        const index = this.videoData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.videoData.length) {
          this.videoData[index].uploadPaused = false;
        }
      } else {
        const index = this.fileData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.fileData.length) {
          this.fileData[index].uploadPaused = false;
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.round(Math.floor(Math.log(bytes) / Math.log(k)));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    updateProcess(targetId, value) {
      if (this.sideBarName === "Videos") {
        const index = this.videoData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.videoData.length) {
          this.videoData[index].progress = value;
        }
      } else {
        const index = this.fileData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.fileData.length) {
          this.fileData[index].progress = value;
        }
      }
    },
    updateRemainingTime(targetId, value) {
      if (this.sideBarName === "Videos") {
        const index = this.videoData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.videoData.length) {
          const totalSeconds = value;
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = totalSeconds % 60;

          let formattedTime = "";
          if (hours > 0) {
            formattedTime += `${hours}hours `;
          }
          if (minutes > 0) {
            formattedTime += `${minutes}mins `;
          }
          formattedTime += `${seconds}sec `;

          this.videoData[index].remainingTime = formattedTime;
        }
      } else {
        const index = this.fileData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.fileData.length) {
          const totalSeconds = value;
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = totalSeconds % 60;

          let formattedTime = "";
          if (hours > 0) {
            formattedTime += `${hours}hours `;
          }
          if (minutes > 0) {
            formattedTime += `${minutes}mins `;
          }
          formattedTime += `${seconds}sec `;

          this.fileData[index].remainingTime = formattedTime;
        }
      }
    },
    updateRemainingFileBytes(targetId, value) {
      if (this.sideBarName === "Videos") {
        const index = this.videoData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.videoData.length) {
          this.videoData[index].uploadedBytes = value;
        }
      } else {
        const index = this.fileData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.fileData.length) {
          this.fileData[index].uploadedBytes = value;
        }
      }
    },
    updateRemainingFileSize(targetId, value) {
      if (this.sideBarName === "Videos") {
        const index = this.videoData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.videoData.length) {
          this.videoData[index].remainingFileSize = this.formatBytes(value, 2);
        }
      } else {
        const index = this.fileData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.fileData.length) {
          this.fileData[index].remainingFileSize = this.formatBytes(value, 2);
        }
      }
    },
    updateUploadingSpeed(targetId, value) {
      if (this.sideBarName === "Videos") {
        const index = this.videoData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.videoData.length) {
          this.videoData[index].uploadingSpeed = value;
        }
      } else {
        const index = this.fileData.findIndex((obj) => obj.id === targetId);
        if (index >= 0 && index < this.fileData.length) {
          this.fileData[index].uploadingSpeed = value;
        }
      }
    },
    async onSend() {
      const promises = [];
      this.fileLoader = true;
      if (this.sideBarName === "Videos") {
        if (this.videoData.length === 0) {
          alert("Please select a video first!");
          this.fileLoader = false;
          return;
        }

        console.log("UPLOAD VIDEOS FILES-------------", this.videoData);
        this.videoData.forEach((media) => {
          promises.push(this.startUpload(media));
        });
        await Promise.all(promises);
        // console.log("results video uploading-----------", results);
      } else {
        if (this.fileData.length === 0) {
          alert("Please select a file first!");
          this.fileLoader = false;
          return;
        }

        this.fileData.forEach((media) => {
          promises.push(this.startUpload(media));
        });
        await Promise.all(promises);
      }
    },
    async startUpload(media) {
      const id = media.id;
      const CHUNK_SIZE = 64 * 1024 * 1024 //64 MB Chunk Size;
      const fileSize = media.file.size;
      const totalParts = Math.ceil(fileSize / CHUNK_SIZE);

      if (!media?.uploadId) {
        this.updateFileProperty(id, "startTime", Date.now());
        const { data: initiateData } = await initiateMultipartUpload({
          fileSize,
          fileName: id,
          fileType: media.ext,
          socketId: this.socket?.id
        });
        this.updateFileProperty(id, "uploadId", initiateData?.result.uploadId);
      }

      while (media.currentPart <= totalParts && !media?.paused) {
        const start = (media.currentPart - 1) * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, fileSize);
        const chunk = media?.file?.slice(start, end);

        const { data: urlData } = await getMultipartUploadUrl({
          uploadId: media?.uploadId,
          name: id,
          partNumber: media?.currentPart,
        });

        const { url } = urlData?.result;
        this.updateFileProperty(id, "cancelSource", axios.CancelToken.source());

        try {
          const { headers } = await axios.put(url, chunk, {
            headers: { "Content-Type": media.ext },
            cancelToken: media.cancelSource.token,
            onUploadProgress: (progressEvent) => {
              const uploadedBytes = start + progressEvent.loaded;
              const uploadProgress = Math.round((uploadedBytes * 100) / fileSize);
              const elapsedTime = (Date.now() - media.startTime) / 1000;
              const uploadSpeed = progressEvent.loaded / elapsedTime;
              const remainingBytes = fileSize - uploadedBytes;
              const remainingTime = remainingBytes / uploadSpeed;

              this.updateFileProperty(id, "uploadedBytes", uploadedBytes);
              this.updateProcess(id, uploadProgress);
              this.updateRemainingTime(id, Math.round(remainingTime));
              this.updateRemainingFileSize(id, uploadedBytes);
              this.updateRemainingFileBytes(id, uploadedBytes);
              this.updateUploadingSpeed(id, uploadSpeed > 0 ? `${this.formatBytes(uploadSpeed, 2)}/s` : "");
            },
          });

          this.addUploadedPart(id, {
            ETag: headers.etag.replace(/"/g, ""),
            PartNumber: media.currentPart,
          });

          this.incrementPart(id);
        } catch (error) {
          if (axios.isCancel(error)) return;
          console.log(error, 'error');
          this.updateFileProperty(id, 'error', error?.message || "An error occurred")
        }
      }

      if (media?.currentPart > totalParts) {
        try {
          await completeMultipartUpload({ uploadId: media.uploadId, name: id, parts: media.uploadedParts });
          await this.finalizeUpload(media);
          this.updateFileProperty(id, 'uploaded', true)
        }
        catch (err) {
          console.log(err, 'error');
          this.updateFileProperty(id, 'error', err?.message || "An error occurred")
        }
      }
      else {
        this.updateFileProperty(id, 'error', "An error occurred")
      }
    },

    pauseUpload(id) {
      let fileObj = this.videoData.find((f) => f.id === id);
      if (!fileObj) {
        fileObj = this.fileData.find((f) => f.id === id)
      }
      if (fileObj) {
        this.updateFileProperty(id, "paused", true);
        if (fileObj.cancelSource) fileObj.cancelSource.cancel();
      }
    },

    async resumeUpload(media) {
      let fileObj = this.videoData.find((f) => f.id === media.id);

      if (!fileObj) {
        fileObj = this.fileData.find((f) => f.id === media.id)
      }

      if (fileObj && fileObj.paused) {
        this.updateFileProperty(media.id, "paused", false);
        this.updateFileProperty(media.id, "cancelSource", null);
        const uploadedBytes = (fileObj.currentPart - 1) * (64 * 1024 * 1024);
        this.updateFileProperty(media.id, "uploadedBytes", uploadedBytes);
        this.updateProcess(media.id, Math.round((uploadedBytes * 100) / media.file.size));
        this.startUpload(media);
      }
    },

    updateFileProperty(id, key, value) {
      const mediaIdx = this.videoData.findIndex((f) => f.id === id);
      if (mediaIdx !== -1) this.videoData[mediaIdx][key] = value;

      const fileIdx = this.fileData.findIndex((f) => f.id === id);
      if (fileIdx !== -1) this.fileData[fileIdx][key] = value;
    },

    addUploadedPart(id, part) {
      const mediaIdx = this.videoData.findIndex((f) => f.id === id);
      if (mediaIdx !== -1) this.videoData[mediaIdx].uploadedParts.push(part);

      const fileIdx = this.fileData.findIndex((f) => f.id === id);
      if (fileIdx !== -1) this.fileData[fileIdx].uploadedParts.push(part);
    },

    incrementPart(id) {
      const mediaIdx = this.videoData.findIndex((f) => f.id === id);
      if (mediaIdx !== -1) this.videoData[mediaIdx].currentPart += 1;

      const fileIdx = this.fileData.findIndex((f) => f.id === id);
      if (fileIdx !== -1) this.fileData[fileIdx].currentPart += 1;
    },

    async finalizeUpload(media) {
      const body = {
        name: media.name || media.originalname,
        notes: media.notes || " ",
        fileSource: media.id,
        fileSize: media.fileOriginalSize,
        fileOriginalSize: media.fileOriginalSize,
        fileBytesSize: media.fileBytesSize,
        ext: media.ext,
        duration: media.duration,
        folderId: media.folderId,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      };
      await axios.post(this.BASE_URL + "api/users/v1/uploadVideo", body, { headers }).catch(console.log);
    }
    ,
    async removeFileFromArray(item) {
      if (this.sideBarName !== "Videos") {
        const filteredData = this.fileData.filter(
          (md) => md?.id !== item.id
        );
        this.fileData = filteredData;

        if (!this?.fileData?.length) {
          this.fileLoader = false
        }
      } else {
        const filteredData = this.videoData.filter((md) => md?.id !== item.id);
        this.videoData = filteredData;

        if (!this?.videoData?.length) {
          this.fileLoader = false
        }
      }

      if (!item?.uploaded) {
        item?.cancelSource?.cancel()
        abortMultipartUpload({
          fileNames: [item?.id],
          socketId: this.socket?.id
        })
      }
      else {
        deleteFile({ attachments: [item?.id] })
      }

    },
    async asyncOperation(media) {
      const params = {
        Bucket: "demo-bucket",
        Key: media.id,
        Expires: 60,
      };
      try {
        var id = media.id;
        const startTime = Date.now();
        const url = this.s3.getSignedUrl("putObject", params);
        console.log("url----------", url);

        const response = await axios.put(url, media.file, {
          headers: {
            "Content-Type": media.ext,
          },
          onUploadProgress: (progressEvent) => {
            const uploadProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.updateProcess(id, uploadProgress);
            const currentTime = Date.now();
            const elapsedTime = (currentTime - startTime) / 1000; // in seconds
            const uploadSpeed = progressEvent.loaded / elapsedTime; // bytes per second
            const remainingBytes = progressEvent.total - progressEvent.loaded;
            const remainingTime = remainingBytes / uploadSpeed; // in seconds
            this.updateRemainingTime(id, Math.round(remainingTime));
            this.updateRemainingFileSize(id, progressEvent.loaded);
            this.updateRemainingFileBytes(id, progressEvent.loaded);

            this.updateUploadingSpeed(
              id,
              uploadSpeed > 0 ? `${this.formatBytes(uploadSpeed, 2)}/s` : ""
            );
          },
        });

        var body = {
          name: media.name ? media.name : media.originalname,
          notes: media.notes ? media.notes : " ",
          fileSource: media.id,
          fileSize: media.fileOriginalSize,
          fileOriginalSize: media.fileOriginalSize,
          fileBytesSize: media.fileBytesSize,
          ext: media.ext,
          duration: media.duration,
          folderId: media.folderId,
        };
        const headers = {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("user"),
        };
        axios
          .post(this.BASE_URL + "api/users/v1/uploadVideo", body, {
            headers: headers,
          })
          .then(() => { })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    async loadData() {
      console.log("getMyFolders---22----");
      const getMyFolders = await getMyFolder();

      console.log("getMyFolders---22----", getMyFolders);
      if (getMyFolders.response) {
        if (
          getMyFolders.response.data.code == 401 ||
          getMyFolders.response.status == 401
        ) {
          localStorage.clear();
          swal(getMyFolders.response.data.message);
          this.$router.push("/login");
        }
      } else {
        this.folders = getMyFolders.data.result;
        this.currentFolderSelected = getMyFolders.data.result[0];
      }
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}

#myProgress {
  width: 100%;
  background-color: white;
}

#myBar {
  height: 4px;
  background-color: #00b67a;
}

.closeBtn {
  font-weight: bold;
  color: red;
  position: absolute;
  right: -4px;
  top: -7px;
  background: white;
  padding: 0px 6px;
  border-radius: 56px;
  text-align: center;
  box-shadow: 1px 1px 4px #0000003d;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.folder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.folder img {
  margin-right: 8px;
  margin-left: 12px;
}

.dropBtn {
  position: absolute;
  right: 17px;
  cursor: pointer;
  top: 45%;
}

.folder-text {
  margin-right: 8px;
}

.myLs {
  transform: translate3d(0px, 64px, 0px) !important;
  -webkit-box-shadow: 4 17px 25px 2px #9e9e9e;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 15px 13px -5px #9e9e9e;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  max-height: 15rem;
  overflow-y: scroll;
}

.selectFolder {
  color: black !important;
  background: #f0f0f0 !important;
  border: none !important;
  height: 100%;
  border-radius: 8px;
  font-weight: bold;
}

.editUpload {
  color: black;
  font-weight: 500;
  display: flex;
  padding-left: 18px;
  align-items: flex-start;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-container {
  width: min(800px, 80%);
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-header {
  padding: 20px;
  font-size: 18px;
  font-weight: 700;
  color: #333;
  border-bottom: 1px solid #e2e2e2;
}

.modal-body {
  padding: 20px;
}

.drop-zone {
  border: 2px dashed #d3d3d3;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.drop-zone-active {
  border-color: #999999;
}

.drop-zone-text {
  color: #666666;
  margin-bottom: 10px;
}

.select-files-btn {
  padding: 8px 16px;
  background: white;
  color: #374151c2;
  border: 1px solid #374151c2;
  border-radius: 9px;
  cursor: pointer;
  font-weight: 600;
}

.file-list {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.file-item {
  background: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 12px;
  border: 1px solid #e2e2e2;
}

.file-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.file-name {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.file-meta {
  font-size: 12px;
  color: #666;
}

.file-progress {
  display: flex;
  align-items: center;
  gap: 8px;
}

.progress-bar-container {
  background: #e7e7e7;
  border-radius: 4px;
  height: 6px;
  flex: 1;
  overflow: hidden;
}

.progress-bar {
  height: 6px;
  background: #4caf50;
  width: 0%;
}

.x-btn {
  background: transparent;
  color: #666;
  border: none;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
}

.upload-controls {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 5%;
}

.discard-btn {
  padding: 8px 45px;
  background: white;
  color: #374151c2;
  border: 1px solid #374151c2;
  border-radius: 9px;
  cursor: pointer;
  font-weight: 600;
}

.send-btn {
  position: relative;
  background: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 50px;
  border-radius: 11px;
  cursor: pointer;
  font-weight: 600;
}

.send-btn:disabled {
  background: #cccccc;
  color: #b1b0b0;
  cursor: not-allowed;
  pointer-events: none;
}

.error-text {
  color: red;
}

a {
  text-decoration: underline;
}
</style>
