import { createApp } from 'vue';

import App from './App.vue';
import store from './store';
// import vuex from "vuex";
import router from "./router/index";
import 'bootstrap'


// createApp(App).use(store).use(vuex).use(router).mount('#app')
createApp(App).use(store).use(router).mount("#app");
