// import createPersistedState from "vuex-persistedstate";

// export default ({
//   state: {
//     user: null,
//     posts: null,
//     storageGB: 0,
//   },
//   getters: {
//     isAuthenticated: state => !!state.user,    
//     StatePosts: state => state.posts,
//     StateUser: state => state.user,
//     getStorageGB: (state) => state.storageGB,
//   },
//   mutations: {
//     setUser(state, username){
//       state.user = username
//   },
//   setPosts(state, posts){
//       state.posts = posts
//   },
//   LogOut(state){
//       state.user = null
//       state.posts = null
//   },
//     setStorageGB(state, storage) {
//       state.storageGB = storage;
//     },
//   },
//   actions: {
//     async LogIn({commit}, token) {
//       await commit('setUser', token)
//     },
//     async LogOut({commit}){
//       let user = null
//       commit('logout', user)
//     },
//     async updateStorageGB({ commit }, storage) {
//       commit("setStorageGB", storage);
//     },
//   },
//   modules: {
//     plugins: [createPersistedState()]
//   }
// })

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  state: {
    user: null,
    posts: null,
    storageGB: 0,
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
    StatePosts: (state) => state.posts,
    StateUser: (state) => state.user,
    getStorageGB: (state) => state.storageGB,
  },
  mutations: {
    setUser(state, username) {
      state.user = username;
    },
    setPosts(state, posts) {
      state.posts = posts;
    },
    LogOut(state) {
      state.user = null;
      state.posts = null;
    },
    setStorageGB(state, storage) {
      state.storageGB = storage;
    },
  },
  actions: {
    async LogIn({ commit }, token) {
      commit("setUser", token);
    },
    async LogOut({ commit }) {
      commit("LogOut"); // Correct mutation name
    },
    async updateStorageGB({ commit }, storage) {
      commit("setStorageGB", storage);
    },
  },
  plugins: [createPersistedState()], // <-- Move plugins here
});

export default store;
