<template>
    <div
      id="main_div"
      class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading"
    >
      <div
        style="z-index: 9999"
        id="kt_header_mobile"
        class="kt-header-mobile kt-header-mobile--fixed"
      >
        <div class="kt-header-mobile__logo">
          <a href="#">
            <img
              alt="Logo"
              src="../../assets/media/uwLogo.png"
              width="40"
              height="40"
            />
          </a>
        </div>
        <div class="kt-header-mobile__toolbar">
          <button
            class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
            id="kt_aside_mobile_toggler"
          >
            <span></span>
          </button>
          <button
            class="kt-header-mobile__toolbar-toggler"
            id="kt_header_mobile_toggler"
          >
            <span></span>
          </button>
        </div>
      </div>
      <!-- end:: Header Mobile -->
      <div
        class="kt-grid kt-grid--hor kt-grid--root fullpage"
        style="height: 1oovh"
      >
        <div
          class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
        >
          <div
            class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper responsiv__space"
            id="kt_wrapper"
            style="padding: 0px !important"
          >
            <!-- begin:: Header -->
            <!-- end:: Header -->
            <div
              class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor white-background"
              id="kt_content"
              style="
                padding-top: 0;
                padding-bottom: 0;
                overflow: hidden;
                position: relative;
                justify-content: flex-start;
                margin-top: 3rem;
                flex: none;
                padding: 10px;
              "
            >
              <!-- begin:: Content -->
              <div
                style="flex: none"
                class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
              >
                <div class="loading" id="loading">Loading&#8230;</div>
                <div class="Scenary" style="height: calc(100vh - 300px) !important">
                  <div class="Conference" id="mainCont">
                    <div
                      class="Screen"
                      id="Screen"
                      style="align-items: center; position: relative; width: 100% !important; flex: 1.5 !important"
                    >
                      <video
                        v-if="
                          mainScreenStreamRef != null &&
                          !mainScreenStreamRef.fileShare &&
                          !mainScreenStreamRef.audioShare &&
                          !mainScreenStreamRef.audioShare &&
                          typeof mainScreenStreamRef.mediaStream == 'object' &&
                          mainScreenStreamRef.screenShare &&
                          !mainScreenStreamRef.docShare
                        "
                        :id="
                          mainScreenStreamRef.storageId + '_SHARE_SCREEN_VIDEO'
                        "
                        muted
                        autoPlay
                        playsInline
                        width="640"
                        height="480"
                        :srcObject.prop="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.mediaStream
                        "
                      ></video>
  
                      <div
                        v-if="
                          mainScreenStreamRef != null &&
                          !mainScreenStreamRef.screenShare &&
                          !mainScreenStreamRef.mutedVideo &&
                          !mainScreenStreamRef.fileShare &&
                          typeof mainScreenStreamRef.mediaStream == 'object' &&
                          !mainScreenStreamRef.docShare
                        "
                        class="video-container"
                      >
                        <video
                          v-if="
                            mainScreenStreamRef != null &&
                            !mainScreenStreamRef.screenShare &&
                            !mainScreenStreamRef.mutedVideo &&
                            !mainScreenStreamRef.fileShare &&
                            typeof mainScreenStreamRef.mediaStream == 'object' &&
                            !mainScreenStreamRef.docShare
                          "
                          id="ID_OF_YOUR_VIDEO_ELEMENT"
                          muted
                          autoPlay
                          playsInline
                          :srcObject.prop="
                            mainScreenStreamRef != null &&
                            mainScreenStreamRef.mediaStream
                          "
                        ></video>
                      </div>
  
                      <video
                        v-if="
                          mainScreenStreamRef != null &&
                          typeof mainScreenStreamRef.mediaStream == 'string' &&
                          !mainScreenStreamRef.audioShare &&
                          !mainScreenStreamRef.fileShare &&
                          !mainScreenStreamRef.docShare
                        "
                        crossorigin="anonymous"
                        :src="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.mediaStream
                        "
                        id="vid"
                        autoPlay
                        controls
                        playsInline
                        width="640"
                        height="480"
                        :onended="stopImportData"
                        :onplaying="VideoShare"
                      ></video>
                      <div
                        class="loader_main"
                        v-if="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.docShare &&
                          srcList.length == 0 &&
                          !mainScreenStreamRef.fileShare
                        "
                      >
                        <div class="loader"></div>
                        Presenting (Document)
                      </div>
                      <div
                        class="loader_main"
                        v-if="
                          mainScreenStreamRef != null &&
                          fileShareLoader &&
                          mainScreenStreamRef.fileShare
                        "
                      >
                        <div class="loader"></div>
                        Presenting (Images)
                      </div>
                      <button
                        type="button"
                        class="btn transparent-btn border-blue"
                        v-if="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.docShare &&
                          mainScreenStreamRef.socketId == socket.id &&
                          srcList.length != 0
                        "
                        :class="currentPage == 1 ? '' : 'nextBtn'"
                        :onClick="toPreviousPage"
                        :style="currentPage == 1 ? 'cursor: no-drop;' : false"
                        :disabled="currentPage == 1 ? true : false"
                        style="
                          font-size: 45px;
                          padding: 0px 10px;
                          margin-right: 10px;
                        "
                      >
                        &#8249;
                      </button>
  
                      <el-image
                        v-if="
                          mainScreenStreamRef != null &&
                          !fileShareLoader &&
                          mainScreenStreamRef.docShare &&
                          !mainScreenStreamRef.fileShare &&
                          srcList.length != 0
                        "
                        :src="srcList[0]"
                        style="height: 100%"
                        :zoom-rate="1.2"
                        :max-scale="7"
                        :min-scale="0.2"
                        :preview-src-list="srcList"
                        :initial-index="4"
                        fit="cover"
                      />
                      <canvas
                        v-if="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.docShare &&
                          !mainScreenStreamRef.fileShare
                        "
                        id="canvas"
                        style="max-height: 70vh; display: none"
                        :ref="canvasRef"
                      ></canvas>
  
                      <button
                        style="
                          font-size: 45px;
                          padding: 0px 10px;
                          margin-left: 10px;
                        "
                        type="button"
                        class="btn transparent-btn border-blue"
                        :class="currentPage == totalPages ? '' : 'nextBtn'"
                        v-if="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.docShare &&
                          mainScreenStreamRef.socketId == socket.id &&
                          srcList.length != 0
                        "
                        :onClick="toNextPage"
                        :style="
                          currentPage == totalPages ? 'cursor: no-drop;' : false
                        "
                        :disabled="currentPage == totalPages ? true : false"
                      >
                        &#8250;
                      </button>
                      <audio
                        :style="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.audioShare
                            ? 'width: 100%;position: absolute; bottom: 0;'
                            : ''
                        "
                        v-if="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.socketId == socket.id &&
                          mainScreenStreamRef.audioShare
                        "
                        crossorigin="anonymous"
                        :src="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.mediaStream
                        "
                        id="aud"
                        autoPlay
                        controls
                        playsInline
                        width="640"
                        height="480"
                        :onended="stopImportAudioData"
                        :onplaying="AudioShare"
                      ></audio>
  
                      <audio
                        :style="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.audioShare
                            ? 'width: 100%;position: absolute; bottom: 0;'
                            : ''
                        "
                        v-if="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.socketId != socket.id &&
                          mainScreenStreamRef.audioShare
                        "
                        crossorigin="anonymous"
                        :srcObject.prop="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.mediaStream
                        "
                        id="aud"
                        autoPlay
                        playsInline
                        width="640"
                        height="480"
                      ></audio>
  
                      <div
                        class="zoom-actions"
                        style="display: block"
                        v-if="
                          mainScreenStreamRef != null &&
                          !mainScreenStreamRef.videoShare &&
                          !mainScreenStreamRef.docShare
                        "
                      >
                        <button
                          class="btn-mute"
                          @click="clickToMiniMiseStream(mainScreenStreamRef)"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#e86969"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0" />
  
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke="#c9c0c0"
                              stroke-width="4.8"
                            >
                              <path
                                d="M10.5 13.5H7.5M10.5 13.5V16.5M10.5 13.5L7 17"
                                stroke="#808080"
                                stroke-width="1.512"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.5 10.5H16.5M13.5 10.5V7.5M13.5 10.5L17 7"
                                stroke="#808080"
                                stroke-width="1.512"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.5 10.5H7.5M10.5 10.5V7.5M10.5 10.5L7 7"
                                stroke="#808080"
                                stroke-width="1.512"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.5 13.5H16.5M13.5 13.5V16.5M13.5 13.5L17 17"
                                stroke="#808080"
                                stroke-width="1.512"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                opacity="0.5"
                                d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                                stroke="#808080"
                                stroke-width="1.512"
                              />
                            </g>
  
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M10.5 13.5H7.5M10.5 13.5V16.5M10.5 13.5L7 17"
                                stroke="#808080"
                                stroke-width="1.512"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.5 10.5H16.5M13.5 10.5V7.5M13.5 10.5L17 7"
                                stroke="#808080"
                                stroke-width="1.512"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.5 10.5H7.5M10.5 10.5V7.5M10.5 10.5L7 7"
                                stroke="#808080"
                                stroke-width="1.512"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.5 13.5H16.5M13.5 13.5V16.5M13.5 13.5L17 17"
                                stroke="#808080"
                                stroke-width="1.512"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                opacity="0.5"
                                d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                                stroke="#808080"
                                stroke-width="1.512"
                              />
                            </g>
                          </svg>
                        </button>
                      </div>
  
                      <div
                        v-if="
                          mainScreenStreamRef != null &&
                          !mainScreenStreamRef.screenShare &&
                          mainScreenStreamRef.socketId == socket.id
                            ? currentMicState
                            : mainScreenStreamRef != null &&
                              !mainScreenStreamRef.screenShare &&
                              !audioMicClose.includes(
                                mainScreenStreamRef.socketId
                              )
                        "
                        style="position: absolute; top: '16%'; right: '3%'"
                        :id="mainScreenStreamRef.socketId"
                        class="audio-icon-cont"
                      >
                        <span class="audio-icon" />
                      </div>
  
                      <div
                        class="vertical-center play-button"
                        v-if="
                          mainScreenStreamRef != null &&
                          role == 'VIEWER' &&
                          !mainScreenStreamRef.is_autoplay &&
                          !mainScreenStreamRef.screenShare
                        "
                        :id="mainScreenStreamRef.socketId + '_PEER_PLAY'"
                      >
                        <img
                          @click="
                            playStream(
                              mainScreenStreamRef.storageId,
                              mainScreenStreamRef.socketId
                            )
                          "
                          src="../../assets/media/play.png"
                          class="r-img rounded participants-img-medium"
                        />
                      </div>
  
                      <div
                        class="vertical-center"
                        v-if="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.screenShare &&
                          mainScreenStreamRef.audioShare
                        "
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          width: 100%;
                        "
                      >
                        <img
                          :src="
                            BASE_URL + mainScreenStreamRef.userDetails.profileImg
                          "
                          class="r-img rounded participants-img-medium"
                        />
                      </div>
                      <div
                        class="vertical-center"
                        v-if="
                          mainScreenStreamRef != null &&
                          !mainScreenStreamRef.screenShare &&
                          mainScreenStreamRef.mutedVideo
                        "
                      >
                        <img
                          :src="
                            BASE_URL + mainScreenStreamRef.userDetails.profileImg
                          "
                          class="r-img rounded participants-img-medium"
                        />
                      </div>
  
                      <div
                        class="name-tag d-flex"
                        style="
                          right: 0;
                          width: max-content;
                          left: auto;
                          display: flex;
                          align-items: center;
                        "
                        v-if="
                          (mainScreenStreamRef != null &&
                            mainScreenStreamRef.screenShare &&
                            mainScreenStreamRef.videoShare &&
                            mainScreenStreamRef.socketId != socket.id &&
                            !mainScreenStreamRef.audioShare &&
                            !mainScreenStreamRef.docShare) ||
                          (mainScreenStreamRef != null &&
                            mainScreenStreamRef.screenShare &&
                            mainScreenStreamRef.audioShare &&
                            mainScreenStreamRef.videoShare &&
                            mainScreenStreamRef.socketId != socket.id &&
                            !mainScreenStreamRef.docShare)
                        "
                      >
                        <svg
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          style="fill: #ffffff"
                          viewBox="0 0 448 512"
                        >
                          <path
                            d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288H175.5L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7H272.5L349.4 44.6z"
                          />
                        </svg>
  
                        <input
                          class="form-control"
                          type="range"
                          min="0"
                          max="200"
                          step="10"
                          v-model="gainLevel"
                          @input="handleBoostVolume(gainLevel)"
                          style="padding: 0"
                        />
                      </div>
  
                      <a
                        style="
                          padding-top: 3px;
                          right: 50%;
                          left: 50%;
                          width: max-content;
                          border-radius: 8px 8px 0px 0px;
                          cursor: pointer;
                        "
                        class="name-tag d-flex"
                        :id="
                          mainScreenStreamRef.screenShare
                            ? ''
                            : mainScreenStreamRef.socketId + 'name-tag'
                        "
                        v-if="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.screenShare &&
                          mainScreenStreamRef.socketId == socket.id &&
                          mainScreenStreamRef.docShare
                        "
                        @click="skipToPage()"
                      >
                        {{ currentPage }} / {{ totalPages }}
                      </a>
                      <a
                        :style="
                          mainScreenStreamRef != null &&
                          mainScreenStreamRef.videoShare &&
                          mainScreenStreamRef.screenShare &&
                          mainScreenStreamRef.socketId == socket.id
                            ? 'top: 10px;bottom: auto'
                            : mainScreenStreamRef.videoShare &&
                              mainScreenStreamRef.docShare
                            ? ''
                            : mainScreenStreamRef.audioShare &&
                              mainScreenStreamRef.socketId == socket.id
                            ? 'top: 10px;bottom: auto'
                            : ''
                        "
                        style="padding-top: 3px"
                        class="name-tag d-flex"
                        :id="
                          mainScreenStreamRef.screenShare
                            ? ''
                            : mainScreenStreamRef.socketId + 'name-tag'
                        "
                        v-if="mainScreenStreamRef != null"
                      >
                        <a
                          :id="mainScreenStreamRef.socketId + '_USER_MAIN_NAME'"
                          >{{
                            mainScreenStreamRef.userDetails
                              ? mainScreenStreamRef.userDetails.user_name
                              : ""
                          }}</a
                        >
  
                        <a style="padding-left: 3px">{{
                          mainScreenStreamRef.screenShare
                            ? mainScreenStreamRef.audioShare
                              ? " (Audio presentation)"
                              : mainScreenStreamRef.docShare
                              ? "(document presentation)"
                              : mainScreenStreamRef.videoShare
                              ? "(video presentation)"
                              : "(presentation)"
                            : ""
                        }}</a>
  
                        <div
                          v-if="mainScreenStreamRef.raiseHand"
                          style="position: relative"
                        >
                          <img
                            @click="
                              role == 'HOST' ||
                              socket.id == mainScreenStreamRef.socketId
                                ? hideRaiseHand(mainScreenStreamRef.socketId)
                                : ''
                            "
                            v-if="mainScreenStreamRef.raiseHand"
                            :id="
                              mainScreenStreamRef.socketId + '_BIG_SCREEN_RAISE'
                            "
                            class="wave"
                            :src="
                              raisehandArray.findIndex(
                                (img) =>
                                  img.socketId === mainScreenStreamRef.socketId
                              ) == 0
                                ? '../../assets/media/raised-hand-blue.png'
                                : '../../assets/media/raised-hand-blue.png'
                            "
                            height="25"
                            width="25"
                          />
                          <span style="position: absolute; bottom: 0px">
                            {{
                              raisehandArray.findIndex(
                                (img) =>
                                  img.socketId === mainScreenStreamRef.socketId
                              ) + 1
                            }}
                          </span>
                        </div>
                      </a>
                      <div>
                        <button
                          v-if="
                            mainScreenStreamRef != null &&
                            !mainScreenStreamRef.videoShare &&
                            !mainScreenStreamRef.docShare &&
                            role != 'VIEWER'
                          "
                          type="button"
                          style="z-index: 999999999999999"
                          class="submenu_btn dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                              d="M0 256a56 56 0 1 1 112 0A56 56 0 1 1 0 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"
                            />
                          </svg>
                        </button>
  
                        <div class="dropdown-menu">
                          <ul class="submenu" v-if="mainScreenStreamRef != null">
                            <li
                              v-if="
                                !mainScreenStreamRef.screenShare &&
                                (role == 'HOST' || role == 'MODERATOR') &&
                                !audioMicClose.includes(
                                  mainScreenStreamRef.socketId
                                ) &&
                                mainScreenStreamRef.socketId != socket.id
                              "
                              :id="
                                mainScreenStreamRef.socketId +
                                '_AUDIO_MAIN_MUTE_ACTIONs'
                              "
                              class="dropdown-item hover-item"
                              @click="muteMic(mainScreenStreamRef.socketId)"
                            >
                              Mute Mic
                            </li>
                            <li
                              v-if="
                                !mainScreenStreamRef.screenShare &&
                                (role == 'HOST' || role == 'MODERATOR') &&
                                !mainScreenStreamRef.mutedVideo &&
                                mainScreenStreamRef.socketId != socket.id &&
                                !mainScreenStreamRef.mutedVideo
                              "
                              class="dropdown-item hover-item"
                              :id="
                                mainScreenStreamRef.socketId +
                                '_VIDEO_MAIN_UNMUTE_ACTIONs'
                              "
                              @click="muteVideo(mainScreenStreamRef.socketId)"
                            >
                              Mute Camera
                            </li>
                            <li
                              v-if="
                                !mainScreenStreamRef.screenShare &&
                                (role == 'HOST' || role == 'MODERATOR') &&
                                mainScreenStreamRef.socketId != socket.id
                              "
                              class="dropdown-item hover-item"
                              @click="removeUser(mainScreenStreamRef.socketId)"
                            >
                              Remove User
                            </li>
                            <li
                              class="dropdown-item hover-item"
                              @click="
                                renameUser(
                                  mainScreenStreamRef,
                                  mainScreenStreamRef.socketId
                                )
                              "
                            >
                              Rename User
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <draggable
                      v-model="paginatedVideos"
                      v-if="!is_stream"
                      @change="handleChange"
                      :list="paginatedVideos"
                      item-key="name"
                      class="Dish"
                      id="ALL_VIDEOS"
                      :style="
                      {flexDirection: (paginatedVideos.length === 3 && (mainScreenStreamRef != null &&
                          !mainScreenStreamRef.fileShare &&
                          !mainScreenStreamRef.audioShare &&
                          !mainScreenStreamRef.audioShare &&
                          typeof mainScreenStreamRef.mediaStream == 'object' &&
                          mainScreenStreamRef.screenShare &&
                          !mainScreenStreamRef.docShare))  ? 'column' : 'row',
                      flex:
                          (mainScreenStreamRef != null &&
                          !mainScreenStreamRef.fileShare &&
                          !mainScreenStreamRef.audioShare &&
                          !mainScreenStreamRef.audioShare &&
                          typeof mainScreenStreamRef.mediaStream == 'object' &&
                          mainScreenStreamRef.screenShare &&
                          !mainScreenStreamRef.docShare) ? 0.5 : 'none'
                        
                      }"
                      handle=".handle"
                      :component-data="getComponentData()"
                      ghost-class="ghost"
                      @start="dragging = true"
                      @end="dragging = false"
                    >
                      <template #item="{ element }">
                        <div
                          :id="element.storageId + '_STREAsM_VIDEO_DIV'"
                          style="background-color: black; flex: none !important"
                        >
                          <div class="lds-ellipsis" v-if="is_stream">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <a
                            class="stream"
                            :style="
                              element.mutedVideo == false
                                ? 'display: flex; justify-content: center;position: relative;width: 100%;'
                                : 'display: flex; justify-content: center;width: 100%;'
                            "
                          >
                            <div
                              v-if="
                                !element.screenShare &&
                                element.socketId == socket.id
                                  ? currentMicState
                                  : !element.screenShare &&
                                    !audioMicClose.includes(element.socketId)
                              "
                              style="position: absolute; top: '16%'; right: '3%'"
                              :id="element.socketId"
                              class="audio-icon-cont"
                            >
                              <span class="audio-icon" />
                            </div>
                            <video
                              v-if="!element.screenShare && !element.mutedVideo"
                              :id="element.socketId + 'STREAM_SRC'"
                              muted
                              autoPlay
                              playsInline
                              :srcObject.prop="element.mediaStream"
                            ></video>
  
                            <video
                              :id="element.socketId + 'STREAM_SCR_SRC'"
                              v-if="element.screenShare"
                              style="visibility: visible"
                              muted
                              autoPlay
                              playsInline
                              :srcObject.prop="element.mediaStream"
                            ></video>
  
                            <div
                              class="vertical-center"
                              v-if="
                                !element.screenShare &&
                                element.mutedVideo &&
                                element.userDetails
                              "
                              :id="element.socketId + '_PEER_IMAGE'"
                            >
                              <img
                                :src="BASE_URL + element.userDetails.profileImg"
                                class="r-img rounded participants-img-medium"
                              />
                            </div>
  
                            <div class="drag-actions" v-if="!mainScreenStreamRef">
                              <button
                                class="btn-mute handle dragButton"
                                draggable="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24"
                                  height="24"
                                  x="0"
                                  y="0"
                                  viewBox="0 0 64 64"
                                  style="enable-background: new 0 0 512 512"
                                  xml:space="preserve"
                                  class=""
                                >
                                  <g>
                                    <path
                                      d="m58.995 35.148-5.766 6.368c-2.634 2.912-7.15-1.045-4.448-4.028L50.58 35.5H35v15.078l1.988-1.799c2.936-2.663 6.972 1.783 4.028 4.449l-6.365 5.763a3.99 3.99 0 0 1-5.3.003l-6.367-5.766c-2.948-2.67 1.094-7.11 4.028-4.448L29 50.579V35.501H13.42l1.8 1.987c2.71 2.994-1.842 6.91-4.45 4.028l-5.762-6.365a3.992 3.992 0 0 1-.003-5.299l5.766-6.368c2.66-2.94 7.113 1.085 4.448 4.029l-1.799 1.986H29V13.421l-1.988 1.799c-2.935 2.662-6.973-1.783-4.028-4.449l6.365-5.763a3.99 3.99 0 0 1 5.3-.003l6.367 5.766c2.997 2.714-1.153 7.055-4.028 4.448L35 13.421v16.078H50.58l-1.8-1.986c-2.66-2.94 1.786-6.975 4.45-4.029l5.762 6.365a3.992 3.992 0 0 1 .003 5.3z"
                                      fill="#808080"
                                      opacity="1"
                                      data-original="#000000"
                                      class=""
                                    ></path>
                                  </g>
                                </svg>
                              </button>
                            </div>
                            <div class="zoom-actions">
                              <button
                                :style="
                                  mainScreenStreamRef != null
                                    ? mainScreenStreamRef.videoShare
                                      ? 'cursor: no-drop;'
                                      : mainScreenStreamRef.docShare
                                      ? 'cursor: no-drop'
                                      : ''
                                    : ''
                                "
                                class="btn-mute"
                                draggable="true"
                                @click="
                                  mainScreenStreamRef != null
                                    ? mainScreenStreamRef.videoShare
                                      ? ''
                                      : mainScreenStreamRef.docShare
                                      ? ''
                                      : clickToBigStream(element)
                                    : clickToBigStream(element)
                                "
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="SVGRepo_bgCarrier" stroke-width="0" />
  
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
  
                                  <g id="SVGRepo_iconCarrier">
                                    <path
                                      d="M17 7H14M17 7V10M17 7L13.5 10.5M7 17H10M7 17V14M7 17L10.5 13.5"
                                      stroke="#808080"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M7 7H10M7 7V10M7 7L10.5 10.5M17 17H14M17 17V14M17 17L13.5 13.5"
                                      stroke="#808080"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                                      stroke="#808080"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                </svg>
                              </button>
                            </div>
  
                            <a
                              class="name-tag"
                              :id="element.socketId + 'peer-name-tag'"
                              v-if="
                                !element.screenShare &&
                                element.userDetails &&
                                typeof element.mediaStream != 'string'
                              "
                            >
                              <img
                                src="../assets/media/verify.png"
                                v-if="socket.id == element.socketId"
                                alt=""
                                width="15"
                                height="15"
                                style="margin-right: 6px"
                              />
                              <p
                                :id="element.socketId + '_USER_NAME'"
                                style="margin: auto"
                              >
                                {{ element.userDetails.user_name }}
                              </p>
                              <div
                                v-if="element.raiseHand"
                                style="position: relative"
                              >
                                <img
                                  class="wave"
                                  @click="
                                    role == 'HOST' ||
                                    role == 'MODERATOR' ||
                                    socket.id == element.socketId
                                      ? hideRaiseHand(element.socketId)
                                      : ''
                                  "
                                  :src="
                                    raisehandArray.findIndex(
                                      (img) => img.socketId === element.socketId
                                    ) == 0
                                      ? '../../assets/media/raised-hand-blue.png'
                                      : '../../assets/media/raised-hand.png'
                                  "
                                  height="25"
                                  width="25"
                                />
  
                                <span style="position: absolute; bottom: 0px">
                                  {{
                                    raisehandArray.findIndex(
                                      (img) => img.socketId === element.socketId
                                    ) + 1
                                  }}
                                </span>
                              </div>
                            </a>
                            <a
                              class="name-tag"
                              :id="element.socketId + '_SCREEN_USER_NAME'"
                              v-if="
                                element.screenShare &&
                                element.userDetails.user_name
                              "
                            >
                              {{ element.userDetails.user_name }} (presentation)
                            </a>
  
                            <div
                              v-if="!element.screenShare"
                              class="participant-actions"
                              id="participant-actions"
                            >
                              <button
                                @click="
                                  role == 'GUEST' && element.socketId == socket.id
                                    ? muteMic(element.socketId, true, 'mute')
                                    : role == 'HOST'
                                    ? muteMic(element.socketId, true, 'mute')
                                    : role == 'MODERATOR' &&
                                      element.userDetails.webinarUserType !=
                                        'HOST'
                                    ? muteMic(element.socketId, true, 'mute')
                                    : ''
                                "
                                id="MIC"
                                class="btn-mute"
                                v-if="
                                  element.socketId == socket.id
                                    ? currentMicState
                                    : !audioMicClose.includes(element.socketId)
                                "
                              >
                                <svg
                                  style="padding: 3px"
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="css-i6dzq1"
                                >
                                  <path
                                    d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"
                                  ></path>
                                  <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                                  <line x1="12" y1="19" x2="12" y2="23"></line>
                                  <line x1="8" y1="23" x2="16" y2="23"></line>
                                </svg>
                              </button>
                              <button
                                @click="
                                  role == 'GUEST' && element.socketId == socket.id
                                    ? muteMic(element.socketId, true, 'unmute')
                                    : role == 'HOST'
                                    ? muteMic(element.socketId, true, 'unmute')
                                    : role == 'MODERATOR' &&
                                      element.userDetails.webinarUserType !=
                                        'HOST'
                                    ? muteMic(element.socketId, true, 'unmute')
                                    : ''
                                "
                                id="SLASH_MIC"
                                class="btn-mute"
                                v-if="
                                  element.socketId == socket.id
                                    ? !currentMicState
                                    : audioMicClose.includes(element.socketId)
                                "
                              >
                                <svg
                                  style="padding: 3px"
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="css-i6dzq1"
                                >
                                  <line x1="1" y1="1" x2="23" y2="23"></line>
                                  <path
                                    d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6"
                                  ></path>
                                  <path
                                    d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23"
                                  ></path>
                                  <line x1="12" y1="19" x2="12" y2="23"></line>
                                  <line x1="8" y1="23" x2="16" y2="23"></line>
                                </svg>
                              </button>
  
                              <button
                                @click="
                                  role == 'GUEST' && element.socketId == socket.id
                                    ? muteVideo(element.socketId, true, 'mute')
                                    : role == 'HOST'
                                    ? muteVideo(element.socketId, true, 'mute')
                                    : role == 'MODERATOR' &&
                                      element.userDetails.webinarUserType !=
                                        'HOST'
                                    ? muteVideo(element.socketId, true, 'mute')
                                    : ''
                                "
                                :id="element.socketId + 'CAM'"
                                v-if="
                                  element.socketId == socket.id
                                    ? video
                                    : !element.mutedVideo
                                "
                                class="btn-camera"
                              >
                                <svg
                                  style="padding: 3px"
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="css-i6dzq1"
                                >
                                  <polygon
                                    points="23 7 16 12 23 17 23 7"
                                  ></polygon>
                                  <rect
                                    x="1"
                                    y="5"
                                    width="15"
                                    height="14"
                                    rx="2"
                                    ry="2"
                                  ></rect>
                                </svg>
                              </button>
  
                              <button
                                @click="
                                  role == 'GUEST' && element.socketId == socket.id
                                    ? muteVideo(element.socketId, true, 'unmute')
                                    : role == 'HOST'
                                    ? muteVideo(element.socketId, true, 'unmute')
                                    : role == 'MODERATOR' &&
                                      element.userDetails.webinarUserType !=
                                        'HOST'
                                    ? muteVideo(element.socketId, true, 'unmute')
                                    : ''
                                "
                                :id="element.socketId + 'SLASH_CAM'"
                                v-if="
                                  element.socketId == socket.id
                                    ? !video
                                    : element.mutedVideo
                                "
                                class="btn-camera"
                              >
                                <svg
                                  style="padding: 3px"
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="css-i6dzq1"
                                >
                                  <path
                                    d="M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10"
                                  ></path>
                                  <line x1="1" y1="1" x2="23" y2="23"></line>
                                </svg>
                              </button>
                            </div>
  
                            <div>
                              <button
                                v-if="role != 'VIEWER'"
                                type="button"
                                class="submenu_btn dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                  <path
                                    d="M0 256a56 56 0 1 1 112 0A56 56 0 1 1 0 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"
                                  />
                                </svg>
                              </button>
  
                              <div
                                class="dropdownStyle dropdown-menu"
                                :class="{
                                  lessThan2DropDown: paginatedVideos.length === 1,
                                  lessThan2MoreDropDown:
                                    paginatedVideos.length === 2,
                                  dropdown5Style: paginatedVideos.length === 5,
                                  dropdown6Style: paginatedVideos.length === 6,
                                  dropdown7Or8Style:
                                    paginatedVideos.length >= 7 ||
                                    paginatedVideos.length <= 12,
                                }"
                              >
                                <ul class="submenu">
                                  <!-- <li>rename</li> -->
                                  <li
                                    class="dropdown-item hover-item mobileItemStyle"
                                    style="text-transform: none"
                                    v-if="
                                      !element.screenShare &&
                                      element.socketId != socket.id &&
                                      (role == 'HOST' || role == 'MODERATOR')
                                    "
                                    @click="hideRaiseHand(element.socketId)"
                                  >
                                    {{
                                      raisehandArray.findIndex(
                                        (img) => img.socketId === element.socketId
                                      ) == -1
                                        ? "Raise Hand"
                                        : "Lower Hand"
                                    }}
                                  </li>
                                  <li
                                    class="dropdown-item hover-item mobileItemStyle"
                                    style="text-transform: none"
                                    v-if="
                                      !element.screenShare &&
                                      element.socketId !== socket.id &&
                                      isOwner &&
                                      element.userDetails.webinarUserType ==
                                        'HOST'
                                    "
                                    @click="
                                      changeRoleModal(
                                        element.userDetails,
                                        element.socketId,
                                        'GUEST'
                                      )
                                    "
                                  >
                                    Manage Roll
                                  </li>
  
                                  <li
                                    class="dropdown-item hover-item mobileItemStyle"
                                    style="text-transform: none"
                                    v-if="
                                      !element.screenShare &&
                                      element.socketId !== socket.id &&
                                      role == 'HOST' &&
                                      element.userDetails.webinarUserType ==
                                        'MODERATOR'
                                    "
                                    @click="
                                      changeRoleModal(
                                        element.userDetails,
                                        element.socketId,
                                        'GUEST'
                                      )
                                    "
                                  >
                                    Manage Roll
                                  </li>
  
                                  <li
                                    class="dropdown-item hover-item mobileItemStyle"
                                    style="text-transform: none"
                                    v-if="
                                      !element.screenShare &&
                                      element.socketId !== socket.id &&
                                      role == 'HOST' &&
                                      element.userDetails.webinarUserType ==
                                        'GUEST'
                                    "
                                    @click="
                                      changeRoleModal(
                                        element.userDetails,
                                        element.socketId,
                                        'HOST'
                                      )
                                    "
                                  >
                                    Manage Roll
                                  </li>
  
                                  <li
                                    class="dropdown-item hover-item mobileItemStyle"
                                    style="text-transform: none"
                                    v-if="
                                      !element.screenShare &&
                                      element.socketId !== socket.id &&
                                      role == 'MODERATOR' &&
                                      element.userDetails.webinarUserType ==
                                        'GUEST'
                                    "
                                    @click="
                                      changeRoleModal(
                                        element.userDetails,
                                        element.socketId,
                                        'MODERATOR'
                                      )
                                    "
                                  >
                                    Manage Roll
                                  </li>
  
                                  <li
                                    class="dropdown-item hover-item mobileItemStyle"
                                    style="text-transform: none"
                                    v-if="
                                      !element.screenShare &&
                                      element.socketId !== socket.id &&
                                      role == 'MODERATOR' &&
                                      element.userDetails.webinarUserType ==
                                        'MODERATOR'
                                    "
                                    @click="
                                      changeRoleModal(
                                        element.userDetails,
                                        element.socketId,
                                        'GUEST'
                                      )
                                    "
                                  >
                                    Manage Roll
                                  </li>
  
                                  <li
                                    v-if="
                                      !element.screenShare &&
                                      (role == 'HOST' || role == 'MODERATOR') &&
                                      !audioMicClose.includes(element.socketId) &&
                                      socket.id != element.socketId
                                    "
                                    class="dropdown-item hover-item mobileItemStyle"
                                    @click="muteMic(element.socketId)"
                                  >
                                    Mute Mic
                                  </li>
                                  <li
                                    v-if="
                                      !element.screenShare &&
                                      (role == 'HOST' || role == 'MODERATOR') &&
                                      socket.id != element.socketId &&
                                      !element.mutedVideo
                                    "
                                    class="dropdown-item hover-item"
                                    @click="muteVideo(element.socketId)"
                                  >
                                    Mute Camera
                                  </li>
  
                                  <li
                                    v-if="
                                      !element.screenShare &&
                                      isOwner == true &&
                                      (role == 'HOST' || role == 'MODERATOR') &&
                                      socket.id != element.socketId
                                    "
                                    class="dropdown-item hover-item mobileItemStyle"
                                    @click="removeUser(element.socketId)"
                                  >
                                    Remove User
                                  </li>
                                  <li
                                    class="dropdown-item hover-item mobileItemStyle"
                                    v-if="
                                      (element.socketId == socket.id &&
                                        isOwner == true) ||
                                      role !== 'GUEST'
                                    "
                                    @click="
                                      renameUser(
                                        element.userDetails,
                                        element.socketId
                                      )
                                    "
                                  >
                                    Rename User
                                  </li>
                                  <li
                                    class="dropdown-item hover-item"
                                    v-if="
                                      element.socketId == socket.id &&
                                      isOwner == false &&
                                      role == 'GUEST'
                                    "
                                    @click="
                                      renameUser(
                                        element.userDetails,
                                        element.socketId
                                      )
                                    "
                                  >
                                    Rename User
                                  </li>
                                  <li
                                    class="dropdown-item hover-item"
                                    v-if="
                                      element.socketId !== socket.id &&
                                      role == 'GUEST'
                                    "
                                  >
                                    No Option
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </a>
                        </div>
                      </template>
                    </draggable>
                  </div>
                </div>
                <!-- <div class="panel-body col-md-7" id="container-screens"></div> -->
              </div>
  
              <!-- end:: Content -->
            </div>
          </div>
  
          <div id="mySidenav" class="sidenav">
            <div class="tab-content">
              <div id="offers" class="tab-pane fade">
                <div class="align-items-center">
                  <h5 class="card-title fw-bolder text-white top-title mt-2 fs-3">
                    Offers
                  </h5>
                </div>
  
                <a
                  href="javascript:void(0)"
                  class="closebtn"
                  onclick="closeNav()"
                >
                  <svg
                    id="Icon_open-collapse-left"
                    data-name="Icon open-collapse-left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.785"
                    height="22.785"
                    viewBox="0 0 22.785 22.785"
                  >
                    <path
                      id="Icon_open-collapse-left-2"
                      data-name="Icon open-collapse-left"
                      d="M22.785,0V22.785H19.937V0ZM5.7,0V22.785H0V0ZM8.544,5.7l5.7,5.7-5.7,5.7Z"
                      fill="#2d8cff"
                    />
                  </svg>
                </a>
              </div>
  
              <div id="documents" class="tab-pane fade">
                <div class="align-items-center">
                  <h5 class="card-title fw-bolder text-white top-title mt-2 fs-3">
                    Documents
                  </h5>
                </div>
  
                <a
                  href="javascript:void(0)"
                  class="closebtn"
                  onclick="closeNav()"
                >
                  <svg
                    id="Icon_open-collapse-left"
                    data-name="Icon open-collapse-left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.785"
                    height="22.785"
                    viewBox="0 0 22.785 22.785"
                  >
                    <path
                      id="Icon_open-collapse-left-2"
                      data-name="Icon open-collapse-left"
                      d="M22.785,0V22.785H19.937V0ZM5.7,0V22.785H0V0ZM8.544,5.7l5.7,5.7-5.7,5.7Z"
                      fill="#2d8cff"
                    />
                  </svg>
                </a>
              </div>
              <div id="slides" class="tab-pane fade">
                <div class="align-items-center">
                  <h5 class="card-title fw-bolder text-white top-title mt-2 fs-3">
                    Slides
                  </h5>
                </div>
  
                <a
                  href="javascript:void(0)"
                  class="closebtn"
                  onclick="closeNav()"
                >
                  <svg
                    id="Icon_open-collapse-left"
                    data-name="Icon open-collapse-left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.785"
                    height="22.785"
                    viewBox="0 0 22.785 22.785"
                  >
                    <path
                      id="Icon_open-collapse-left-2"
                      data-name="Icon open-collapse-left"
                      d="M22.785,0V22.785H19.937V0ZM5.7,0V22.785H0V0ZM8.544,5.7l5.7,5.7-5.7,5.7Z"
                      fill="#2d8cff"
                    />
                  </svg>
                </a>
              </div>
              <div id="videos" class="tab-pane fade">
                <div class="align-items-center">
                  <h5 class="card-title fw-bolder text-white top-title mt-2 fs-3">
                    Videos
                  </h5>
                </div>
  
                <a
                  href="javascript:void(0)"
                  class="closebtn"
                  onclick="closeNav()"
                >
                  <svg
                    id="Icon_open-collapse-left"
                    data-name="Icon open-collapse-left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.785"
                    height="22.785"
                    viewBox="0 0 22.785 22.785"
                  >
                    <path
                      id="Icon_open-collapse-left-2"
                      data-name="Icon open-collapse-left"
                      d="M22.785,0V22.785H19.937V0ZM5.7,0V22.785H0V0ZM8.544,5.7l5.7,5.7-5.7,5.7Z"
                      fill="#2d8cff"
                    />
                  </svg>
                </a>
              </div>
              <div id="polls" class="tab-pane fade">
                <div class="align-items-center">
                  <h5 class="card-title fw-bolder text-white top-title mt-2 fs-3">
                    Polls
                  </h5>
                </div>
  
                <a
                  href="javascript:void(0)"
                  class="closebtn"
                  onclick="closeNav()"
                >
                  <svg
                    id="Icon_open-collapse-left"
                    data-name="Icon open-collapse-left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.785"
                    height="22.785"
                    viewBox="0 0 22.785 22.785"
                  >
                    <path
                      id="Icon_open-collapse-left-2"
                      data-name="Icon open-collapse-left"
                      d="M22.785,0V22.785H19.937V0ZM5.7,0V22.785H0V0ZM8.544,5.7l5.7,5.7-5.7,5.7Z"
                      fill="#2d8cff"
                    />
                  </svg>
                </a>
              </div>
              <div id="participant1" class="tab-pane fade">
                <div class="align-items-center">
                  <h5 class="card-title fw-bolder text-white top-title mt-2 fs-3">
                    Participants({{ peers.length }})
                  </h5>
                </div>
                <a
                  href="javascript:void(0)"
                  class="closebtn"
                  onclick="closeNav()"
                >
                  <svg
                    id="Icon_open-collapse-left"
                    data-name="Icon open-collapse-left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.785"
                    height="22.785"
                    viewBox="0 0 22.785 22.785"
                  >
                    <path
                      id="Icon_open-collapse-left-2"
                      data-name="Icon open-collapse-left"
                      d="M22.785,0V22.785H19.937V0ZM5.7,0V22.785H0V0ZM8.544,5.7l5.7,5.7-5.7,5.7Z"
                      fill="#2d8cff"
                    />
                  </svg>
                </a>
  
                <div id="participants1">
                  <!-- begin:: Form-->
                  <div class="mt-5">
                    <div class="input-group mb-3" style="width: 95%">
                      <input
                        class="form-control"
                        placeholder="Search..."
                        v-model="searchMessage"
                        @keydown="searchInputHandler"
                      />
  
                      <div class="input-group-append">
                        <span class="input-group-text p-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                          >
                            <g
                              id="Group_58"
                              data-name="Group 58"
                              transform="translate(-1520 -98)"
                            >
                              <rect
                                id="Rectangle_125"
                                data-name="Rectangle 125"
                                width="32"
                                height="32"
                                rx="8"
                                transform="translate(1520 98)"
                                fill="#2d8cff"
                              />
                              <g
                                id="Icon_feather-search"
                                data-name="Icon feather-search"
                                transform="translate(1529.495 107.645)"
                              >
                                <path
                                  id="Path_426"
                                  data-name="Path 426"
                                  d="M15.622,10.061A5.561,5.561,0,1,1,10.061,4.5,5.561,5.561,0,0,1,15.622,10.061Z"
                                  transform="translate(-4.5 -4.5)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                                <path
                                  id="Path_427"
                                  data-name="Path 427"
                                  d="M28,28l-3.024-3.024"
                                  transform="translate(-15.486 -15.486)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
  
                  <div class="mt-3">
                    <div class="">
                      <ul
                        class="nav nav-tabs nav-tabs-line nav-tabs-inverse participants-rols"
                        style="margin-right: 17px; margin: 0 0 9px 0"
                      >
                        <li
                          class="nav-item"
                          @click="getFilterParticipate('HOST')"
                        >
                          <a
                            id="hostsTab"
                            class="hostsTab nav-link"
                            data-toggle="tab"
                            href="#hosts-tab"
                            style="font-size: 11px !important"
                            >Hosts ({{
                              peers.filter(
                                (a) => a.userDetails.webinarUserType == "HOST"
                              ).length
                            }})</a
                          >
                        </li>
                        <li
                          class="nav-item"
                          @click="getFilterParticipate('MODERATOR')"
                        >
                          <a
                            id="moderatorsTab"
                            class="moderatorsTab nav-link"
                            data-toggle="tab"
                            href="#moderators-tab"
                            style="font-size: 11px !important"
                            >Moderators ({{
                              peers.filter(
                                (a) =>
                                  a.userDetails.webinarUserType == "MODERATOR"
                              ).length
                            }})</a
                          >
                        </li>
                        <li
                          class="nav-item"
                          @click="getFilterParticipate('GUEST')"
                        >
                          <a
                            id="guestsTab"
                            class="guestsTab nav-link"
                            data-toggle="tab"
                            href="#guests-tab"
                            style="font-size: 11px !important"
                            >Guests ({{
                              peers.filter(
                                (a) => a.userDetails.webinarUserType == "GUEST"
                              ).length
                            }})</a
                          >
                        </li>
                        <li
                          class="nav-item"
                          @click="getFilterParticipate('VIEWER')"
                        >
                          <a
                            id="viewersTab"
                            class="viewersTab nav-link"
                            data-toggle="tab"
                            href="#viewers-tab"
                            style="font-size: 11px !important"
                            >Viewers ({{ viewers.length }})</a
                          >
                        </li>
                      </ul>
  
                      <a
                        class="tab-content"
                        data-toggle="tab"
                        onclick="openNav('WaitingUser')"
                        href="#participant1"
                        v-if="role == 'HOST'"
                        style="display: flex; justify-content: center"
                      >
                        <div class="waitingMain">
                          <img
                            src="../../assets/media/Waiting-Room.png"
                            height="30"
                            width="30"
                          />
                          <span class="waitingRoomText">Waiting Room</span>
                        </div>
                        <div
                          class="waitingMain"
                          style="margin-left: 2px; padding: 2px 15px"
                        >
                          <span
                            class="waitingRoomText"
                            style="margin-left: 0px; font-size: 28px"
                            >{{ waitingUser.length }}</span
                          >
                        </div>
                      </a>
  
                      <hr class="hrLine" />
                      <div
                        class="tab-content"
                        id="WaitedUser"
                        v-if="waitingUser.length && role == 'HOST'"
                        style="
                          padding-right: 15px;
                          display: flex;
                          justify-content: center;
                          margin-top: 15px;
                        "
                      >
                        <div
                          @click="rejectAllWaitedUser()"
                          class="waitingMain"
                          style="
                            margin-right: 2px;
                            padding: 4px 8px;
                            letter-spacing: 1px;
                            border: 1px solid red;
                            cursor: pointer;
                          "
                        >
                          <img
                            src="../../assets/media/Reject-All.png"
                            height="25"
                            width="25"
                          />
                          <span
                            class="waitingRoomText"
                            style="font-size: 13px; margin-left: 4px"
                            >Reject All</span
                          >
                        </div>
                        <div
                          @click="acceptAllWaitedUser()"
                          class="waitingMain"
                          style="
                            padding: 5px;
                            margin-left: 2px;
                            padding: 4px 8px;
                            letter-spacing: 1px;
                            border: 1px solid #00ba00;
                            cursor: pointer;
                          "
                        >
                          <img
                            src="../../assets/media/Allow-All.png"
                            height="25"
                            width="25"
                          />
                          <span
                            class="waitingRoomText"
                            style="font-size: 13px; margin-left: 4px"
                            >Allow All</span
                          >
                        </div>
                      </div>
                      <div
                        style="padding-top: 31px"
                        id="WaitedUserList"
                        v-if="waitingUser.length && role == 'HOST'"
                      >
                        <div
                          v-for="parti in waitingUser"
                          v-if="role == 'HOST'"
                          :key="parti.storageId"
                          class="row mt-3"
                          style="padding: 0px 20px"
                        >
                          <div
                            class="col-6"
                            style="display: flex; align-items: center"
                            :style="isActionPopup ? 'opacity: 0.1;' : ''"
                          >
                            <img
                              :src="
                                parti.userDetails.profileImg
                                  ? BASE_URL + parti.userDetails.profileImg
                                  : '../../assets/media/unitydefaultlogo.png'
                              "
                              alt="John Doe"
                              class="mr-2 rounded participants-img-small"
                            />
                            <div class="participant-menber-info">
                              <h5 class="text-white extra-small">
                                {{ parti.userDetails.user_name }}
                              </h5>
                              <!-- <p class="extra-small">Project Manager</p> -->
                            </div>
                          </div>
                          <div
                            style="display: none"
                            class="col-6"
                            :id="'UPDATING_' + parti.socketId"
                          >
                            <span
                              class="spinner spinner-white spinner-right"
                            ></span>
                          </div>
  
                          <div
                            class="col-6 text-right"
                            :id="'HANDLE_BUTTON_' + parti.socketId"
                          >
                            <span
                              class="rejectBtn"
                              style="cursor: pointer"
                              @click="denyUser(parti.socketId)"
                            >
                              Reject
                            </span>
                            <span
                              style="margin-left: 5px; cursor: pointer"
                              @click="allowUser(parti.socketId)"
                              class="acceptBtn"
                            >
                              Allow
                            </span>
                          </div>
                        </div>
                      </div>
  
                      <div
                        class="tab-content mt-5"
                        id="myTabContent"
                        style="padding-right: 15px"
                      >
                        <div
                          class="tab-pane fade show active"
                          id="hosts-tab"
                          role="tabpanel"
                          aria-labelledby="kt_tab_pane_2"
                        >
                          <h3
                            style="text-align: center"
                            v-if="filterdParticipants.length == 0"
                          >
                            No Participants.
                          </h3>
                          <div
                            v-for="parti in filterdParticipants"
                            :key="parti.storageId"
                            class="row mt-3"
                          >
                            <div
                              class="col-6"
                              style="display: flex; align-items: center"
                              :style="isActionPopup ? 'opacity: 0.1;' : ''"
                            >
                              <img
                                :src="
                                  parti.userDetails.profileImg
                                    ? BASE_URL + parti.userDetails.profileImg
                                    : '../../assets/media/unitydefaultlogo.png'
                                "
                                alt="John Doe"
                                class="mr-2 rounded participants-img-small"
                              />
                              <div class="participant-menber-info">
                                <h5 class="text-white extra-small">
                                  {{ parti.userDetails.user_name }}
                                </h5>
                                <!-- <p class="extra-small">Project Manager</p> -->
                              </div>
                            </div>
                            <div
                              style="display: none"
                              class="col-6"
                              :id="'UPDATING_' + parti.socketId"
                            >
                              <span
                                class="spinner spinner-white spinner-right"
                              ></span>
                            </div>
  
                            <div
                              class="col-6 text-right"
                              :id="'HANDLE_BUTTON_' + parti.socketId"
                            >
                              <p
                                v-if="parti.is_mute"
                                class="badge badge-pill badge-info bg-blue"
                              >
                                Muted
                              </p>
                              <p
                                style="margin-left: 5px"
                                v-if="parti.is_ban"
                                class="badge badge-pill badge-info bg-danger"
                              >
                                Banned
                              </p>
  
                              <span
                                style="margin-left: 8px"
                                v-if="role == 'HOST' || role == 'MODERATOR'"
                              >
                                <a
                                  href="javascript:void(0)"
                                  data-toggle="dropdown"
                                  @click="OpenActionPopup()"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="5"
                                    height="19"
                                    viewBox="0 0 5 19"
                                  >
                                    <g
                                      id="Group_334"
                                      data-name="Group 334"
                                      transform="translate(-1545 -326)"
                                    >
                                      <circle
                                        id="Ellipse_30"
                                        data-name="Ellipse 30"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 326)"
                                        fill="#fff"
                                        opacity="0.5"
                                      />
                                      <circle
                                        id="Ellipse_31"
                                        data-name="Ellipse 31"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 333)"
                                        fill="#fff"
                                        opacity="0.5"
                                      />
                                      <circle
                                        id="Ellipse_32"
                                        data-name="Ellipse 32"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 340)"
                                        fill="#fff"
                                        opacity="0.5"
                                      />
                                    </g>
                                  </svg>
                                </a>
                                <div
                                  class="dropdown-menu dropdown-menu-right bg-gray openactiondropdown"
                                  :id="'openactiondropdownA' + parti.storageId"
                                  style="box-shadow: 1px 4px 13px #ffffff2b"
                                >
                                  <div
                                    class="border-bottom-white"
                                    style="display: flex; align-items: center"
                                  >
                                    <img
                                      :src="
                                        parti.userDetails.profileImg
                                          ? BASE_URL +
                                            parti.userDetails.profileImg
                                          : '../../assets/media/unitydefaultlogo.png'
                                      "
                                      :alt="parti.firstName"
                                      class="mr-2 rounded participants-img-small"
                                    />
  
                                    <div class="participant-menber-info">
                                      <h5 class="text-white extra-small">
                                        {{ parti.userDetails.user_name }} <br />
                                        <span style="color: #bbbbbb">
                                          {{ parti.userDetails.email }}
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
  
                                  <div
                                    class="row mb-2"
                                    v-if="parti.socketId != socket.id"
                                  >
                                    <div class="col-8">
                                      <a
                                        @click="
                                          BannedUser(
                                            parti.socketId,
                                            parti.userDetails.email,
                                            parti.is_ban
                                          )
                                        "
                                        href="#"
                                        style="color: #fff"
                                        >{{
                                          parti.is_ban
                                            ? "Remove from ban"
                                            : "Ban User"
                                        }}</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15.76"
                                        height="15.76"
                                        viewBox="0 0 15.76 15.76"
                                      >
                                        <path
                                          id="Icon_awesome-ban"
                                          data-name="Icon awesome-ban"
                                          d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                          transform="translate(-0.563 -0.563)"
                                          fill="#2d8cff"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    class="row mb-2"
                                    v-if="
                                      (!parti.screenShare &&
                                        socket.id != parti.socketId &&
                                        role == 'HOST') ||
                                      (role == 'MODERATOR' &&
                                        !audioMicClose.includes(parti.socketId) &&
                                        parti.socketId != socket.id)
                                    "
                                  >
                                    <div class="col-8">
                                      <a
                                        @click="muteMic(parti.socketId)"
                                        href="#"
                                        style="color: #fff"
                                        >Mute User</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.575"
                                        height="13.182"
                                        viewBox="0 0 17.575 13.182"
                                      >
                                        <path
                                          id="Icon_awesome-volume-mute"
                                          data-name="Icon awesome-volume-mute"
                                          d="M7.381,4.742,4.327,7.8H.824A.824.824,0,0,0,0,8.619v4.943a.824.824,0,0,0,.824.824h3.5l3.054,3.053a.824.824,0,0,0,1.406-.583V5.325A.824.824,0,0,0,7.381,4.742Zm8.465,6.349,1.567-1.567a.554.554,0,0,0,0-.783l-.783-.783a.554.554,0,0,0-.783,0L14.279,9.524,12.713,7.957a.554.554,0,0,0-.783,0l-.783.783a.554.554,0,0,0,0,.783l1.567,1.567-1.566,1.566a.554.554,0,0,0,0,.783l.783.783a.554.554,0,0,0,.783,0l1.566-1.566,1.567,1.567a.554.554,0,0,0,.783,0l.783-.783a.554.554,0,0,0,0-.783Z"
                                          transform="translate(0 -4.5)"
                                          fill="#2d8cff"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-8">
                                      <a
                                        href="#"
                                        style="color: #fff"
                                        @click="
                                          renameUser(
                                            parti.userDetails,
                                            parti.socketId
                                          )
                                        "
                                        >Edit Name</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15.547"
                                        height="15.67"
                                        viewBox="0 0 15.547 15.67"
                                      >
                                        <g
                                          id="Group_348"
                                          data-name="Group 348"
                                          transform="translate(0.5 0.624)"
                                        >
                                          <path
                                            id="Path_491"
                                            data-name="Path 491"
                                            d="M9.507,6H4.446A1.446,1.446,0,0,0,3,7.446V17.567a1.446,1.446,0,0,0,1.446,1.446H14.567a1.446,1.446,0,0,0,1.446-1.446V12.507"
                                            transform="translate(-3 -4.467)"
                                            fill="none"
                                            stroke="#2d8cff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_492"
                                            data-name="Path 492"
                                            d="M19.591,3.267A1.534,1.534,0,1,1,21.76,5.436L14.892,12.3,12,13.027l.723-2.892Z"
                                            transform="translate(-7.662 -2.818)"
                                            fill="none"
                                            stroke="#2d8cff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
  
                        <div
                          class="tab-pane fade"
                          id="moderators-tab"
                          role="tabpanel"
                          aria-labelledby="kt_tab_pane_2"
                        >
                          <h3
                            style="text-align: center"
                            v-if="filterdParticipants.length == 0"
                          >
                            No Participants.
                          </h3>
  
                          <div
                            v-for="parti in filterdParticipants"
                            class="row mt-3"
                            :key="parti.socketId"
                            :id="'UPDAsTING_' + parti.socketId"
                          >
                            <div
                              class="col-6"
                              style="display: flex; align-items: center"
                              :style="isActionPopup ? 'opacity: 0.1;' : ''"
                            >
                              <img
                                :src="
                                  parti.userDetails.profileImg
                                    ? BASE_URL + parti.userDetails.profileImg
                                    : '../../assets/media/unitydefaultlogo.png'
                                "
                                alt="John Doe"
                                class="mr-2 rounded participants-img-small"
                              />
                              <div class="participant-menber-info">
                                <h5 class="text-white extra-small">
                                  {{ parti.userDetails.user_name }}
                                </h5>
                              </div>
                            </div>
                            <div
                              style="display: none"
                              class="col-6"
                              :id="'UPDATING_' + parti.socketId"
                            >
                              <span
                                class="spinner spinner-white spinner-right"
                              ></span>
                            </div>
  
                            <div class="col-6 text-right">
                              <p
                                v-if="parti.is_mute"
                                class="badge badge-pill badge-info bg-blue"
                              >
                                Muted
                              </p>
                              <p
                                v-if="parti.is_ban"
                                class="badge badge-pill badge-info bg-danger"
                              >
                                Banned
                              </p>
  
                              <span
                                style="margin-left: 8px"
                                v-if="
                                  role == 'HOST' ||
                                  role == 'MODERATOR' ||
                                  role == 'MODERATOR'
                                "
                              >
                                <a
                                  href="javascript:void(0)"
                                  data-toggle="dropdown"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="5"
                                    height="19"
                                    viewBox="0 0 5 19"
                                  >
                                    <g
                                      id="Group_334"
                                      data-name="Group 334"
                                      transform="translate(-1545 -326)"
                                    >
                                      <circle
                                        id="Ellipse_30"
                                        data-name="Ellipse 30"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 326)"
                                        fill="#fff"
                                        opacity="0.5"
                                      ></circle>
                                      <circle
                                        id="Ellipse_31"
                                        data-name="Ellipse 31"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 333)"
                                        fill="#fff"
                                        opacity="0.5"
                                      ></circle>
                                      <circle
                                        id="Ellipse_32"
                                        data-name="Ellipse 32"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 340)"
                                        fill="#fff"
                                        opacity="0.5"
                                      ></circle>
                                    </g>
                                  </svg>
                                </a>
                                <div
                                  class="dropdown-menu dropdown-menu-right bg-gray"
                                  :id="'openactiondropdownB' + parti.storageId"
                                  style="box-shadow: 1px 4px 13px #ffffff2b"
                                >
                                  <div
                                    class="border-bottom-white"
                                    style="display: flex; align-items: center"
                                  >
                                    <img
                                      :src="
                                        parti.userDetails.profileImg
                                          ? BASE_URL +
                                            parti.userDetails.profileImg
                                          : '../../assets/media/unitydefaultlogo.png'
                                      "
                                      :alt="parti.firstName"
                                      class="mr-2 rounded participants-img-small"
                                    />
                                    <div class="participant-menber-info">
                                      <h5 class="text-white extra-small">
                                        {{ parti.userDetails.user_name }}
                                        <br />
                                        <span style="color: #bbbbbb">
                                          {{ parti.userDetails.email }}</span
                                        >
                                      </h5>
                                    </div>
                                  </div>
  
                                  <div
                                    class="row mb-2"
                                    v-if="parti.socketId != socket.id"
                                  >
                                    <div class="col-8">
                                      <a
                                        @click="BannedUser(parti.storageId)"
                                        href="#"
                                        style="color: #fff"
                                        >{{
                                          parti.is_ban
                                            ? "Remove from ban"
                                            : "Ban User"
                                        }}</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15.76"
                                        height="15.76"
                                        viewBox="0 0 15.76 15.76"
                                      >
                                        <path
                                          id="Icon_awesome-ban"
                                          data-name="Icon awesome-ban"
                                          d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                          transform="translate(-0.563 -0.563)"
                                          fill="#2d8cff"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    class="row mb-2"
                                    v-if="
                                      (!parti.screenShare &&
                                        socket.id != parti.socketId &&
                                        role == 'HOST') ||
                                      (role == 'MODERATOR' &&
                                        !audioMicClose.includes(parti.socketId))
                                    "
                                  >
                                    <div class="col-8">
                                      <a
                                        @click="muteMic(parti.socketId)"
                                        href="#"
                                        style="color: #fff"
                                        >Mute User</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.575"
                                        height="13.182"
                                        viewBox="0 0 17.575 13.182"
                                      >
                                        <path
                                          id="Icon_awesome-volume-mute"
                                          data-name="Icon awesome-volume-mute"
                                          d="M7.381,4.742,4.327,7.8H.824A.824.824,0,0,0,0,8.619v4.943a.824.824,0,0,0,.824.824h3.5l3.054,3.053a.824.824,0,0,0,1.406-.583V5.325A.824.824,0,0,0,7.381,4.742Zm8.465,6.349,1.567-1.567a.554.554,0,0,0,0-.783l-.783-.783a.554.554,0,0,0-.783,0L14.279,9.524,12.713,7.957a.554.554,0,0,0-.783,0l-.783.783a.554.554,0,0,0,0,.783l1.567,1.567-1.566,1.566a.554.554,0,0,0,0,.783l.783.783a.554.554,0,0,0,.783,0l1.566-1.566,1.567,1.567a.554.554,0,0,0,.783,0l.783-.783a.554.554,0,0,0,0-.783Z"
                                          transform="translate(0 -4.5)"
                                          fill="#2d8cff"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-8">
                                      <a
                                        href="#"
                                        style="color: #fff"
                                        @click="
                                          renameUser(
                                            parti.userDetails,
                                            parti.socketId
                                          )
                                        "
                                        >Edit Name</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15.547"
                                        height="15.67"
                                        viewBox="0 0 15.547 15.67"
                                      >
                                        <g
                                          id="Group_348"
                                          data-name="Group 348"
                                          transform="translate(0.5 0.624)"
                                        >
                                          <path
                                            id="Path_491"
                                            data-name="Path 491"
                                            d="M9.507,6H4.446A1.446,1.446,0,0,0,3,7.446V17.567a1.446,1.446,0,0,0,1.446,1.446H14.567a1.446,1.446,0,0,0,1.446-1.446V12.507"
                                            transform="translate(-3 -4.467)"
                                            fill="none"
                                            stroke="#2d8cff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_492"
                                            data-name="Path 492"
                                            d="M19.591,3.267A1.534,1.534,0,1,1,21.76,5.436L14.892,12.3,12,13.027l.723-2.892Z"
                                            transform="translate(-7.662 -2.818)"
                                            fill="none"
                                            stroke="#2d8cff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </div>
                            <div class="col-6 text-right">
                              <!-- <p class="badge badge-pill badge-info">Host</p> -->
                            </div>
                          </div>
                        </div>
  
                        <div
                          class="tab-pane fade"
                          id="guests-tab"
                          role="tabpanel"
                          aria-labelledby="kt_tab_pane_2"
                        >
                          <h3
                            style="text-align: center"
                            v-if="filterdParticipants.length == 0"
                          >
                            No Participants.
                          </h3>
                          <div
                            v-for="parti in filterdParticipants"
                            :key="parti.socketId"
                            :id="'UPDATIsNG_' + parti.socketId"
                            class="row mt-3"
                          >
                            <div
                              class="col-6"
                              style="display: flex; align-items: center"
                              :style="isActionPopup ? 'opacity: 0.1;' : ''"
                            >
                              <img
                                :src="
                                  parti.userDetails.profileImg
                                    ? BASE_URL + parti.userDetails.profileImg
                                    : '../../assets/media/unitydefaultlogo.png'
                                "
                                alt="John Doe"
                                class="mr-2 rounded participants-img-small"
                              />
                              <div class="participant-menber-info">
                                <h5 class="text-white extra-small">
                                  {{ parti.userDetails.user_name }}
                                </h5>
                              </div>
                            </div>
                            <div
                              style="display: none"
                              class="col-6"
                              :id="'UPDATING_' + parti.socketId"
                            >
                              <span
                                class="spinner spinner-white spinner-right"
                              ></span>
                            </div>
                            <div class="col-6 text-right">
                              <p
                                v-if="parti.is_mute"
                                class="badge badge-pill badge-info bg-blue"
                              >
                                Muted
                              </p>
                              <p
                                v-if="parti.is_ban"
                                class="badge badge-pill badge-info bg-danger"
                              >
                                Banned
                              </p>
  
                              <span
                                style="margin-left: 8px"
                                v-if="
                                  role == 'HOST' ||
                                  role == 'MODERATOR' ||
                                  role == 'MODERATOR'
                                "
                              >
                                <a
                                  href="javascript:void(0)"
                                  data-toggle="dropdown"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="5"
                                    height="19"
                                    viewBox="0 0 5 19"
                                  >
                                    <g
                                      id="Group_334"
                                      data-name="Group 334"
                                      transform="translate(-1545 -326)"
                                    >
                                      <circle
                                        id="Ellipse_30"
                                        data-name="Ellipse 30"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 326)"
                                        fill="#fff"
                                        opacity="0.5"
                                      />
                                      <circle
                                        id="Ellipse_31"
                                        data-name="Ellipse 31"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 333)"
                                        fill="#fff"
                                        opacity="0.5"
                                      />
                                      <circle
                                        id="Ellipse_32"
                                        data-name="Ellipse 32"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 340)"
                                        fill="#fff"
                                        opacity="0.5"
                                      />
                                    </g>
                                  </svg>
                                </a>
                                <div
                                  class="dropdown-menu dropdown-menu-right bg-gray"
                                  :id="'openactiondropdownC' + parti.storageId"
                                  style="box-shadow: 1px 4px 13px #ffffff2b"
                                >
                                  <div
                                    class="border-bottom-white"
                                    style="display: flex; align-items: center"
                                  >
                                    <img
                                      :src="
                                        parti.userDetails.profileImg
                                          ? BASE_URL +
                                            parti.userDetails.profileImg
                                          : '../../assets/media/unitydefaultlogo.png'
                                      "
                                      :alt="parti.firstName"
                                      class="mr-2 rounded participants-img-small"
                                    />
                                    <div class="participant-menber-info">
                                      <h5 class="text-white extra-small">
                                        {{ parti.userDetails.user_name }}
                                        <br />
                                        <span style="color: #bbbbbb">
                                          {{ parti.userDetails.email }}</span
                                        >
                                      </h5>
                                    </div>
                                  </div>
  
                                  <div
                                    class="row mb-2"
                                    v-if="parti.socketId != socket.id"
                                  >
                                    <div class="col-8">
                                      <a
                                        @click="BannedUser(parti.storageId)"
                                        href="#"
                                        style="color: #fff"
                                        >{{
                                          parti.is_ban
                                            ? "Remove from ban"
                                            : "Ban User"
                                        }}</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15.76"
                                        height="15.76"
                                        viewBox="0 0 15.76 15.76"
                                      >
                                        <path
                                          id="Icon_awesome-ban"
                                          data-name="Icon awesome-ban"
                                          d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                          transform="translate(-0.563 -0.563)"
                                          fill="#2d8cff"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    class="row mb-2"
                                    v-if="
                                      (!parti.screenShare &&
                                        socket.id != parti.socketId &&
                                        role == 'HOST') ||
                                      (role == 'MODERATOR' &&
                                        !audioMicClose.includes(parti.socketId))
                                    "
                                  >
                                    <div class="col-8">
                                      <a
                                        @click="muteMic(parti.socketId)"
                                        href="#"
                                        style="color: #fff"
                                        >Mute User</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.575"
                                        height="13.182"
                                        viewBox="0 0 17.575 13.182"
                                      >
                                        <path
                                          id="Icon_awesome-volume-mute"
                                          data-name="Icon awesome-volume-mute"
                                          d="M7.381,4.742,4.327,7.8H.824A.824.824,0,0,0,0,8.619v4.943a.824.824,0,0,0,.824.824h3.5l3.054,3.053a.824.824,0,0,0,1.406-.583V5.325A.824.824,0,0,0,7.381,4.742Zm8.465,6.349,1.567-1.567a.554.554,0,0,0,0-.783l-.783-.783a.554.554,0,0,0-.783,0L14.279,9.524,12.713,7.957a.554.554,0,0,0-.783,0l-.783.783a.554.554,0,0,0,0,.783l1.567,1.567-1.566,1.566a.554.554,0,0,0,0,.783l.783.783a.554.554,0,0,0,.783,0l1.566-1.566,1.567,1.567a.554.554,0,0,0,.783,0l.783-.783a.554.554,0,0,0,0-.783Z"
                                          transform="translate(0 -4.5)"
                                          fill="#2d8cff"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-8">
                                      <a
                                        href="#"
                                        style="color: #fff"
                                        @click="
                                          renameUser(
                                            parti.userDetails,
                                            parti.socketId
                                          )
                                        "
                                        >Edit Name</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15.547"
                                        height="15.67"
                                        viewBox="0 0 15.547 15.67"
                                      >
                                        <g
                                          id="Group_348"
                                          data-name="Group 348"
                                          transform="translate(0.5 0.624)"
                                        >
                                          <path
                                            id="Path_491"
                                            data-name="Path 491"
                                            d="M9.507,6H4.446A1.446,1.446,0,0,0,3,7.446V17.567a1.446,1.446,0,0,0,1.446,1.446H14.567a1.446,1.446,0,0,0,1.446-1.446V12.507"
                                            transform="translate(-3 -4.467)"
                                            fill="none"
                                            stroke="#2d8cff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_492"
                                            data-name="Path 492"
                                            d="M19.591,3.267A1.534,1.534,0,1,1,21.76,5.436L14.892,12.3,12,13.027l.723-2.892Z"
                                            transform="translate(-7.662 -2.818)"
                                            fill="none"
                                            stroke="#2d8cff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
  
                        <div
                          class="tab-pane fade"
                          id="viewers-tab"
                          role="tabpanel"
                          aria-labelledby="kt_tab_pane_2"
                        >
                          <h3
                            style="text-align: center"
                            v-if="filterdParticipants.length == 0"
                          >
                            No Participants.
                          </h3>
                          <div
                            v-for="parti in filterdParticipants"
                            :key="parti.userid"
                            :id="'UPDATsING_' + parti.userid"
                            class="row mt-3"
                          >
                            <div
                              class="col-6"
                              style="display: flex; align-items: center"
                              :style="isActionPopup ? 'opacity: 0.1;' : ''"
                            >
                              <img
                                :src="
                                  parti.userDetails.profileImg
                                    ? BASE_URL + parti.userDetails.profileImg
                                    : '../../assets/media/unitydefaultlogo.png'
                                "
                                alt="John Doe"
                                class="mr-2 rounded participants-img-small"
                              />
                              <div class="participant-menber-info">
                                <h5 class="text-white extra-small">
                                  {{ parti.userDetails.user_name }}
                                  <br />
                                  <span style="color: #bbbbbb">
                                    {{ parti.userDetails.email }}</span
                                  >
                                </h5>
                              </div>
                            </div>
  
                            <div
                              style="display: none"
                              class="col-6"
                              :id="'UPDATING_' + parti.userid"
                            >
                              <span
                                class="spinner spinner-white spinner-right"
                              ></span>
                            </div>
                            <div class="col-6 text-right">
                              <p
                                v-if="parti.is_mute"
                                class="badge badge-pill badge-info bg-blue"
                              >
                                Muted
                              </p>
                              <p
                                v-if="parti.is_ban"
                                class="badge badge-pill badge-info bg-danger"
                              >
                                Banned
                              </p>
  
                              <span
                                style="margin-left: 8px"
                                v-if="
                                  role == 'HOST' ||
                                  role == 'MODERATOR' ||
                                  role == 'MODERATOR'
                                "
                              >
                                <a
                                  href="javascript:void(0)"
                                  data-toggle="dropdown"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="5"
                                    height="19"
                                    viewBox="0 0 5 19"
                                  >
                                    <g
                                      id="Group_334"
                                      data-name="Group 334"
                                      transform="translate(-1545 -326)"
                                    >
                                      <circle
                                        id="Ellipse_30"
                                        data-name="Ellipse 30"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 326)"
                                        fill="#fff"
                                        opacity="0.5"
                                      />
                                      <circle
                                        id="Ellipse_31"
                                        data-name="Ellipse 31"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 333)"
                                        fill="#fff"
                                        opacity="0.5"
                                      />
                                      <circle
                                        id="Ellipse_32"
                                        data-name="Ellipse 32"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(1545 340)"
                                        fill="#fff"
                                        opacity="0.5"
                                      />
                                    </g>
                                  </svg>
                                </a>
                                <div
                                  class="dropdown-menu dropdown-menu-right bg-gray"
                                  :id="'openactiondropdownD' + parti.storageId"
                                  style="box-shadow: 1px 4px 13px #ffffff2b"
                                >
                                  <div
                                    class="border-bottom-white"
                                    style="display: flex; align-items: center"
                                  >
                                    <img
                                      :src="
                                        parti.userDetails.profileImg
                                          ? BASE_URL +
                                            parti.userDetails.profileImg
                                          : '../../assets/media/unitydefaultlogo.png'
                                      "
                                      :alt="parti.firstName"
                                      class="mr-2 rounded participants-img-small"
                                    />
                                    <div class="participant-menber-info">
                                      <h5 class="text-white extra-small">
                                        {{ parti.userDetails.user_name }}
                                        <br />
                                        <span style="color: #bbbbbb">
                                          {{ parti.userDetails.email }}</span
                                        >
                                      </h5>
                                    </div>
                                  </div>
                                  <div
                                    class="row mb-2"
                                    v-if="parti.socketId != socket.id"
                                  >
                                    <div class="col-8">
                                      <a
                                        @click="
                                          changeRoleModal(
                                            parti.userDetails,
                                            parti.socketId,
                                            'VIEWER'
                                          )
                                        "
                                        href="#"
                                        style="color: #fff"
                                        >Manage Roll</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15.76"
                                        height="15.76"
                                        viewBox="0 0 15.76 15.76"
                                      >
                                        <path
                                          id="Icon_awesome-ban"
                                          data-name="Icon awesome-ban"
                                          d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                          transform="translate(-0.563 -0.563)"
                                          fill="#2d8cff"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    class="row mb-2"
                                    v-if="parti.socketId != socket.id"
                                  >
                                    <div class="col-8">
                                      <a
                                        @click="BannedUser(parti.storageId)"
                                        href="#"
                                        style="color: #fff"
                                        >{{
                                          parti.is_ban
                                            ? "Remove from ban"
                                            : "Ban User"
                                        }}</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15.76"
                                        height="15.76"
                                        viewBox="0 0 15.76 15.76"
                                      >
                                        <path
                                          id="Icon_awesome-ban"
                                          data-name="Icon awesome-ban"
                                          d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                          transform="translate(-0.563 -0.563)"
                                          fill="#2d8cff"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    class="row mb-2"
                                    v-if="
                                      (!parti.screenShare &&
                                        socket.id != parti.socketId &&
                                        role == 'HOST') ||
                                      (role == 'MODERATOR' &&
                                        !audioMicClose.includes(parti.socketId))
                                    "
                                  >
                                    <div class="col-8">
                                      <a
                                        @click="muteMic(parti.socketId)"
                                        href="#"
                                        style="color: #fff"
                                        >Mute User</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.575"
                                        height="13.182"
                                        viewBox="0 0 17.575 13.182"
                                      >
                                        <path
                                          id="Icon_awesome-volume-mute"
                                          data-name="Icon awesome-volume-mute"
                                          d="M7.381,4.742,4.327,7.8H.824A.824.824,0,0,0,0,8.619v4.943a.824.824,0,0,0,.824.824h3.5l3.054,3.053a.824.824,0,0,0,1.406-.583V5.325A.824.824,0,0,0,7.381,4.742Zm8.465,6.349,1.567-1.567a.554.554,0,0,0,0-.783l-.783-.783a.554.554,0,0,0-.783,0L14.279,9.524,12.713,7.957a.554.554,0,0,0-.783,0l-.783.783a.554.554,0,0,0,0,.783l1.567,1.567-1.566,1.566a.554.554,0,0,0,0,.783l.783.783a.554.554,0,0,0,.783,0l1.566-1.566,1.567,1.567a.554.554,0,0,0,.783,0l.783-.783a.554.554,0,0,0,0-.783Z"
                                          transform="translate(0 -4.5)"
                                          fill="#2d8cff"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-8">
                                      <a
                                        href="#"
                                        style="color: #fff"
                                        @click="
                                          renameUser(
                                            parti.userDetails,
                                            parti.socketId
                                          )
                                        "
                                        >Edit Name</a
                                      >
                                    </div>
                                    <div class="col-4 text-right">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15.547"
                                        height="15.67"
                                        viewBox="0 0 15.547 15.67"
                                      >
                                        <g
                                          id="Group_348"
                                          data-name="Group 348"
                                          transform="translate(0.5 0.624)"
                                        >
                                          <path
                                            id="Path_491"
                                            data-name="Path 491"
                                            d="M9.507,6H4.446A1.446,1.446,0,0,0,3,7.446V17.567a1.446,1.446,0,0,0,1.446,1.446H14.567a1.446,1.446,0,0,0,1.446-1.446V12.507"
                                            transform="translate(-3 -4.467)"
                                            fill="none"
                                            stroke="#2d8cff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_492"
                                            data-name="Path 492"
                                            d="M19.591,3.267A1.534,1.534,0,1,1,21.76,5.436L14.892,12.3,12,13.027l.723-2.892Z"
                                            transform="translate(-7.662 -2.818)"
                                            fill="none"
                                            stroke="#2d8cff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end:: Form-->
                </div>
              </div>
              <div id="chat" class="tab-pane fade active show">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <h5
                    style="font-size: x-large; margin: 0"
                    class="card-title fw-bolder text-white"
                  >
                    Live Chat
                  </h5>
  
                  <a href="javascript:void(0)" class="" onclick="closeNav()">
                    <svg
                      id="Icon_open-collapse-left"
                      data-name="Icon open-collapse-left"
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.785"
                      height="22.785"
                      viewBox="0 0 22.785 22.785"
                    >
                      <path
                        id="Icon_open-collapse-left-2"
                        data-name="Icon open-collapse-left"
                        d="M22.785,0V22.785H19.937V0ZM5.7,0V22.785H0V0ZM8.544,5.7l5.7,5.7-5.7,5.7Z"
                        fill="#2d8cff"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div id="participants1">
                  <!-- begin:: Form-->
                  <div class="mt-4">
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        style="background: #374151; color: white"
                        placeholder="Search..."
                        v-model="searchMessage"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text p-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                          >
                            <g
                              id="Group_58"
                              data-name="Group 58"
                              transform="translate(-1520 -98)"
                            >
                              <rect
                                id="Rectangle_125"
                                data-name="Rectangle 125"
                                width="32"
                                height="32"
                                rx="8"
                                transform="translate(1520 98)"
                                fill="#2d8cff"
                              ></rect>
                              <g
                                id="Icon_feather-search"
                                data-name="Icon feather-search"
                                transform="translate(1529.495 107.645)"
                              >
                                <path
                                  id="Path_426"
                                  data-name="Path 426"
                                  d="M15.622,10.061A5.561,5.561,0,1,1,10.061,4.5,5.561,5.561,0,0,1,15.622,10.061Z"
                                  transform="translate(-4.5 -4.5)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                ></path>
                                <path
                                  id="Path_427"
                                  data-name="Path 427"
                                  d="M28,28l-3.024-3.024"
                                  transform="translate(-15.486 -15.486)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                ></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end:: Form-->
  
                  <div style="height: 65vh; overflow-y: auto; overflow-x: hidden">
                    <div
                      class="chatBox"
                      v-for="(chat, i) in chatFilteredList"
                      :key="i"
                      style="display: flex; margin-bottom: 10px"
                    >
                      <img
                        style="width: 35px; height: 35px"
                        :src="BASE_URL + chat.profileImg"
                        class="mr-2 rounded participants-img-small"
                      />
  
                      <div
                        class="two-line-paragraph"
                        style="display: flex; align-items: center"
                      >
                        <p
                          v-html="chat.message"
                          style="
                            color: white;
                            font-size: 15px;
                            overflow-wrap: break-word;
                            text-align: start;
                            width: -webkit-fill-available;
                            margin: 0;
                          "
                        ></p>
                      </div>
  
                      <a
                        href="javascript:void(0)"
                        class="threeDots"
                        data-toggle="dropdown"
                        v-if="role == 'HOST' || role == 'MODERATOR'"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="5"
                          height="19"
                          viewBox="0 0 5 19"
                        >
                          <g
                            id="Group_334"
                            data-name="Group 334"
                            transform="translate(-1545 -326)"
                          >
                            <circle
                              id="Ellipse_30"
                              data-name="Ellipse 30"
                              cx="2.5"
                              cy="2.5"
                              r="2.5"
                              transform="translate(1545 326)"
                              fill="#fff"
                              opacity="0.5"
                            />
                            <circle
                              id="Ellipse_31"
                              data-name="Ellipse 31"
                              cx="2.5"
                              cy="2.5"
                              r="2.5"
                              transform="translate(1545 333)"
                              fill="#fff"
                              opacity="0.5"
                            />
                            <circle
                              id="Ellipse_32"
                              data-name="Ellipse 32"
                              cx="2.5"
                              cy="2.5"
                              r="2.5"
                              transform="translate(1545 340)"
                              fill="#fff"
                              opacity="0.5"
                            />
                          </g>
                        </svg>
                      </a>
  
                      <div
                        v-if="role == 'HOST' || role == 'MODERATOR'"
                        class="dropdown-menu dropdown-menu-right bg-gray openactiondropdown"
                        id="openactiondropdownALocalStream_storageId"
                        style="box-shadow: rgba(255, 255, 255, 0.17) 1px 4px 13px"
                      >
                        <div
                          class="border-bottom-white"
                          v-if="chat.socketId != socket.id"
                          style="display: flex; align-items: center"
                        >
                          <img
                            :src="BASE_URL + chat.profileImg"
                            class="mr-2 rounded participants-img-small"
                          />
                          <div class="participant-menber-info">
                            <h5 class="text-white extra-small">
                              {{ chat.user }} <br />
                            </h5>
                          </div>
                        </div>
                        <div class="row mb-2" v-if="chat.socketId != socket.id">
                          <div class="col-8">
                            <a
                              href="#"
                              @click="muteMic(chat.socketId)"
                              style="color: #fff"
                              >{{
                                audioMicClose.includes(chat.socketId)
                                  ? "Unmute Mic"
                                  : "Mute Mic"
                              }}</a
                            >
                          </div>
                          <div class="col-4 text-right">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.575"
                              height="13.182"
                              viewBox="0 0 17.575 13.182"
                            >
                              <path
                                id="Icon_awesome-volume-mute"
                                data-name="Icon awesome-volume-mute"
                                d="M7.381,4.742,4.327,7.8H.824A.824.824,0,0,0,0,8.619v4.943a.824.824,0,0,0,.824.824h3.5l3.054,3.053a.824.824,0,0,0,1.406-.583V5.325A.824.824,0,0,0,7.381,4.742Zm8.465,6.349,1.567-1.567a.554.554,0,0,0,0-.783l-.783-.783a.554.554,0,0,0-.783,0L14.279,9.524,12.713,7.957a.554.554,0,0,0-.783,0l-.783.783a.554.554,0,0,0,0,.783l1.567,1.567-1.566,1.566a.554.554,0,0,0,0,.783l.783.783a.554.554,0,0,0,.783,0l1.566-1.566,1.567,1.567a.554.554,0,0,0,.783,0l.783-.783a.554.554,0,0,0,0-.783Z"
                                transform="translate(0 -4.5)"
                                fill="#2d8cff"
                              />
                            </svg>
                          </div>
                        </div>
  
                        <div class="row mb-2" v-if="chat.socketId != socket.id">
                          <div class="col-8">
                            <a
                              href="#"
                              @click="
                                banChatUser(chat.userId, chat.socketId, true)
                              "
                              style="color: #fff"
                              >Ban User</a
                            >
                          </div>
                          <div class="col-4 text-right">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.76"
                              height="15.76"
                              viewBox="0 0 15.76 15.76"
                            >
                              <path
                                id="Icon_awesome-ban"
                                data-name="Icon awesome-ban"
                                d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                transform="translate(-0.563 -0.563)"
                                fill="#2d8cff"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="row mb-2" v-if="chat.socketId != socket.id">
                          <div class="col-10">
                            <a
                              href="#"
                              @click="
                                muteChatUser(
                                  chat.userId,
                                  chat.socketId,
                                  chat.is_chat_mute ? false : true
                                )
                              "
                              style="color: #fff"
                              >{{
                                chat.is_chat_mute
                                  ? "Unmute From Chat"
                                  : "Mute From Chat"
                              }}
                            </a>
                          </div>
                          <div class="col-1 text-right" style="padding-left: 4px">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.76"
                              height="15.76"
                              viewBox="0 0 15.76 15.76"
                            >
                              <path
                                id="Icon_awesome-ban"
                                data-name="Icon awesome-ban"
                                d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                transform="translate(-0.563 -0.563)"
                                fill="#2d8cff"
                              />
                            </svg>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div
                            class="col-8"
                            style="display: flex; align-items: center"
                          >
                            <a
                              href="#"
                              @click="deleteMessage(chat)"
                              style="color: #fff"
                              >Delete Message</a
                            >
                          </div>
                          <div
                            class="col-4 text-right"
                            style="padding-right: 7px"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              enable-background="new 0 0 64 64"
                              viewBox="0 0 64 64"
                              id="Delete"
                            >
                              <g
                                transform="translate(232 228)"
                                fill="#000000"
                                class="color000000 svgShape"
                              >
                                <path
                                  fill="#2d8cff"
                                  d="M-207.5-205.1h3v24h-3zM-201.5-205.1h3v24h-3zM-195.5-205.1h3v24h-3zM-219.5-214.1h39v3h-39z"
                                  class="color134563 svgShape"
                                ></path>
                                <path
                                  fill="#2d8cff"
                                  d="M-192.6-212.6h-2.8v-3c0-.9-.7-1.6-1.6-1.6h-6c-.9 0-1.6.7-1.6 1.6v3h-2.8v-3c0-2.4 2-4.4 4.4-4.4h6c2.4 0 4.4 2 4.4 4.4v3"
                                  class="color134563 svgShape"
                                ></path>
                                <path
                                  fill="#2d8cff"
                                  d="M-191-172.1h-18c-2.4 0-4.5-2-4.7-4.4l-2.8-36 3-.2 2.8 36c.1.9.9 1.6 1.7 1.6h18c.9 0 1.7-.8 1.7-1.6l2.8-36 3 .2-2.8 36c-.2 2.5-2.3 4.4-4.7 4.4"
                                  class="color134563 svgShape"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-8">
                            <a
                              href="#"
                              @click="editMessage(chat)"
                              style="color: #fff"
                              >Edit Message</a
                            >
                          </div>
                          <div class="col-4 text-right">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.547"
                              height="16"
                              viewBox="0 0 15.547 15.67"
                            >
                              <g
                                id="Group_348"
                                data-name="Group 348"
                                transform="translate(0.5 0.624)"
                              >
                                <path
                                  id="Path_491"
                                  data-name="Path 491"
                                  d="M9.507,6H4.446A1.446,1.446,0,0,0,3,7.446V17.567a1.446,1.446,0,0,0,1.446,1.446H14.567a1.446,1.446,0,0,0,1.446-1.446V12.507"
                                  transform="translate(-3 -4.467)"
                                  fill="none"
                                  stroke="#2d8cff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                ></path>
                                <path
                                  id="Path_492"
                                  data-name="Path 492"
                                  d="M19.591,3.267A1.534,1.534,0,1,1,21.76,5.436L14.892,12.3,12,13.027l.723-2.892Z"
                                  transform="translate(-7.662 -2.818)"
                                  fill="none"
                                  stroke="#2d8cff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div
                      class="kt-portlet__foot"
                      style="position: fixed; bottom: 0; width: 94%"
                    >
                      <div
                        style="
                          position: fixed;
                          bottom: 0px;
                          width: 90%;
                          background-color: #0f1621;
                          padding: 12px;
                          border-radius: 13px;
                          margin-bottom: 10px;
                          right: 17px;
                        "
                      >
                        <div style="display: flex">
                          <span
                            style="margin-right: 11px; display: block !important"
                            class="emoji"
                            id="emojiPicker"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.318"
                              height="12.316"
                              viewBox="0 0 12.318 12.316"
                            >
                              <path
                                id="Path_555"
                                data-name="Path 555"
                                d="M308.492,937.333a6.159,6.159,0,1,0,6.159,6.159A6.166,6.166,0,0,0,308.492,937.333Zm0,.924a5.235,5.235,0,1,1-5.235,5.235A5.228,5.228,0,0,1,308.492,938.257Zm-2.617,2.771a.459.459,0,0,0-.331.133.463.463,0,0,0-.1.151.47.47,0,0,0,0,.357.455.455,0,0,0,.1.151.446.446,0,0,0,.151.1.457.457,0,0,0,.179.033h1.231a.46.46,0,0,0,.179-.033.446.446,0,0,0,.151-.1.461.461,0,0,0,.1-.507.46.46,0,0,0-.1-.151.459.459,0,0,0-.331-.133Zm4,0a.459.459,0,0,0-.331.133.461.461,0,0,0-.1.151.462.462,0,0,0,.1.507.446.446,0,0,0,.151.1.46.46,0,0,0,.179.033h1.231a.457.457,0,0,0,.179-.033.446.446,0,0,0,.151-.1.456.456,0,0,0,.1-.151.47.47,0,0,0,0-.357.463.463,0,0,0-.1-.151.459.459,0,0,0-.331-.133Zm-4,2.464a.461.461,0,0,0-.462.462,3.083,3.083,0,1,0,6.158,0,.46.46,0,0,0-.462-.462Zm.554.924h4.126a2.075,2.075,0,0,1-4.126,0Z"
                                transform="translate(-302.333 -937.334)"
                                fill="#9ca3af"
                              ></path>
                            </svg>
                          </span>
                          <textarea
                            rows="3"
                            cols="50"
                            style="
                              background: transparent;
                              border: none;
                              resize: none;
                              width: -webkit-fill-available;
                              text-decoration: none;
                              outline: none;
                              color: white;
                            "
                            :disabled="is_disable || is_chat_mute == true"
                            :placeholder="
                              is_chat_mute
                                ? 'You are not able to send message.'
                                : peers.find(
                                    (peer) => peer?.socketId === senderRole
                                  )?.userDetails.user_name == undefined
                                ? 'Message to Everyone'
                                : 'Message to ' +
                                  peers.find(
                                    (peer) => peer?.socketId === senderRole
                                  )?.userDetails.user_name
                            "
                            spellcheck="false"
                            v-model="chatMessage"
                            @keydown="chatInputHandler"
                          >
                          </textarea>
                        </div>
  
                        <div
                          style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <div
                            class="form-group col-6"
                            style="
                              margin: 0 !important;
                              padding-left: 0px !important;
                            "
                          >
                            <select
                              style="
                                color: white;
                                background: rgb(15, 22, 33);
                                border: 1px solid #232d3d !important;
                                border-radius: 4px;
                                padding: 0px 0px;
                              "
                              class="blue-border dark-bg form-control"
                              v-model="senderRole"
                              placeholder="Time Zone"
                            >
                              <option value="Everyone" selected>Everyone</option>
  
                              <option
                                v-for="peer in peers"
                                v-if="peers.length"
                                :value="peer.socketId"
                              >
                                {{ peer.userDetails.user_name }}
                                {{ peer.socketId == socketId ? "(You)" : "" }}
                              </option>
                            </select>
                          </div>
                          <div class="kt_chat__actions">
                            <span class="attachments">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 12.01 12.299"
                              >
                                <path
                                  id="Path_554"
                                  data-name="Path 554"
                                  d="M278.8,937.348a3.546,3.546,0,0,0-2.514,1.037l-5.756,5.757a.626.626,0,0,0-.139.2.619.619,0,0,0-.05.238.629.629,0,0,0,.045.239.614.614,0,0,0,1.014.193l5.756-5.756a2.324,2.324,0,1,1,3.287,3.286l-5.547,5.548A1.126,1.126,0,0,1,273.3,946.5l4.417-4.417a.612.612,0,0,0,.139-.2.62.62,0,0,0,.051-.238.621.621,0,0,0-.18-.442.615.615,0,0,0-.442-.18.618.618,0,0,0-.237.05.632.632,0,0,0-.2.139l-4.417,4.417a2.357,2.357,0,0,0,3.334,3.334l5.547-5.547a3.551,3.551,0,0,0-2.514-6.066Z"
                                  transform="translate(-270.339 -937.349)"
                                  fill="white"
                                ></path>
                              </svg>
                            </span>
                            <button
                              type="button"
                              style="
                                margin-left: 11px;
                                border-radius: 50%;
                                padding: 6px 7px;
                                background-color: #2d8cff;
                              "
                              :disabled="
                                chatMessage == '' || is_chat_mute == true
                              "
                              v-on:click="sendMessage()"
                              class="btn btn-brand btn-md btn-upper btn-bold kt-chat__reply"
                            >
                              <span
                                v-if="is_message_loader"
                                class="spinner spinner-white spinner-right"
                              ></span>
                              <svg
                                v-if="is_message_loader == false"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="15"
                                viewBox="0 0 18.475 18.473"
                              >
                                <path
                                  id="Path_557"
                                  data-name="Path 557"
                                  d="M291.668,984a.681.681,0,0,0-.314.089.693.693,0,0,0-.307.854l2.54,6.559a.695.695,0,0,0,.535.433l6.9,1.12c.172.028.155.078.155.182s.017.154-.155.182l-6.9,1.121a.694.694,0,0,0-.535.433l-2.54,6.56a.678.678,0,0,0-.028.406.665.665,0,0,0,.208.351.636.636,0,0,0,.37.176.68.68,0,0,0,.4-.065l17.09-8.544a.693.693,0,0,0,0-1.24L292,984.073A.687.687,0,0,0,291.668,984Z"
                                  transform="translate(-291 -984)"
                                  fill="#fff"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="kt-chat__input">
                        <div class="kt-chat__editor"></div>
                      </div>
                    </div>
  
                    <div v-for="(chat, i) in !chatFilteredList" :key="i">
                      <div
                        class="kt-chat__message"
                        v-if="chat.socketId != socket.id"
                      >
                        <div class="kt-chat__user">
                          <a
                            href="javascript:void(0)"
                            data-toggle="dropdown"
                            class="kt-media kt-media--circle kt-media--sm"
                          >
                            <img :src="BASE_URL + chat.profileImg" alt="image" />
                          </a>
                          From
                          <a href="#" class="kt-chat__username">{{
                            chat.user
                          }}</a>
                          to <a href="#">Everyone</a>
                          <!-- <span class="kt-chat__datetime">2 Hours</span> -->
                        </div>
                        <div class="d-flex align-items-center">
                          <div
                            style="
                              word-wrap: break-word;
                              width: -webkit-fill-available;
                              text-align: start;
                            "
                            class="kt-chat__text kt-bg-light-brand col-9"
                            v-html="chat.message"
                          ></div>
                          <a
                            href="javascript:void(0)"
                            data-toggle="dropdown"
                            style="margin-left: 10px"
                            v-if="role == 'HOST' || role == 'MODERATOR'"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="5"
                              height="19"
                              viewBox="0 0 5 19"
                            >
                              <g
                                id="Group_334"
                                data-name="Group 334"
                                transform="translate(-1545 -326)"
                              >
                                <circle
                                  id="Ellipse_30"
                                  data-name="Ellipse 30"
                                  cx="2.5"
                                  cy="2.5"
                                  r="2.5"
                                  transform="translate(1545 326)"
                                  fill="#fff"
                                  opacity="0.5"
                                />
                                <circle
                                  id="Ellipse_31"
                                  data-name="Ellipse 31"
                                  cx="2.5"
                                  cy="2.5"
                                  r="2.5"
                                  transform="translate(1545 333)"
                                  fill="#fff"
                                  opacity="0.5"
                                />
                                <circle
                                  id="Ellipse_32"
                                  data-name="Ellipse 32"
                                  cx="2.5"
                                  cy="2.5"
                                  r="2.5"
                                  transform="translate(1545 340)"
                                  fill="#fff"
                                  opacity="0.5"
                                />
                              </g>
                            </svg>
                          </a>
  
                          <div
                            v-if="role == 'HOST' || role == 'MODERATOR'"
                            class="dropdown-menu dropdown-menu-right bg-gray openactiondropdown"
                            id="openactiondropdownALocalStream_storageId"
                            style="
                              box-shadow: rgba(255, 255, 255, 0.17) 1px 4px 13px;
                            "
                          >
                            <div
                              class="border-bottom-white"
                              style="display: flex; align-items: center"
                            >
                              <img
                                :src="BASE_URL + chat.profileImg"
                                class="mr-2 rounded participants-img-small"
                              />
                              <div class="participant-menber-info">
                                <h5 class="text-white extra-small">
                                  {{ chat.user }} <br />
                                </h5>
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-8">
                                <a
                                  href="#"
                                  @click="muteMic(chat.socketId)"
                                  style="color: #fff"
                                  >{{
                                    audioMicClose.includes(chat.socketId)
                                      ? "Unmute Mic"
                                      : "Mute Mic"
                                  }}</a
                                >
                              </div>
                              <div class="col-4 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17.575"
                                  height="13.182"
                                  viewBox="0 0 17.575 13.182"
                                >
                                  <path
                                    id="Icon_awesome-volume-mute"
                                    data-name="Icon awesome-volume-mute"
                                    d="M7.381,4.742,4.327,7.8H.824A.824.824,0,0,0,0,8.619v4.943a.824.824,0,0,0,.824.824h3.5l3.054,3.053a.824.824,0,0,0,1.406-.583V5.325A.824.824,0,0,0,7.381,4.742Zm8.465,6.349,1.567-1.567a.554.554,0,0,0,0-.783l-.783-.783a.554.554,0,0,0-.783,0L14.279,9.524,12.713,7.957a.554.554,0,0,0-.783,0l-.783.783a.554.554,0,0,0,0,.783l1.567,1.567-1.566,1.566a.554.554,0,0,0,0,.783l.783.783a.554.554,0,0,0,.783,0l1.566-1.566,1.567,1.567a.554.554,0,0,0,.783,0l.783-.783a.554.554,0,0,0,0-.783Z"
                                    transform="translate(0 -4.5)"
                                    fill="#2d8cff"
                                  />
                                </svg>
                              </div>
                            </div>
  
                            <div class="row mb-2">
                              <div class="col-8">
                                <a
                                  href="#"
                                  @click="
                                    banChatUser(chat.userId, chat.socketId, true)
                                  "
                                  style="color: #fff"
                                  >Ban User</a
                                >
                              </div>
                              <div class="col-4 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.76"
                                  height="15.76"
                                  viewBox="0 0 15.76 15.76"
                                >
                                  <path
                                    id="Icon_awesome-ban"
                                    data-name="Icon awesome-ban"
                                    d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                    transform="translate(-0.563 -0.563)"
                                    fill="#2d8cff"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div
                              class="row mb-2"
                              v-if="role == 'HOST' || role == 'MODERATOR'"
                            >
                              <div class="col-10">
                                <a
                                  href="#"
                                  @click="
                                    muteChatUser(
                                      chat.userId,
                                      chat.socketId,
                                      chat.is_chat_mute ? false : true
                                    )
                                  "
                                  style="color: #fff"
                                  >{{
                                    chat.is_chat_mute
                                      ? "Unmute From Chat"
                                      : "Mute From Chat"
                                  }}
                                </a>
                              </div>
                              <div
                                class="col-1 text-right"
                                style="padding-left: 4px"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.76"
                                  height="15.76"
                                  viewBox="0 0 15.76 15.76"
                                >
                                  <path
                                    id="Icon_awesome-ban"
                                    data-name="Icon awesome-ban"
                                    d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                    transform="translate(-0.563 -0.563)"
                                    fill="#2d8cff"
                                  />
                                </svg>
                              </div>
                            </div>
  
                            <div class="row mb-2">
                              <div
                                class="col-8"
                                style="display: flex; align-items: center"
                              >
                                <a
                                  href="#"
                                  @click="deleteMessage(chat)"
                                  style="color: #fff"
                                  >Delete Message</a
                                >
                              </div>
                              <div
                                class="col-4 text-right"
                                style="padding-right: 7px"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  enable-background="new 0 0 64 64"
                                  viewBox="0 0 64 64"
                                  id="Delete"
                                >
                                  <g
                                    transform="translate(232 228)"
                                    fill="#000000"
                                    class="color000000 svgShape"
                                  >
                                    <path
                                      fill="#2d8cff"
                                      d="M-207.5-205.1h3v24h-3zM-201.5-205.1h3v24h-3zM-195.5-205.1h3v24h-3zM-219.5-214.1h39v3h-39z"
                                      class="color134563 svgShape"
                                    ></path>
                                    <path
                                      fill="#2d8cff"
                                      d="M-192.6-212.6h-2.8v-3c0-.9-.7-1.6-1.6-1.6h-6c-.9 0-1.6.7-1.6 1.6v3h-2.8v-3c0-2.4 2-4.4 4.4-4.4h6c2.4 0 4.4 2 4.4 4.4v3"
                                      class="color134563 svgShape"
                                    ></path>
                                    <path
                                      fill="#2d8cff"
                                      d="M-191-172.1h-18c-2.4 0-4.5-2-4.7-4.4l-2.8-36 3-.2 2.8 36c.1.9.9 1.6 1.7 1.6h18c.9 0 1.7-.8 1.7-1.6l2.8-36 3 .2-2.8 36c-.2 2.5-2.3 4.4-4.7 4.4"
                                      class="color134563 svgShape"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
  
                            <div class="row mb-2">
                              <div class="col-8">
                                <a
                                  href="#"
                                  @click="editMessage(chat)"
                                  style="color: #fff"
                                  >Edit Message</a
                                >
                              </div>
                              <div class="col-4 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.547"
                                  height="16"
                                  viewBox="0 0 15.547 15.67"
                                >
                                  <g
                                    id="Group_348"
                                    data-name="Group 348"
                                    transform="translate(0.5 0.624)"
                                  >
                                    <path
                                      id="Path_491"
                                      data-name="Path 491"
                                      d="M9.507,6H4.446A1.446,1.446,0,0,0,3,7.446V17.567a1.446,1.446,0,0,0,1.446,1.446H14.567a1.446,1.446,0,0,0,1.446-1.446V12.507"
                                      transform="translate(-3 -4.467)"
                                      fill="none"
                                      stroke="#2d8cff"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1"
                                    ></path>
                                    <path
                                      id="Path_492"
                                      data-name="Path 492"
                                      d="M19.591,3.267A1.534,1.534,0,1,1,21.76,5.436L14.892,12.3,12,13.027l.723-2.892Z"
                                      transform="translate(-7.662 -2.818)"
                                      fill="none"
                                      stroke="#2d8cff"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="kt-chat__message kt-chat__message--right"
                        v-else
                      >
                        <div class="kt-chat__user">
                          <!-- <span class="kt-chat__datetime">30 Seconds</span> -->
                          From
                          <a href="#" class="kt-chat__username">{{
                            chat.user
                          }}</a>
                          to <a href="#">Everyone</a>
                          <a class="kt-media kt-media--circle kt-media--sm">
                            <img :src="BASE_URL + chat.profileImg" alt="image" />
                          </a>
                        </div>
                        <div
                          style="display: flex; flex-direction: row-reverse"
                          class="d-flex align-items-center"
                        >
                          <div
                            style="
                              word-wrap: break-word;
                              text-align: end;
                              overflow-wrap: break-word;
                              text-align: end;
                              width: -webkit-fill-available;
                            "
                            class="kt-chat__text kt-bg-light-success col-9"
                            v-html="chat.message"
                          ></div>
                          <a
                            href="javascript:void(0)"
                            data-toggle="dropdown"
                            style="margin-right: 10px"
                            v-if="role == 'HOST' || role == 'MODERATOR'"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="5"
                              height="19"
                              viewBox="0 0 5 19"
                            >
                              <g
                                id="Group_334"
                                data-name="Group 334"
                                transform="translate(-1545 -326)"
                              >
                                <circle
                                  id="Ellipse_30"
                                  data-name="Ellipse 30"
                                  cx="2.5"
                                  cy="2.5"
                                  r="2.5"
                                  transform="translate(1545 326)"
                                  fill="#fff"
                                  opacity="0.5"
                                />
                                <circle
                                  id="Ellipse_31"
                                  data-name="Ellipse 31"
                                  cx="2.5"
                                  cy="2.5"
                                  r="2.5"
                                  transform="translate(1545 333)"
                                  fill="#fff"
                                  opacity="0.5"
                                />
                                <circle
                                  id="Ellipse_32"
                                  data-name="Ellipse 32"
                                  cx="2.5"
                                  cy="2.5"
                                  r="2.5"
                                  transform="translate(1545 340)"
                                  fill="#fff"
                                  opacity="0.5"
                                />
                              </g>
                            </svg>
                          </a>
                          <div
                            v-if="role == 'HOST' || role == 'MODERATOR'"
                            class="dropdown-menu dropdown-menu-right bg-gray openactiondropdown"
                            id="openactiondropdownALocalStream_storageId"
                            style="
                              box-shadow: rgba(255, 255, 255, 0.17) 1px 4px 13px;
                            "
                          >
                            <div
                              class="border-bottom-white"
                              style="display: flex; align-items: center"
                            >
                              <img
                                :src="BASE_URL + chat.profileImg"
                                class="mr-2 rounded participants-img-small"
                              />
                              <div class="participant-menber-info">
                                <h5 class="text-white extra-small">
                                  {{ chat.user }} <br />
                                </h5>
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div
                                class="col-8"
                                style="display: flex; align-items: center"
                              >
                                <a
                                  href="#"
                                  @click="deleteMessage(chat)"
                                  style="color: #fff"
                                  >Delete Message</a
                                >
                              </div>
                              <div
                                class="col-4 text-right"
                                style="padding-right: 7px"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  enable-background="new 0 0 64 64"
                                  viewBox="0 0 64 64"
                                  id="Delete"
                                >
                                  <g
                                    transform="translate(232 228)"
                                    fill="#000000"
                                    class="color000000 svgShape"
                                  >
                                    <path
                                      fill="#2d8cff"
                                      d="M-207.5-205.1h3v24h-3zM-201.5-205.1h3v24h-3zM-195.5-205.1h3v24h-3zM-219.5-214.1h39v3h-39z"
                                      class="color134563 svgShape"
                                    ></path>
                                    <path
                                      fill="#2d8cff"
                                      d="M-192.6-212.6h-2.8v-3c0-.9-.7-1.6-1.6-1.6h-6c-.9 0-1.6.7-1.6 1.6v3h-2.8v-3c0-2.4 2-4.4 4.4-4.4h6c2.4 0 4.4 2 4.4 4.4v3"
                                      class="color134563 svgShape"
                                    ></path>
                                    <path
                                      fill="#2d8cff"
                                      d="M-191-172.1h-18c-2.4 0-4.5-2-4.7-4.4l-2.8-36 3-.2 2.8 36c.1.9.9 1.6 1.7 1.6h18c.9 0 1.7-.8 1.7-1.6l2.8-36 3 .2-2.8 36c-.2 2.5-2.3 4.4-4.7 4.4"
                                      class="color134563 svgShape"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
  
                            <div class="row mb-2">
                              <div class="col-8">
                                <a
                                  href="#"
                                  @click="editMessage(chat)"
                                  style="color: #fff"
                                  >Edit Message</a
                                >
                              </div>
                              <div class="col-4 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.547"
                                  height="16"
                                  viewBox="0 0 15.547 15.67"
                                >
                                  <g
                                    id="Group_348"
                                    data-name="Group 348"
                                    transform="translate(0.5 0.624)"
                                  >
                                    <path
                                      id="Path_491"
                                      data-name="Path 491"
                                      d="M9.507,6H4.446A1.446,1.446,0,0,0,3,7.446V17.567a1.446,1.446,0,0,0,1.446,1.446H14.567a1.446,1.446,0,0,0,1.446-1.446V12.507"
                                      transform="translate(-3 -4.467)"
                                      fill="none"
                                      stroke="#2d8cff"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1"
                                    ></path>
                                    <path
                                      id="Path_492"
                                      data-name="Path 492"
                                      d="M19.591,3.267A1.534,1.534,0,1,1,21.76,5.436L14.892,12.3,12,13.027l.723-2.892Z"
                                      transform="translate(-7.662 -2.818)"
                                      fill="none"
                                      stroke="#2d8cff"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        style="background-color: #000000bd"
        id="remove_users"
        tabindex="-1"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">Attention:</h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      You have been removed from the webinar. Thank you for
                      attending.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="changeRoleModal"
        style="background: #00000091"
        tabindex="-1"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-xl"
          role="document"
          style="justify-content: center"
        >
          <div
            class="modal-content"
            style="width: max-content; border: 1px solid"
          >
            <div class="border-dotted">
              <div class="modal-body" v-if="changeRollData">
                <h2 class="text-white weight-700 text-center">
                  Manage Roles for {{ changeRollData.user_name }}
                </h2>
                <div class="align-self-end mt-5">
                  <lable class="text-white mb-1">Select Role</lable>
                  <div class="form-group">
                    <select
                      class="blue-border dark-bg form-control"
                      v-model="selectedRoll"
                      placeholder="Time Zone"
                    >
                      <option
                        value="MODERATOR"
                        v-if="
                          changeRollData.socketId !== socket.id &&
                          isOwner &&
                          changeRollData.webinarUserType == 'HOST'
                        "
                        selected
                      >
                        Make Moderator
                      </option>
  
                      <option
                        value="GUEST"
                        v-if="
                          changeRollData.socketId !== socket.id &&
                          isOwner &&
                          changeRollData.webinarUserType == 'HOST'
                        "
                        selected
                      >
                        Make Guest
                      </option>
  
                      <option
                        value="HOST"
                        v-if="
                          changeRollData.socketId !== socket.id &&
                          role == 'HOST' &&
                          changeRollData.webinarUserType == 'MODERATOR'
                        "
                        selected
                      >
                        Make Host
                      </option>
  
                      <option
                        value="GUEST"
                        v-if="
                          changeRollData.socketId !== socket.id &&
                          role == 'HOST' &&
                          changeRollData.webinarUserType == 'MODERATOR'
                        "
                        selected
                      >
                        Make Guest
                      </option>
  
                      <option
                        value="HOST"
                        v-if="
                          changeRollData.socketId !== socket.id &&
                          role == 'HOST' &&
                          changeRollData.webinarUserType == 'GUEST'
                        "
                        selected
                      >
                        Make Host
                      </option>
  
                      <option
                        value="MODERATOR"
                        v-if="
                          changeRollData.socketId !== socket.id &&
                          role == 'HOST' &&
                          changeRollData.webinarUserType == 'GUEST'
                        "
                        selected
                      >
                        Make Moderator
                      </option>
  
                      <option
                        value="MODERATOR"
                        v-if="
                          changeRollData.socketId !== socket.id &&
                          role == 'MODERATOR' &&
                          changeRollData.webinarUserType == 'GUEST'
                        "
                        selected
                      >
                        Make Moderator
                      </option>
  
                      <option
                        value="GUEST"
                        v-if="
                          changeRollData.socketId !== socket.id &&
                          role == 'MODERATOR' &&
                          changeRollData.webinarUserType == 'MODERATOR'
                        "
                        selected
                      >
                        Make Guest
                      </option>
                      <option
                        value="HOST"
                        v-if="
                          changeRollData.socketId !== socket.id &&
                          role == 'HOST' &&
                          changeRollData.webinarUserType == 'VIEWER'
                        "
                        selected
                      >
                        Make Host
                      </option>
                      <option
                        value="MODERATOR"
                        v-if="
                          changeRollData.socketId !== socket.id &&
                          role == 'HOST' &&
                          changeRollData.webinarUserType == 'VIEWER'
                        "
                        selected
                      >
                        Make Moderator
                      </option>
                      <option
                        value="GUEST"
                        v-if="
                          changeRollData.socketId !== socket.id &&
                          role == 'HOST' &&
                          changeRollData.webinarUserType == 'VIEWER'
                        "
                        selected
                      >
                        Make Guest
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  style="color: #ff8b00; font-weight: bold"
                  v-if="edit_name_error"
                >
                  <em>{{ edit_name_error }}</em>
                </div>
                <div
                  class="row mt-5 text-center align-items-center justify-content-center justify-content-center"
                >
                  <button
                    v-on:click="(e) => saveRoleForUser(selectedRoll)"
                    type="button"
                    class="btn white-btn mr-3"
                    style="width: 150px; position: relative"
                    data-toggle="modal"
                    :disabled="edit_join_btn"
                    data-target="#add-new-webinar-calendar"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    class="btn transparent-btn border-blue"
                    style="width: 150px"
                    data-dismiss="modal"
                    @click="DismissModal('changeRoleModal')"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="editMessagePopup"
        style="background: #00000091"
        tabindex="-1"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-xl"
          role="document"
          style="justify-content: center"
        >
          <div
            class="modal-content"
            style="width: max-content; border: 1px solid"
          >
            <div class="border-dotted">
              <div class="modal-body">
                <h2 class="text-white weight-700 text-center">Editing message</h2>
                <div
                  class="align-self-end text-right mt-5"
                  style="display: flex; margin: auto"
                >
                  <input type="hidden" v-model="editMessageId" />
                  <input
                    style="margin-right: 10px"
                    @input="handleEditMessageInput"
                    type="text"
                    v-model="editedMessage"
                    class="form-control bg-white text-black"
                    placeholder="Enter new message"
                  />
                </div>
                <div
                  style="color: #ff8b00; font-weight: bold"
                  v-if="edit_name_error"
                >
                  <em>{{ edit_name_error }}</em>
                </div>
                <div
                  class="row mt-5 text-center align-items-center justify-content-center justify-content-center"
                >
                  <button
                    v-on:click="(e) => renameSubmitMessageUser(editMessageId)"
                    type="button"
                    class="btn white-btn mr-3"
                    style="width: 150px; position: relative"
                    data-toggle="modal"
                    :disabled="edit_join_btn"
                    data-target="#add-new-webinar-calendar"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    class="btn transparent-btn border-blue"
                    style="width: 150px"
                    data-dismiss="modal"
                    @click="closeEditMessageModal()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="RenamePopup"
        style="background: #00000091"
        tabindex="-1"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-xl"
          role="document"
          style="justify-content: center"
        >
          <div
            class="modal-content"
            style="width: max-content; border: 1px solid"
          >
            <div class="border-dotted">
              <div class="modal-body">
                <h2 class="text-white weight-700 text-center">Enter new name</h2>
                <div
                  class="align-self-end text-right mt-5"
                  style="display: flex; margin: auto"
                >
                  <input type="hidden" v-model="edit_socketId" />
                  <input
                    style="margin-right: 10px"
                    @input="handleRenameInput"
                    type="text"
                    v-model="edit_name"
                    class="form-control bg-white text-black"
                    placeholder="Enter new name"
                  />
                </div>
                <div
                  style="color: #ff8b00; font-weight: bold"
                  v-if="edit_name_error"
                >
                  <em>{{ edit_name_error }}</em>
                </div>
                <div
                  class="row mt-5 text-center align-items-center justify-content-center justify-content-center"
                >
                  <button
                    v-on:click="(e) => renameSubmitUser(edit_socketId)"
                    type="button"
                    class="btn white-btn mr-3"
                    style="width: 150px; position: relative"
                    data-toggle="modal"
                    :disabled="edit_join_btn"
                    data-target="#add-new-webinar-calendar"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    class="btn transparent-btn border-blue"
                    style="width: 150px"
                    data-dismiss="modal"
                    @click="closeRenameModal()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="enterUnlockPinModal"
        style="background: #000000fc"
        tabindex="-1"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-xl"
          role="document"
          style="justify-content: center"
        >
          <div
            class="modal-content"
            style="width: max-content; border: 1px solid"
          >
            <div class="border-dotted">
              <div class="modal-body">
                <h2 class="text-white weight-700 text-center mb-5">
                  Enter Room PIN
                </h2>
                <div
                  class="align-self-end text-right"
                  style="display: flex; margin: auto; justify-content: center"
                >
                  <div id="inputs" class="inputs">
                    <input
                      class="input inputs1"
                      type="text"
                      v-model="otpFirst"
                      @input="cleanVariable1"
                      inputmode="numeric"
                      maxlength="1"
                    />
                    <input
                      class="input inputs1"
                      type="text"
                      v-model="otpSecond"
                      @input="cleanVariable2"
                      inputmode="numeric"
                      maxlength="1"
                    />
                    <input
                      class="input inputs1"
                      type="text"
                      v-model="otpThird"
                      @input="cleanVariable3"
                      inputmode="numeric"
                      maxlength="1"
                    />
                    <input
                      class="input inputs1"
                      type="text"
                      v-model="otpFourth"
                      @input="cleanVariable4"
                      inputmode="numeric"
                      maxlength="1"
                    />
                  </div>
                </div>
                <div
                  style="color: #ff8b00; font-weight: bold"
                  class="mt-3"
                  v-if="name_error"
                >
                  <em>{{ name_error }}</em>
                </div>
                <div
                  class="row mt-5 text-center align-items-center justify-content-center justify-content-center"
                >
                  <button
                    v-on:click="(e) => varifyOtp()"
                    type="button"
                    class="btn white-btn mr-3"
                    style="width: 150px; position: relative"
                    data-toggle="modal"
                    :disabled="verify_btn"
                    data-target="#add-new-webinar-calendar"
                  >
                    Verify
                    <span
                      v-if="verify_loader"
                      class="spinner spinner-white spinner-right"
                    ></span>
                  </button>
                  <button
                    type="button"
                    class="btn transparent-btn border-blue"
                    style="width: 150px"
                    data-dismiss="modal"
                    @click="closeMeetingModal()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="enterNameModal"
        style="background: #000000fc"
        tabindex="-1"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-xl"
          role="document"
          style="justify-content: center"
        >
          <div
            class="modal-content"
            style="width: max-content; border: 1px solid"
          >
            <div class="border-dotted">
              <div class="modal-body">
                <h2 class="text-white weight-700 text-center">Enter your name</h2>
                <div
                  class="align-self-end text-right mt-5"
                  style="display: flex; margin: auto"
                >
                  <input
                    style="margin-right: 10px"
                    @input="handlejoinInput"
                    type="text"
                    v-model="your_name"
                    class="form-control bg-white text-black"
                    placeholder="Enter your name"
                  />
                </div>
                <div style="color: #ff8b00; font-weight: bold" v-if="name_error">
                  <em>{{ name_error }}</em>
                </div>
                <div
                  class="row mt-5 text-center align-items-center justify-content-center justify-content-center"
                >
                  <button
                    v-on:click="(e) => joinMeeting()"
                    v-if="role != 'VIEWER'"
                    type="button"
                    class="btn white-btn mr-3"
                    style="width: 150px; position: relative"
                    data-toggle="modal"
                    :disabled="join_btn"
                    data-target="#add-new-webinar-calendar"
                  >
                    Join Meeting
                    <span
                      v-if="join_loader"
                      class="spinner spinner-white spinner-right"
                    ></span>
                  </button>
                  <button
                    v-on:click="(e) => joinAsViewer()"
                    v-if="role == 'VIEWER'"
                    type="button"
                    class="btn white-btn mr-3"
                    style="position: relative"
                    data-toggle="modal"
                    :disabled="join_btn"
                    data-target="#add-new-webinar-calendar"
                  >
                    Join Meeting (Viewer)
                    <span
                      v-if="join_loader"
                      class="spinner spinner-white spinner-right"
                    ></span>
                  </button>
                  <button
                    type="button"
                    class="btn transparent-btn border-blue"
                    style="width: 150px"
                    data-dismiss="modal"
                    @click="closeMeetingModal()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="joinByViewerNameModal"
        style="background: #000000fc"
        tabindex="-1"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-xl"
          role="document"
          style="justify-content: center"
        >
          <div
            class="modal-content"
            style="width: max-content; border: 1px solid"
          >
            <div class="border-dotted">
              <div class="modal-body">
                <h2 class="text-white weight-700 text-center">
                  Welcome to the room
                </h2>
                <h6 class="mt-3 text-white text-center">
                  You are able to hear and see the hosts.
                </h6>
  
                <div
                  class="row mt-4 text-center align-items-center justify-content-center justify-content-center"
                >
                  <button
                    v-on:click="(e) => joinAsViewer()"
                    type="button"
                    class="btn white-btn mr-3"
                    style="width: 150px; position: relative"
                    data-toggle="modal"
                    :disabled="join_btn"
                    data-target="#add-new-webinar-calendar"
                  >
                    Join Meeting
                    <span
                      v-if="join_loader"
                      class="spinner spinner-white spinner-right"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        style="background-color: #000000fc"
        id="invalidLink"
        tabindex="-1"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">Oops, Something's Wrong:</h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      Couldn't find the meeting you're trying to join. Please
                      ensure you have the correct link and try again
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 32px"
            >
              <button
                v-on:click="gotToLogin()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="width: 150px; background: #b60808; color: #fff"
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        style="background-color: #000000bd"
        id="not_found_video"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">Can't find your camera</h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      Check your system settings to make sure that a camera is
                      available. If not, plug one in. You might then need to
                      restart your browse.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        style="background-color: #000000bd"
        id="reconnecting"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal"
          role="document"
          style="justify-content: center"
        >
          <div class="modal-content" style="background: #fff; width: auto">
            <div class="border-dotted">
              <div
                class="modal-body"
                style="
                  display: flex;
                  flex-wrap: wrap;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <h3 class="text-dark weight-700">
                  <img
                    height="100"
                    width="100"
                    src="../../assets/media/1488.gif"
                    alt="image"
                  />
                </h3>
                <h3 class="text-dark weight-700">You are a reconnecting...</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        id="TypeError"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">
                      No camera and mic detected 2
                    </h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      Unable to detect a camera or mic. No one can see or hear
                      you. please try connecting a camera and microphone. then
                      reload this call.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 15px"
            >
              <button
                v-on:click="closeTypeErrorModal()"
                type="button"
                class="btn white-btn"
                id="BtnTypeError"
                style="width: 150px; background: #00cd8b; color: #fff"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Try again
              </button>
              <br />
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="errorModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">Error:</h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      {{ running_error }}
                    </h4>
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 15px"
            >
              <button
                v-on:click="DeniedModal()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="width: 150px; background: #b60808; color: #fff"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Refresh Page
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="stopLocalRecordingModal"
        tabindex="-1"
        role="dialog"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        aria-hidden="true"
        style="background: #000000b8"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      style="
                        justify-content: center;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <h3 style="margin: 0" class="text-dark weight-700">
                        Attention:
                      </h3>
                      <h3
                        v-on:click="DismissModal('stopLocalRecordingModal')"
                        class="text-dark weight-700 closeIconModal"
                      >
                        X
                      </h3>
                    </div>
                    <hr />
                    <h4 class="text-dark weight-400 mt-3">
                      You are about to stop the recording and download the replay.
                    </h4>
                    <h4 class="text-dark weight-400 mt-4">
                      If this was in error, please click the "X" in the top right
                      to continue recording.
                    </h4>
  
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row text-center align-items-center justify-content-center"
              style="margin-bottom: 15px; flex-direction: column"
            >
              <button
                v-on:click="isRecordLoader ? '' : stopCloudRecording()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn"
                :class="isRecordLoader ? 'recordAnimation' : ''"
                style="
                  background: #b60808;
                  color: #fff;
                  display: flex;
                  padding: 5px 7px;
                  border: 2px solid #0b111b;
                "
                :style="
                  isRecordLoader ? 'background: #2d8cff' : ' background: #b60808'
                "
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                <img
                  alt="Logo"
                  v-if="!isRecordLoader"
                  src="../../assets/media/download-circular-button.png"
                  height="25"
                  width="25"
                />
  
                <span style="margin-left: 9px">{{
                  isRecordLoader
                    ? "Download in progress...please wait until complete."
                    : "Stop Recording & Download"
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="stopCloudRecordingModal"
        tabindex="-1"
        role="dialog"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        aria-hidden="true"
        style="background: #000000b8"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      style="
                        justify-content: center;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <h3 style="margin: 0" class="text-dark weight-700">
                        Attention:
                      </h3>
                      <h3
                        v-on:click="DismissModal('stopCloudRecordingModal')"
                        class="text-dark weight-700 closeIconModal"
                      >
                        X
                      </h3>
                    </div>
                    <hr />
                    <h4 class="text-dark weight-400 mt-3">
                      You are about to stop the cloud recording.
                    </h4>
                    <h4 class="text-dark weight-400 mt-4">
                      After the video is done processing (Approx 15 min) you will
                      be able to download the file from your back office.
                    </h4>
                    <h4 class="text-dark weight-400 mt-4">
                      If this was in error, please click the "X" in the top right
                      to continue recording.
                    </h4>
  
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row text-center align-items-center justify-content-center"
              style="margin-bottom: 15px; flex-direction: column"
            >
              <button
                v-on:click="isRecordLoader ? '' : stopCloudRecording()"
                :class="isRecordLoader ? 'recordAnimation' : ''"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn"
                :style="
                  isRecordLoader ? 'background: #2d8cff' : ' background: #b60808'
                "
                style="
                  color: #fff;
                  display: flex;
                  padding: 5px 7px;
                  border: 2px solid #0b111b;
                "
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                <span>{{
                  isRecordLoader
                    ? "Uploading in progress...please wait until complete."
                    : "Stop Recording"
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="LeaveLocalRecordingModal"
        tabindex="-1"
        role="dialog"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        aria-hidden="true"
        style="background: #000000b8"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      style="
                        justify-content: center;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <h3 style="margin: 0" class="text-dark weight-700">
                        Attention:
                      </h3>
                      <h3
                        v-on:click="
                          isActionForLeave
                            ? leaveWithStopLocalRec()
                            : DismissModal('LeaveLocalRecordingModal')
                        "
                        class="text-dark weight-700 closeIconModal"
                      >
                        X
                      </h3>
                    </div>
                    <hr />
                    <h4 class="text-dark weight-400 mt-3">
                      You are about to stop the recording, download the replay and
                      end the broadcast.
                    </h4>
                    <h4 class="text-dark weight-400 mt-4">
                      If this was in error, please click the "X" in the top right
                      to continue recording.
                    </h4>
  
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row text-center align-items-center justify-content-center"
              style="margin-bottom: 15px; flex-direction: column"
            >
              <button
                v-on:click="isRecordLoader ? '' : leaveWithStopLocalRec()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn"
                :class="isRecordLoader ? 'recordAnimation' : ''"
                style="
                  background: #b60808;
                  color: #fff;
                  display: flex;
                  padding: 5px 7px;
                  border: 2px solid #0b111b;
                "
                :style="
                  isRecordLoader ? 'background: #2d8cff' : ' background: #b60808'
                "
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                <img
                  alt="Logo"
                  v-if="
                    (!isRecordLoader || !isActionForLeave) &&
                    (isRecordLoader || !isActionForLeave)
                  "
                  src="../../assets/media/download-circular-button.png"
                  height="25"
                  width="25"
                  style="margin-right: 9px"
                />
  
                <span>{{
                  isRecordLoader
                    ? "Download in progress...please wait until complete."
                    : isActionForLeave
                    ? "End Broadcast"
                    : "End Broadcast & Download Recording"
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl microphoneDeniedModal"
        id="unlockModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">Attention:</h3>
                    <hr />
                    <h4 class="text-dark weight-700">
                      Are you sure you want to unlock the room?
  
                      <h4 class="text-dark weight-700">
                        This means anyone with the link will be able to join.
                      </h4>
                    </h4>
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 15px"
            >
              <button
                v-on:click="DismissModal('unlockModal')"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="width: 150px; background: #b60808; color: #fff"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Cancel
              </button>
              <button
                v-on:click="unLockOpenRoom()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="width: 150px; color: #fff; background: green"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Unlock Room
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl microphoneDeniedModal"
        id="lockModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">Attention:</h3>
                    <hr />
                    <h4 class="text-dark weight-700">
                      You are about to lock the room.
  
                      <h4 class="text-dark weight-700">
                        No one will be able to join the room unless you unlock the
                        room.
                      </h4>
                    </h4>
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 15px"
            >
              <button
                v-on:click="DismissModal('lockModal')"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="width: 150px; background: green; color: #fff"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Cancel
              </button>
              <button
                v-on:click="lockOpenRoom()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="width: 150px; background: #b60808; color: #fff"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Lock Room
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl microphoneDeniedModal"
        id="lockedWithPinModal"
        tabindex="-1"
        data-bs-keyboard="false"
        style="background: #000000fc"
        data-bs-backdrop="static"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3
                      class="text-dark weight-700"
                      style="text-align: center; font-size: 28px"
                    >
                      🔒
                    </h3>
                    <hr />
                    <h4 class="text-dark weight-700 text-align-center;">
                      The room is locked. You must wait for the host to unlock the
                      room.
                      <br />if you were given a private PIN code, click to button
                      below.
  
                      <h3 class="text-dark weight-700" style="text-align: center">
                        <img
                          height="70"
                          width="70"
                          src="../../assets/media/1488.gif"
                          alt="image"
                        />
                      </h3>
                      <div
                        style="color: red; font-weight: bold; text-align: center"
                        v-if="retryChance == 5"
                      >
                        <em>Maximum attempts to enter PIN exceeded</em>
                      </div>
                    </h4>
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 15px"
            >
              Have a pin to enter the room?
  
              <button
                v-on:click="reEnterRoomPin()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="
                  width: 150px;
                  background: green;
                  color: #fff;
                  margin-left: 12px;
                "
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
                :disabled="retryChance == 5"
              >
                Enter Room
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl microphoneDeniedModal"
        id="lockedModal"
        tabindex="-1"
        data-bs-keyboard="false"
        style="background: #000000fc"
        data-bs-backdrop="static"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3
                      class="text-dark weight-700"
                      style="text-align: center; font-size: 28px"
                    >
                      🔒
                    </h3>
                    <hr />
                    <h4 class="text-dark weight-700">
                      The room is currently locked.
  
                      <h4 class="text-dark weight-700">
                        You will automatically enter the room when it gets
                        unlocked.
                      </h4>
                      <h3 class="text-dark weight-700" style="text-align: center">
                        <img
                          height="70"
                          width="70"
                          src="../../assets/media/1488.gif"
                          alt="image"
                        />
                      </h3>
                    </h4>
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 15px"
            >
              <button
                v-on:click="leaveRoom()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="width: 150px; background: #b60808; color: #fff"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Leave Waiting Room
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl microphoneDeniedModal"
        id="microphoneDeniedModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">Attention:</h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      To join the room you need to give microphone access to the
                      browser.
                      <h5 class="text-dark weight-700">
                        1. Go to your device settings.
                      </h5>
                      <h5 class="text-dark weight-700">
                        2. Allow access to the mic then refresh the page.
                      </h5>
                    </h4>
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 15px"
            >
              <button
                v-on:click="DeniedModal()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="width: 150px; background: #b60808; color: #fff"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Refresh Page
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="requestMicModal"
        style="background: #000000c2"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div
            class="modal-content"
            style="width: max-content; border: 1px solid"
          >
            <div class="border-dotted">
              <div class="modal-body">
                <h2 class="text-white weight-700 text-center">
                  {{ requestUserName }} is requesting you to unmute your
                  microphone
                </h2>
  
                <div
                  class="row mt-5 text-center align-items-center justify-content-center justify-content-center"
                >
                  <button
                    v-on:click="(e) => allowMicModal()"
                    type="button"
                    class="btn white-btn mr-3"
                    style="width: 150px; position: relative"
                    data-toggle="modal"
                    data-target="#add-new-webinar-calendar"
                  >
                    Allow
                  </button>
                  <button
                    type="button"
                    class="btn transparent-btn border-blue"
                    style="width: 150px"
                    data-dismiss="modal"
                    @click="denyMicModal()"
                  >
                    Deny
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="requestCamModal"
        style="background: #000000c2"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div
            class="modal-content"
            style="width: max-content; border: 1px solid"
          >
            <div class="border-dotted">
              <div class="modal-body">
                <h2 class="text-white weight-700 text-center">
                  {{ requestUserName }} is requesting you to unmute your camera
                </h2>
  
                <div
                  class="row mt-5 text-center align-items-center justify-content-center justify-content-center"
                >
                  <button
                    v-on:click="(e) => allowCamModal()"
                    type="button"
                    class="btn white-btn mr-3"
                    style="width: 150px; position: relative"
                    data-toggle="modal"
                    data-target="#add-new-webinar-calendar"
                  >
                    Allow
                  </button>
                  <button
                    type="button"
                    class="btn transparent-btn border-blue"
                    style="width: 150px"
                    data-dismiss="modal"
                    @click="denyCamModal()"
                  >
                    Deny
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl cameraDeniedModal"
        id="cameraDeniedModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">Attention:</h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      To join the room you need to give camera access to the
                      browser.
                      <h5 class="text-dark weight-700">
                        1. Go to your device settings.
                      </h5>
                      <h5 class="text-dark weight-700">
                        2. Allow access to the camera then refresh the page.
                      </h5>
                    </h4>
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 15px"
            >
              <button
                v-on:click="DeniedModal()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="width: 150px; background: #b60808; color: #fff"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Refresh Page
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl cameraMicDeniedModal"
        id="cameraMicDeniedModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">Attention:</h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      To join the room you need to give camera and microphone
                      access to the browser.
                      <h5 class="text-dark weight-700">
                        1. Go to your device settings.
                      </h5>
                      <h5 class="text-dark weight-700">
                        2. Allow access to the camera and microphone then refresh
                        the page.
                      </h5>
                    </h4>
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 15px"
            >
              <button
                v-on:click="DeniedModal()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="background: #b60808; color: #fff"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Refresh Page
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade bd-example-modal-xl DeniedModal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        id="DeniedModal"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal"
          role="document"
          style="margin-top: 0px"
        >
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">Attention:</h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      We could not locate a camera or microphone on your computer.
                      Please connect camera or microphone or check if they are
                      connected properly. Waiting for devices to be connected....
                    </h4>
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 15px"
            >
              <button
                v-on:click="joinAsViewer()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="background: #b60808; color: #fff"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Join As Viewer Instead
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl DeniedModalCamera"
        id="DeniedModalCamera"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal"
          role="document"
          style="margin-top: 0px"
        >
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">Attention:</h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      We could not locate a camera or microphone on your computer.
                      Please connect camera or microphone or check if they are
                      connected properly. Waiting for devices to be connected....
                    </h4>
  
                    <hr style="margin-bottom: 0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 15px"
            >
              <button
                v-on:click="DismissModal('DeniedModalCamera')"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="background: #b60808; color: #fff"
                data-toggle="modal"
                data-target="#add-new-webinar-calendar"
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade bd-example-modal-xl"
        id="NotReadableErrorIn"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">
                      Another app is using your camera
                    </h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      We cannot access your camera. Close any apps (like Zoom or
                      Teams) that might be using your camera. then refresh the
                      page.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 32px"
            >
              <button
                v-on:click="closeNotReadableErrorModal()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="width: 150px; background: #b60808; color: #fff"
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade bd-example-modal-xl"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        id="NotReadableError"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" style="background: #fff">
            <div class="border-dotted">
              <div class="modal-body">
                <div class="row" style="margin-top: 14px">
                  <div class="col-md-12">
                    <h3 class="text-dark weight-700">
                      Another app is using your camera
                    </h3>
                    <hr />
                    <h4 class="text-dark weight-400">
                      We cannot access your camera. Close any apps (like Zoom or
                      Teams) that might be using your camera. then refresh the
                      page.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3 text-center align-items-center justify-content-center"
              style="margin-bottom: 32px"
            >
              <button
                v-on:click="closeNotReadableErrorModal()"
                type="button"
                id="BtnNotReadableError"
                class="btn white-btn mr-3"
                style="width: 150px; background: #b60808; color: #fff"
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  function openNav() {
    console.log("Hiiii----------openNav");
  }
  
  window.addEventListener("pageshow", function (event) {
    var historyTraversal = event.persisted,
      perf = window.performance,
      perfEntries =
        perf && perf.getEntriesByType && perf.getEntriesByType("navigation"),
      perfEntryType = perfEntries && perfEntries[0] && perfEntries[0].type,
      navigationType = perf && perf.navigation && perf.navigation.type;
    if (
      historyTraversal ||
      perfEntryType === "back_forward" ||
      navigationType === 2
    ) {
      // Handle page restore.
      window.location.reload(true);
    }
  });
  window.addEventListener(
    "load",
    function () {
      // select parent of dish
    },
    false
  );
  
  var _ratios = ["16:10"];
  var _aspect = 0;
  var _margin = 5;
  var _ratio = ratio();
  
  // resize event of window
  window.addEventListener("resize", function () {
    // resize event to dimension cameras
    resize();
  });
  
  // split aspect ratio (format n:n)
  
  function resize() {
    dimensions();
  
    // loop (i recommend you optimize this)
    let max = 0;
    let i = 1;
    while (i < 5000) {
      var area = areas(i);
      if (area === false) {
        max = i - 1;
        break;
      }
      i++;
    }
  
    // remove margins
    max = max - _margin * 1;
  
    // set dimensions to all cameras
    resizer(max);
  }
  
  function resizer(width) {
    var _dish = document.getElementsByClassName("Dish")[0];
  
    if (_dish) {
      const totalChildren = _dish.children.length;
      for (var s = 0; s < totalChildren; s++) {
        // camera fron dish (div without class)
  
        const element = _dish.children[s];
        const pseudoElementStyle = getComputedStyle(element, "display");
  
        // console.log('pseudoElementStyle___', pseudoElementStyle.display);
        if (pseudoElementStyle.display == "flex") {
          // custom margin
          element.style.margin = _margin + "px";
  
          // calculate dimensions
          element.style.width = width + "px";
          element.style.height = width * _ratio + "px";
  
          // Calculate font size based on width or height (adjust as needed)
          const nameTagElements = element.getElementsByClassName("name-tag");
          for (var j = 0; j < nameTagElements.length; j++) {
            const nameTagElement = nameTagElements[j];
            let fontSize = Math.min(width, width * _ratio) * 0.1; // 10% of the smallest dimension
            fontSize = Math.min(fontSize, 15); // Set maximum font size to 20px
  
            let padding = Math.min(width, width * _ratio) * 0.02; // 2% of the smallest dimension
            padding = Math.min(padding, 8); // Max padding 10px
            nameTagElement.style.padding = padding + "px";
  
            nameTagElement.style.fontSize = fontSize + "px";
          }
          const usersThreeDotElements =
            element.getElementsByClassName("usersThr2eeDot");
          for (var j = 0; j < usersThreeDotElements.length; j++) {
            const usersThreeDotElement = usersThreeDotElements[j];
            let ElemetSize = Math.min(width, width * _ratio) * 0.1; // 10% of the smallest dimension
            ElemetSize = Math.min(ElemetSize, 15); // Set maximum font size to 20px
  
            usersThreeDotElement.style.width = ElemetSize + "px";
            usersThreeDotElement.style.height = ElemetSize + "px";
          }
          const usersPictureElements = element.getElementsByClassName("r-img");
          for (var j = 0; j < usersPictureElements.length; j++) {
            const usersPictureElement = usersPictureElements[j];
            let ElemetSize = Math.min(width, width * _ratio) * 0.2;
            ElemetSize = Math.min(ElemetSize, 100); // Max size 100px
  
            // Center the image within the element
            usersPictureElement.style.width = ElemetSize + "px";
            usersPictureElement.style.height = ElemetSize + "px";
            usersPictureElement.style.position = "absolute";
            usersPictureElement.style.left = "50%";
            usersPictureElement.style.top = "50%";
            usersPictureElement.style.transform = "translate(-50%, -50%)";
          }
          const usersverifyElements = element.getElementsByClassName("verify");
          for (var j = 0; j < usersverifyElements.length; j++) {
            const usersverifyElement = usersverifyElements[j];
            let ElemetverifySize = Math.min(width, width * _ratio) * 0.2;
            ElemetverifySize = Math.min(ElemetverifySize, 15); // Max size 100px
  
            // Center the image within the element
            usersverifyElement.style.width = ElemetverifySize + "px";
            usersverifyElement.style.height = ElemetverifySize + "px";
          }
  
          // Responsive size for SVG elements
          const svgElements = element.getElementsByClassName("svgThreeDots");
          for (var j = 0; j < svgElements.length; j++) {
            const svgElement = svgElements[j];
            let svgSize = Math.min(width, width * _ratio) * 0.2; // 20% of the smallest dimension
            svgSize = Math.min(svgSize, 35); // Max size 100px
  
            // Apply size and center the SVG within the element
            svgElement.style.width = svgSize + "px";
            svgElement.style.height = svgSize + "px";
          }
  
          const muteCamElements = element.getElementsByClassName("muteCam");
          for (var j = 0; j < muteCamElements.length; j++) {
            const muteCamElement = muteCamElements[j];
            let muteCamSize = Math.min(width, width * _ratio) * 0.1; // 20% of the smallest dimension
            muteCamSize = Math.min(muteCamSize, 20); // Max size 100px
  
            let padding = Math.min(width, width * _ratio) * 0.02; // 2% of the smallest dimension
            padding = Math.min(padding, 4); // Max padding 10px
            muteCamElement.style.margin = padding + "px";
  
            // Apply size and center the SVG within the element
            muteCamElement.style.width = muteCamSize + "px";
            muteCamElement.style.height = muteCamSize + "px";
          }
  
          const muteMicElements = element.getElementsByClassName("muteMic");
          for (var j = 0; j < muteMicElements.length; j++) {
            const muteMicElement = muteMicElements[j];
            let muteMicSize = Math.min(width, width * _ratio) * 0.1; // 20% of the smallest dimension
            muteMicSize = Math.min(muteMicSize, 20); // Max size 100px
  
            let padding = Math.min(width, width * _ratio) * 0.02; // 2% of the smallest dimension
            padding = Math.min(padding, 4); // Max padding 10px
            muteMicElement.style.margin = padding + "px";
  
            // Apply size and center the SVG within the element
            muteMicElement.style.width = muteMicSize + "px";
            muteMicElement.style.height = muteMicSize + "px";
          }
  
          const unmuteCamElements = element.getElementsByClassName("unMuteCam");
          for (var j = 0; j < unmuteCamElements.length; j++) {
            const unmuteCamElement = unmuteCamElements[j];
            let unmuteCamSize = Math.min(width, width * _ratio) * 0.1; // 20% of the smallest dimension
            unmuteCamSize = Math.min(unmuteCamSize, 20); // Max size 100px
  
            let padding = Math.min(width, width * _ratio) * 0.02; // 2% of the smallest dimension
            padding = Math.min(padding, 4); // Max padding 10px
            unmuteCamElement.style.margin = padding + "px";
  
            // Apply size and center the SVG within the element
            unmuteCamElement.style.width = unmuteCamSize + "px";
            unmuteCamElement.style.height = unmuteCamSize - 5 + "px";
          }
          const unmuteMicElements = element.getElementsByClassName("unMuteMic");
          for (var j = 0; j < unmuteMicElements.length; j++) {
            const unmuteMicElement = unmuteMicElements[j];
            let unmuteMicSize = Math.min(width, width * _ratio) * 0.1; // 20% of the smallest dimension
            unmuteMicSize = Math.min(unmuteMicSize, 20); // Max size 100px
  
            let padding = Math.min(width, width * _ratio) * 0.02; // 2% of the smallest dimension
            padding = Math.min(padding, 4); // Max padding 10px
            unmuteMicElement.style.margin = padding + "px";
            // Apply size and center the SVG within the element
            unmuteMicElement.style.width = unmuteMicSize - 4 + "px";
            unmuteMicElement.style.height = unmuteMicSize + "px";
          }
  
          // to show the aspect ratio in demo (optional)
          element.setAttribute("data-aspect", _ratios[_aspect]);
        }
      }
    }
  }
  
  function ratio() {
    var ratio = _ratios[_aspect].split(":");
    return ratio[1] / ratio[0];
  }
  
  function dimensions() {
    var _dish = document.getElementsByClassName("Dish")[0];
  
    //   let _width = _dish ? _dish.offsetWidth : 0 - _margin * 2;
    //   let _height = _dish.offsetHeight - _margin * 2;
    // }
  }
  
  function areas(increment) {
    var _dish = document.getElementsByClassName("Dish")[0];
  
    if (_dish) {
      let i = 0;
      let w = 0;
      let _width = _dish ? _dish.offsetWidth : 0 - _margin * 2;
      let _height = _dish ? _dish.offsetHeight : 0 - _margin * 2;
      let h = increment * _ratio + _margin * 2;
      while (i < _dish.children.length) {
        if (w + increment > _width - _margin * 2) {
          w = 0;
          h = h + increment * _ratio + _margin * 2;
        }
        w = w + increment + _margin * 2;
        i++;
      }
      if (h > _height - _margin * 2 || increment > _width - _margin * 2)
        return false;
      else return increment;
    }
  }
  
  import io from "socket.io-client";
  import { Device, RtpCapabilities, detectDevice } from "mediasoup-client";
  import { ref, toRaw } from "vue";
  import axios from "axios";
  import { verifywebinars, updateWebinarType } from "../services/service.js";
  import {
    liveWebinar,
    lockUnlockOpenRoom,
    webinarParticipateUserList,
    getSingleWebinarData,
    listupdateParticipants,
    startRecording,
    stopRecording,
    addToRecording,
    verifyOpenRoomWebinars,
  } from "../services/service.js";
  import { CaptureStream } from "./CaptureStream.js";
  import { AudioCaptureStream } from "./AudioCaptureStream.js";
  import { createPopup } from "@picmo/popup-picker";
  axios.defaults.headers.post["Content-Type"] = "application/json";
  import MultiStreamsMixer from "multistreamsmixer";
  import { useToast } from "vue-toast-notification";
  import "vue-toast-notification/dist/theme-sugar.css";
  import StreamSaver from "streamsaver";
  import { v4 as uuidv4 } from "uuid";
  import fixWebmDuration from "webm-duration-fix";
  import draggable from "vuedraggable";
  import * as pdfjsLib from "pdfjs-dist/build/pdf";
  import { ElImage } from "element-plus";
  pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";
  
  export default {
    components: {
      draggable,
      ElImage,
    },
    mounted() {
      const script = document.createElement("script");
  
      // Set the source attribute to the CDN link
      script.src = "https://element-plus.org/assets/app.30648b36.js";
  
      // Append the script tag to the document's head
      document.head.appendChild(script);
    },
    data() {
      localStorage.setItem("debug", "* -engine* -socket* -RIE* *WARN* *ERROR*");
      localStorage.setItem("loglevel", "INFO");
  
      let urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.has("webinarid")) {
        const loc = window.location.pathname;
        console.log("starting loc value = " + loc);
        const locs =
          loc.lastIndexOf("/") == loc.length - 1
            ? loc.substring(0, loc.length - 1)
            : loc.substring(0, loc.lastIndexOf("/"));
        const companyname = locs.substring(locs.lastIndexOf("/") + 1);
  
        Vue.nextTick(() => {
          document.title =
            "UW - " +
            companyname +
            " " +
            window.location.pathname.split("/").pop();
        });
      } else {
        Vue.nextTick(() => {
          document.title = "UW - Unity Webinar";
        });
      }
      return {
        dragging: false,
        isOwner: false,
        enabled: true,
        ownerUserId: "",
        requestUserName: "",
        webinarId: "",
        lockRoom: "",
        hostUnlockRoom: "",
        moderatorsUnlockRoom: "",
        alwaysOpenRoom: "",
        lockRoomPin: "",
        isRequirePin: false,
        OV: undefined,
        session: undefined,
        sessionScreen: undefined,
        screensharing: false,
        is_mic_allow: false,
        is_cam_allow: false,
        verify_btn: false,
        verify_loader: false,
        running_error: "",
        ism_w_c: "",
        audioDeviceData: false,
        name_error: "",
        otpFirst: "",
        otpSecond: "",
        otpThird: "",
        otpFourth: "",
        retryChance: 0,
        edit_name_error: "",
        edit_join_btn: false,
        videoDeviceData: false,
        is_disable: false,
        publisherScreen: undefined,
        mainStreamManager: undefined,
        publisher: undefined,
        disabled: true,
        microphoneState: false,
        cameraState: "",
        childId: "",
        webinar_data: "",
        subscribers: [],
        stremErr: "",
        mySessionId: "SessionD",
        layoutClass: "single_user_main",
        deviceData: [],
        video: false,
        audio: false,
        join_loader: false,
        join_btn: false,
        changeRollData: null,
        selectedRoll: "",
        edit_socketId: "",
        editedMessage: "",
        editMessageId: "",
        editMessageUser: "",
        socketId: "",
        edit_name: "",
        your_name: "",
        profileImg: "unitydefaultlogo.png",
        is_stream: true,
        is_message_loader: false,
        getDevicesOV: "",
        searchMessage: "",
        goLiveStatus: "",
        currentTabSelect: "HOST",
        role: "",
        filterdParticipants: [],
        filterdWaitingUser: [],
        chatHistory: [],
        chatFilteredUserList: [],
        selectedAudio: undefined,
        selectedOuterAudio: undefined,
        screenShareLoader: false,
        recordingToaster: false,
        isActionForLeave: false,
        isActionPopup: false,
        startTimer: "",
        selectedVideo: undefined,
        users: {
          host: [{ name: "xyz", image: "imagepath" }],
          moderator: [],
          viewers: [],
          guest: [],
        },
        isMyRaiseHand: false,
        isRaiseHand: false,
        RaiseCount: 1,
        chatMessage: "",
        senderRoleName: "Everyone",
        senderRole: "Everyone",
        room_type: "",
        localStreamRef: "",
        userId: "",
        loggedUserData: "",
        user_name: "",
        audioProducer: ref(),
        localVideoCont: "",
        micElem: ref(),
        videoElem: ref(),
        produceTransport: ref({}),
        videoProducer: ref(),
        recordType: "",
        waitingUser: [],
        countDownVal: "",
        recordStartLoader: false,
        isRecordLoader: false,
        raisehandArray: [],
        socket: null,
        demoSocket: null,
        BASE_URL:
          window.location.host == "unitywebinar.com"
            ? "https://api.unitywebinar.com/"
            : window.location.host == "dev.unitywebinar.com"
            ? "https://dev-api.unitywebinar.com/"
            : "https://api.unitywebinar.com/",
        FRONT_BASE_URL:
          window.location.host == "unitywebinar.com"
            ? "https://unitywebinar.com/"
            : window.location.host == "dev.unitywebinar.com"
            ? "https://dev.unitywebinar.com/"
            : "https://unitywebinar.com/",
        device: null,
        roomId: window.location.pathname.split("/").pop(),
        docLoader: false,
        is_chat_mute: false,
        is_chat_ban: false,
        producerTransport: null,
        viewers: [],
        peersRef: ref([]),
        audioPeersRef: ref([]),
        globalTimeout: ref(),
        date: new Date(),
        time: new Date().toLocaleString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        guestUsers: [],
        peers: [],
        dragList: [],
        peerShow: [],
        consumers: ref(new Map()),
        receiveTransport: ref([]),
        audioMicClose: ref([]),
        audioElementSocket: ref([]),
        audioLevel: 5,
        gainLevel: 0,
        audioElement: ref([]),
        remoteStreamsRef: ref({}),
        localScreenStreamRef: ref(),
        mainScreenStreamRef: ref(null),
        globalTimeout: ref(),
        localScreenCont: ref(),
        audioCtx: ref(),
        gainNode: ref(),
        source: ref(),
        data: [],
        processing: false,
        list: [
          {
            name: "John",
            id: 1,
            link: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          },
          {
            name: "Joao",
            id: 2,
            link: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          },
          {
            name: "Jean",
            id: 3,
            link: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
          },
        ],
        localStream: null,
        videoProducerId2: ref(),
        videoProducerId: ref(),
        videoProducer2: ref(),
        audioProducer2: ref(),
        videoProducer3: ref(),
        audioProducer3: ref(),
        audioProducerId2: ref(),
        audioProducerId: ref(),
        mediasoupDevice: ref(),
        srcList: [],
        audioShared: false,
        videoShared: false,
        docShared: false,
        is_cam_request: false,
        recorderUsers: "",
        topRecording: false,
        currentVideoState: ref(false),
        screenShareStarted: ref(false),
        currentMicState: ref(false),
        mixer: ref(),
        recStream: ref(),
        mainCont: ref(),
        workerRef: ref(),
        audioContextRef: ref(),
        mediaRecorder: ref(),
        processingRef: ref(false),
        recordingRef: ref(),
        recordingToaster: ref(),
        recordingPauseElem: ref(),
        recordingResumeElem: ref(),
        recordingTextElem: ref(),
        dataProducer: ref(),
        dataProducerId: ref(),
        chunksRef: ref([]),
        sepChunks: ref([]),
        writer: ref(),
        savedChunks: ref([]),
        streamsArr: [],
        recordingIcon: ref(),
        isMobile: false,
        isMobileDevice: false,
        isBrave: false,
        dest: ref(),
        sourceNodes: ref(new Map()),
        hrs: "",
        title: "",
        min: "",
        sec: "",
        hrsApi: "",
        minApi: "",
        secApi: "",
        recordIds: ref([]),
        recordAudioProducer: ref(),
        recordVideoProducer: ref(),
        pdfUrl:
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
        pdfLoaded: false,
        canvasRef: ref(),
        pdfjs: ref(),
        pdfjsWorker: ref(),
        pdf: "",
        totalPages: 0,
        currentPage: 1,
        pageNumber: 1,
        canv: ref(),
        videoRef: ref(),
        images: [],
        currentIndex: 0,
        isNextBtnLoader: false,
        isPrevBtnLoader: false,
        pingInterval: "",
        pingStatus: "",
        pingTime: "",
        fileShareLoader: false,
        data: [],
        layoutStyle: "#2",
        processing: false,
        navTypeNavigation: "",
        uniqueSocketIds: new Set(),
        searchFilteredParticipents: [],
        pageSize: this.getPageSize(),
        currentPagePagination: 1,
        videosPerPage: 16,
        paginationPageSize: 16,
        showLeftBtn: false,
        showRightBtn: false,
        getWidth: screen.width,
        // orientation: this.getOrientation(),
        currentDevice: this.getCurrentDevice(),
        isLandscape: window.matchMedia("(orientation: landscape)").matches,
      };
    },
    beforeDestroy() {
      console.log("Component is being destroyed, removing resize event listener");
      window.removeEventListener("orientationchange", this.updateOrientation);
      window.removeEventListener("resize", this.updatePaginationPage);
      window.removeEventListener("resize", this.handleResize);
      window.removeEventListener("resize", this.updatePageSize);
    },
    computed: {
      computedPageSize() {
        console.log(
          "windows width-------",
          window.innerWidth,
          "screen width======",
          screen.width
        );
        if (this.getWidth >= 1440) {
          console.log("go inside  if-----", window.innerWidth);
          return 12;
        } else if (this.getWidth >= 1025 && this.getWidth < 1440) {
          console.log("go inside else if-----", window.innerWidth);
          return 9; // 3x4 layout
        } else if (
          this.getWidth >= 768 &&
          this.getWidth <= 1366 &&
          this.isLandscape
        ) {
          console.log("go inside else if tablet-----", window.innerWidth);
          return 9;
        } else {
          console.log("go inside else-----", window.innerWidth);
          return 6; // 2x4 layout
        }
      },
      paginatedVideos() {
        const start = (this.currentPagePagination - 1) * this.computedPageSize;
        const end = this.currentPagePagination * this.computedPageSize;
        console.log(
          "this.peerShow.slice(start, end) paginatedVideos------",
          this.peerShow.slice(start, end)
        );
        return this.peerShow.slice(start, end);
      },
      dynamicStyle() {
        const videoCount = this.paginatedVideos.length;
        console.log(
          "device type------",
          this.pageSize,
          "device orientation------",
          this.orientation,
          "widows orientation------",
          window.screen.orientation
        );
  
        if (videoCount === 1) {
          // If there's only 1 video, it takes up most of the screen
          if (this.getWidth >= 768 && this.getWidth < 1024) {
            return {
              "flex-basis": "100% !important",
            };
          } else if (
            this.getWidth >= 768 &&
            this.getWidth <= 1366 &&
            this.isLandscape
          ) {
            return {
              "flex-basis": "100% !important",
            };
          } else {
            return {
              // 'flex-basis': '50%', // Large size for a single video
              "flex-basis": "70%", // Large size for a single video
            };
          }
        } else if (videoCount === 2) {
          if (this.getWidth >= 768 && this.getWidth < 1024) {
            return {
              "flex-basis": "100% !important",
            };
          } else if (
            this.getWidth >= 768 &&
            this.getWidth <= 1366 &&
            this.isLandscape
          ) {
            return {
              "flex-basis": "100% !important",
            };
          } else {
            return {
              "flex-basis": "95%", // Larger size for few videos
            };
          }
        } else if (videoCount >= 2 && videoCount <= 8) {
          // For 2 or 3 videos, adjust the size
          if (this.getWidth >= 768 && this.getWidth < 1024) {
            return {
              "flex-basis": "95% !important",
            };
          } else if (
            this.getWidth >= 768 &&
            this.getWidth <= 1366 &&
            this.isLandscape
          ) {
            return {
              "flex-basis": "100% !important",
            };
          } else {
            return {
              "flex-basis":
                videoCount >= 6 ? "100%" : videoCount === 5 ? "100%" : "75%", // Larger size for few videos
            };
          }
        } else {
          // For 4+ videos, make it more grid-like based on screen size
          if (this.getWidth >= 1440) {
            return {
              "flex-basis": "100%", // 4 videos per row for large screens
              // 'max-width': videoCount > 3 && 'calc(33.33% - 20px)'
            };
          } else if (this.getWidth >= 1024 && this.getWidth < 1440) {
            return {
              "flex-basis": "100%", // 3 videos per row for medium screens
              // 'max-width': videoCount > 3 && 'calc(50% - 20px)'
            };
          } else if (this.getWidth >= 768 && this.getWidth < 1024) {
            return {
              "flex-basis": "90%",
            };
          } else {
            return {
              "flex-basis": "90%", // 2 videos per row for small screens
              "max-width": videoCount > 3 && "calc(85% - 20px)",
            };
          }
        }
      },
      dynamicStreamStyle() {
        const videoCount = this.paginatedVideos.length;
        if (videoCount > 3) {
          return {
            "max-width": "calc(33.33% - 20px)",
            height: videoCount <= 6 ? "45% !important" : "30% !important",
          };
        }
      },
      dynamicProfilePicStyle() {
        const videoCount = this.paginatedVideos.length;
        if (this.getWidth > 1515) {
          if (videoCount > 15)
            return {
              width: "50px !important",
              height: "50px !important",
            };
        } else if (this.getWidth > 1024) {
          if (videoCount > 8)
            return {
              width: "42px !important",
              height: "42px !important",
            };
        } else {
          if (videoCount > 7)
            return {
              width: "36px !important",
              height: "36px !important",
            };
          return {
            "flex-basis": "48%", // 2 videos per row for small screens
          };
        }
      },
      totalPagesPagination() {
        return Math.ceil(this.peerShow.length / this.computedPageSize);
      },
      dragOptions() {
        return {
          animation: 0,
          group: "description",
          disabled: !this.editable,
          ghostClass: "ghost",
        };
  
        window.setInterval(() => {
          resize();
        }, 100);
      },
      listString() {
        return JSON.stringify(this.list, null, 2);
      },
      list2String() {
        return JSON.stringify(this.list2, null, 2);
      },
      chatFilteredList() {
        if (this.searchMessage == "") {
          return this.chatHistory;
        } else {
          this.chatFilteredUserList = this.chatHistory.filter((name) => {
            return name.user
              .toLowerCase()
              .includes(this.searchMessage.toLowerCase());
          });
          return this.chatFilteredUserList;
        }
      },
    },
    watch: {
      isDragging(newValue) {
        if (newValue) {
          this.delayedDragging = true;
          return;
        }
        this.$nextTick(() => {
          this.delayedDragging = false;
        });
      },
    },
    mounted: async function () {
      console.log("Component mounted, adding resize event listener");
      window.addEventListener("resize", this.updatePageSize);
      this.handleResize = this.throttle(this.updateWidth, 100);
      window.addEventListener("orientationchange", this.updateOrientation);
      window.addEventListener("resize", this.handleResize);
      window.addEventListener("resize", this.updatePaginationPage);
      this.updatePaginationPage();
      this.$watch("peersRef", () => {
        // alert('peers watch ')
        // if (!this.peers || this.peers.length === 0) return;
  
        Object.keys(this.remoteStreamsRef).forEach((key) => {
          console.log(
            "this.remoteStreamsRef[key]------------",
            this.remoteStreamsRef[key]
          );
  
          if (!this.remoteStreamsRef[key].srcObject) {
            const source = this.peersRef.find(
              (peer) => peer?.storageId === key?.split("_")[0]
            )?.mediaStream;
            this.$refs.remoteStreamsRef[key].srcObject = source;
          }
        });
  
        resize();
      });
  
      window.onunload = () => {
        // writableStream.abort()
        // also possible to call abort on the writer you got from `getWriter()`
        this.writer.abort();
      };
  
      let urlParams = new URLSearchParams(window.location.search);
      this.webinarId = urlParams.get("webinarid");
      this.mySessionId = urlParams.get("webinarid");
      let email = urlParams.get("email");
      let content = urlParams.get("content");
      this.childId = urlParams.get("childId");
      let body = {
        webinarId: this.webinarId,
        childId: this.childId ?? 0,
      };
      if (urlParams.has("webinarid")) {
        this.room_type = "JOIN_ROOM";
        console.log("this.webinarId = " + this.webinarId);
        this.role = "VIEWER";
        this.user_name = this.webinarId;
        var userdetailsData = JSON.parse(localStorage.getItem("userdetails"));
        this.loggedUserData = {
          ...userdetailsData,
          profileImg: "unitydefaultlogo.png",
        };
        this.profileImg = "unitydefaultlogo.png";
  
        this.joinMeeting();
      } else {
        const loc = window.location.pathname;
        console.log("starting loc value = " + loc);
        const locs =
          loc.lastIndexOf("/") == loc.length - 1
            ? loc.substring(0, loc.length - 1)
            : loc.substring(0, loc.lastIndexOf("/"));
        const companyname = locs.substring(locs.lastIndexOf("/") + 1);
  
        console.log("starting loc value = " + loc);
        let urlParams = new URLSearchParams(window.location.search);
        var payLoad = {
          companyName: companyname,
          userId: localStorage.getItem("userdetails")
            ? JSON.parse(localStorage.getItem("userdetails"))._id
            : "users_",
          roomName: window.location.pathname.split("/").pop(),
        };
        const verifyOpenRoom = await verifyOpenRoomWebinars(payLoad);
  
        this.room_type = "OPEN_ROOM";
        this.role = "HOST";
        this.user_name = this.webinarId;
        var userdetailsData = JSON.parse(localStorage.getItem("userdetails"));
        this.loggedUserData = {
          ...userdetailsData,
          profileImg: "unitydefaultlogo.png",
        };
        this.profileImg = "unitydefaultlogo.png";
  
        this.webinarId = verifyOpenRoom.data.result.webinar_data._id;
        console.log("this.webinarId = " + this.webinarId);
        this.joinMeeting();
      }
    },
    async created() {
      if (!navigator.mediaDevices?.enumerateDevices) {
      } else {
        // List cameras and microphones.
        await navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            this.deviceData = devices;
          })
          .catch((error) => {
            if (error.name == "TypeError") {
              $("#TypeError").modal("show");
            }
            if (error.name == "NotReadableError") {
              $("#NotReadableError").modal("show");
              // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
            }
          });
      }
  
      navigator.mediaDevices.ondevicechange = async (e) => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        console.log("e-----", e);
        console.log("devices-----", devices);
        this.deviceData = devices;
  
        console.log(
          "--------------",
          devices.some((device) => device.kind === "videoinput")
        );
        if (!devices.some((device) => device.kind === "videoinput")) {
          if (this.currentVideoState) {
            this.handleVideo();
            this.is_cam_allow = false;
          }
        } else {
          $("#DeniedModalCamera").modal("hide");
        }
        if (this.stremErr == "NotFoundError") {
          if (this.socketId == "") {
            if (
              devices.some((device) => device.kind === "videoinput") &&
              devices.some((device) => device.kind === "audioinput")
            ) {
              this.joinMeeting();
            }
          }
        }
      };
  
      let videoInput = this.deviceData.filter(
        (device) => device.kind === "videoinput"
      );
      if (videoInput.length > 0) {
        // this.selectedVideo = videoInput[0].deviceId;
      }
      let audioInput = this.deviceData.filter(
        (device) => device.kind === "audioinput"
      );
      if (audioInput.length > 0) {
        // this.selectedAudio = audioInput[0].deviceId;
      }
      let audioOutput = this.deviceData.filter(
        (device) => device.kind === "audiooutput"
      );
      if (audioOutput.length > 0) {
        const audioOutputDe = this.deviceData.filter(
          (device) =>
            device.kind === "audiooutput" && device.deviceId == "default"
        );
        console.log("audioOutputDe--------", audioOutputDe);
        if (audioOutputDe.length) {
          this.selectedOuterAudio = audioOutputDe[0].deviceId;
        } else {
          this.selectedOuterAudio = audioOutput[0].deviceId;
        }
      }
  
      console.log("1--------", audioInput);
      console.log("2--------", audioOutput);
      console.log("3--------", this.deviceData);
  
      // try {
      //   const stream = await navigator.mediaDevices
      //     .getUserMedia({
      //       audio: {
      //         echoCancellation: true,
      //         noiseSuppression: true,
      //         sampleRate: 44100,
      //       },
      //       video: true,
      //     });
      // } catch (error) {
  
      //   if (error.name == "TypeError") {
      //     $("#TypeError").modal("show");
      //   }
  
      //   if (error.name == "AbortError") {
      //     $("#NotReadableError").modal("show");
      //   }
      //   if (error.name == "NotReadableError") {
      //     $("#NotReadableError").modal("show");
      //     console.log("this.publisher=============", this.publisher);
      //     // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
      //   }
      //   this.join_btn = false;
      //   console.log("error 3 .name", error.name + ": " + error.message);
      // }
  
      setInterval(async () => {
        if (!navigator.mediaDevices?.enumerateDevices) {
          console.log("enumerateDevices() not supported.");
        } else {
          // List cameras and microphones.
          await navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
              // console.log("devices.",devices);
              this.deviceData = devices;
            })
            .catch((error) => {
              console.log("error.name", error.name + ": " + error.message);
              if (error.name == "TypeError") {
                $("#TypeError").modal("show");
              }
              if (error.name == "NotReadableError") {
                $("#NotReadableError").modal("show");
                // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
              }
            });
        }
      }, 1000);
  
      const interv = setInterval(() => {
        this.handleVoiceSort();
        // this.getCurrStats()
      }, 700);
      return () => {
        clearInterval(interv);
      };
    },
  
    methods: {
      updateOrientation() {
        this.isLandscape = window.matchMedia("(orientation: landscape)").matches;
      },
      getPageSize() {
        console.log("inner width-----", window.innerWidth);
        if (window.innerWidth > 1515) {
          return "desktop";
        } else if (window.innerWidth > 1023) {
          return "tablet";
        } else {
          return "mobile";
        }
      },
      getCurrentDevice() {
        if (screen.width >= 1515) {
          return "desktop";
        } else if (screen.width >= 768 && screen.width <= 1024) {
          return "tablet";
        } else {
          return "mobile";
        }
      },
      getOrientation() {
        return screen.orientation.type;
      },
      updatePageSize() {
        console.log("Window resized! New page size:", this.getPageSize());
        this.pageSize = this.getPageSize(); // Update the page size dynamically
        this.orientation = this.getOrientation();
        this.currentDevice = this.getCurrentDevice();
      },
      prevPage() {
        if (this.currentPagePagination > 1) {
          this.currentPagePagination--;
        }
      },
      nextPage() {
        if (this.currentPagePagination < this.totalPagesPagination) {
          this.currentPagePagination++;
        }
      },
      updatePaginationPage() {
        this.paginationPageSize = this.computedPageSize;
      },
      updateWidth() {
        // this.getWidth = window.innerWidth;
        this.getWidth = screen.width;
      },
      throttle(func, limit) {
        let inThrottle;
        return function () {
          const args = arguments;
          const context = this;
          if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
          }
        };
      },
      changeObjectIndex(array, currentIndex, newIndex) {
        if (newIndex >= array.length) {
          let k = newIndex - array.length + 1;
          while (k--) {
            array.push(undefined);
          }
        }
        array.splice(newIndex, 0, array.splice(currentIndex, 1)[0]);
      },
      async importData() {
        const checkUsername = (obj) => obj.screenShare == true;
  
        if (
          (this.mainScreenStreamRef != null &&
            this.mainScreenStreamRef.remoteScreen == true) ||
          this.peers.some(checkUsername) == true
        ) {
          swal({
            text: "This will let you take over from as ther main video presenter.",
            buttons: true,
            dangerMode: true,
          }).then(async (willDelete) => {
            if (willDelete) {
              let obj = this.peers.find((o) => o.screenShare == true);
  
              console.log("T M----", this.mainScreenStreamRef);
              if (this.mainScreenStreamRef) {
                if (this.mainScreenStreamRef.screenShare == true) {
                  if (this.mainScreenStreamRef.audioShare == true) {
                    this.socket.emit(
                      "stopAudioScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else if (
                    this.mainScreenStreamRef.videoShare == true &&
                    this.mainScreenStreamRef.docShare
                  ) {
                    this.socket.emit(
                      "stopDocScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else if (
                    this.mainScreenStreamRef.videoShare == true &&
                    !this.mainScreenStreamRef.docShare
                  ) {
                    this.socket.emit(
                      "stopVideoScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else {
                    this.socket.emit(
                      "stopShareScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  }
                } else if (obj) {
                  this.socket.emit("stopShareScreen", obj.socketId);
                  console.log("___obj----", obj);
                  console.log("___obj.storageId----", obj.storageId);
                  if (
                    document.getElementById(
                      obj.storageId + "_STREAM_VIDEO_DIV"
                    ) != null
                  ) {
                    this.peers.push({ is_show: true, ...obj });
                    // document.getElementById(obj.storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
                  }
                } else {
                }
                console.log("___obj----", obj);
  
                // let input = document.createElement("input");
                // input.type = "file";
                // input.id = "fileInp";
  
                $("#fileInp").click();
  
                document
                  .getElementById("fileInp")
                  .addEventListener("change", async (e) => {
                    const file = e.target.files[0];
                    if (file.type.startsWith("video/")) {
                      console.log("file--------", file);
                      console.log("fileSSS--------", URL.createObjectURL(file));
                      document.getElementById("Screen").style.display = "flex";
                      if (this.mainScreenStreamRef == null) {
                        this.screenShareLoader = true;
                      }
                      // if (this.mainScreenStreamRef) {
                      //   this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                      //   document.getElementById("ALL_VIDEOS").style.display = "flex";
                      // }
  
                      if (this.mainScreenStreamRef == null) {
                        document.getElementById("Screen").style.display = "flex";
                        this.screenShareLoader = true;
                      }
                      if (this.mainScreenStreamRef) {
                        this.updateIsShow(
                          this.mainScreenStreamRef.storageId,
                          true
                        );
                        document.getElementById("ALL_VIDEOS").style.display =
                          "flex";
                      }
                      this.mainScreenStreamRef = {
                        storageId: this.socket.id + "VIDEO",
                        mediaStream: URL.createObjectURL(file),
                        remoteScreen: false,
                        screenShare: true,
                        videoShare: true,
                        audioShare: false,
                        socketId: this.socket.id,
                        userDetails: {
                          user_name: this.user_name,
                          profileImg: this.profileImg,
                        },
                      };
                      console.log(this.mainScreenStreamRef);
  
                      // document.getElementById('vid').src = URL.createObjectURL(file);
                      // document.getElementById('vid').autoplay = true;
                      console.log(document.getElementById("vid"));
                    } else {
                      alert("The file is not a video file.");
                    }
                  });
                // input.click();
                // The file is a video file.
              } else {
                if (obj) {
                  this.socket.emit("stopShareScreen", obj.socketId);
                  console.log("___obj----", obj);
                  console.log("___obj.storageId----", obj.storageId);
                  if (
                    document.getElementById(
                      obj.storageId + "_STREAM_VIDEO_DIV"
                    ) != null
                  ) {
                    this.peers.push({ is_show: true, ...obj });
                    // document.getElementById(obj.storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
                  }
  
                  console.log("___obj----", obj);
  
                  // let input = document.createElement("input");
                  // input.type = "file";
                  // input.id = "fileInp";
  
                  $("#fileInp").click();
                  document
                    .getElementById("fileInp")
                    .addEventListener("change", async (e) => {
                      const file = e.target.files[0];
  
                      if (file.type.startsWith("video/")) {
                        console.log("file--------", file);
                        console.log("fileSSS--------", URL.createObjectURL(file));
                        document.getElementById("Screen").style.display = "flex";
                        if (this.mainScreenStreamRef == null) {
                          this.screenShareLoader = true;
                        }
                        // if (this.mainScreenStreamRef) {
                        //   this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                        //   document.getElementById("ALL_VIDEOS").style.display = "flex";
                        // }
  
                        if (this.mainScreenStreamRef == null) {
                          document.getElementById("Screen").style.display =
                            "flex";
                          this.screenShareLoader = true;
                        }
                        if (this.mainScreenStreamRef) {
                          this.updateIsShow(
                            this.mainScreenStreamRef.storageId,
                            true
                          );
                          document.getElementById("ALL_VIDEOS").style.display =
                            "flex";
                        }
                        this.mainScreenStreamRef = {
                          storageId: this.socket.id + "VIDEO",
                          mediaStream: URL.createObjectURL(file),
                          remoteScreen: false,
                          screenShare: true,
                          videoShare: true,
                          audioShare: false,
                          socketId: this.socket.id,
                          userDetails: {
                            user_name: this.user_name,
                            profileImg: this.profileImg,
                          },
                        };
                        console.log(this.mainScreenStreamRef);
  
                        // document.getElementById('vid').src = URL.createObjectURL(file);
                        // document.getElementById('vid').autoplay = true;
                        console.log(document.getElementById("vid"));
                      } else {
                        alert("The file is not a video file.");
                      }
                    });
                  // input.click();
                } else {
                }
              }
            } else {
            }
          });
        } else {
          try {
            console.log("File picking");
            // let input = document.createElement("input");
            // input.type = "file";
            // input.id = "fileInp";
  
            $("#fileInp").click();
            document
              .getElementById("fileInp")
              .addEventListener("change", async (e) => {
                console.log("File picked");
  
                const file = e.target.files[0];
                if (file.type.startsWith("video/")) {
                  console.log("file--------", file);
                  console.log("fileSSS--------", URL.createObjectURL(file));
                  document.getElementById("Screen").style.display = "flex";
                  if (this.mainScreenStreamRef == null) {
                    this.screenShareLoader = true;
                  }
                  // if (this.mainScreenStreamRef) {
                  //   this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                  //   document.getElementById("ALL_VIDEOS").style.display = "flex";
                  // }
  
                  if (this.mainScreenStreamRef == null) {
                    document.getElementById("Screen").style.display = "flex";
                    this.screenShareLoader = true;
                  }
                  if (this.mainScreenStreamRef) {
                    this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                    document.getElementById("ALL_VIDEOS").style.display = "flex";
                  }
                  this.mainScreenStreamRef = {
                    storageId: this.socket.id + "VIDEO",
                    mediaStream: URL.createObjectURL(file),
                    remoteScreen: false,
                    screenShare: true,
                    videoShare: true,
                    audioShare: false,
                    socketId: this.socket.id,
                    userDetails: {
                      user_name: this.user_name,
                      profileImg: this.profileImg,
                    },
                  };
                  console.log(this.mainScreenStreamRef);
  
                  // document.getElementById('vid').src = URL.createObjectURL(file);
                  // document.getElementById('vid').autoplay = true;
                  console.log(document.getElementById("vid"));
                } else {
                  alert("The file is not a video file.");
                }
              });
          } catch (e) {
            console.log("e----", e);
          }
        }
      },
      async importAudioData(e) {
        const checkUsername = (obj) => obj.screenShare == true;
  
        if (
          (this.mainScreenStreamRef != null &&
            this.mainScreenStreamRef.remoteScreen == true) ||
          this.peers.some(checkUsername) == true
        ) {
          swal({
            text: "This will let you take over from as ther main audio presenter.",
            buttons: true,
            dangerMode: true,
          }).then(async (willDelete) => {
            if (willDelete) {
              let obj = this.peers.find((o) => o.screenShare == true);
  
              console.log("T M----", this.mainScreenStreamRef);
              if (this.mainScreenStreamRef) {
                if (this.mainScreenStreamRef.screenShare == true) {
                  if (this.mainScreenStreamRef.audioShare == true) {
                    this.socket.emit(
                      "stopAudioScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else if (
                    this.mainScreenStreamRef.videoShare == true &&
                    this.mainScreenStreamRef.docShare
                  ) {
                    this.socket.emit(
                      "stopDocScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else if (
                    this.mainScreenStreamRef.videoShare == true &&
                    !this.mainScreenStreamRef.docShare
                  ) {
                    this.socket.emit(
                      "stopVideoScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else {
                    this.socket.emit(
                      "stopShareScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  }
                } else if (obj) {
                  this.socket.emit("stopShareScreen", obj.socketId);
                  console.log("___obj----", obj);
                  console.log("___obj.storageId----", obj.storageId);
                  if (
                    document.getElementById(
                      obj.storageId + "_STREAM_VIDEO_DIV"
                    ) != null
                  ) {
                    this.peers.push({ is_show: true, ...obj });
                    // document.getElementById(obj.storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
                  }
                } else {
                }
  
                $("#audioInp").click();
  
                document
                  .getElementById("audioInp")
                  .addEventListener("change", async (e) => {
                    const file = e.target.files[0];
                    console.log("File picked", file);
                    if (file.type.match("audio/*")) {
                      console.log("file--------", file);
                      console.log("fileSSS--------", URL.createObjectURL(file));
                      document.getElementById("Screen").style.display = "flex";
                      if (this.mainScreenStreamRef == null) {
                        this.screenShareLoader = true;
                      }
  
                      if (this.mainScreenStreamRef == null) {
                        document.getElementById("Screen").style.display = "flex";
                        this.screenShareLoader = true;
                      }
                      if (this.mainScreenStreamRef) {
                        this.updateIsShow(
                          this.mainScreenStreamRef.storageId,
                          true
                        );
                        document.getElementById("ALL_VIDEOS").style.display =
                          "flex";
                      }
                      this.mainScreenStreamRef = {
                        storageId: this.socket.id + "VIDEO",
                        mediaStream: URL.createObjectURL(file),
                        remoteScreen: false,
                        screenShare: true,
                        videoShare: false,
                        audioShare: true,
                        socketId: this.socket.id,
                        userDetails: {
                          user_name: this.user_name,
                          profileImg: this.profileImg,
                        },
                      };
                      console.log(this.mainScreenStreamRef);
  
                      console.log(document.getElementById("vid"));
                    } else {
                      alert("The file is not an audio file. 2");
                    }
                  });
              } else {
                if (obj) {
                  this.socket.emit("stopShareScreen", obj.socketId);
                  console.log("___obj----", obj);
                  console.log("___obj.storageId----", obj.storageId);
                  if (
                    document.getElementById(
                      obj.storageId + "_STREAM_VIDEO_DIV"
                    ) != null
                  ) {
                    this.peers.push({ is_show: true, ...obj });
                  }
  
                  console.log("___obj----", obj);
  
                  $("#audioInp").click();
                  document
                    .getElementById("audioInp")
                    .addEventListener("change", async (e) => {
                      const file = e.target.files[0];
                      console.log("File picked", file);
                      if (file.type.match("audio/*")) {
                        console.log("file--------", file);
                        console.log("fileSSS--------", URL.createObjectURL(file));
                        document.getElementById("Screen").style.display = "flex";
                        if (this.mainScreenStreamRef == null) {
                          this.screenShareLoader = true;
                        }
                        // if (this.mainScreenStreamRef) {
                        //   this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                        //   document.getElementById("ALL_VIDEOS").style.display = "flex";
                        // }
  
                        if (this.mainScreenStreamRef == null) {
                          document.getElementById("Screen").style.display =
                            "flex";
                          this.screenShareLoader = true;
                        }
                        if (this.mainScreenStreamRef) {
                          this.updateIsShow(
                            this.mainScreenStreamRef.storageId,
                            true
                          );
                          document.getElementById("ALL_VIDEOS").style.display =
                            "flex";
                        }
                        this.mainScreenStreamRef = {
                          storageId: this.socket.id + "VIDEO",
                          mediaStream: URL.createObjectURL(file),
                          remoteScreen: false,
                          screenShare: true,
                          audioShare: true,
                          videoShare: false,
                          socketId: this.socket.id,
                          userDetails: {
                            user_name: this.user_name,
                            profileImg: this.profileImg,
                          },
                        };
                        console.log(this.mainScreenStreamRef);
  
                        console.log(document.getElementById("vid"));
                      } else {
                        alert("The file is not an audio file. 1");
                      }
                    });
                  // input.click();
                } else {
                }
              }
            } else {
            }
          });
        } else {
          try {
            console.log("File picking");
  
            $("#audioInp").click();
            document
              .getElementById("audioInp")
              .addEventListener("change", async (e) => {
                const file = e.target.files[0];
                console.log("File picked", file);
  
                if (file.type.match("audio/*")) {
                  console.log("file--------", file);
                  console.log("fileSSS--------", URL.createObjectURL(file));
                  document.getElementById("Screen").style.display = "flex";
                  if (this.mainScreenStreamRef == null) {
                    this.screenShareLoader = true;
                  }
  
                  if (this.mainScreenStreamRef == null) {
                    document.getElementById("Screen").style.display = "flex";
                    this.screenShareLoader = true;
                  }
                  if (this.mainScreenStreamRef) {
                    this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                    document.getElementById("ALL_VIDEOS").style.display = "flex";
                  }
                  this.mainScreenStreamRef = {
                    storageId: this.socket.id + "VIDEO",
                    mediaStream: URL.createObjectURL(file),
                    remoteScreen: false,
                    screenShare: true,
                    videoShare: false,
                    audioShare: true,
                    socketId: this.socket.id,
                    userDetails: {
                      user_name: this.user_name,
                      profileImg: this.profileImg,
                    },
                  };
                  console.log(this.mainScreenStreamRef);
  
                  console.log(document.getElementById("vid"));
                } else {
                  alert("The file is not an audio file.");
                }
              });
          } catch (e) {
            console.log("e----", e);
          }
        }
      },
      async importDocumentsData(e) {
        const checkUsername = (obj) => obj.screenShare == true;
  
        if (
          (this.mainScreenStreamRef != null &&
            this.mainScreenStreamRef.remoteScreen == true) ||
          this.peers.some(checkUsername) == true
        ) {
          swal({
            text: "This will let you take over from as ther main document presenter.",
            buttons: true,
            dangerMode: true,
          }).then(async (willDelete) => {
            if (willDelete) {
              let obj = this.peers.find((o) => o.screenShare == true);
  
              console.log("T M----", this.mainScreenStreamRef);
              if (this.mainScreenStreamRef) {
                if (this.mainScreenStreamRef.screenShare == true) {
                  if (
                    this.mainScreenStreamRef.audioShare != undefined &&
                    this.mainScreenStreamRef.audioShare == true
                  ) {
                    this.socket.emit(
                      "stopAudioScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else if (
                    this.mainScreenStreamRef.videoShare == true &&
                    this.mainScreenStreamRef.docShare
                  ) {
                    this.socket.emit(
                      "stopDocScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else if (
                    this.mainScreenStreamRef.videoShare == true &&
                    !this.mainScreenStreamRef.docShare
                  ) {
                    this.socket.emit(
                      "stopVideoScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else {
                    this.socket.emit(
                      "stopShareScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  }
                } else if (obj) {
                  this.socket.emit("stopShareScreen", obj.socketId);
                  if (
                    document.getElementById(
                      obj.storageId + "_STREAM_VIDEO_DIV"
                    ) != null
                  ) {
                    this.peers.push({ is_show: true, ...obj });
                    // document.getElementById(obj.storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
                  }
                } else {
                }
  
                try {
                  console.log("File picking");
  
                  $("#documentInp").click();
                  document
                    .getElementById("documentInp")
                    .addEventListener("change", async (e) => {
                      console.log("File picked");
  
                      const file = e.target.files[0];
                      console.log(file);
                      var upld = file.name.split(".").pop();
                      if (upld == "pdf") {
                        this.pageNumber = 1;
                        this.totalPages = 0;
                        this.currentPage = 1;
                        console.log("file--------", file);
                        console.log("fileSSS--------", URL.createObjectURL(file));
                        document.getElementById("Screen").style.display = "flex";
                        if (this.mainScreenStreamRef == null) {
                          this.screenShareLoader = true;
                        }
  
                        if (this.mainScreenStreamRef == null) {
                          document.getElementById("Screen").style.display =
                            "flex";
                          this.screenShareLoader = true;
                        }
                        if (this.mainScreenStreamRef) {
                          this.updateIsShow(
                            this.mainScreenStreamRef.storageId,
                            true
                          );
                          document.getElementById("ALL_VIDEOS").style.display =
                            "flex";
                        }
                        this.mainScreenStreamRef = {
                          storageId: this.socket.id + "VIDEO",
                          mediaStream: "",
                          remoteScreen: false,
                          screenShare: true,
                          videoShare: false,
                          audioShare: false,
                          docShare: true,
                          socketId: this.socket.id,
                          userDetails: {
                            user_name: this.user_name,
                            profileImg: this.profileImg,
                          },
                        };
                        console.log(this.mainScreenStreamRef);
  
                        console.log(document.getElementById("vid"));
                        await this.loadPDF(URL.createObjectURL(file));
                      } else {
                        alert("Only PDF are allowed");
                      }
                      // if (file.type.startsWith("video/")) {
                      // } else {
                      //   alert("The file is not a video file.");
                      // }
                    });
                } catch (e) {
                  console.log("e----", e);
                }
              } else {
                if (obj) {
                  this.socket.emit("stopShareScreen", obj.socketId);
                  console.log("___obj----", obj);
                  console.log("___obj.storageId----", obj.storageId);
                  if (
                    document.getElementById(
                      obj.storageId + "_STREAM_VIDEO_DIV"
                    ) != null
                  ) {
                    this.peers.push({ is_show: true, ...obj });
                  }
  
                  console.log("___obj----", obj);
  
                  try {
                    console.log("File picking");
  
                    $("#documentInp").click();
                    document
                      .getElementById("documentInp")
                      .addEventListener("change", async (e) => {
                        console.log("File picked");
  
                        const file = e.target.files[0];
                        console.log(file);
                        var upld = file.name.split(".").pop();
                        if (upld == "pdf") {
                          this.pageNumber = 1;
                          this.totalPages = 0;
                          this.currentPage = 1;
                          console.log("file--------", file);
                          console.log(
                            "fileSSS--------",
                            URL.createObjectURL(file)
                          );
                          document.getElementById("Screen").style.display =
                            "flex";
                          if (this.mainScreenStreamRef == null) {
                            this.screenShareLoader = true;
                          }
  
                          if (this.mainScreenStreamRef == null) {
                            document.getElementById("Screen").style.display =
                              "flex";
                            this.screenShareLoader = true;
                          }
                          if (this.mainScreenStreamRef) {
                            this.updateIsShow(
                              this.mainScreenStreamRef.storageId,
                              true
                            );
                            document.getElementById("ALL_VIDEOS").style.display =
                              "flex";
                          }
                          this.mainScreenStreamRef = {
                            storageId: this.socket.id + "VIDEO",
                            mediaStream: "",
                            remoteScreen: false,
                            screenShare: true,
                            videoShare: false,
                            audioShare: false,
                            docShare: true,
                            socketId: this.socket.id,
                            userDetails: {
                              user_name: this.user_name,
                              profileImg: this.profileImg,
                            },
                          };
                          console.log(this.mainScreenStreamRef);
  
                          console.log(document.getElementById("vid"));
                          await this.loadPDF(URL.createObjectURL(file));
                        } else {
                          alert("Only PDF are allowed");
                        }
                        // if (file.type.startsWith("video/")) {
                        // } else {
                        //   alert("The file is not a video file.");
                        // }
                      });
                  } catch (e) {
                    console.log("e----", e);
                  }
                  // input.click();
                } else {
                }
              }
            } else {
            }
          });
        } else {
          try {
            console.log("File picking");
  
            $("#documentInp").click();
            document
              .getElementById("documentInp")
              .addEventListener("change", async (e) => {
                console.log("File picked");
  
                const file = e.target.files[0];
                console.log(file);
                var upld = file.name.split(".").pop();
                if (upld == "pdf") {
                  this.pageNumber = 1;
                  this.totalPages = 0;
                  this.currentPage = 1;
                  console.log("file--------", file);
                  console.log("fileSSS--------", URL.createObjectURL(file));
                  document.getElementById("Screen").style.display = "flex";
                  if (this.mainScreenStreamRef == null) {
                    this.screenShareLoader = true;
                  }
  
                  if (this.mainScreenStreamRef == null) {
                    document.getElementById("Screen").style.display = "flex";
                    this.screenShareLoader = true;
                  }
                  if (this.mainScreenStreamRef) {
                    this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                    document.getElementById("ALL_VIDEOS").style.display = "flex";
                  }
                  this.mainScreenStreamRef = {
                    storageId: this.socket.id + "VIDEO",
                    mediaStream: "",
                    remoteScreen: false,
                    screenShare: true,
                    videoShare: false,
                    audioShare: false,
                    docShare: true,
                    socketId: this.socket.id,
                    userDetails: {
                      user_name: this.user_name,
                      profileImg: this.profileImg,
                    },
                  };
                  console.log(this.mainScreenStreamRef);
  
                  console.log(URL.createObjectURL(file));
                  await this.loadPDF(URL.createObjectURL(file));
                } else {
                  alert("Only PDF are allowed");
                }
                // if (file.type.startsWith("video/")) {
                // } else {
                //   alert("The file is not a video file.");
                // }
              });
          } catch (e) {
            console.log("e----", e);
          }
        }
      },
      async shareStreamToSocketId(socketId) {
        const canvas = document.getElementById("canvas");
        console.log(canvas.height);
        console.log(canvas.width);
  
        // const dataURL = canvas.toDataURL();
        // console.log(dataURL);
  
        const isSafari =
          window?.safari ||
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
          /iPad|iPhone|iPod/.test(navigator.userAgent)
            ? true
            : false;
  
        const type = isSafari ? "image/jpeg" : "image/webp";
        const quality = 0.1;
  
        console.log(quality, type);
        canvas.toBlob(
          (blob) => {
            const dataURL = URL.createObjectURL(blob);
            const newImg = document.getElementById("mySharedDoc");
            // newImg.src = dataURL;
  
            this.srcList = [];
            this.srcList.push(dataURL);
            const newIm22g = document.getElementById("mySharedDoc");
            console.log("********************************");
            console.log(newIm22g);
            console.log("********************************");
            this.socket.emit("sendDocumentDataToRecord", {
              type: type,
              socketId: socketId,
              roomId: this.roomId,
              blob: blob,
              height: canvas.height,
              width: canvas.width,
            });
          },
          type,
          quality
        );
      },
      async shareStream() {
        const canvas = document.getElementById("canvas");
        console.log(canvas.height);
        console.log(canvas.width);
  
        // const dataURL = canvas.toDataURL();
        // console.log(dataURL);
  
        const isSafari =
          window?.safari ||
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
          /iPad|iPhone|iPod/.test(navigator.userAgent)
            ? true
            : false;
  
        const type = isSafari ? "image/jpeg" : "image/webp";
        const quality = 0.1;
  
        console.log(quality, type);
        canvas.toBlob(
          (blob) => {
            const dataURL = URL.createObjectURL(blob);
            const newImg = document.getElementById("mySharedDoc");
            // newImg.src = dataURL;
  
            this.srcList = [];
            this.srcList.push(dataURL);
            const newIm22g = document.getElementById("mySharedDoc");
            console.log("********************************");
            console.log(newIm22g);
            console.log("********************************");
            this.socket.emit("sendDocumentData", {
              type: type,
              socketId: this.socketId,
              roomId: this.roomId,
              blob: blob,
              height: canvas.height,
              width: canvas.width,
            });
          },
          type,
          quality
        );
      },
      async toSpecificPage(number) {
        this.currentPage = number;
        console.log("number=============", typeof number);
        console.log("this.currentPage=============", this.currentPage);
        console.log("this.pageNumber=============", this.pageNumber);
        const page = await toRaw(this.pdf).getPage(number);
        console.log("Page loaded");
  
        const scale = 1.5;
        const viewport = page.getViewport({ scale: scale });
  
        // Prepare canvas using PDF page dimensions
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
  
        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        const renderTask = page.render(renderContext);
        const pdfrendered = await renderTask.promise;
        console.log("Page rendered", pdfrendered);
        this.shareStream();
      },
      async toNextPage() {
        this.currentPage++;
        console.log("this.currentPage=============", this.currentPage);
        console.log("this.pageNumber=============", this.pageNumber);
        const page = await toRaw(this.pdf).getPage(this.currentPage);
        console.log("Page loaded");
  
        const scale = 1.5;
        const viewport = page.getViewport({ scale: scale });
  
        // Prepare canvas using PDF page dimensions
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
  
        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        const renderTask = page.render(renderContext);
        const pdfrendered = await renderTask.promise;
        console.log("Page rendered", pdfrendered);
        this.shareStream();
      },
      async toPreviousPage() {
        this.currentPage--;
        console.log("this.currentPage=============", this.currentPage);
        console.log("this.pageNumber=============", --this.pageNumber);
        const page = await toRaw(this.pdf).getPage(this.currentPage);
        console.log("Page loaded");
  
        const scale = 1.5;
        const viewport = page.getViewport({ scale: scale });
  
        // Prepare canvas using PDF page dimensions
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
  
        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        const renderTask = page.render(renderContext);
        const pdfrendered = await renderTask.promise;
        console.log("Page rendered", pdfrendered);
        this.shareStream();
      },
      async loadPDF(file) {
        this.docShared = true;
        const pdfPath = file;
        const loadingTask = pdfjsLib.getDocument(pdfPath);
        const pdf = await loadingTask.promise;
        this.pdf = pdf;
        this.totalPages = pdf.numPages;
        // Fetch the first page
        console.log("PDF loaded", pdf.numPages);
  
        const page = await pdf.getPage(this.pageNumber);
        // .then(async function (page) {
        console.log("Page loaded");
  
        const scale = 1.5;
        const viewport = page.getViewport({ scale: scale });
  
        // Prepare canvas using PDF page dimensions
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
  
        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        const renderTask = page.render(renderContext);
        await renderTask.promise;
        console.log("Page rendered");
  
        canvas.toBlob((blob) => {
          const dataURL = URL.createObjectURL(blob);
  
          const newImg = document.getElementById("mySharedDoc");
          // newImg.src = dataURL;
          this.srcList = [];
          this.srcList.push(dataURL);
          // document.body.appendChild(newImg);
        });
  
        // document.getElementById("videoRef");
        const stream = document.getElementById("canvas").captureStream(15);
        // document.getElementById("videoRef").srcObject = stream;
        // document.getElementById("videoRef").play()
        console.log("stream captured", stream.getVideoTracks()[0]);
        const Videotracks = stream.getVideoTracks()[0];
  
        if (Videotracks) {
          this.videoProducer3 = await this.produceTransport?.produce({
            track: Videotracks,
            appData: {
              type: "screen",
              action: "shareVideo",
              actionA: "shareDoc",
            },
          });
          // this.shareStream();
        }
      },
  
      async VideoShare(e) {
        try {
          if (!this.videoShared) {
            const capture = new CaptureStream(e.target);
            const stream = await capture?.generatedStream;
            // document.getElementById('stream').srcObject = stream;
  
            console.log("stream---------", stream);
            console.log("capture---------", capture);
            const Audiotracks = stream.getAudioTracks()[0];
            console.log("Audiotracks---------", Audiotracks);
            const Videotracks = stream.getVideoTracks()[0];
            console.log("Videotracks---------", Videotracks);
            console.log("playing");
            if (Audiotracks) {
              this.audioProducer3 = await this.produceTransport?.produce({
                track: Audiotracks,
                appData: {
                  type: "screen",
                  action: "shareVideo",
                },
              });
            }
            if (Videotracks) {
              this.videoProducer3 = await this.produceTransport?.produce({
                track: Videotracks,
                appData: {
                  type: "screen",
                  action: "shareVideo",
                },
              });
            }
            stream
              .getVideoTracks()[0]
              ?.addEventListener("ended", () => this.stopImportData());
            this.videoShared = true;
          }
        } catch (e) {
          console.log("e----", e);
        }
      },
  
      async AudioShare(e) {
        // alert('Started!')
        try {
          if (!this.audioShared) {
            const capture = new AudioCaptureStream(e.target);
            const stream = await capture?.generatedStream;
            // document.getElementById('stream').srcObject = stream;
  
            const Audiotracks = stream.getAudioTracks()[0];
            console.log("Audiotracks---------", Audiotracks);
            const Videotracks = stream.getVideoTracks()[0];
            console.log("Videotracks---------", Videotracks);
            console.log("playing");
            if (Audiotracks) {
              this.audioProducer3 = await this.produceTransport?.produce({
                track: Audiotracks,
                appData: {
                  type: "screen",
                  action: "shareVideo",
                  actionA: "shareAudio",
                },
              });
            }
            if (Videotracks) {
              this.videoProducer3 = await this.produceTransport?.produce({
                track: Videotracks,
                appData: {
                  type: "screen",
                  action: "shareVideo",
                  actionA: "shareAudio",
                },
              });
            }
            stream
              .getVideoTracks()[0]
              ?.addEventListener("ended", () => this.stopImportAudioData());
            this.audioShared = true;
          }
        } catch (e) {
          console.log("e----", e);
        }
      },
  
      startRoomAfterVerifyPin() {
        if (!localStorage.getItem("userdetails")) {
          $("#enterNameModal").modal("show");
          this.role = "GUEST";
        } else {
          this.userId = JSON.parse(localStorage.getItem("userdetails"))._id;
          var userdetailsData = JSON.parse(localStorage.getItem("userdetails"));
          if (userdetailsData.profileImg) {
            this.loggedUserData = {
              ...userdetailsData,
              profileImg: userdetailsData.profileImg,
            };
            this.profileImg = userdetailsData.profileImg;
            // this.loggedUserData = { ...userdetailsData, profileImg: this.BASE_URL + userdetailsData.profileImg };
          } else {
            this.loggedUserData = {
              ...userdetailsData,
              profileImg: "unitydefaultlogo.png",
            };
            this.profileImg = "unitydefaultlogo.png";
          }
          this.user_name = userdetailsData.display_name
            ? userdetailsData.display_name
            : userdetailsData.firstName + " " + userdetailsData.lastName;
  
          if (this.role != "VIEWER") {
            this.joinMeeting();
          }
        }
  
        // alert(this.webinarId)
        // this.filterdParticipants = verifyOpenRoom.data.result.participants.data.userId.filter(a => a.webinarUserType == 'Host');
        // this.participants = verifyOpenRoom.data.result.participants.data.userId
        if (this.lockRoom == true && this.role == "HOST") {
          // this.startRoomAfterVeify();
        } else {
        }
      },
      startRoomAfterVeify() {
        if (!localStorage.getItem("userdetails")) {
          $("#enterNameModal").modal("show");
          this.role = "GUEST";
        } else {
          var userdetailsData = JSON.parse(localStorage.getItem("userdetails"));
          if (userdetailsData.profileImg) {
            this.loggedUserData = {
              ...userdetailsData,
              profileImg: userdetailsData.profileImg,
            };
            this.profileImg = userdetailsData.profileImg;
            // this.loggedUserData = { ...userdetailsData, profileImg: this.BASE_URL + userdetailsData.profileImg };
          } else {
            this.loggedUserData = {
              ...userdetailsData,
              profileImg: "unitydefaultlogo.png",
            };
            this.profileImg = "unitydefaultlogo.png";
          }
          this.user_name = userdetailsData.display_name
            ? userdetailsData.display_name
            : userdetailsData.firstName + " " + userdetailsData.lastName;
  
          if (this.role != "VIEWER") {
            this.joinMeeting();
          }
        }
      },
      unLockOpenRoom() {
        var body = {
          webinarId: $("#txt_name").val(),
          lockRoom: "false",
        };
        console.log("body-D--ds-", $("#txt_name").val());
  
        console.log("body----", body);
        lockUnlockOpenRoom(body);
        $("#unlockModal").modal("hide");
        const element = document.querySelector("#toggleP");
        this.socket.emit("roomUnlockNotify", { room: this.roomId });
        this.socket.emit("unlockLockFlag", { room: this.roomId, status: 0 });
        element.classList.remove("on");
        this.lockRoom = false;
  
        this.waitingUser = this.waitingUser.filter((a) => a.room !== this.roomId);
        this.filterdWaitingUser = this.waitingUser.filter(
          (a) => a.room !== this.roomId
        );
  
        this.filterdWaitingUser = this.waitingUser.filter(
          (a) => a.userDetails.webinarUserType == this.currentTabSelect
        );
  
        this.socket.emit("allowJoinUserFromUnlock", this.roomId);
      },
      rejectAllWaitedUser() {
        this.waitingUser = this.waitingUser.filter((a) => a.room !== this.roomId);
        this.filterdWaitingUser = this.waitingUser.filter(
          (a) => a.room !== this.roomId
        );
        this.socket.emit("denyToAllJoinRoom", this.roomId);
      },
      acceptAllWaitedUser() {
        this.waitingUser = this.waitingUser.filter((a) => a.room !== this.roomId);
        this.filterdWaitingUser = this.waitingUser.filter(
          (a) => a.room !== this.roomId
        );
        this.socket.emit("allowToAllJoinRoom", this.roomId);
      },
      lockOpenRoom() {
        var body = {
          webinarId: $("#txt_name").val(),
          lockRoom: "true",
        };
        console.log("body-D--ds-", $("#txt_name").val());
        console.log("body----", body);
        lockUnlockOpenRoom(body);
        $("#lockModal").modal("hide");
        const element = document.getElementById("toggleP");
        element.classList.add("on");
        this.socket.emit("unlockLockFlag", { room: this.roomId, status: 1 });
  
        this.lockRoom = true;
      },
      handleChange(newOrder) {
        // this.yourArray = newOrder;
        console.log("Updated oldIndex:", newOrder.moved.oldIndex);
        console.log("Updated newIndex:", newOrder.moved.newIndex);
        console.log("Updated storageId:", newOrder.moved.element.storageId);
  
        let myArray = this.peerShow;
        let objectToMove = myArray.find(
          (obj) => obj.storageId === newOrder.moved.element.storageId
        );
        let currentIndex = myArray.indexOf(objectToMove);
        let newIndex = newOrder.moved.newIndex;
        this.changeObjectIndex(myArray, currentIndex, newIndex);
  
        let myArray2 = this.peersRef;
        let objectToMove2 = myArray2.find(
          (obj) => obj.storageId === newOrder.moved.element.storageId
        );
        let currentIndex2 = myArray2.indexOf(objectToMove2);
        let newIndex2 = newOrder.moved.newIndex;
        this.changeObjectIndex(myArray2, currentIndex2, newIndex2);
  
        let myArray3 = this.peers;
        let objectToMove3 = myArray3.find(
          (obj) => obj.storageId === newOrder.moved.element.storageId
        );
        let currentIndex3 = myArray3.indexOf(objectToMove3);
        let newIndex3 = newOrder.moved.newIndex;
        this.changeObjectIndex(myArray3, currentIndex3, newIndex3);
  
        // Display the updated array
        console.log(myArray);
        this.peerShow = myArray;
        this.peersRef = myArray2;
        this.peers = myArray3;
      },
      inputChanged(value) {
        console.log("changed value----- ", value);
        // this.activeNames = value;
      },
      getComponentData() {
        return {
          on: {
            change: this.handleChange,
            input: this.inputChanged,
          },
          attrs: {
            wrap: true,
          },
          props: {
            value: "this.activeNames",
          },
        };
      },
      videresize() {},
      expandNew: function expandNew() {
        resize();
      },
      handleVoiceSort() {
        if (!this.audioPeersRef) return;
  
        let tempArr = [];
        this.audioPeersRef.forEach((item) => {
          if (
            item.consumer?.rtpReceiver?.getSynchronizationSources()[0]
              ?.audioLevel >= 0.01
          ) {
            if (!tempArr.includes(item?.socketId)) {
              tempArr.push(item?.socketId);
            }
            document
              ?.getElementById(item?.socketId)
              ?.childNodes?.forEach((item) => {
                if (!item.classList.contains("animate-flicker")) {
                  item.classList.add("animate-flicker");
                }
              });
            if (document.getElementById(item?.socketId + "peer-name-tag")) {
              document
                .getElementById(item?.socketId + "peer-name-tag")
                .classList.add("name-tag-style");
            }
            if (document.getElementById(item?.socketId + "name-tag")) {
              document
                .getElementById(item?.socketId + "name-tag")
                .classList.add("name-tag-style");
            }
          } else {
            document
              ?.getElementById(item?.socketId)
              ?.childNodes?.forEach((item) => {
                if (item.classList.contains("animate-flicker")) {
                  item.classList.remove("animate-flicker");
                }
              });
  
            if (document.getElementById(item?.socketId + "peer-name-tag")) {
              document
                .getElementById(item?.socketId + "peer-name-tag")
                .classList.remove("name-tag-style");
            }
            if (document.getElementById(item?.socketId + "name-tag")) {
              document
                .getElementById(item?.socketId + "name-tag")
                .classList.remove("name-tag-style");
            }
          }
        });
      },
      async handlejoinInput() {
        this.user_name = this.your_name;
        if (this.your_name == "") {
          this.name_error = "Please enter your name.";
          this.join_btn = true;
        } else {
          this.name_error = "";
          this.join_btn = false;
        }
      },
      async cleanVariable1(event) {
        this.otpFirst = event.target.value.replace(/[^0-9]/g, "");
      },
      async cleanVariable2(event) {
        this.otpSecond = event.target.value.replace(/[^0-9]/g, "");
      },
      async cleanVariable3(event) {
        this.otpThird = event.target.value.replace(/[^0-9]/g, "");
      },
      async cleanVariable4(event) {
        this.otpFourth = event.target.value.replace(/[^0-9]/g, "");
      },
      async handleRenameInput() {
        this.editedName = this.edit_name;
        if (this.edit_name == "") {
          this.edit_name_error = "Please enter new name.";
          this.edit_join_btn = true;
        } else {
          this.edit_name_error = "";
          this.edit_join_btn = false;
        }
      },
      async joinMeeting() {
        // this.destination = this.audContext.createMediaStreamDestination();
  
        navigator.getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia;
        if (this.user_name == "") {
          this.name_error = "Please enter your name.";
          this.join_btn = true;
        } else {
          if (!localStorage.getItem("userdetails")) {
            this.loggedUserData = {
              firstName: this.user_name,
              lastName: "",
              profileImg: "unitydefaultlogo.png",
            };
          }
          if (this.role != "VIEWER") {
            this.name_error = "";
            this.join_btn = false;
            this.join_btn = true;
            this.join_loader = true;
            var now = Date.now();
            this.is_stream = true;
  
            try {
              const constraints = {
                video: {
                  width: { max: 1920 },
                  height: { max: 1080 },
                  frameRate: { ideal: 30 },
                },
                audio: {
                  volume: 1,
                  echoCancellation: false,
                  noiseSuppression: true,
                },
                systemAudio: "include",
                preferCurrentTab: true,
              };
              // const stream = await navigator.mediaDevices.getDisplayMedia(
              //   constraints
              // );
              this.localStreamRef = null;
              // const audioTracks = stream.getAudioTracks();
              // if (audioTracks.length > 0) {
              //   const audioDeviceId = audioTracks[0].getSettings().deviceId;
              //   this.selectedAudio = audioDeviceId;
              //   console.log("Audio device ID:", audioDeviceId);
              // } else {
              //   console.log("No video tracks found in the stream.");
              // }
  
              // const videoTracks = stream.getVideoTracks();
              // if (videoTracks.length > 0) {
              //   const videoDeviceId = videoTracks[0].getSettings().deviceId;
              //   this.selectedVideo = videoDeviceId;
              //   console.log("Video device ID:", videoDeviceId);
              // } else {
              //   console.log("No video tracks found in the stream.");
              // }
              // $("#DeniedModal").modal("hide");
              if (this.socketId == "") {
                await this.startWebinar(this.loggedUserData);
              }
            } catch (error) {
              console.log(error.name, "-------------");
              console.log(error.message, "-------------");
              if (error.name == "NotFoundError") {
                // this.running_error = error;
                this.is_cam_allow = false;
                this.stremErr = "NotFoundError";
                $("#DeniedModal").modal("show");
              } else if (error.name == "NotAllowedError") {
                $("#cameraMicDeniedModal").modal("show");
              } else if (error.name == "NotReadableError") {
                this.is_cam_allow = false;
                this.is_cam_allow = false;
                $("#NotReadableError").modal("show");
              } else {
                this.is_cam_allow = false;
  
                $("#errorModal").modal("show");
                this.running_error = error;
              }
              this.join_btn = false;
              this.join_loader = false;
              this.is_mic_allow = false;
            }
            let userAgentString = navigator.userAgent;
            let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
            if (!firefoxAgent) {
              setInterval(async () => {
                // console.log('connected-----', this.socket.connected);
  
                navigator.permissions
                  .query({ name: "microphone" })
                  .then((permissionObj) => {
                    if (permissionObj.state == "denied") {
                      this.is_mic_allow = false;
                      if ($(".DeniedModal").hasClass("show")) {
                      } else {
                        this.is_cam_allow = false;
                        this.is_mic_allow = false;
                        // $("#DeniedModal").modal("show");
                      }
                    } else if (permissionObj.state == "granted") {
                      this.is_mic_allow = true;
                      // $("#DeniedModal").modal("hide");
                    } else if (permissionObj.state == "prompt") {
                      this.is_mic_allow = false;
                      // $("#microphoneDeniedModal").modal("show");
                    } else {
                      this.is_mic_allow = false;
                    }
                    permissionObj.onchange = (e) => {
                      if (e.type === "change") {
                        const newState = e.target.state;
  
                        if (newState == "denied") {
                          this.is_mic_allow = false;
                          if ($(".DeniedModal").hasClass("show")) {
                          } else {
                            this.is_cam_allow = false;
                            this.is_mic_allow = false;
                            // $("#DeniedModal").modal("show");
                          }
                        } else if (newState == "granted") {
                          this.is_mic_allow = true;
                          // $("#microphoneDeniedModal").modal("hide");
                        } else if (newState == "prompt") {
                          this.is_mic_allow = false;
                          // $("#microphoneDeniedModal").modal("show");
                        } else {
                        }
                      }
                    };
                  })
                  .catch((error) => {
                    console.log("this.is_mic_allow :", this.is_mic_allow);
                    console.log("Got error :", error);
                  });
  
                navigator.permissions
                  .query({ name: "camera" })
                  .then((permissionObj) => {
                    if (permissionObj.state == "denied") {
                      this.is_cam_allow = false;
                      if ($(".DeniedModal").hasClass("show")) {
                      } else {
                        this.is_cam_allow = false;
                        // $("#DeniedModal").modal("show");
                      }
                    } else if (permissionObj.state == "granted") {
                      // this.is_cam_allow = true;
                      if (
                        this.deviceData.some(
                          (device) => device.kind === "videoinput"
                        )
                      ) {
                        this.is_cam_allow = true;
                      }
                      // $("#DeniedModal").modal("hide");
                    } else if (permissionObj.state == "prompt") {
                      this.is_cam_allow = false;
                      // $("#microphoneDeniedModal").modal("show");
                    } else {
                      this.is_cam_allow = false;
                    }
                    permissionObj.onchange = (e) => {
                      if (e.type === "change") {
                        const newState = e.target.state;
  
                        console.log("newState :", newState);
  
                        if (newState == "denied") {
                          this.is_cam_allow = false;
                          if ($(".DeniedModal").hasClass("show")) {
                          } else {
                            this.is_cam_allow = false;
                            this.is_cam_allow = false;
                            // $("#DeniedModal").modal("show");
                          }
                        } else if (newState == "granted") {
                          this.is_cam_allow = true;
                          // $("#microphoneDeniedModal").modal("hide");
                        } else if (newState == "prompt") {
                          this.is_cam_allow = false;
                          // $("#microphoneDeniedModal").modal("show");
                        } else {
                        }
                      }
                    };
                  })
                  .catch((error) => {
                    console.log("this.is_cam_allow :", this.is_cam_allow);
                    console.log("Got error :", error);
                  });
  
                if (this.is_mic_allow && this.is_cam_allow) {
                  $("#cameraMicDeniedModal").modal("hide");
                }
              }, 3000);
            }
          } else {
            await this.startWebinar(this.loggedUserData);
          }
        }
      },
      async varifyOtp() {
        this.verify_loader = true;
  
        var otp =
          this.otpFirst +
          "" +
          this.otpSecond +
          "" +
          this.otpThird +
          "" +
          this.otpFourth;
  
        if (otp == "") {
          this.name_error = "Please enter PIN.";
          this.verify_loader = false;
        } else {
          if (otp != this.lockRoomPin) {
            this.name_error = "";
            // if (this.lockRoom == true && this.role != "HOST") {
            this.socket = io(this.BASE_URL, { autoConnect: true });
  
            if (this.role == "HOST" && this.hostUnlockRoom) {
              this.lockRoom = true;
            }
            if (this.role == "MODERATOR" && this.moderatorsUnlockRoom) {
              this.lockRoom = true;
            }
  
            const obj = {
              room: this.roomId,
              webinarUserType: this.role,
              user_name: this.user_name,
              email: "",
              profileImg: "",
              is_recorder_user: true,
              isWaiting: true,
              storageId: this.user_name,
              userDetails: {
                user_name: this.user_name,
                profileImg: "",
                email: "",
                webinarUserType: this.role,
              },
            };
            console.log("obj________", obj);
            // this.socket.emit("addUserCall", obj);
            // this.demoSocket.emit("addUser", { ...obj, });
            this.socket.emit("addUserWaitingList", obj);
  
            this.socket.on("waitingUsers", (data) => {
              this.waitingUser = data;
              // this.filterdWaitingUser = data;
              console.log("waitingUsers--adsasdas------", this.waitingUser); // undefined
              // this.filterdParticipants = { ...this.filterdParticipants, data }
              if (this.waitingUser.length != 0) {
                this.filterdWaitingUser = this.waitingUser.filter(
                  (a) => a.userDetails.webinarUserType == this.currentTabSelect
                );
              }
            });
            this.socket.on("roomUnlockNotify", (data) => {
              this.lockRoom = false;
              $("#lockedModal").modal("hide");
              $("#lockedWithPinModal").modal("hide");
              $("#enterUnlockPinModal").modal("hide");
              if (this.socketId == "") {
                this.joinMeetingFinally();
              }
              console.log("roomUnlockNotify--", data); // undefined
            });
  
            this.socket.on("denyUserRequest", (data) => {
              window.close();
              window.location.href = window.origin;
            });
  
            this.socket.on("unlockLockFlag", (data) => {
              if (this.role != "GUEST") {
                if (data.status == 1) {
                  const element = document.querySelector("#toggleP");
                  element.classList.add("on");
                  this.lockRoom = true;
                } else {
                  const element = document.querySelector("#toggleP");
                  element.classList.remove("on");
                  this.lockRoom = false;
                }
              }
              console.log("unlockLockFlag-----------", data);
            });
  
            $("#lockedWithPinModal").modal("show");
            // } else {
  
            // }
            this.name_error =
              "You've entered wrong PIN. Please enter correct PIN.";
            this.verify_loader = false;
          } else {
            this.joinMeetingFinally();
          }
        }
        console.log("otp------", otp);
      },
      async reEnterRoomPin() {
        this.name_error = "";
        this.otpFirst = "";
        this.otpSecond = "";
        this.otpThird = "";
        this.otpFourth = "";
        this.retryChance = ++this.retryChance;
        this.socket.emit("disconnectFromReEnterPin");
  
        if (this.retryChance == 5) {
        } else {
          $("#lockedWithPinModal").modal("hide");
          $("#enterUnlockPinModal").modal("show");
        }
      },
      async joinMeetingFinally() {
        navigator.getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia;
        if (this.user_name == "") {
          this.name_error = "Please enter your name.";
          this.join_btn = true;
        } else {
          if (!localStorage.getItem("userdetails")) {
            this.loggedUserData = {
              firstName: this.user_name,
              lastName: "",
              profileImg: "unitydefaultlogo.png",
            };
          }
          if (this.role != "VIEWER") {
            this.name_error = "";
            this.join_btn = false;
            this.join_btn = true;
            this.join_loader = true;
            var now = Date.now();
            this.is_stream = true;
            if (this.socketId == "") {
              await this.startWebinar(this.loggedUserData);
            }
          } else {
            await this.startWebinar(this.loggedUserData);
          }
        }
      },
  
      expand: function expand(ev) {
        let myScreen = document.getElementById("Screen");
        let screens = document.getElementsByClassName("Screen")[0];
        let _conference = document.getElementsByClassName("Conference")[0];
  
        if ($("#Screen").css("display") == "none") {
          document.getElementById("Screen").style.display = "flex";
        } else {
          document.getElementById("Screen").style.display = "none";
        }
        resize();
      },
      async startWebinar(userdetailsData) {
        // alert('Let\'s start');
  
        window.addEventListener("beforeunload", function (e) {
          let confirmationMessage =
            "If you leave before saving, your changes will be lost.";
  
          (e || window.event).returnValue = confirmationMessage; //Gecko + IE
          return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        });
  
        // if (this.socketId == "") {
        const loc = window.location.pathname;
        console.log("starting loc value = " + loc);
        const locs =
          loc.lastIndexOf("/") == loc.length - 1
            ? loc.substring(0, loc.length - 1)
            : loc.substring(0, loc.lastIndexOf("/"));
        const companyname = locs.substring(locs.lastIndexOf("/") + 1);
  
        let urlParams = new URLSearchParams(window.location.search);
  
        if (urlParams.has("webinarid")) {
          this.roomId = this.webinarId;
        } else {
          this.roomId = companyname + window.location.pathname.split("/").pop();
        }
        console.log("companyname = " + companyname);
  
        console.log(
          "Webinar name = " + window.location.pathname.split("/").pop()
        );
  
        console.log("this.loggedUserData________", this.loggedUserData);
        console.log("this.roomId-----------------------", this.roomId);
        console.log("this.role-----------------------", this.role);
  
        // console.log('LocalStream_storage-', document.getElementById("LocalStream_storageId_STREAsM_VIDEO_DIV"));
        // if (document.getElementById('LocalStream_storageId_STREAsM_VIDEO_DIV')) {
        //   document.body.removeChild(document.getElementById('LocalStream_storageId_STREAsM_VIDEO_DIV'))
  
        //   const peerSData = this.peerShow.filter((peerData) => (peerData?.storageId !== 'LocalStream_storageId_STREAsM_VIDEO_DIV'))
        //   const peerData = this.peers.filter((peerData) => (peerData?.storageId !== 'LocalStream_storageId_STREAsM_VIDEO_DIV'))
        //   // this.peerShow = peerData;
        //   this.peers = peerData;
        //   this.peerShow = peerSData;
        // }
  
        // this.demoSocket = io("https://record.unitywebinar.com", {
        //   autoConnect: true,
        // });
  
        // console.log("this.demoSocket-----------------------", this.demoSocket);
        this.socket = io(this.BASE_URL, { autoConnect: true });
  
        this.socket.on("connect", async () => {
          if (
            document.getElementById("LocalStream_storageId_STREAsM_VIDEO_DIV") !=
            null
          ) {
            const peerSData = this.peerShow.filter(
              (peerData) => peerData?.storageId !== "LocalStream_storageId"
            );
            const peerData = this.peers.filter(
              (peerData) => peerData?.storageId !== "LocalStream_storageId"
            );
            // this.peerShow = peerData;
            this.peers = peerData;
            this.peerShow = peerSData;
          }
          this.socket.emit("getRTPCapabilites", async (response) => {
            console.log("response-------------0", response);
            this.handleCapabilities(response?.capabilities);
            this.socketId = this.socket.id;
            this.loggedUserData = {
              ...userdetailsData,
              socketId: this.socket.id,
            };
  
            this.user_name =
              userdetailsData.firstName + " " + userdetailsData.lastName;
            // this.profileImg = userdetailsData.profileImg;
            const obj = {
              room: this.roomId,
              webinarUserType: this.role,
              is_recorder_user: true,
              user_name:
                userdetailsData.firstName + " " + userdetailsData.lastName,
              profileImg: this.profileImg,
            };
            console.log("obj________", obj);
            this.socket.emit("addUserCall", obj);
            // await this.startStream();
  
            // await this.startProducing();
            this.viewers.push({
              socketId: this.socket.id,
              room: this.roomId,
              userDetails: {
                user_name: this.user_name,
                profileImg: this.profileImg,
                webinarUserType: this.role,
              },
            });
  
            const objV = {
              room: this.roomId,
              webinarUserType: this.role,
              userDetails: {
                user_name:
                  userdetailsData.firstName + " " + userdetailsData.lastName,
                profileImg: userdetailsData.profileImg,
              },
            };
  
            this.is_stream = false;
            // this.socket.emit("addUserInViewerCall", objV);
            console.log("-----------------");
            console.log(this.role);
            setInterval(async () => {
              // console.log('connected-----', this.socket.connected);
  
              if (this.socket.connected == false) {
                $("#reconnecting").modal("show");
                setInterval(async () => {
                  window.location.reload(true);
                }, 3000);
                // this.startWebinar(this.loggedUserData)
              } else {
                $("#reconnecting").modal("hide");
              }
            }, 3000);
          });
        });
  
        this.socket.on("updateWaitingUsers", (data) => {
          this.waitingUser = data;
          // this.filterdWaitingUser = data;
          console.log("updateWaitingUsers--adsasdas------", this.waitingUser); // undefined
          // this.filterdParticipants = { ...this.filterdParticipants, data }
          if (this.waitingUser.length != 0) {
            this.filterdWaitingUser = this.waitingUser.filter(
              (a) => a.userDetails.webinarUserType == this.currentTabSelect
            );
          }
        });
  
        this.socket.on("unlockLockFlag", (data) => {
          if (this.role != "GUEST") {
            if (data.status == 1) {
              const element = document.querySelector("#toggleP");
              element.classList.add("on");
              this.lockRoom = true;
            } else {
              const element = document.querySelector("#toggleP");
              element.classList.remove("on");
              this.lockRoom = false;
            }
          }
          console.log("2-----unlockLockFlag-----------", data);
        });
  
        this.socket.on("stopRecordingNotify", (data) => {
          console.log("data-------", data);
  
          if (data.recordingUsers.length == 0) {
            this.topRecording = false;
          } else {
            this.topRecording = true;
          }
          this.recordType = "";
  
          let names = data.recordingUsers.map((x) => x.username).join(", ");
          this.recorderUsers = names;
        });
  
        this.socket.on("recordingNotify", (data) => {
          if (data.recordingUsers.length != 0) {
            this.topRecording = true;
            let names = data.recordingUsers.map((x) => x.username).join(", ");
            console.log("data-------", data);
            console.log("data-------", data.data.recordType);
            console.log(
              "recordType-------",
              data.data.recordType ? "If" : "Else"
            );
  
            if (data.data.recordType != undefined) {
              this.recordType = data.data.recordType;
            }
            this.recorderUsers = names;
            console.log("names-------", names);
          }
        });
  
        this.socket.on("userLeft", (socketId) => {
          console.log("userLeft--------", socketId);
          console.log("userLeft------PF--", this.peersRef);
          console.log("userLeft------P--", this.peers);
          console.log("userLeft------PS--", this.peerShow);
  
          const peerSData = this.peers.filter(
            (peerData) => peerData?.socketId !== socketId
          );
          this.peers = peerSData;
          const peerRefData = this.peersRef.filter(
            (peerData) => peerData?.socketId !== socketId
          );
          this.peersRef = peerRefData;
          const peerShowData = this.peerShow.filter(
            (peerData) => peerData?.socketId !== socketId
          );
          this.peerShow = peerShowData;
          // const peeridx = this.peers.findIndex(
          //   (peer) => peer?.socketId === socketId
          // );
          // if (peeridx !== -1) {
          //   const peersFilter = this.peers.filter(
          //     (peer) => peer?.socketId !== socketId
          //   );
          //   console.log("userLeft------PR--", peersFilter);
          // }
  
          console.log("userLeft------PR--", peerSData);
          const peersPFilter = this.filterdParticipants.filter(
            (peer) => peer?.socketId !== socketId
          );
          this.filterdParticipants = peersPFilter;
  
          this.filterdWaitingUser = this.waitingUser.filter(
            (a) => a.userDetails.socketId !== socketId
          );
          this.waitingUser = this.waitingUser.filter(
            (a) => a.userDetails.socketId !== socketId
          );
        });
  
        this.socket.on("disconnect", () => {
          this.socketId = this.socket.id;
          console.log("disconnect--", this.socket.id); // undefined
        });
  
        this.socket.on("loadPdfFile", async () => {
          this.toNextPage();
        });
        this.socket.on("finalCloudFile", async ({ filename, fileSize }) => {
          console.log("finalCloudFile--", filename, fileSize); // undefined
          var today = new Date();
  
          const hrs = this.hrsApi == 0 ? "" : this.hrsApi + " Hrs ";
          const min = this.minApi == 0 ? "" : this.minApi + " Min ";
          const sec = this.secApi == 0 ? "" : this.secApi + " Sec ";
          this.isRecordLoader = false;
          this.recordType = "";
  
          // const addToRecordings = await addToRecording(payLoad);
          // console.log('payLoad---------', payLoad)
          // console.log('addToRecordings---------', addToRecordings)
  
          // if (addToRecordings.data.code == 200) {
          if (this.isActionForLeave) {
            this.isActionForLeave = false;
            window.close();
            window.location.href = window.origin;
          } else {
            $("#stopCloudRecordingModal").modal("hide");
          }
          // }
        });
  
        this.socket.on("finalFile", (id) => {
          // this.downloadRecordFile(id)
          const a = document.createElement("a");
          var aRE = document.getElementById("RECORD" + id);
  
          console.log("a--1----", aRE); // undefined
          a.id = "RECORD" + id;
          // a.hidden = true;
          a.href =
            "https://record.unitywebinar.com/api/users/v1/webinar/file/" + id;
          a.download = true;
          // a.target = '_blank';
          a.click();
  
          document.body.appendChild(a);
          this.isRecordLoader = false;
          $("#stopRecordingModal").modal("hide");
          var wrap = document.getElementById("RECORD" + id);
          console.log("this.isActionForLeave--", this.isActionForLeave); // undefined
          console.log("finalFile--", id); // undefined
  
          // if (this.isActionForLeave) {
  
          //   $.get(this.BASE_URL + "api/users/v1/webinar/file/" + id, function (response, status, xhr) {
          //     console.log("status--", status);
  
          //     if (xhr.getResponseHeader("content-type").indexOf("mp4") > -1) {
          //       // Text based stuff.
          //       console.log("Text based stuff--");
          //     } else {
          //       if (status == 'success') {
          //         console.log("Download based stuff--");
          //         window.close();
          //         window.location.href = window.origin;
          //       }
  
          //     }
          //   });
  
          // }
          this.recordType = "";
  
          $("#stopLocalRecordingModal").modal("hide");
        });
  
        this.socket.on("receiveDocumentDataToRecordUser", async (socketId) => {
          this.shareStreamToSocketId(socketId);
        });
  
        this.socket.on("receiveDocumentData", async () => {
          this.shareStream();
        });
  
        this.socket.on(
          "sendDocumentData",
          async ({ roomId, blob, socketId, height, width, type }) => {
            console.log(this.socketId, roomId, socketId, height, width);
            if (this.socketId != socketId) {
              this.docLoader = true;
  
              try {
                console.log(roomId, blob);
                const canvas = document.getElementById("canvas");
                const ctx = canvas.getContext("2d");
                const data = new Blob([blob], { type: type });
                const url = URL.createObjectURL(data);
                const newImg = document.getElementById("mySharedDoc");
                // newImg.src = url;
                this.srcList = [];
                this.srcList.push(url);
  
                const image = new Image();
                image.src = url;
                canvas.height = height;
                canvas.width = width;
                image.onload = () => {
                  ctx.drawImage(image, 0, 0);
                };
                // const image = new Image();
                // image.src = data;
                // const base64String = data;
  
                // const blob = new Blob([atob(base64String)], { type: "image/png" });
  
                // const dataURL = URL.createObjectURL(blob);
                // document.getElementById("doc_source").src = dataURL;
  
                // const canvas = document.getElementById("canvas");
                // const ctx = canvas.getContext("2d");
                // const image = document.getElementById("doc_source");
                // canvas.height = height;
                // canvas.width = width;
                // image.addEventListener("load", (e) => {
                //   ctx.drawImage(image, 0, 0);
                //   this.docLoader = false;
                // });
              } catch (e) {
                console.log(e);
              }
            }
  
            // const ctx = canvas.getContext("2d");
            // const image1 = document.getElementById("doc_source");
  
            // image1.addEventListener("load", (e) => {
            //   ctx.drawImage(image1, 33, 71, 104, 124, 21, 20, 87, 104);
            // });
          }
        );
  
        this.socket.on("closeCamera", async ({ socketId, status }) => {
          console.log("closeCamera--", { socketId, status }); // undefined
  
          if (status == true) {
            // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "9" : '';
            // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "10" : '';
          } else {
            // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
            // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
          }
          if (status) {
            if (document.getElementById(socketId + "_PEER_IMAGE") != null) {
              document.getElementById(socketId + "_PEER_IMAGE").style.display =
                "flex";
            }
          } else {
            if (document.getElementById(socketId + "_PEER_IMAGE") != null) {
              document.getElementById(socketId + "_PEER_IMAGE").style.display =
                "none";
            }
          }
          if (this.mainScreenStreamRef) {
            if (
              this.mainScreenStreamRef.socketId == socketId &&
              !this.mainScreenStreamRef.screenShare
            ) {
              this.mainScreenStreamRef.mutedVideo = status;
            }
          }
          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) => user?.socketId == socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peers[objIndex].mutedVideo = status;
            }
          }
          if (this.peerShow.length > 0) {
            var objIndex = this.peerShow.findIndex(
              (user) => user?.socketId == socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peerShow[objIndex].mutedVideo = status;
            }
          }
          if (this.peersRef.length > 0) {
            var objIndex = this.peersRef.findIndex(
              (user) => user?.socketId == socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peersRef[objIndex].mutedVideo = status;
            }
          }
        });
  
        this.socket.on(
          "closeConsumer",
          async ({ socketId, screenShare, producerId, storageId, kind }) => {
            console.log("close consumer", {
              socketId,
              screenShare,
              producerId,
              storageId,
              kind,
            });
            console.log("this.mainScreenStreamRef:  ", this.mainScreenStreamRef);
            const consumer = this.consumers.get(producerId);
  
            await consumer?.close();
            this.consumers.delete(producerId);
            // const idx = this.peersRef?.findIndex((item) => item?.socketId === socketId && item?.screenShare === screenShare)
  
            if (this.mainScreenStreamRef) {
              if (this.mainScreenStreamRef.audioShare) {
                this.gainLevel = 0;
                this.stopPlayConsumerAudioShare();
              } else if (
                !this.mainScreenStreamRef.audioShare &&
                this.mainScreenStreamRef.screenShare &&
                this.mainScreenStreamRef.videoShare
              ) {
                this.gainLevel = 0;
              } else {
              }
            }
            // await this.receiveTransport[storageId]?.close();
            // this.receiveTransport[storageId] = null;
            this.consumers.delete(storageId);
  
            console.log("this.peers  cc ", this.peers);
            console.log("this.peerShow  cc ", this.peerShow);
  
            if (kind === "video") {
              const idx = this.peers?.findIndex(
                (item) =>
                  item?.storageId === storageId && item?.screenShare === false
              );
              if (idx !== -1) {
                if (this.peers[idx]?.screenShare === false) {
                  this.remoteStreamsRef[`${socketId}_video`].srcObject = null;
                }
                console.log("If 1 ");
                if (this.mainScreenStreamRef) {
                  console.log("If 2 ");
                  if (this.mainScreenStreamRef.screenShare == false) {
                    if (this.mainScreenStreamRef.storageId == storageId) {
                      this.mainScreenStreamRef.mediaStream = null;
                      // this.mainScreenStreamRef.storageId = storageId;
                      this.mainScreenStreamRef.mutedVideo = true;
                      // this.updateIsShow(this.mainScreenStreamRef.storageId, true)
                    }
                  } else {
                    this.videoShared = false;
                  }
                }
  
                if (this.peers[idx]?.screenShare === false) {
                  this.peers[idx].mediaStream = null;
                  this.peers[idx].mutedVideo = true;
                } else {
                  console.log("cc ELSE ");
  
                  // this.peers?.splice(idx, 1)
                }
                const idx1 = this.peerShow?.findIndex(
                  (item) =>
                    item?.storageId === storageId && item?.screenShare === false
                );
                if (this.peerShow[idx1]?.screenShare === false) {
                  this.peerShow[idx1].mediaStream = null;
                  this.peerShow[idx1].mutedVideo = true;
                } else {
                  console.log("cc ELSE 33");
  
                  // this.peerShow?.splice(idx, 1)
                }
              } else {
                console.log("DISPLAY NONE HTI");
  
                // if (document.getElementById(storageId + "_STREAM_VIDEO_DIV")) {
                //   document.getElementById(storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
                // }
                if (
                  this.peersRef?.some((item) => item?.storageId === storageId)
                ) {
                  const peerFilter = this.peersRef?.filter(
                    (peer) => peer?.storageId !== storageId
                  );
                  this.peersRef = peerFilter;
                  // setPeers(peerFilter)
                }
  
                const peerSData = this.peerShow.filter(
                  (peerData) => peerData?.storageId !== storageId
                );
                const peerData = this.peers.filter(
                  (peerData) => peerData?.storageId !== storageId
                );
                const guestUsersData = this.filterdParticipants.filter(
                  (peerData) => peerData?.storageId !== storageId
                );
                // this.peerShow = peerData;
                this.peers = peerData;
                this.peerShow = peerSData;
                this.filterdParticipants = guestUsersData;
  
                console.log(peerSData);
                console.log(peerData);
  
                if (this.mainScreenStreamRef) {
                  if (this.mainScreenStreamRef.storageId == storageId) {
                    console.log("S 1 ");
                    this.mainScreenStreamRef = null;
                    this.screenShareStarted = false;
                    this.videoShared = false;
                    document.getElementById("Screen").style.display = "none";
                  } else {
                    if (
                      document.getElementById(
                        this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                      )
                    ) {
                      console.log("ELSE");
                      // document.getElementById(this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV").style.display = "none";
                      this.updateIsShow(
                        this.mainScreenStreamRef.storageId,
                        false
                      );
                    }
                  }
                }
                const peerFilter = this.peers.filter(
                  (peerData) => peerData?.is_show == true
                );
                this.peerShow = peerFilter;
                //console.log("this.peers______", this.peers);
                console.log("this.peerShow______", this.peerShow);
                this.manageDiv();
              }
            } else {
              if (this.mainScreenStreamRef) {
                if (
                  this.mainScreenStreamRef.storageId == storageId &&
                  this.mainScreenStreamRef.audioShare
                ) {
                  console.log("S 1 ");
                  this.mainScreenStreamRef = null;
                  this.screenShareStarted = false;
                  this.videoShared = false;
                  document.getElementById("Screen").style.display = "none";
                } else {
                  // if (
                  //   document.getElementById(
                  //     this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                  //   )
                  // ) {
                  //   console.log("ELSE");
                  //   // document.getElementById(this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV").style.display = "none";
                  //   this.updateIsShow(this.mainScreenStreamRef.storageId, false);
                  // }
                }
              }
              const audioElementData = this.audioElement.filter(
                (audioData) => audioData !== storageId
              );
              this.audioElement = audioElementData;
              const raisehandArrayData = this.raisehandArray.filter(
                (u) => u.socketId !== socketId
              );
              this.raisehandArray = raisehandArrayData;
  
              if (
                document.getElementById(
                  `${socketId}_${screenShare ? "screenaudio" : "audio"}`
                )
              ) {
                document.body.removeChild(
                  document.getElementById(
                    `${socketId}_${screenShare ? "screenaudio" : "audio"}`
                  )
                );
                const audioPeersIdx = this.audioPeersRef?.findIndex(
                  (item) =>
                    item?.socketId === socketId &&
                    item?.screenShare === screenShare
                );
                this.audioPeersRef?.splice(audioPeersIdx, 1);
              }
            }
          }
        );
  
        this.socket.on("speaking", (value) => {
          if (value) {
            if (this.globalTimeout) {
              clearTimeout(this.globalTimeout);
            }
  
            const myAudioIcon = document?.getElementById(
              this.socket.id
            )?.childNodes;
            myAudioIcon?.forEach((item) => {
              if (!item.classList.contains("animate-flicker")) {
                item.classList.add("animate-flicker");
  
                if (document.getElementById(this.socket.id + "peer-name-tag")) {
                  document
                    .getElementById(this.socket.id + "peer-name-tag")
                    .classList.add("name-tag-style");
                }
                if (document.getElementById(this.socket.id + "name-tag")) {
                  document
                    .getElementById(this.socket.id + "name-tag")
                    .classList.add("name-tag-style");
                }
              }
            });
  
            this.globalTimeout = setTimeout(() => {
              const audioIconItems = document?.getElementById(
                this.socket.id
              )?.childNodes;
              audioIconItems?.forEach((item) => {
                if (item.classList.contains("animate-flicker")) {
                  item.classList.remove("animate-flicker");
  
                  if (document.getElementById(this.socket.id + "name-tag")) {
                    document
                      .getElementById(this.socket.id + "name-tag")
                      .classList.remove("name-tag-style");
                  }
                  if (document.getElementById(this.socket.id + "peer-name-tag")) {
                    document
                      .getElementById(this.socket.id + "peer-name-tag")
                      .classList.remove("name-tag-style");
                  }
                }
              });
            }, 700);
          }
        });
  
        this.socket.on("closeConsumerOLD", async (storageId) => {
          console.log("close consumer", storageId);
          //console.log("this.peers______", this.peers);
  
          // console.log('peerData_________',peerData);
          // if (document.getElementById(storageId + "_SHARE_SCREEN_VIDEO") != null) {
          //   document.getElementById("Screen").style.display = "none";
          //   this.mainScreenStreamRef = null
          //   console.log('_SHARE_SCREEN_VIDEO---------------', storageId);
          // }
          if (document.getElementById(storageId + "_STREAM_VIDEO_DIV")) {
            console.log("DISPLAY NONE HTI");
            document.getElementById(
              storageId + "_STREAM_VIDEO_DIV"
            ).style.display = "flex";
  
            // var el = document.getElementById(storageId + "_STREAM_VIDEO_DIV");
            // var removedChild = el.remove();
            // document.body.removeChild(document.getElementById(storageId + "_STREAM_VIDEO_DIV"))
          }
          if (
            document.getElementById(storageId + "_STREAM_SCREEN_VIDEO") != null
          ) {
            //ADDED IN DIV OR SCREEN SHARING OR
            document.getElementById("Screen").style.display = "none";
            console.log("_1---------------", storageId);
            // document.getElementById(storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
  
            if (this.mainScreenStreamRef.storageId == storageId) {
              console.log(
                "_2---------------",
                this.mainScreenStreamRef.storageId
              );
              console.log("if___3______");
              if (
                document.getElementById(
                  this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                ) != null
              ) {
                console.log("if___2______");
                const peerData = this.peers.filter(
                  (peerData) =>
                    peerData?.storageId !== this.mainScreenStreamRef.storageId
                );
                this.peers = peerData;
                console.log(
                  "removeChild____v_____",
                  this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                );
                document
                  .getElementById(
                    this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                  )
                  .remove();
                await this.receiveTransport[storageId]?.close();
                this.receiveTransport[storageId] = null;
                this.consumers.delete(storageId);
                // document.body.removeChild(document.getElementById(this.mainScreenStreamRef.storageId))
              }
            } else {
              console.log("AVVo");
            }
            this.mainScreenStreamRef = null;
          } else {
            const peerData = this.peers.filter(
              (peerData) => peerData?.storageId !== storageId
            );
            this.peers = peerData;
  
            await this.receiveTransport[storageId]?.close();
            this.receiveTransport[storageId] = null;
            this.consumers.delete(storageId);
            // if (this.peersRef.some((item) => item?.storageId === storageId)) {
            //   const peerFilter = this.peersRef.filter((peer) => peer?.storageId !== storageId)
            //   this.peersRef = peerFilter;
            //   this.peers = peerFilter;
            //   console.log('peerFilter_________', peerFilter);
            // }
            // setPeers(peerFilter)
            if (this.mainScreenStreamRef) {
              console.log("if___1______");
              if (this.mainScreenStreamRef.storageId == storageId) {
                const peerData = this.peers.filter(
                  (peerData) => peerData?.storageId !== storageId
                );
                this.peers = peerData;
                console.log("if___3______");
                if (
                  document.getElementById(
                    this.mainScreenStreamRef.storageId + "_SHARE_SCREEN_VIDEO"
                  )
                ) {
                  console.log("if___2______");
                  console.log(
                    "removeChild____ifff_____",
                    this.mainScreenStreamRef.storageId + "_SHARE_SCREEN_VIDEO"
                  );
                  document.body.removeChild(
                    document.getElementById(
                      this.mainScreenStreamRef.storageId + "_SHARE_SCREEN_VIDEO"
                    )
                  );
                }
              } else {
                console.log("AAVO");
              }
            } else {
              console.log("if___5______");
            }
          }
          if (document.getElementById(storageId)) {
            console.log("removeChild_________", storageId);
            document.body.removeChild(document.getElementById(storageId));
          }
          const audioElementData = this.audioElement.filter(
            (audioData) => audioData !== storageId
          );
          this.audioElement = audioElementData;
          console.log("this.peers", this.peers);
          this.manageDiv();
        });
  
        this.socket.on("transportCreated", (data) => {
          this.handleTransport(data);
        });
  
        this.socket.on("stopDocScreen", (data) => {
          console.log("_________stopDocScreen___________");
          this.stopDocumentsData(data);
        });
  
        this.socket.on("stopVideoScreen", (data) => {
          console.log("_________stopVideoScreen___________");
          this.stopImportData(data);
        });
  
        this.socket.on("stopAudioScreen", (data) => {
          console.log("_________stopAudioScreen___________");
          this.stopImportAudioData(data);
        });
  
        this.socket.on("stopShareScreen", (data) => {
          console.log("_________stopShareScreen___________");
          this.ScreenShare(data);
        });
  
        this.socket.on("waitingUsers", (data) => {
          this.waitingUser = data;
          // this.filterdWaitingUser = data;
  
          // this.filterdParticipants = [...data, this.filterdParticipants];
  
          if (this.waitingUser.length != 0) {
            this.filterdWaitingUser = this.waitingUser.filter(
              (a) => a.userDetails.webinarUserType == this.currentTabSelect
            );
          }
          // this.filterdParticipants.push(...data)
          console.log("this.TTTT--", this.filterdWaitingUser); // undefined
          console.log("waitingUsers--", data); // undefined
        });
  
        this.socket.on("chat", (data) => {
          console.log("CHAT______", data);
          this.chatHistory.unshift(data);
        });
  
        this.socket.on("editChatMessageNotify", (data) => {
          console.log("CHAT______", data);
  
          var objIndex = this.chatHistory.findIndex(
            (user) => user?.id == data.id
          );
  
          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.chatHistory[objIndex].message = data.message;
          }
        });
        this.socket.on("deleteChatNotify", (data) => {
          console.log("CHAT______", data);
  
          this.chatHistory = this.chatHistory.filter((a) => a.id !== data.id);
        });
  
        this.socket.on("hideRaideHandForUser", (data) => {
          this.raiseHand();
        });
        this.socket.on("current_raise_hand", (data) => {
          console.log("current_raise_hand", data);
  
          this.raisehandArray = data.raiseHandUsers;
        });
        this.socket.on("raise_hand", (data) => {
          console.log("data-----------", data);
          console.log("datas-----------", this.raisehandArray);
          this.raisehandArray = data.raiseHandUsers;
          if (this.mainScreenStreamRef) {
            if (
              this.mainScreenStreamRef.socketId == data.data.socketId &&
              this.mainScreenStreamRef?.screenShare == false
            ) {
              this.mainScreenStreamRef.raiseHand = data.data.is_show;
              this.mainScreenStreamRef.countRaiseHand = data.data.is_showCount;
            }
          }
          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) =>
                user?.socketId == data.data.socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peers[objIndex].raiseHand = data.data.is_show;
              this.peers[objIndex].countRaiseHand = data.data.is_showCount;
            }
          }
          if (this.peerShow.length > 0) {
            var objIndex = this.peerShow.findIndex(
              (user) =>
                user?.socketId == data.data.socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peerShow[objIndex].raiseHand = data.data.is_show;
              this.peerShow[objIndex].countRaiseHand = data.data.is_showCount;
            }
          }
  
          if (data.data.is_show) {
            if (data.data.socketId == this.socket.id) {
              this.isMyRaiseHand = true;
            }
          } else {
            if (data.data.socketId == this.socket.id) {
              this.isMyRaiseHand = false;
            }
          }
  
          console.log("raise_hand______", data);
  
          // this.chatHistory.push(data);
        });
  
        this.socket.on("currentViewers", (producers) => {
          console.log("currentViewers:   ", producers);
          this.viewers = producers;
          this.filterdParticipants = producers;
        });
        this.socket.on("currentProducers", async (producers) => {
          console.log("currentProducers:   ", producers);
  
          for (let producer of producers) {
            this.addItemToProcess(producer);
            // const data = await this.startConsumeProducer(producer);
            // await this.consume(data);
          }
  
          // for (let producer of producers) {
          //   const data = await this.startConsumeProducer(producer);
          //   await this.consume(data);
          // }
        });
  
        this.socket.on(
          "producerResumed",
          async ({ storageId, socketId, kind }) => {
            //console.log("this.peers_______________", this.peers);
            console.log("producerResumed________", {
              storageId,
              socketId,
              kind,
            });
            if (kind === "video") {
              if (this.mainScreenStreamRef) {
                if (this.mainScreenStreamRef.socketId == socketId) {
                  this.mainScreenStreamRef.mutedVideo = false;
                }
              }
              if (this.peers.length > 0) {
                var objIndex = this.peers.findIndex(
                  (user) =>
                    user?.storageId == storageId && user?.screenShare == false
                );
  
                //console.log("objIndex______", objIndex);
                if (objIndex >= 0) {
                  //console.log("objIndex______", objIndex);
                  this.peers[objIndex].mutedVideo = false;
                }
              }
              if (this.peerShow.length > 0) {
                var objIndex = this.peerShow.findIndex(
                  (user) =>
                    user?.storageId == storageId && user?.screenShare == false
                );
  
                //console.log("objIndex______", objIndex);
                if (objIndex >= 0) {
                  //console.log("objIndex______", objIndex);
                  this.peerShow[objIndex].mutedVideo = false;
                }
              }
              if (
                document.getElementById(storageId + "_STREAM_VIDEO_POSITION") !=
                null
              ) {
                document.getElementById(
                  storageId + "_STREAM_VIDEO_POSITION"
                ).style.position = "relative";
                document.getElementById(
                  storageId + "_STREAM_VIDEO_POSITION"
                ).style.width = "max-content";
              }
              if (document.getElementById(storageId + "_STREAM_VIDEO") != null) {
                document.getElementById(
                  storageId + "_STREAM_VIDEO"
                ).style.visibility = "visible";
              }
              if (document.getElementById(storageId + "_VIDEO") != null) {
                document.getElementById(storageId + "_VIDEO").style.visibility =
                  "hidden";
              }
              if (
                document.getElementById(storageId + "_STREAM_VIDEO_ZOOM_ICON") !=
                null
              ) {
                document.getElementById(
                  storageId + "_STREAM_VIDEO_ZOOM_ICON"
                ).style.visibility = "visible";
              }
              if (
                document.getElementById(storageId + "_STREAM_SCREEN_VIDEO") !=
                null
              ) {
                document.getElementById(
                  storageId + "_STREAM_SCREEN_VIDEO"
                ).style.visibility = "visible";
                document.getElementById("Screen").style.background = "#black";
              }
              if (
                document.getElementById(
                  "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
                ) != null
              ) {
                document.getElementById(
                  "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
                ).style.background = "transparent";
              }
              if (
                document.getElementById(storageId + "_OFFLINE_SCREEN_VIDEO") !=
                null
              ) {
                document.getElementById(
                  storageId + "_OFFLINE_SCREEN_VIDEO"
                ).style.display = "none";
              }
              if (
                document.getElementById(
                  "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
                ) != null
              ) {
                document.getElementById(
                  "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
                ).style.display = "none";
              }
              if (document.getElementById(storageId + "_SCREEN_VIDEO") != null) {
                document.getElementById(
                  storageId + "_SCREEN_VIDEO"
                ).style.display = "none";
              }
              if (
                document.getElementById(
                  socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH"
                ) != null
              ) {
                document.getElementById(
                  socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH"
                )
                  ? (document.getElementById(
                      socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH"
                    ).style.display = "none")
                  : "";
              }
  
              if (
                document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY") !=
                null
              ) {
                document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY")
                  ? (document.getElementById(
                      socketId + "_OFFLINE_VIDEO_TOP_NOTIFY"
                    ).style.display = "none")
                  : "";
              } else {
                console.log("offlinedoes not exist! _OFFLINE_VIDEO_TOP_NOTIFY");
              }
  
              if (
                document.getElementById(socketId + "_VIDEO_TOP_NOTIFY_SLASH") !=
                null
              ) {
                document.getElementById(
                  socketId + "_VIDEO_TOP_NOTIFY_SLASH"
                ).style.display = "none";
              }
              if (
                document.getElementById(socketId + "_VIDEO_TOP_NOTIFY") != null
              ) {
                document.getElementById(
                  socketId + "_VIDEO_TOP_NOTIFY"
                ).style.display = "inline";
              }
              if (
                document.getElementById(socketId + "_VIDEO_UNMUTE_ACTION") != null
              ) {
                document.getElementById(
                  socketId + "_VIDEO_UNMUTE_ACTION"
                ).innerHTML = "Mute Camera";
              }
              if (
                document.getElementById(socketId + "_VIDEO_MAIN_UNMUTE_ACTION") !=
                null
              ) {
                document.getElementById(
                  socketId + "_VIDEO_MAIN_UNMUTE_ACTION"
                ).innerHTML = "Mute Camera";
              }
              if (socketId == this.socket.id) {
                this.currentVideoState = true;
              }
              //Select Remote Stream Container Element With SocketID And Display Picture And Name In Front Of Video. Video Goes To Back With ZIndex.
            } else {
              const index = this.audioMicClose.indexOf(socketId);
              if (index > -1) {
                // only splice array when item is found
                this.audioMicClose.splice(index, 1); // 2nd parameter means remove one item only
              }
              if (
                document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY") !=
                null
              ) {
                document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY")
                  ? (document.getElementById(
                      socketId + "_OFFLINE_AUDIO_TOP_NOTIFY"
                    ).style.display = "none")
                  : "";
              }
              if (
                document.getElementById(
                  socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH"
                ) != null
              ) {
                document.getElementById(
                  socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH"
                )
                  ? (document.getElementById(
                      socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH"
                    ).style.display = "none")
                  : "";
              }
              if (
                document.getElementById(socketId + "_AUDIO_MUTE_ACTION") != null
              ) {
                document.getElementById(
                  socketId + "_AUDIO_MUTE_ACTION"
                ).innerHTML = "Mute Mic";
              }
              if (
                document.getElementById(socketId + "_AUDIO_MAIN_MUTE_ACTION") !=
                null
              ) {
                document.getElementById(
                  socketId + "_AUDIO_MAIN_MUTE_ACTION"
                ).innerHTML = "Mute Mic";
              }
              if (
                document.getElementById(socketId + "_AUDIO_TOP_NOTIFY") != null
              ) {
                document.getElementById(
                  socketId + "_AUDIO_TOP_NOTIFY"
                ).style.display = "inline";
              }
              if (
                document.getElementById(socketId + "_AUDIO_TOP_NOTIFY_SLASH") !=
                null
              ) {
                document.getElementById(
                  socketId + "_AUDIO_TOP_NOTIFY_SLASH"
                ).style.display = "none";
              }
              if (socketId == this.socket.id) {
                this.currentMicState = true;
              }
  
              //Select Remote Stream Container Element With SocketID And Display Mic Closed Icon On Top.
            }
          }
        );
  
        this.socket.on(
          "producerPaused",
          async ({ storageId, socketId, kind }) => {
            //console.log("this.peers_______________", this.peers);
            console.log("producerPaused________", {
              storageId,
              socketId,
              kind,
            });
  
            if (kind === "video") {
              if (this.mainScreenStreamRef) {
                if (this.mainScreenStreamRef.socketId == socketId) {
                  this.mainScreenStreamRef.mutedVideo = true;
                }
              }
              if (this.peers.length > 0) {
                var objIndex = this.peers.findIndex(
                  (user) =>
                    user?.storageId == storageId && user?.screenShare == false
                );
  
                //console.log("objIndex______", objIndex);
                if (objIndex >= 0) {
                  //console.log("objIndex______", objIndex);
                  this.peers[objIndex].mutedVideo = true;
                }
              }
              if (this.peerShow.length > 0) {
                var objIndex = this.peerShow.findIndex(
                  (user) =>
                    user?.storageId == storageId && user?.screenShare == false
                );
  
                //console.log("objIndex______", objIndex);
                if (objIndex >= 0) {
                  //console.log("objIndex______", objIndex);
                  this.peerShow[objIndex].mutedVideo = true;
                }
              }
              if (socketId == this.socket.id) {
                this.currentVideoState = false;
              }
              if (document.getElementById(storageId + "_STREAM_VIDEO") != null) {
                document.getElementById(
                  storageId + "_STREAM_VIDEO"
                ).style.visibility = "hidden";
              }
              if (
                document.getElementById(storageId + "_STREAM_VIDEO_POSITION") !=
                null
              ) {
                document.getElementById(
                  storageId + "_STREAM_VIDEO_POSITION"
                ).style.position = "";
                document.getElementById(
                  storageId + "_STREAM_VIDEO_POSITION"
                ).style.width = "inherit";
              }
              if (document.getElementById(storageId + "_VIDEO") != null) {
                document.getElementById(storageId + "_VIDEO").style.visibility =
                  "visible";
              }
  
              if (
                document.getElementById(storageId + "_STREAM_VIDEO_ZOOM_ICON") !=
                null
              ) {
                document.getElementById(
                  storageId + "_STREAM_VIDEO_ZOOM_ICON"
                ).style.visibility = "hidden";
              }
  
              if (
                document.getElementById(
                  "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
                ) != null
              ) {
                document.getElementById(
                  "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
                ).style.background = "black";
              }
              if (
                document.getElementById(storageId + "_STREAM_SCREEN_VIDEO") !=
                null
              ) {
                document.getElementById("Screen").style.background = "black";
                document.getElementById(
                  storageId + "_STREAM_SCREEN_VIDEO"
                ).style.visibility = "hidden";
              }
              if (
                document.getElementById(storageId + "_OFFLINE_SCREEN_VIDEO") !=
                null
              ) {
                document.getElementById(
                  storageId + "_OFFLINE_SCREEN_VIDEO"
                ).style.display = "none";
              }
              if (
                document.getElementById(
                  "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
                ) != null
              ) {
                document.getElementById(
                  "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
                ).style.display = "none";
              }
              if (document.getElementById(storageId + "_SCREEN_VIDEO") != null) {
                document.getElementById(
                  storageId + "_SCREEN_VIDEO"
                ).style.display = "flex";
              }
              if (
                document.getElementById(
                  socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH"
                ) != null
              ) {
                document.getElementById(
                  socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH"
                )
                  ? (document.getElementById(
                      socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH"
                    ).style.display = "none")
                  : "";
              }
  
              if (
                document.getElementById(
                  storageId + "_OFFLINE_VIDEO_TOP_NOTIFY"
                ) != null
              ) {
                document.getElementById(storageId + "_OFFLINE_VIDEO_TOP_NOTIFY")
                  ? (document.getElementById(
                      storageId + "_OFFLINE_VIDEO_TOP_NOTIFY"
                    ).style.display = "none")
                  : "";
              } else {
                console.log("offline does not exist!");
              }
              //DROP TOP LEFT HOVER ACTION
              if (
                document.getElementById(socketId + "_VIDEO_TOP_NOTIFY_SLASH") !=
                null
              ) {
                document.getElementById(
                  socketId + "_VIDEO_TOP_NOTIFY_SLASH"
                ).style.display = "inline";
              }
  
              if (
                document.getElementById(socketId + "_VIDEO_TOP_NOTIFY") != null
              ) {
                document.getElementById(
                  socketId + "_VIDEO_TOP_NOTIFY"
                ).style.display = "none";
              }
              //DROP DOWN REMOTE ACTION
              if (
                document.getElementById(socketId + "_VIDEO_UNMUTE_ACTION") != null
              ) {
                document.getElementById(
                  socketId + "_VIDEO_UNMUTE_ACTION"
                ).innerHTML = "Unmute Camera";
              }
              if (
                document.getElementById(socketId + "_VIDEO_MAIN_UNMUTE_ACTION") !=
                null
              ) {
                document.getElementById(
                  socketId + "_VIDEO_MAIN_UNMUTE_ACTION"
                ).innerHTML = "Unmute Camera";
              }
              //Select Remote Stream Container Element With SocketID And Display Picture And Name In Front Of Video. Video Goes To Back With ZIndex.
            } else {
              this.audioMicClose.push(socketId);
              if (
                document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY") !=
                null
              ) {
                document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY")
                  ? (document.getElementById(
                      socketId + "_OFFLINE_AUDIO_TOP_NOTIFY"
                    ).style.display = "none")
                  : "";
              }
              if (
                document.getElementById(
                  socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH"
                ) != null
              ) {
                document.getElementById(
                  socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH"
                )
                  ? (document.getElementById(
                      socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH"
                    ).style.display = "none")
                  : "";
              }
              if (
                document.getElementById(socketId + "_AUDIO_TOP_NOTIFY") != null
              ) {
                document.getElementById(
                  socketId + "_AUDIO_TOP_NOTIFY"
                ).style.display = "none";
              }
              if (
                document.getElementById(socketId + "_AUDIO_TOP_NOTIFY_SLASH") !=
                null
              ) {
                document.getElementById(
                  socketId + "_AUDIO_TOP_NOTIFY_SLASH"
                ).style.display = "inline";
              }
              if (
                document.getElementById(socketId + "_AUDIO_MUTE_ACTION") != null
              ) {
                document.getElementById(
                  socketId + "_AUDIO_MUTE_ACTION"
                ).innerHTML = "Unmute Mic";
              }
              if (
                document.getElementById(socketId + "_AUDIO_MAIN_MUTE_ACTION") !=
                null
              ) {
                document.getElementById(
                  socketId + "_AUDIO_MAIN_MUTE_ACTION"
                ).innerHTML = "Unmute Mic";
              }
              if (socketId == this.socket.id) {
                this.currentMicState = false;
              }
  
              //Select Remote Stream Container Element With SocketID And Display Mic Closed Icon On Top.
            }
          }
        );
  
        // this.socket.on("ConsumeTransportCreated", async (data) => {
        //   this.consume(data);
        // });
  
        this.socket.on("consumerCreated", async (data) => {
          console.log("consumerCreated---------", data);
          await this.handleConsumer(data);
        });
  
        this.socket.on("myRoleChanged", async ({ webinarUserType, lockRoom }) => {
          // this.role = webinarUserType;
          console.log("webinarUserType-------------0", webinarUserType);
          console.log("webinarUserType-------------0", this.hostUnlockRoom);
          console.log("lockRoom-------------0", lockRoom);
  
          if (this.role == "VIEWER") {
            this.role = webinarUserType;
            this.viewers = this.viewers.filter(
              (a) => a.userDetails.socketId !== this.socket.id
            );
            // window.location.reload()
            // await this.startWebinar();
            // await this.startProducing();
  
            this.socket.emit("getRTPCapabilites", async (response) => {
              // alert('socketId ', this.socketId);
              console.log("response-------------0", response);
              this.handleCapabilities(response?.capabilities);
              this.socketId = this.socket.id;
              // this.loggedUserData = {
              //   ...userdetailsData,
              //   socketId: this.socket.id,
              // };
  
              this.user_name =
                this.loggedUserData.firstName +
                " " +
                this.loggedUserData.lastName;
              // this.profileImg = this.loggedUserData.profileImg;
              const obj = {
                room: this.roomId,
                webinarUserType: this.role,
                is_recorder_user: true,
                user_name:
                  this.loggedUserData.firstName +
                  " " +
                  this.loggedUserData.lastName,
                email: this.loggedUserData.email
                  ? this.loggedUserData.email
                  : this.loggedUserData.firstName +
                    this.loggedUserData.lastName +
                    "@gmail.com",
                profileImg: this.profileImg,
                isWaiting: false,
              };
              console.log("obj________", obj);
              this.socket.emit("addUserCall", obj);
              // this.demoSocket.emit("addUser", { ...obj });
              this.socket.emit("fetchViewerUser", { room: this.roomId });
              if (this.role != "VIEWER") {
                // alert("Hi", this.role);
                await this.startStream();
  
                await this.startProducing();
                this.is_disable = false;
              } else {
                // alert("By", this.role);
                this.viewers.push({
                  socketId: this.socket.id,
                  room: this.roomId,
                  userDetails: {
                    user_name: this.user_name,
                    email: this.loggedUserData.email,
                    profileImg: this.profileImg,
                    webinarUserType: this.role,
                  },
                });
  
                const objV = {
                  socketId: this.socket.id,
                  room: this.roomId,
                  webinarUserType: this.role,
                  userDetails: {
                    email: this.loggedUserData.email,
                    socketId: this.socket.id,
                    user_name:
                      this.loggedUserData.firstName +
                      " " +
                      this.loggedUserData.lastName,
                    profileImg: this.loggedUserData.profileImg,
                    webinarUserType: this.role,
                  },
                };
  
                this.is_stream = false;
                this.socket.emit("addUserInViewerCall", objV);
                console.log("-----------------", objV);
              }
              console.log(this.role);
              setInterval(async () => {
                // console.log('connected-----', this.socket.connected);
  
                if (this.socket.connected == false) {
                  $("#reconnecting").modal("show");
                  setInterval(async () => {
                    window.location.reload(true);
                  }, 3000);
                  // this.startWebinar(this.loggedUserData)
                } else {
                  $("#reconnecting").modal("hide");
                }
              }, 3000);
            });
          } else {
            this.role = webinarUserType;
  
            //           if(this.role != 'GUEST'){
            if (this.hostUnlockRoom && this.role == "HOST") {
              this.lockRoom = lockRoom;
            }
  
            if (this.moderatorsUnlockRoom && this.role == "MODERATOR") {
              this.lockRoom = lockRoom;
            }
            //  }
            $(function () {
              $(".toggleP").on("click", function () {
                if ($(this).hasClass("on")) {
                  $("#lockModal").modal("hide");
                  $("#unlockModal").modal("show");
                  // $(this).removeClass('on');
                } else {
                  $("#unlockModal").modal("hide");
                  $("#lockModal").modal("show");
                  // $(this).addClass('on');
                }
              });
            });
          }
          if (webinarUserType == "GUEST") {
            this.lockRoom = false;
          }
        });
        this.socket.on("roleChanged", ({ webinarUserType, socketId, roomId }) => {
          console.log("roleChanged:   ", webinarUserType, socketId, roomId);
  
          this.changeRoleOnPeers(socketId, webinarUserType);
        });
        this.socket.on("newProducer", async (producer) => {
          console.log("newProducer:   ", producer);
  
          this.addItemToProcess(producer);
          // const data = await this.startConsumeProducer(producer);
          // console.log(data, "data");
          // await this.consume(data);
        });
  
        this.socket.on("renameUser", (data) => {
          if (
            document.getElementById(data.socketId + "_USER_MAIN_NAME") != null
          ) {
            document.getElementById(data.socketId + "_USER_MAIN_NAME").innerHTML =
              data.name;
            console.log("renameUser________updated");
          }
          if (document.getElementById(data.socketId + "_USER_NAME") != null) {
            document.getElementById(data.socketId + "_USER_NAME").innerHTML =
              data.name;
            console.log("renameUser________updated");
          }
          if (
            document.getElementById(data.socketId + "_SCREEN_USER_NAME") != null
          ) {
            document.getElementById(
              data.socketId + "_SCREEN_USER_NAME"
            ).innerHTML = data.name;
            console.log("renameUser________updated");
          }
          //console.log("this.peers______", this.peers);
          console.log("data______", data);
  
          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) =>
                user?.socketId == data.socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peers[objIndex].userDetails.user_name = data.name;
            }
            //Update object's name property.
            var objIndexScreen = this.peers.findIndex(
              (user) =>
                user?.socketId == data.socketId && user?.screenShare == true
            );
            if (objIndexScreen >= 0) {
              console.log("objIndexScreen______", objIndexScreen);
              this.peers[objIndexScreen].userDetails.user_name = data.name;
            }
          }
  
          if (this.peerShow.length > 0) {
            var objIndex = this.peerShow.findIndex(
              (user) =>
                user?.socketId == data.socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peerShow[objIndex].userDetails.user_name = data.name;
            }
            //Update object's name property.
            var objIndexScreen = this.peerShow.findIndex(
              (user) =>
                user?.socketId == data.socketId && user?.screenShare == true
            );
            if (objIndexScreen >= 0) {
              console.log("objIndexScreen______", objIndexScreen);
              this.peerShow[objIndexScreen].userDetails.user_name = data.name;
            }
          }
  
          if (this.mainScreenStreamRef) {
            console.log("changes ______", this.mainScreenStreamRef);
            if (this.mainScreenStreamRef.socketId == data.socketId) {
              this.mainScreenStreamRef.userDetails.user_name = data.name;
              console.log("changes DONE______", this.mainScreenStreamRef);
            }
          }
  
          console.log("this.peers___NEW___", this.peers);
          if (this.socket.id == data.socketId) {
            console.log("HIII saME AS");
            this.user_name = data.name;
          }
        });
  
        this.socket.on("removeUser", () => {
          $("#remove_users").modal("show");
  
          window.addEventListener("beforeunload", function (event) {
            event.returnValue = "";
          });
          setTimeout(function () {
            window.location.href = window.origin;
            // this.$router.push("/login");
          }, 2000);
        });
  
        this.socket.on("receivedBanChatUser", (data) => {
          console.log("receivedBanChatUser----", data);
        });
        this.socket.on("receivedMuteChatUser", ({ remote, action, userName }) => {
          console.log("receivedMuteChatUser----");
  
          if (action) {
            this.is_chat_mute = true;
          } else {
            this.is_chat_mute = false;
          }
          // this.handleMic();
        });
        this.socket.on("muteMic", ({ remote, action, userName }) => {
          console.log("remote---muteMic---", remote);
          if (action == "unmute") {
            this.requestUserName = userName;
            $("#requestMicModal").modal("show");
          } else {
            this.handleMic();
          }
        });
  
        this.socket.on("muteVideo", ({ socketId, remote, action, userName }) => {
          console.log("remote---muteVideo---", socketId, remote, action);
          console.log("is_cam_request-------", this.is_cam_request);
  
          if (action == "unmute") {
            this.requestUserName = userName;
            $("#requestCamModal").modal("show");
          } else {
            this.handleVideo();
          }
        });
  
        window.setInterval(() => {
          for (let i = 0; i < this.filterdParticipants.length; i++) {
            const storageId = this.filterdParticipants[i].storageId;
            if ($("#openactiondropdownB" + storageId).hasClass("show")) {
              this.isActionPopup = true;
              break;
            } else {
              this.isActionPopup = false;
            }
            if ($("#openactiondropdownA" + storageId).hasClass("show")) {
              this.isActionPopup = true;
              break;
            } else {
              this.isActionPopup = false;
            }
            if ($("#openactiondropdownC" + storageId).hasClass("show")) {
              this.isActionPopup = true;
              break;
            } else {
              this.isActionPopup = false;
            }
            if ($("#openactiondropdownD" + storageId).hasClass("show")) {
              this.isActionPopup = true;
              break;
            } else {
              this.isActionPopup = false;
            }
          }
  
          resize();
          var video = document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT");
          if (video) {
            sdsad;
            video.videoHeight; // returns the intrinsic height of the video
            video.videoWidth; // returns the intrinsic width of the video
            if (video.videoHeight < video.videoWidth) {
              var aspectRatio = "16 / 9";
              if (
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width !=
                "100%"
              ) {
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width =
                  "100%";
              }
              if (
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style
                  .objectFit != "contain"
              ) {
                document.getElementById(
                  "ID_OF_YOUR_VIDEO_ELEMENT"
                ).style.objectFit = "contain";
                // document.getElementById(
                //   "ID_OF_YOUR_VIDEO_ELEMENT"
                // ).style.aspectRatio = `${aspectRatio}`;
  
                console.log("video_landscape_: TRUE");
              }
            } else {
              if (
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style
                  .objectFit != "contain"
              ) {
                var aspectRatio = "16 / 9";
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width =
                  "100%";
                document.getElementById(
                  "ID_OF_YOUR_VIDEO_ELEMENT"
                ).style.objectFit = "contain";
                // document.getElementById(
                //   "ID_OF_YOUR_VIDEO_ELEMENT"
                // ).style.aspectRatio = `${aspectRatio}`;
              }
              if (
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width !=
                "auto"
              ) {
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width =
                  "auto";
                console.log("video_landscape_: FALSE______");
              }
              /*OR*/
              /*video_portrait = "true"*/
            }
          }
        }, 100);
        //}
      },
      async openactiondropdown() {
        console.log("openactiondropdown");
      },
      async allowMicModal() {
        $("#requestMicModal").modal("hide");
        // this.requestUserName = "";
        this.handleMic();
      },
      async denyMicModal() {
        $("#requestMicModal").modal("hide");
        // this.requestUserName = "";
      },
      async allowCamModal(producer) {
        this.is_cam_request = true;
        $("#requestCamModal").modal("hide");
        // this.requestUserName = "";
        this.handleVideo();
        // this.is_cam_request = false;
      },
      async denyCamModal(producer) {
        $("#requestCamModal").modal("hide");
        // this.requestUserName = "";
      },
      async startConsumeProducer(producer) {
        return new Promise((resolve, reject) => {
          this.socket.emit("createConsumeTransport", producer, (response) => {
            resolve(response);
          });
  
          // this.socket.once("ConsumeTransportCreated", (producer) => {
          //   resolve(producer);
          // });
        });
        // return new Promise((resolve, reject) => {
        //   this.socket.emit("createConsumeTransport", producer);
  
        //   this.socket.once("ConsumeTransportCreated",(producer)=>{
        //     resolve(producer)
        //   });
        // });
  
        //   this.socket.emit("createConsumeTransport", producer, (response) => {
        //     resolve(response);
        //   });
        // console.log("HHHHHHHHHHHH");
      },
      async handleCapabilities(capabilities) {
        const cap = { routerRtpCapabilities: capabilities };
        const detectedHandler = detectDevice();
        let handlerName;
        // alert(detectedHandler)
        if (detectedHandler) {
          handlerName = detectedHandler;
        } else {
          handlerName = "Safari12";
        }
        try {
          if (handlerName !== "Firefox60") {
            cap.routerRtpCapabilities.headerExtensions =
              cap.routerRtpCapabilities.headerExtensions.filter(
                (ext) => ext.uri !== "urn:3gpp:video-orientation"
              );
          }
          this.mediasoupDevice = new Device({ handlerName: handlerName });
          // alert(handlerName)
          console.log("this.mediasoupDevice-----", this.mediasoupDevice);
        } catch (err) {
          console.log("err-----", err);
        }
        await this.mediasoupDevice?.load(cap);
  
        if (this.mediasoupDevice?.loaded) {
          console.log("selectedVideo --- ", this.selectedVideo);
          console.log("selectedAudio --- ", this.selectedAudio);
          console.log("loaded --- ");
        }
      },
      async startStream() {
        try {
          // const localStream = await navigator?.mediaDevices?.getUserMedia({
          //   audio: {
          //     noiseSuppression: true,
          //     echoCancellation: true,
          //   },
          // });
  
          const audioTracks = this.localStreamRef?.getAudioTracks();
          if (audioTracks.length > 0) {
            const audioDeviceId = audioTracks[0].getSettings().deviceId;
            this.selectedAudio = audioDeviceId;
            this.is_mic_allow = true;
            console.log("Audio device ID:", audioDeviceId);
          } else {
            console.error("No video tracks found in the stream.");
          }
          var userdetailsData = JSON.parse(localStorage.getItem("userdetails"));
  
          const videoTracks = this.localStreamRef.getVideoTracks();
          if (videoTracks.length > 0) {
            const videoDeviceId = videoTracks[0].getSettings().deviceId;
            this.selectedVideo = videoDeviceId;
            console.log("Video device ID:", videoDeviceId);
          } else {
            console.error("No video tracks found in the stream.");
          }
          // this.localStreamRef = localStream;
  
          console.log("localStream----222-------", this.localStreamRef);
  
          this.peers.unshift({
            is_show: true,
            socketId: this.socket.id,
            storageId: "LocalStream_storageId",
            screenShare: false,
            userDetails: {
              user_name: this.user_name,
              profileImg: this.profileImg,
              email: userdetailsData
                ? userdetailsData.email
                  ? userdetailsData.email
                  : userdetailsData.firstName +
                    userdetailsData.lastName +
                    "@gmail.com"
                : this.username + "@gmail.com",
              webinarUserType: this.role,
            },
            is_mute: false,
            is_ban: false,
            paused: true,
            raiseHand: false,
            mutedVideo: true,
            kind: "video",
          });
  
          this.peersRef.unshift({
            kind: "video",
            raiseHand: false,
            mutedVideo: true,
            is_show: true,
            paused: true,
            userDetails: {
              user_name: this.user_name,
              profileImg: this.profileImg,
              email: userdetailsData
                ? userdetailsData.email
                  ? userdetailsData.email
                  : userdetailsData.firstName +
                    userdetailsData.lastName +
                    "@gmail.com"
                : this.username + "@gmail.com",
              webinarUserType: this.role,
            },
            socketId: this.socket.id,
            storageId: "LocalStream_storageId",
            screenShare: false,
            mediaStream: this.localStreamRef,
          });
  
          this.peerShow.unshift({
            is_show: true,
            socketId: this.socket.id,
            storageId: "LocalStream_storageId",
            screenShare: false,
            raiseHand: false,
            userDetails: {
              user_name: this.user_name,
              email: userdetailsData
                ? userdetailsData.email
                  ? userdetailsData.email
                  : userdetailsData.firstName +
                    userdetailsData.lastName +
                    "@gmail.com"
                : this.username + "@gmail.com",
              profileImg: this.profileImg,
              webinarUserType: this.role,
            },
            is_mute: false,
            is_ban: false,
            paused: true,
            mutedVideo: true,
            kind: "video",
            mediaStream: this.localStreamRef,
          });
  
          this.is_stream = false;
          if (this.peerShow.length != 0) {
            this.filterdParticipants = this.peers.filter(
              (a) => a.userDetails.webinarUserType == this.currentTabSelect
            );
          }
  
          console.log("____________FF", this.peersRef);
          // this.audioPeersRef = [
          //   ...this.audioPeersRef,
          //   {
          //     socketId: this.socket.id,
          //     storageId: "LocalStream_storageId",
          //     mediaStream: this.localStreamRef,
          //     consumer: "consumer",
          //   },
          // ];
          // this.handleAudioLevel();
        } catch (error) {
          if (error.name == "AbortError") {
            $("#NotReadableError").modal("show");
          } else if (error.name == "TypeError") {
            $("#errorModal").modal("show");
            this.running_error =
              "Your Browser Does Not Support WebRTC. Join This Room With A Supported Browser Or Update Your Browser";
            this.is_cam_allow = false;
            this.is_cam_allow = false;
          } else {
            $("#errorModal").modal("show");
            this.running_error = error;
          }
        }
        // this.audioElement.push('localStream' + this.socket.id)
        // const audioElem = document.createElement('audio')
        // audioElem.autoplay = true;
        // audioElem.srcObject = localStream;
        // audioElem.id = 'localStream' + this.socket.id;
        // this.audioElement.push('localStream' + this.socket.id)
        // document.body.appendChild(audioElem);
      },
      async openenterNameModal() {
        $("#enterNameModal").modal("show");
      },
      async manageDiv() {
        console.log("this.peers.length__________", this.peers.length);
  
        if (this.peers.length == 1) {
          if (this.mainScreenStreamRef == null) {
            document.getElementById("ALL_VIDEOS").style.display = "flex";
          } else {
            if (this.mainScreenStreamRef.screenShare) {
              document.getElementById("ALL_VIDEOS").style.display = "flex";
            } else {
              document.getElementById("ALL_VIDEOS").style.display = "none";
            }
          }
        } else {
          if (this.mainScreenStreamRef) {
            if (this.peers.length == 0) {
              document.getElementById("ALL_VIDEOS").style.display = "none";
            } else {
              document.getElementById("ALL_VIDEOS").style.display = "flex";
            }
          } else {
            document.getElementById("ALL_VIDEOS").style.display = "flex";
          }
        }
      },
      async startProducing() {
        // this.socket.emit("createTransport", this.socket.id);
  
        // this.localStreamRef?.getTracks().forEach((track) => {
        //   this.socket.emit('createTransport', this.socket.id, (data) => {
        //     this.handleTransport(data, track, track.kind)
        //   })
        // });
      },
      async handleTransport({ data }) {
        console.log("trans", data);
        this.produceTransport = await this.mediasoupDevice.createSendTransport(
          data
        );
  
        console.log(
          "trans ---  this.produceTransport----",
          this.produceTransport
        );
        this.produceTransport.on(
          "connect",
          ({ dtlsParameters }, callback, errback) => {
            console.log("trans ---  connect----", typeof dtlsParameters);
            this.socket.emit("connectTransport", {
              dtlsParameters,
              id: this.socket.id,
            });
            this.socket.on("transportConnected", () => {
              console.log("trans ---  transportConnected----");
              callback();
            });
          }
        );
  
        this.produceTransport.on(
          "produce",
          ({ kind, rtpParameters, appData }, callback, errback) => {
            console.log("trans ---  appData----", typeof appData);
            console.log("trans ---  produce----", typeof rtpParameters);
            console.log("trans ---  produce----", {
              kind,
              rtpParameters,
              appData,
            });
            this.socket.emit("produce", {
              kind,
              rtpParameters,
              id: this.socket.id,
              room: this.roomId,
              appData,
            });
            this.socket.on(
              "producing",
              ({ producerId, kind, screenShare, appData }) => {
                console.log(
                  "trans ---  producing----",
                  producerId,
                  kind,
                  screenShare
                );
                if (kind === "video") {
                  if (screenShare) {
                    this.videoProducerId2 = producerId;
                  } else if (appData?.type === "record") {
                    if (!this.recordIds?.includes(producerId)) {
                      this.recordIds?.push(producerId);
  
                      if (this.recordIds.length === 2) {
                        this.socket.emit("startRecording", this.recordIds);
                      }
                    }
                  } else {
                    this.videoProducerId = producerId;
                  }
                } else {
                  if (screenShare) {
                    this.audioProducerId2 = producerId;
                  } else if (appData?.type === "record") {
                    if (!this.recordIds?.includes(producerId)) {
                      this.recordIds?.push(producerId);
                      if (this.recordIds.length === 2) {
                        this.socket.emit("startRecording", this.recordIds);
                      }
                    }
                  } else {
                    this.audioProducerId = producerId;
                  }
                }
  
                callback(producerId);
              }
            );
          }
        );
  
        try {
          this.produceTransport?.on(
            "producedata",
            ({ sctpStreamParameters }, callback, errback) => {
              this.socket.emit(
                "produceData",
                { params: sctpStreamParameters, roomId: this.roomId },
                (response) => {
                  if (response?.type === "success") {
                    this.dataProducerId = response?.id;
                    console.log("response?.id-------------", response?.id);
                    callback(response?.id);
                  } else {
                    console.log("response?.error-------------", response?.error);
                    errback(response?.error);
                  }
                }
              );
            }
          );
          this.produceTransport.on("connectionstatechange", (state) => {
            console.log("state-----------", state);
            if (state == "connected") {
              let videoInput = this.deviceData.filter(
                (device) => device.kind === "videoinput"
              );
              if (videoInput.length > 0) {
                // this.selectedVideo = videoInput[0].deviceId;
              }
              let audioInput = this.deviceData.filter(
                (device) => device.kind === "audioinput"
              );
              if (audioInput.length > 0) {
                // this.selectedAudio = audioInput[0].deviceId;
              }
              let audioOutput = this.deviceData.filter(
                (device) => device.kind === "audiooutput"
              );
              if (audioOutput.length > 0) {
                const audioOutputDe = this.deviceData.filter(
                  (device) =>
                    device.kind === "audiooutput" && device.deviceId == "default"
                );
  
                if (audioOutputDe.length) {
                  this.selectedOuterAudio = audioOutputDe[0].deviceId;
                } else {
                  this.selectedOuterAudio = audioOutput[0].deviceId;
                }
              }
              this.verify_loader = false;
              $("#enterUnlockPinModal").modal("hide");
  
              $("#enterNameModal").modal("hide");
              this.join_loader = false;
            }
            switch (state) {
              case "connecting":
                console.log("connecting");
                break;
              case "connected":
                console.log("connected");
                break;
              case "failed":
                console.log("failed");
                this.join_loader = false;
                // window.location.reload()
                break;
              default:
                break;
            }
          });
  
          try {
            // console.log("this.localStreamRef----", this.localStreamRef);
            const Audiotracks = this.localStreamRef?.getAudioTracks()[0];
            const Videotracks = this.localStreamRef.getVideoTracks()[0];
            if (Audiotracks) {
              this.audioProducer = await this.produceTransport?.produce({
                track: Audiotracks,
                appData: { type: "record" },
              });
            }
  
            if (Videotracks) {
              this.videoProducer = await this.produceTransport?.produce({
                track: Videotracks,
                appData: { type: "record" },
                codec: this.mediasoupDevice.rtpCapabilities.codecs.find(
                  (codec) => codec.mimeType.toLowerCase() === "video/h264"
                ),
              });
            }
          } catch (err) {
            $("#errorModal").modal("show");
            this.running_error = err;
            console.log(err);
          }
          // this.handleMic()
          // this.handleVideso()
          this.is_stream = false;
        } catch (e) {
          console.log("-----------e--------", e);
        }
      },
      addToData() {},
      async handleConsumer(data) {
        try {
          const {
            producerId,
            kind,
            id,
            paused,
            rtpParameters,
            screenShare,
            socketId,
            storageId,
            userDetails,
            audioShare,
            videoShare,
            muted,
            docShare,
            is_raise,
          } = data;
  
          console.log("R T ::dd:::::", {
            id,
            producerId,
            kind,
            rtpParameters,
            streamId: socketId + screenShare ? "-share-screen" : "-mic-webcam",
          });
          console.log("R T :::::::", this.receiveTransport);
          const consumer = await this.receiveTransport[data?.storageId].consume({
            id,
            producerId,
            kind,
            rtpParameters,
            streamId: socketId + screenShare ? "-share-screen" : "-mic-webcam",
          });
          this.socket.emit("resumeConsumer", id);
          const mediaStream = new MediaStream();
          mediaStream.addTrack(consumer.track);
          this.consumers.set(producerId, consumer);
  
          console.log("-mediaStream", mediaStream.getTracks());
          // console.log("-consumer", consumer);
          console.log("-ppppppp", data);
          console.log("-this.peers", this.peers);
          if (
            this.peers.some(
              (peer) => peer.socketId === socketId && peer.screenShare === false
            )
          ) {
            if (kind === "video") {
              // alert("Iffff");
              if (screenShare) {
                var video = document.getElementById(socketId + "STREAM_SCR_SRC");
                this.screenShareLoader = true;
                if (this.mainScreenStreamRef) {
                  if (this.mainScreenStreamRef.screenShare == false) {
                    console.log("*****1*******");
                    this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                  } else {
                    this.mainScreenStreamRef = null;
                  }
                } else {
                }
                // this.screenShareStarted = screenShare;
                // this.videoShared = videoShare;
                this.mainScreenStreamRef = {
                  socketId: socketId,
                  storageId: storageId,
                  is_autoplay: false,
                  screenShare: screenShare,
                  userDetails: userDetails,
                  videoShare: videoShare,
                  audioShare: audioShare,
                  docShare: docShare,
                  remoteScreen: true,
                  paused: paused,
                  mutedVideo: paused,
                  raiseHand: false,
                  kind: kind,
                  mediaStream,
                };
  
                if (this.mainScreenStreamRef) {
                  if (
                    this.mainScreenStreamRef.docShare == true &&
                    this.mainScreenStreamRef.socketId == this.socket.id
                  ) {
                    this.shareStream();
                  }
                }
                document.getElementById("Screen").style.display = "flex";
              } else {
                if (screenShare && !audioShare && !docShare) {
                  const audioElem = document.createElement("audio");
                  audioElem.autoplay = true;
                  audioElem.id = "screenSharingAudio";
                  console.log("screenSharingAudio");
  
                  const audioCtx = new AudioContext();
  
                  // Create a source node from the audio element
                  const source = audioCtx.createMediaStreamSource(mediaStream);
  
                  // Create a gain node to control volume
                  this.gainNode = audioCtx.createGain();
  
                  // Connect the audio path
                  source.connect(this.gainNode);
  
                  const destination = audioCtx.createMediaStreamDestination();
                  this.gainNode.connect(destination);
  
                  audioElem.srcObject = destination.stream;
                }
                if (this.mainScreenStreamRef) {
                  if (
                    this.mainScreenStreamRef.socketId == socketId &&
                    this.mainScreenStreamRef.screenShare == false
                  ) {
                    this.mainScreenStreamRef.mediaStream = mediaStream;
                  } else {
                    var video = document.getElementById(socketId + "STREAM_SRC");
                    // video.srcObject = mediaStream;
                    console.log("-----", mediaStream);
                    this.updateCamStream(socketId, mediaStream);
                    this.updateCamStatus(socketId, false);
                    // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
                    // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
                  }
                } else {
                  var video = document.getElementById(socketId + "STREAM_SRC");
                  // video.srcObject = mediaStream;
                  console.log("-----", mediaStream);
                  this.updateCamStream(socketId, mediaStream);
                  this.updateCamStatus(socketId, false);
                  // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
                  // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
                }
                // video.autoplay = true;
              }
            } else {
              // alert("Main else");
  
              if (screenShare && audioShare) {
                // alert("else in if");
                var video = document.getElementById(socketId + "STREAM_SCR_SRC");
                this.screenShareLoader = true;
                if (this.mainScreenStreamRef) {
                  if (this.mainScreenStreamRef.screenShare == false) {
                    console.log("*****1*******");
                    this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                  } else {
                    this.mainScreenStreamRef = null;
                  }
                } else {
                }
                // this.screenShareStarted = screenShare;
                // this.videoShared = videoShare;
                this.mainScreenStreamRef = {
                  socketId: socketId,
                  storageId: storageId,
                  is_autoplay: false,
                  screenShare: screenShare,
                  userDetails: userDetails,
                  videoShare: videoShare,
                  audioShare: audioShare,
                  docShare: docShare,
                  remoteScreen: true,
                  paused: paused,
                  mutedVideo: paused,
                  raiseHand: false,
                  kind: kind,
                  mediaStream: null,
                };
  
                if (this.mainScreenStreamRef) {
                  if (
                    this.mainScreenStreamRef.docShare == true &&
                    this.mainScreenStreamRef.socketId == this.socket.id
                  ) {
                    this.shareStream();
                  }
                }
                const audioElem = document.createElement("audio");
                audioElem.autoplay = true;
                audioElem.id = "audio";
  
                const audioCtx = new AudioContext();
  
                // Create a source node from the audio element
                const source = audioCtx.createMediaStreamSource(mediaStream);
  
                // Create a gain node to control volume
                this.gainNode = audioCtx.createGain();
  
                // Connect the audio path
                source.connect(this.gainNode);
  
                const destination = audioCtx.createMediaStreamDestination();
                this.gainNode.connect(destination);
  
                audioElem.srcObject = destination.stream;
  
                document.getElementById("Screen").style.display = "flex";
              } else {
                // alert("else audio tagsdsasd");
                if (muted == true) {
                  this.audioMicClose.push(socketId);
                }
                const audioElem = document.createElement("audio");
                audioElem.autoplay = true;
                audioElem.srcObject = mediaStream;
                // audioElem.play();
                audioElem.id = `${data?.socketId}_${
                  screenShare ? "screenaudio" : "audio"
                }`;
                this.audioElement.push(
                  `${data?.socketId}_${screenShare ? "screenaudio" : "audio"}`
                );
                this.audioElementSocket.push({
                  storageId: data.storageId,
                  socketId: data.socketId,
                });
  
                if (screenShare && !audioShare && !docShare) {
                  const audioCtx = new AudioContext();
  
                  // Create a source node from the audio element
                  const source = audioCtx.createMediaStreamSource(mediaStream);
  
                  // Create a gain node to control volume
                  this.gainNode = audioCtx.createGain();
  
                  // Connect the audio path
                  source.connect(this.gainNode);
  
                  const destination = audioCtx.createMediaStreamDestination();
                  this.gainNode.connect(destination);
  
                  audioElem.srcObject = destination.stream;
                }
                console.log("*************************");
                console.log(this.selectedOuterAudio);
                console.log(this.role);
                console.log("*************************");
                document.body.appendChild(audioElem);
                // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "9" : '';
                // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "10" : '';
                if (!screenShare) {
                  this.updateCamStatus(socketId, true);
                }
                this.audioPeersRef = [
                  ...this.audioPeersRef,
                  {
                    socketId: socketId,
                    storageId: storageId,
                    mediaStream: mediaStream,
                    consumer: consumer,
                  },
                ];
                if (this.role == "VIEWER") {
                } else {
                  // this.changeOuterSpeaker(this.selectedOuterAudio);
                }
              }
            }
          } else {
            // alert("Else ma avyu bhai");
            // alert('ELSE')
  
            if (screenShare && audioShare) {
              // alert("Ifff in if 22");
  
              var video = document.getElementById(socketId + "STREAM_SCR_SRC");
              this.screenShareLoader = true;
              if (this.mainScreenStreamRef) {
                if (this.mainScreenStreamRef.screenShare == false) {
                  console.log("*****1*******");
                  this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                } else {
                  this.mainScreenStreamRef = null;
                }
              } else {
              }
              // this.screenShareStarted = screenShare;
              // this.videoShared = videoShare;
              this.mainScreenStreamRef = {
                socketId: socketId,
                storageId: storageId,
                is_autoplay: false,
                screenShare: screenShare,
                userDetails: userDetails,
                videoShare: false,
                audioShare: audioShare,
                remoteScreen: true,
                docShare: docShare,
                paused: paused,
                mutedVideo: paused,
                raiseHand: false,
                kind: kind,
                mediaStream,
              };
  
              if (this.mainScreenStreamRef) {
                if (
                  this.mainScreenStreamRef.docShare == true &&
                  this.mainScreenStreamRef.socketId == this.socket.id
                ) {
                  this.shareStream();
                }
              }
              document.getElementById("Screen").style.display = "flex";
            } else {
              // alert("else in if 22");
              if (this.mainScreenStreamRef) {
                if (
                  this.mainScreenStreamRef.docShare == true &&
                  this.mainScreenStreamRef.socketId == this.socket.id
                ) {
                  this.shareStream();
                }
              }
  
              this.peerShow.push({
                is_show: true,
                socketId: socketId,
                is_autoplay: false,
                storageId: storageId,
                screenShare: screenShare,
                userDetails: userDetails,
                paused: paused,
                mutedVideo: paused,
                is_mute: false,
                docShare: docShare,
                is_ban: false,
                raiseHand: is_raise,
                videoShare: false,
                audioShare: audioShare,
                kind: kind,
                mediaStream,
              });
  
              this.peers.push({
                is_show: true,
                socketId: socketId,
                storageId: storageId,
                is_autoplay: false,
                screenShare: screenShare,
                userDetails: userDetails,
                raiseHand: is_raise,
                is_mute: false,
                is_ban: false,
                docShare: docShare,
                paused: paused,
                mutedVideo: paused,
                videoShare: false,
                audioShare: audioShare,
                kind: kind,
                mediaStream,
              });
              // const peerFilter = this.peers.filter((peerData) => peerData?.is_show == true);
              // this.peerShow = peerFilter;
  
              this.peersRef.push({
                is_show: true,
                socketId: socketId,
                storageId: storageId,
                is_autoplay: false,
                screenShare: screenShare,
                userDetails: userDetails,
                raiseHand: is_raise,
                is_mute: false,
                is_ban: false,
                paused: paused,
                videoShare: false,
                audioShare: audioShare,
                mutedVideo: paused,
                kind: kind,
                mediaStream,
              });
              this.filterdWaitingUser = this.waitingUser.filter(
                (a) => a.userDetails.socketId !== socketId
              );
              this.waitingUser = this.waitingUser.filter(
                (a) => a.userDetails.socketId !== socketId
              );
  
              if (kind === "video") {
                // if (this.recordingRef) {
                //   this.mixer?.appendStreams(mediaStream)
                // }
                if (screenShare) {
                  var video = document.getElementById(
                    socketId + "STREAM_SCR_SRC"
                  );
                  this.screenShareLoader = true;
                  if (this.mainScreenStreamRef) {
                    if (this.mainScreenStreamRef.screenShare == false) {
                      this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                    } else {
                      this.mainScreenStreamRef = null;
                    }
                  } else {
                  }
  
                  // this.screenShareStarted = screenShare;
                  // this.videoShared = videoShare;
                  this.mainScreenStreamRef = {
                    socketId: socketId,
                    storageId: storageId,
                    is_autoplay: false,
                    screenShare: screenShare,
                    userDetails: userDetails,
                    videoShare: videoShare,
                    audioShare: audioShare,
                    remoteScreen: true,
                    paused: paused,
                    docShare: docShare,
                    mutedVideo: muted,
                    raiseHand: false,
                    kind: kind,
                    mediaStream,
                  };
  
                  if (this.mainScreenStreamRef) {
                    if (
                      this.mainScreenStreamRef.docShare == true &&
                      this.mainScreenStreamRef.socketId == this.socket.id
                    ) {
                      this.shareStream();
                    }
                  }
                  console.log("*****1*******", this.mainScreenStreamRef);
                  document.getElementById("Screen").style.display = "flex";
                } else {
                  var video = document.getElementById(socketId + "STREAM_SRC");
                  // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
                  // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
                  // video.srcObject = mediaStream;
                  this.updateCamStream(socketId, mediaStream);
                  this.updateCamStatus(socketId, false);
                  // video.autoplay = true;
                }
              } else {
                if (muted == true) {
                  this.audioMicClose.push(socketId);
                }
                const audioElem = document.createElement("audio");
                audioElem.autoplay = true;
                audioElem.srcObject = mediaStream;
                // audioElem.play();
                audioElem.id = `${data?.socketId}_${
                  screenShare ? "screenaudio" : "audio"
                }`;
                this.audioElement.push(
                  `${data?.socketId}_${screenShare ? "screenaudio" : "audio"}`
                );
                this.audioElementSocket.push({
                  storageId: data.storageId,
                  socketId: data.socketId,
                });
                console.log("*************************");
                console.log(this.selectedOuterAudio);
                console.log(this.role);
                console.log("*************************");
                document.body.appendChild(audioElem);
                if (!screenShare) {
                  this.updateCamStatus(socketId, true);
                }
  
                this.audioPeersRef = [
                  ...this.audioPeersRef,
                  {
                    socketId: socketId,
                    storageId: storageId,
                    mediaStream: mediaStream,
                    consumer: consumer,
                  },
                ];
  
                if (this.role == "VIEWER") {
                } else {
                  // this.changeOuterSpeaker(this.selectedOuterAudio);
                }
              }
            }
          }
  
          // if (this.recordingRef) {
          //   this.resetVideoRec();
          // }
  
          this.filterdParticipants = this.peerShow.filter(
            (a) => a.userDetails.webinarUserType == this.currentTabSelect
          );
          // this.handleAudioLevel();
          this.manageDiv();
          console.log("----------------", document.getElementById("aud"));
          // console.log("newConsume______r", consumer?.track, screenShare);
          console.log("this.peersRef___________", this.peersRef);
          console.log("this.waitingUser^^^^^^^^^^^^^^^^^^", this.waitingUser);
        } catch (err) {
          console.log("err____err____err______", err);
        }
      },
      async handleBoostVolume(value) {
        const newGainValue = 1 + value / 100;
        this.gainNode.gain.value = newGainValue;
        console.log("newGainValue______", newGainValue);
        console.log("value______", value);
        console.log("gainLevel______", this.gainLevel);
        console.log("gainNode______", this.gainNode);
      },
      async skipToPage() {
        //this.currentPage;
        //this.totalPages;
        let text;
        var numberRegex = /^\d+$/;
        let person = prompt(
          "Please enter your number you want to skip: ",
          this.currentPage + 1
        );
  
        const number = !numberRegex.test(person);
        if (!numberRegex.test(person)) {
          // If the input does not match the regular expression, alert the user.
          alert("Please enter a valid number.");
        } else {
          if (person == null || person == "") {
          } else {
            if (person == 0) {
              alert("Something went wrong");
            } else {
              if (person > this.totalPages) {
                alert("Something went wrong");
              } else {
                //alert("Wow!");
                this.toSpecificPage(parseInt(person));
              }
            }
          }
        }
      },
      async handleAudioLevel() {
        for (let i = 0; i < this.audioElement.length; i++) {
          const element = this.audioElement[i];
          var x = document.getElementById(element);
          console.log("x--", x);
  
          if (x != null) {
            x.volume = this.audioLevel / 10;
          }
        }
        // document.getElementById("myAudio").volume = 0.2;
        console.log("this.audioElement----", this.audioElement);
        console.log("handleAudioLevel----", this.audioLevel);
      },
      async playStream(storageId, socketId) {
        console.log("_____-__-----____", this.audioElementSocket);
        console.log("s_____________", { storageId, socketId });
        let obj = this.audioElementSocket.find((o) => o.socketId == socketId);
        console.log("obj", obj);
  
        if (obj.length != 0) {
          var x = document.getElementById(obj.storageId);
          x.autoplay = true;
          x.play();
  
          if (this.mainScreenStreamRef) {
            if (
              this.mainScreenStreamRef.socketId == socketId &&
              this.mainScreenStreamRef?.screenShare == false
            ) {
              this.mainScreenStreamRef.is_autoplay = true;
            }
          }
          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) => user?.socketId == socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peers[objIndex].is_autoplay = true;
            }
          }
          if (this.peerShow.length > 0) {
            var objIndex = this.peerShow.findIndex(
              (user) => user?.socketId == socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peerShow[objIndex].is_autoplay = true;
            }
          }
        }
        document.getElementById(socketId + "_PEER_PLAY").style.display = "none";
        if (document.getElementById(socketId + "_PEER_IMAGE") != null) {
          document.getElementById(socketId + "_PEER_IMAGE").style.display =
            "flex";
        }
      },
      async addItemToProcess(data) {
        this.data.push({
          data,
        });
        if (!this.processing) {
          this.processing = true;
          this.processItem();
        }
      },
      async processItem() {
        if (this.data.length === 0) {
          this.processing = false;
          return;
        }
        const item = this.data[0];
        const data = await this.startConsumeProducer(item.data);
        console.log(data, "data");
        await this.consume(data);
        this.data.shift();
        this.processItem();
      },
      async joinAsViewer() {
        this.is_disable = true;
        this.role = "VIEWER";
        for (let i = 0; i < this.audioElement.length; i++) {
          const element = this.audioElement[i];
          var x = document.getElementById(element);
          x.autoplay = true;
          x.load();
        }
        $("#DeniedModal").modal("hide");
        $("#enterNameModal").modal("hide");
        $("#joinByViewerNameModal").modal("hide");
        this.joinMeeting();
      },
      async closeMeetingModal() {
        this.$router.push("/login");
      },
      async consume(data) {
        console.log("consume---", data);
        console.log("FFFFFFFFFFF---", this.receiveTransport?.[data?.storageId]);
  
        if (!this.receiveTransport?.[data?.storageId]) {
          console.log("If---");
          this.receiveTransport[data?.storageId] =
            await this.mediasoupDevice?.createRecvTransport(data.data);
  
          this.receiveTransport[data?.storageId].on(
            "connect",
            ({ dtlsParameters }, callback, errback) => {
              this.socket.emit("transportConnect", {
                dtlsParameters,
                storageId: data?.storageId,
              });
              this.socket.on("consumerTransportConnected", (storageId) => {
                console.log("If connect---");
                if (storageId === data?.storageId) {
                  callback();
                }
              });
            }
          );
  
          this.receiveTransport[data?.storageId].on(
            "connectionstatechange",
            (state) => {
              switch (state) {
                case "connecting":
                  console.log("Connecting To Stream!");
                  break;
                case "connected":
                  console.log("subscribed!");
  
                  if (this.mainScreenStreamRef) {
                    if (this.mainScreenStreamRef.docShare == true) {
                      this.socket.emit(
                        "receiveDocumentData",
                        this.mainScreenStreamRef?.socketId
                      );
                    }
                  }
                  break;
                case "failed":
                  console.log("Failed!");
                  this.socket.emit(
                    "consumerRestartIce",
                    data?.storageId,
                    async (params) => {
                      await this.receiveTransport[data?.storageId]?.restartIce({
                        iceParameters: params,
                      });
                    }
                  );
                  break;
                default:
                  break;
              }
            }
          );
        }
        this.socket.emit("startConsuming", {
          rtpCapabilities: this.mediasoupDevice?.rtpCapabilities,
          storageId: data?.storageId,
          producerId: data?.producerId,
          socketId: data?.socketId,
          paused: true,
          webinarId: this.roomId,
          screenShare: data?.screenShare,
          docShare: data?.docShare,
          videoShare: data?.videoShare,
          audioShare: data?.audioShare,
        });
  
        // this.socket.emit("startConsuming", {
        //   roomId: this.roomId,
        //   rtpCapabilities: this.mediasoupDevice?.rtpCapabilities,
        //   storageId: data?.storageId,
        //   producerId: data?.producerId,
        //   socketId: data?.socketId,
        //   paused: false,
        //   screenShare: data?.screenShare,
        // });
      },
      async OpenActionPopup() {
        // this.isActionPopup = true;
      },
      async stopCloudRecording() {
        this.isRecordLoader = true;
  
        const loc = window.location.pathname;
        console.log("starting loc value = " + this.roomId);
        const locs =
          loc.lastIndexOf("/") == loc.length - 1
            ? loc.substring(0, loc.length - 1)
            : loc.substring(0, loc.lastIndexOf("/"));
        const companyname = locs.substring(locs.lastIndexOf("/") + 1);
  
        const hrs = this.hrsApi == 0 ? "" : this.hrsApi + " Hrs ";
        const min = this.minApi == 0 ? "" : this.minApi + " Min ";
        const sec = this.secApi == 0 ? "" : this.secApi + " Sec ";
        var payLoad = {
          title:
            this.room_type == "JOIN_ROOM"
              ? "Unity Webinar - " + this.title + " Recording"
              : "Unity Webinar - Open Room Recording",
          date:
            this.date.toLocaleDateString("en-US") +
            " " +
            this.date.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }),
          webinarId: this.roomId,
          userId: this.isOwner
            ? [JSON.parse(localStorage.getItem("userdetails"))._id]
            : [
                this.ownerUserId,
                JSON.parse(localStorage.getItem("userdetails"))._id,
              ],
          recordingType: this.room_type,
          duration: hrs + min + sec,
          isCloudRec: this.recordType == "Cloud" ? true : false,
        };
        console.log("payLoad-------- ", JSON.stringify(payLoad));
  
        // const stopRecordingApi = await stopRecording(payLoad);
        // this.demoSocket.emit("stopRecording", {
        //   meetingId: this.roomId,
        //   isDev: window.location.host == "unitywebinar.com" ? false : true,
        //   socketId: this.socketId,
        //   isCloudRec: this.recordType == "Cloud" ? true : false,
        //   payLoad: payLoad,
        // });
  
        this.socket.emit("stopRecording", {
          payLoad: payLoad,
          meetingId: this.roomId,
          roomType: this.room_type,
          url: url,
          ownerUserId: this.ownerUserId,
          userId: JSON.parse(localStorage.getItem("userdetails"))._id,
          userId: JSON.parse(localStorage.getItem("userdetails"))._id,
          roomName: window.location.pathname.split("/").pop(),
          socketId: this.socketId,
          isDev: window.location.host == "unitywebinar.com" ? false : true,
          isCloudRec: this.recordType == "Cloud" ? true : false,
        });
  
        // console.log("stopRecording--API------ " + JSON.stringify(stopRecordingApi));
        // console.log("stopRecording--API------ ", stopRecordingApi);
        // console.log("stopRecording--API------ ", stopRecordingApi.status);
  
        // if (this.recordType == 'Local') {
        //   $("#stopLocalRecordingModal").modal("hide");
  
        // } else {
  
        // }
        document.querySelector(".hrs").innerText = "00";
        document.querySelector(".min").innerText = "00";
        document.querySelector(".sec").innerText = "00";
        this.recordingToaster = false;
        // $("#stopRecordingModal").modal("hide");
        // const $toast = useToast();
        // $toast.open({
        //   message: 'Your recording is converting is in progress. please wait a few minutes.',
        //   type: 'info',
        //   dismissible: false,
        //   duration: 30000
        //   // all of other options may go here
        // });
        clearInterval(this.startTimer);
        // if (stopRecordingApi.status == 200) {
        // if (this.recordType == 'Local') {
        //   const a = document.createElement("a");
        //     var aRE = document.getElementById('RECORD' + stopRecordingApi.data.result)
  
        //     console.log("a--1----", aRE); // undefined
        //     a.id = 'RECORD' + stopRecordingApi.data.result;
        //     // a.hidden = true;
        //     a.href =  "https://record.unitywebinar.com:3000/api/users/v1/webinar/file/" + stopRecordingApi.data.result;
        //     a.download = true;
        //     // a.target = '_blank';
        //     a.click();
  
        //     document.body.appendChild(a);
        //   this.isRecordLoader = false;
        //   $("#stopRecordingModal").modal("hide");
        //   console.log("this.isActionForLeave--", this.isActionForLeave); // undefined
  
        //   this.recordType = ''
  
        //   $("#stopLocalRecordingModal").modal("hide");
        // } else {
  
        //   this.isRecordLoader = false;
        //   this.recordType = '';
        //   if (stopRecordingApi.status == 200) {
        //     if (this.isActionForLeave) {
        //       this.isActionForLeave = false;
        //       window.close();
        //       window.location.href = window.origin;
        //     } else {
        //     }
        //   }
        // }
        // }
  
        if (this.room_type == "OPEN_ROOM") {
          var url = `${this.FRONT_BASE_URL}record${window.location.pathname}`;
        } else {
          var url = `${this.FRONT_BASE_URL}record${window.location.pathname}/${window.location.search}`;
        }
  
        this.socket.emit("stopRecordingNotify", {
          roomId: this.roomId,
          username: this.user_name,
          socketId: this.socketId,
        });
  
        $("#stopCloudRecordingModal").modal("hide");
        this.isRecordLoader = false;
        this.recordType = "";
      },
      async startRecording(type) {
        this.recordType = type;
        this.recordStartLoader = true;
        const loc = window.location.pathname;
        console.log("starting loc value = " + loc);
        const locs =
          loc.lastIndexOf("/") == loc.length - 1
            ? loc.substring(0, loc.length - 1)
            : loc.substring(0, loc.lastIndexOf("/"));
        const companyname = locs.substring(locs.lastIndexOf("/") + 1);
  
        if (this.room_type == "OPEN_ROOM") {
          var url = `${this.FRONT_BASE_URL}record${window.location.pathname}`;
        } else {
          var url = `${this.FRONT_BASE_URL}record${window.location.pathname}/${window.location.search}`;
        }
  
        console.log("url----------", url);
  
        // this.demoSocket.emit("startRecording", {
        //   meetingId: this.roomId,
        //   roomType: this.room_type,
        //   url: url,
        //   email: JSON.parse(localStorage.getItem("userdetails")).email,
        //   userId: JSON.parse(localStorage.getItem("userdetails"))._id,
        //   roomName: window.location.pathname.split("/").pop(),
        //   socketId: this.socketId,
        //   isDev: window.location.host == "unitywebinar.com" ? false : true,
        //   isCloudRec: this.recordType == "Cloud" ? true : false,
        // });
  
        var payLoad = {
          meetingId: this.roomId,
          url: url,
        };
  
        this.finalyStartRecord();
        let count = 6;
        const timer = setInterval(() => {
          count--;
          console.log(count);
          this.countDownVal = count;
          if (count === -1) {
            clearInterval(timer);
            this.countDownVal = "";
            this.recordStartLoader = false;
          }
        }, 1000);
  
        console.log("Time's up EEEE!", this.recordStartLoader);
      },
      async finalyStartRecord() {
        this.recordStartLoader = false;
        console.log("Time's up and started record!", this.recordStartLoader);
  
        // const addToRecordings = await startRecording(payLoad);
        this.recordingToaster = true;
        // console.log("addToRecordings = " + addToRecordings);
  
        this.recordingRef = true;
        let hrs = 0;
        let min = 0;
        let sec = 0;
        let ms = 0;
  
        this.startTimer = setInterval(() => {
          sec++; //ms=ms+1;
          if (ms == 1000) {
            sec++;
            ms = 0;
          }
          if (sec == 60) {
            min++;
            sec = 0;
          }
          if (min == 60) {
            hrs++;
            min = 0;
          }
          this.updateDisplay(hrs, min, sec);
        }, 1000);
        this.socket.emit("startRecordingNotify", {
          roomId: this.roomId,
          username: this.user_name,
          recordType: this.recordType,
        });
        this.recordStartLoader = false;
      },
      async stopAskModal() {
        if (this.recordType == "Local") {
          $("#stopLocalRecordingModal").modal("show");
        } else {
          $("#stopCloudRecordingModal").modal("show");
        }
        // $("#stopRecordingModal").modal("show");
      },
      async returnToRoom() {
        $("#stopRecordingModal").modal("hide");
      },
      async handleData(e) {
        // console.log(e);
        try {
          if (e.data?.size === 0) return;
          this.savedChunks.push(e.data);
  
          this.downloadData();
          // if(this.isBrave){
          // }else{
  
          //   if (!this.processingRef) {
          //     this.processingRef = true;
          //     this.processChunks();
          //   }
          // }
          // if (this.isBrave) {
          //   // this.downloadData();
          // } else {
          // }
        } catch (error) {
          $("#errorModal").modal("show");
          this.running_error = error;
        }
        // chunksRef.current?.push(e.data)
        // if (!processingRef.current) {
        //   processingRef.current = true
        //   processChunks()
  
        // }
      },
      async sendStrMessage(message) {
        const size = new Blob([message]).size;
        const limit = 200 * 1024;
        if (this.dataProducer?.bufferedAmount + size >= limit) {
          await new Promise((resolve, reject) => {
            this.dataProducer?.on("bufferedamountlow", async () => {
              await this.dataProducer?.send(message);
              resolve();
            });
          });
        } else {
          await this.dataProducer?.send(message);
        }
      },
      async updateDisplay(hrs, min, sec) {
        const phrs = hrs < 10 ? "0" + hrs : hrs;
        const pmin = min < 10 ? "0" + min : min;
        const psec = sec < 10 ? "0" + sec : sec;
        //Output
        this.hrs = phrs;
        this.min = pmin;
        this.sec = psec;
  
        const phrsApi = hrs < 10 ? "" + hrs : hrs;
        const pminApi = min < 10 ? "" + min : min;
        const psecApi = sec < 10 ? "" + sec : sec;
        this.hrsApi = phrsApi;
        this.minApi = pminApi;
        this.secApi = psecApi;
      },
      async openNav() {
        console.log("***********openNav************");
      },
      async goLive() {
        let body = {
          id: this.webinarId,
          childID: this.childId ?? undefined,
        };
        body = await liveWebinar(body);
        if (body.status == 200) {
          this.goLiveStatus = false;
        }
  
        // goLiveStatus
      },
      async stopImportData() {
        const producerIds = this.audioProducerId2
          ? [this.videoProducerId2, this.audioProducerId2]
          : [this.videoProducerId2];
        this.socket.emit("closeScreenShare", producerIds, async (response) => {
          if (this.audioProducer3) {
            await this.audioProducer3?.close();
            this.audioProducer3 = null;
            this.audioProducerId2 = null;
          }
          if (this.videoProducer3) {
            await this.videoProducer3?.close();
            this.videoProducer3 = null;
            this.videoProducerId2 = null;
          }
          // this.localScreenStreamRef?.getTracks()?.forEach((track) => {
          //   track.stop();
          // });
          // this.mainScreenStreamRef = null
          console.log("_______R A J______", this.peers);
          console.log("_______this.socket.id______", this.socket.id);
          const peerFilter = this.peers.filter(
            (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
          );
          const peerSData = this.peerShow.filter(
            (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
          );
          this.peers = peerFilter;
          this.peerShow = peerSData;
          console.log("_______peerFilter______", peerFilter);
          console.log("_______peerSData______", peerSData);
          if (this.mainScreenStreamRef) {
            if (this.mainScreenStreamRef.screenShare == true) {
              document.getElementById("Screen").style.display = "none";
              document.getElementById("ALL_VIDEOS").style.display = "flex";
              this.mainScreenStreamRef = null;
            }
          }
          this.manageDiv();
        });
  
        // if (item) {
        //   this.importData(item);
        // }
        this.videoShared = false;
      },
      async stopDocumentsData() {
        this.srcList = [];
        console.log("******************", this.docShared);
  
        const producerIds = this.audioProducerId2
          ? [this.videoProducerId2, this.audioProducerId2]
          : [this.videoProducerId2];
        this.socket.emit("closeScreenShare", producerIds, async (response) => {
          if (this.audioProducer3) {
            await this.audioProducer3?.close();
            this.audioProducer3 = null;
            this.audioProducerId2 = null;
          }
          if (this.videoProducer3) {
            await this.videoProducer3?.close();
            this.videoProducer3 = null;
            this.videoProducerId2 = null;
          }
          // this.localScreenStreamRef?.getTracks()?.forEach((track) => {
          //   track.stop();
          // });
          // this.mainScreenStreamRef = null
          console.log("_______R A J______", this.peers);
          console.log("_______this.socket.id______", this.socket.id);
          const peerFilter = this.peers.filter(
            (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
          );
          const peerSData = this.peerShow.filter(
            (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
          );
          this.peers = peerFilter;
          this.peerShow = peerSData;
          console.log("_______peerFilter______", peerFilter);
          console.log("_______peerSData______", peerSData);
          if (this.mainScreenStreamRef) {
            if (this.mainScreenStreamRef.screenShare == true) {
              document.getElementById("Screen").style.display = "none";
              this.mainScreenStreamRef = null;
            }
          }
          this.docShared = false;
  
          this.manageDiv();
        });
      },
      async stopPlayConsumerAudioShare() {
        console.log("source:::::::::::::::::::4", this.source);
        // this.source.disconnect();
        this.gainNode.disconnect();
        // this.audioCtx.close()
        console.log("source:DDD::::::::::::::::::4", this.source);
        console.log("gainNode:::::::::::::::::::4", this.gainNode);
        console.log("audioCtx:::::::::::::::::::4", this.audioCtx);
      },
      async playConsumerAudioShare(streama) {
        const audioCtx = new AudioContext();
  
        // Create a source node from the audio element
        const source = audioCtx.createMediaStreamSource(streama);
  
        // Create a gain node to control volume
        this.gainNode = audioCtx.createGain();
  
        // Connect the audio path
        source.connect(this.gainNode);
  
        const destination = audioCtx.createMediaStreamDestination();
        this.gainNode.connect(destination);
        console.log("AUD:::::::4", document.getElementById("aud"));
  
        // document.getElementById('aud').srcObject = destination.stream
      },
      async stopImportAudioData() {
        this.audioShared = false;
  
        console.log("******************", this.audioShared);
  
        const producerIds = this.audioProducerId2
          ? [this.videoProducerId2, this.audioProducerId2]
          : [this.videoProducerId2];
        this.socket.emit("closeScreenShare", producerIds, async (response) => {
          if (this.audioProducer3) {
            await this.audioProducer3?.close();
            this.audioProducer3 = null;
            this.audioProducerId2 = null;
          }
          if (this.videoProducer3) {
            await this.videoProducer3?.close();
            this.videoProducer3 = null;
            this.videoProducerId2 = null;
          }
          // this.localScreenStreamRef?.getTracks()?.forEach((track) => {
          //   track.stop();
          // });
          // this.mainScreenStreamRef = null
          console.log("_______R A J______", this.peers);
          console.log("_______this.socket.id______", this.socket.id);
          const peerFilter = this.peers.filter(
            (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
          );
          const peerSData = this.peerShow.filter(
            (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
          );
          this.peers = peerFilter;
          this.peerShow = peerSData;
          console.log("_______peerFilter______", peerFilter);
          console.log("_______peerSData______", peerSData);
          if (this.mainScreenStreamRef) {
            if (this.mainScreenStreamRef.screenShare == true) {
              document.getElementById("Screen").style.display = "none";
              this.mainScreenStreamRef = null;
            }
          }
          this.manageDiv();
        });
      },
      async ScreenShare() {
        console.log("__", this.mainScreenStreamRef);
        console.log("***********************");
  
        if (this.screenShareStarted) {
          const producerIds = this.audioProducerId2
            ? [this.videoProducerId2, this.audioProducerId2]
            : [this.videoProducerId2];
          this.socket.emit("closeScreenShare", producerIds, async (response) => {
            if (this.audioProducer2) {
              await this.audioProducer2?.close();
              this.audioProducer2 = null;
              this.audioProducerId2 = null;
            }
            if (this.videoProducer2) {
              await this.videoProducer2?.close();
              this.videoProducer2 = null;
              this.videoProducerId2 = null;
            }
            this.localScreenStreamRef?.getTracks()?.forEach((track) => {
              track.stop();
            });
            // this.mainScreenStreamRef = null
            console.log("_______R A J______", this.peers);
            console.log("_______this.socket.id______", this.socket.id);
            const peerFilter = this.peers.filter(
              (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
            );
            const peerSData = this.peerShow.filter(
              (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
            );
            this.peers = peerFilter;
            this.peerShow = peerSData;
            console.log("_______peerFilter______", peerFilter);
            console.log("_______peerSData______", peerSData);
            if (this.mainScreenStreamRef) {
              if (this.mainScreenStreamRef.screenShare == true) {
                document.getElementById("Screen").style.display = "none";
                this.mainScreenStreamRef = null;
              }
            }
            this.manageDiv();
          });
  
          this.screenShareStarted = false;
        } else {
          const checkUsername = (obj) => obj.screenShare == true;
  
          if (
            (this.mainScreenStreamRef != null &&
              this.mainScreenStreamRef.remoteScreen == true) ||
            this.peers.some(checkUsername) == true
          ) {
            swal({
              text: "This will let you take over from as ther main presenter.",
              buttons: true,
              dangerMode: true,
            }).then(async (willDelete) => {
              if (willDelete) {
                let obj = this.peers.find((o) => o.screenShare == true);
  
                if (this.mainScreenStreamRef != null) {
                  if (this.mainScreenStreamRef.audioShare == true) {
                    this.socket.emit(
                      "stopAudioScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else if (
                    this.mainScreenStreamRef.videoShare == true &&
                    this.mainScreenStreamRef.docShare
                  ) {
                    this.socket.emit(
                      "stopDocScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else if (
                    this.mainScreenStreamRef.videoShare == true &&
                    !this.mainScreenStreamRef.docShare
                  ) {
                    this.socket.emit(
                      "stopVideoScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  } else {
                    this.socket.emit(
                      "stopShareScreen",
                      this.mainScreenStreamRef.socketId
                    );
                  }
                } else if (obj) {
                  this.socket.emit("stopShareScreen", obj.socketId);
                  console.log("___obj----", obj);
                  console.log("___obj.storageId----", obj.storageId);
                  if (
                    document.getElementById(
                      obj.storageId + "_STREAM_VIDEO_DIV"
                    ) != null
                  ) {
                    this.peers.push({ is_show: true, ...obj });
                    // document.getElementById(obj.storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
                  }
                } else {
                  console.log(
                    "this.mainScreenStreamRef----",
                    this.mainScreenStreamRef
                  );
                  console.log(
                    "this.mainScreenStreamRef.storageId----",
                    this.mainScreenStreamRef.storageId
                  );
                  this.socket.emit(
                    "stopShareScreen",
                    this.mainScreenStreamRef.socketId
                  );
                  this.peers.push({ is_show: true, ...this.mainScreenStreamRef });
                  if (
                    document.getElementById(
                      this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                    ) != null
                  ) {
                    // document.getElementById(this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
                  }
  
                  console.log("socketId----", this.mainScreenStreamRef.socketId);
                }
  
                const localScreenStream =
                  await navigator?.mediaDevices?.getDisplayMedia({
                    audio: {
                      noiseSuppression: true,
                      echoCancellation: true,
                    },
                    video: {
                      width: { max: 1920 },
                      height: { max: 1200 },
                      frameRate: { ideal: 30 },
                    },
                  });
                this.localScreenStreamRef = localScreenStream;
                // if (!this.localScreenStreamRef) return;
  
                // this.$refs.localScreenStreamElemRef.srcObject = this.localScreenStreamRef
                const Audiotracks = this.localScreenStreamRef?.getAudioTracks()[0];
                const Videotracks = this.localScreenStreamRef.getVideoTracks()[0];
                if (Audiotracks) {
                  this.audioProducer2 = await this.produceTransport?.produce({
                    track: Audiotracks,
                    appData: {
                      type: "screen",
                    },
                  });
                }
                if (Videotracks) {
                  this.videoProducer2 = await this.produceTransport?.produce({
                    track: Videotracks,
                    appData: {
                      type: "screen",
                    },
                  });
                }
                if (this.mainScreenStreamRef == null) {
                  document.getElementById("Screen").style.display = "flex";
                  this.screenShareLoader = true;
                }
                if (this.mainScreenStreamRef) {
                  this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                  document.getElementById("ALL_VIDEOS").style.display = "flex";
                }
                this.mainScreenStreamRef = {
                  storageId: this.socket.id + "SCREEN",
                  mediaStream: localScreenStream,
                  remoteScreen: false,
                  screenShare: true,
                  socketId: this.socket.id,
                  userDetails: {
                    user_name: this.user_name,
                    profileImg: this.profileImg,
                  },
                };
                this.localScreenStreamRef
                  .getVideoTracks()[0]
                  ?.addEventListener("ended", () => this.ScreenShare());
                this.manageDiv();
                this.screenShareStarted = true;
                this.screenShareLoader = false;
              }
            });
          } else {
            const localScreenStream =
              await navigator?.mediaDevices?.getDisplayMedia({
                audio: {
                  noiseSuppression: true,
                  echoCancellation: true,
                },
                video: {
                  width: { max: 1920 },
                  height: { max: 1200 },
                  frameRate: { ideal: 30 },
                },
              });
            this.localScreenStreamRef = localScreenStream;
            // if (!this.localScreenStreamRef) return;
            // this.$refs.localScreenStreamElemRef.srcObject = this.localScreenStreamRef
            const Audiotracks = this.localScreenStreamRef?.getAudioTracks()[0];
            const Videotracks = this.localScreenStreamRef.getVideoTracks()[0];
            if (Audiotracks) {
              this.audioProducer2 = await this.produceTransport?.produce({
                track: Audiotracks,
                appData: {
                  type: "screen",
                },
              });
            }
            if (Videotracks) {
              this.videoProducer2 = await this.produceTransport?.produce({
                track: Videotracks,
                appData: {
                  type: "screen",
                },
              });
            }
            if (this.mainScreenStreamRef == null) {
              document.getElementById("Screen").style.display = "flex";
              this.screenShareLoader = true;
            }
            if (this.mainScreenStreamRef) {
              this.updateIsShow(this.mainScreenStreamRef.storageId, true);
              document.getElementById("ALL_VIDEOS").style.display = "flex";
            }
            this.mainScreenStreamRef = {
              storageId: this.socket.id + "SCREEN",
              mediaStream: localScreenStream,
              remoteScreen: false,
              screenShare: true,
              socketId: this.socket.id,
              userDetails: {
                user_name: this.user_name,
                profileImg: this.profileImg,
              },
            };
  
            this.localScreenStreamRef
              .getVideoTracks()[0]
              ?.addEventListener("ended", () => this.ScreenShare());
            this.screenShareStarted = true;
            this.screenShareLoader = false;
            // this.manageDiv()
          }
        }
      },
      async handleMic(state) {
        const stateVar = state ? state : this.currentMicState;
        console.log("this.currentMicState-----------", this.currentMicState);
  
        try {
          let userAgentString = navigator.userAgent;
          let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
  
          this.socket.emit(
            "handleProducer",
            {
              producerId: this.audioProducerId,
              state: stateVar,
            },
            async (response) => {
              if (this.currentMicState) {
                await this.audioProducer.pause();
                this.currentMicState = false;
                this.audio = false;
              } else {
                await this.audioProducer.resume();
                this.currentMicState = true;
                this.audio = true;
              }
            }
          );
        } catch (error) {
          if (error.name == "NotAllowedError") {
            this.is_mic_allow = false;
            $("#microphoneDeniedModal").modal("show");
          } else {
            this.is_mic_allow = false;
            $("#errorModal").modal("show");
            this.running_error = error;
          }
        }
      },
      async handleVideo(state) {
        const stateVar = state ? state : this.currentVideoState;
        console.log("this.currentVideoState-----------", this.currentVideoState);
  
        try {
          if (stateVar) {
            console.log("Video permissions:");
  
            this.socket.emit(
              "closeProducer",
              this.videoProducerId,
              async (response) => {
                await this.videoProducer?.close();
                this.videoProducer = null;
                this.videoProducerId = null;
  
                // this.localStreamRef?.getTracks()?.forEach((track) => {
                //   track?.stop();
                // });
                // this.localStreamRef?.getVideoTracks()?.forEach((track) => {
                //   track?.stop();
                //   this.localStreamRef?.removeTrack(track);
                // });
              }
            );
            //   this.socket.emit('handleProducer', {
            //   producerId: this.videoProducerId,
            //   state: true
            // }, async (response) => {
            //     await this.videoProducer.pause()
            // console.log('this.videoProducer==============',this.videoProducer);
            // })
            console.log("closeProducer-----------", this.localStreamRef);
            const tracks1 = this.localStreamRef.getTracks();
            console.log("tracks-----------", tracks1);
            this.localStreamRef?.getVideoTracks()?.forEach((track) => {
              track?.stop();
            });
            if (this.peerShow.length > 0) {
              var objIndex = this.peerShow.findIndex(
                (user) =>
                  user?.socketId == this.socket.id && user?.screenShare == false
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                const source = this.peerShow.find(
                  (user) =>
                    user?.socketId == this.socket.id && user?.screenShare == false
                );
                //console.log("objIndex______", objIndex);
                if (source.mediaStream) {
                  source.mediaStream.getVideoTracks().forEach(function (track) {
                    console.log("PS :)  ", track.stop());
                    track.stop();
                  });
                }
                this.peerShow[objIndex].mediaStream = null;
                this.peerShow[objIndex].mutedVideo = true;
              }
            }
            this.currentVideoState = false;
            const socketId = this.socket.id;
  
            if (this.mainScreenStreamRef) {
              if (
                this.mainScreenStreamRef.socketId == socketId &&
                this.mainScreenStreamRef.screenShare == false
              ) {
                //console.log("objIndex______", objIndex);
                if (this.mainScreenStreamRef.mediaStream) {
                  this.mainScreenStreamRef.mediaStream
                    .getVideoTracks()
                    .forEach(function (track) {
                      console.log("MS :)  ", track.stop());
                      track.stop();
                    });
                }
                this.mainScreenStreamRef.mediaStream = null;
                this.mainScreenStreamRef.mutedVideo = true;
              }
            }
  
            if (this.peers.length > 0) {
              const idx = this.peers?.findIndex(
                (peer) =>
                  peer?.socketId === socketId && peer?.screenShare === false
              );
  
              if (idx !== -1) {
                const source = this.peers.find(
                  (user) =>
                    user?.socketId == this.socket.id && user?.screenShare == false
                );
                //console.log("objIndex______", objIndex);
                if (source.mediaStream) {
                  source.mediaStream.getVideoTracks().forEach(function (track) {
                    console.log("P :)  ", track.stop());
                    track.stop();
                  });
                }
                this.peers[idx].mediaStream = null;
                this.peers[idx].storageId = "LocalStream_storageId";
                this.peers[idx].mutedVideo = true;
              }
            }
  
            if (this.peersRef.length > 0) {
              const idx1 = this.peersRef?.findIndex(
                (peer) =>
                  peer?.socketId === socketId && peer?.screenShare === false
              );
              if (idx1 !== -1) {
                const source = this.peersRef.find(
                  (user) =>
                    user?.socketId == this.socket.id && user?.screenShare == false
                );
                //console.log("objIndex______", objIndex);
                if (source.mediaStream) {
                  source.mediaStream.getVideoTracks().forEach(function (track) {
                    track.stop();
                  });
                }
                this.peersRef[idx1].mediaStream = null;
                this.peersRef[idx1].storageId = "LocalStream_storageId";
                this.peersRef[idx1].mutedVideo = true;
              }
            }
            this.socket.emit("closeCamera", {
              roomId: this.roomId,
              socketId: this.socket.id,
              status: true,
            });
  
            console.log({
              roomId: this.roomId,
              socketId: this.socket.id,
              status: false,
            });
            this.video = false;
            // if (this.recordingRef) {
            //   this.resetVideoRec();
            // }
            // videoElem.current.innerHTML = "Video On";
          } else {
            console.log("Video permissions:");
            let userAgentString = navigator.userAgent;
            let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
  
            if (this.selectedVideo != undefined) {
              const localStreamRef = await navigator?.mediaDevices?.getUserMedia({
                video: {
                  deviceId: { exact: this.selectedVideo },
                },
              });
  
              this.localStreamRef = localStreamRef;
            } else {
              const localStreamRef = await navigator?.mediaDevices?.getUserMedia({
                video: true,
              });
              const videoTracks = localStreamRef.getVideoTracks();
              if (videoTracks.length > 0) {
                this.is_cam_allow = true;
  
                const videoDeviceId = videoTracks[0].getSettings().deviceId;
                this.selectedVideo = videoDeviceId;
                console.log("Video device ID:", videoDeviceId);
              } else {
                console.log("No video tracks found in the stream.");
              }
  
              this.localStreamRef = localStreamRef;
            }
  
            this.videoProducer = await this.produceTransport?.produce({
              track: this.localStreamRef.getVideoTracks()[0],
              encodings: [
                {
                  rid: "r0",
                  scaleResolutionDownBy: 4,
                  maxBitrate: 500000,
                  scalabilityMode: "L1T3",
                },
                {
                  rid: "r1",
                  scaleResolutionDownBy: 2,
                  maxBitrate: 750000,
                  scalabilityMode: "L1T3",
                },
                {
                  rid: "r2",
                  scaleResolutionDownBy: 1,
                  maxBitrate: 1000000,
                  scalabilityMode: "L1T3",
                },
              ],
            });
  
            const socketId = this.socket.id;
  
            if (this.mainScreenStreamRef) {
              if (
                this.mainScreenStreamRef.socketId == socketId &&
                this.mainScreenStreamRef.screenShare == false
              ) {
                this.mainScreenStreamRef.mediaStream = this.localStreamRef;
                this.mainScreenStreamRef.mutedVideo = false;
              }
            }
            const idx = this.peers?.findIndex(
              (peer) => peer?.socketId === socketId && peer?.screenShare === false
            );
  
            if (idx !== -1) {
              this.peers[idx].mediaStream = this.localStreamRef;
              this.peers[idx].storageId = "LocalStream_storageId";
              this.peers[idx].mutedVideo = false;
            }
            const idx1 = this.peerShow?.findIndex(
              (peer) => peer?.socketId === socketId && peer?.screenShare === false
            );
  
            if (idx1 !== -1) {
              this.peerShow[idx1].mediaStream = this.localStreamRef;
              this.peerShow[idx1].storageId = "LocalStream_storageId";
              this.peerShow[idx1].mutedVideo = false;
            }
            this.socket.emit("closeCamera", {
              roomId: this.roomId,
              socketId: this.socket.id,
              status: false,
            });
            // if (this.recordingRef) {
            //   this.resetVideoRec();
            // }
            this.video = true;
            this.currentVideoState = true;
          }
        } catch (error) {
          if (error.name == "NotAllowedError") {
            $("#cameraDeniedModal").modal("show");
          } else if (error.name == "NotReadableError") {
            $("#NotReadableErrorIn").modal("show");
          } else if (error.name == "NotFoundError") {
            this.stremErr = "NotFoundError";
            $("#DeniedModalCamera").modal("show");
          } else {
            this.is_cam_allow = false;
            $("#errorModal").modal("show");
            this.running_error = error;
          }
          console.log("error.name", error.name + ": " + error.message);
  
          console.log(error);
        }
      },
      async handleLeave() {
        if (this.recordingToaster) {
          if (this.recordType == "Local") {
            $("#LeaveLocalRecordingModal").modal("show");
          } else {
            this.stopCloudRecording();
            this.isActionForLeave = true;
          }
        } else {
          window.close();
          window.location.href = window.origin;
        }
      },
      async leaveRoom() {
        window.close();
        window.location.href = window.origin;
      },
      async leaveWithStopLocalRec() {
        if (this.isActionForLeave) {
          window.close();
          window.location.href = window.origin;
        } else {
          this.stopCloudRecording();
          this.isActionForLeave = true;
        }
      },
      async microphoneDeniedModal() {
        $("#microphoneDeniedModal").modal("hide");
      },
      async closeerrorModalModal() {
        $("#closeerrorModalModal").modal("hide");
      },
      async DismissModal(name) {
        if (name == "DeniedModal") {
          this.is_disable = true;
          this.role = "VIEWER";
        }
  
        if (name == "LeaveLocalRecordingModal") {
          this.isActionForLeave = false;
        }
        this.isRecordLoader = false;
        $("#" + name).modal("hide");
      },
      async cameraDeniedModal() {
        $("#cameraDeniedModal").modal("hide");
      },
      async DeniedModal() {
        window.location.reload();
        // $("#DeniedModal").modal("hide");
      },
      async closeDeniedModal() {
        window.location.reload();
      },
      async gotToLogin() {
        this.$router.push("/login");
      },
      async closeNotReadableErrorModal() {
        window.location.reload();
        $("#NotReadableError").modal("hide");
      },
      async closeTypeErrorModal() {
        document.getElementById("BtnTypeError").innerHTML = "Please wait..";
        window.location.reload();
        $("#TypeError").modal("hide");
      },
      async removeUser(socketId) {
        this.socket.emit("removeUser", socketId);
      },
      async renameSubmitUser(socketId) {
        console.log("___socketId_", socketId);
        if (this.edit_name != "") {
          this.socket.emit("renameUser", {
            name: this.edit_name,
            socketId: socketId,
            roomId: this.roomId,
          });
  
          this.edit_socketId = "";
          this.edit_name = "";
          $("#RenamePopup").modal("hide");
        } else {
          this.edit_name_error = "Please enter new name.";
          this.edit_join_btn = true;
        }
      },
      async changeRoleModal(peer, socketId, webinarUserType) {
        console.log(":::::::::::::==::::::::", peer, socketId, webinarUserType);
  
        this.changeRollData = peer;
        // this.selectedRoll = peer.webinarUserType;
  
        if (peer.webinarUserType == "HOST") {
          this.selectedRoll = "MODERATOR";
        } else if (peer.webinarUserType == "MODERATOR") {
          this.selectedRoll = "GUEST";
        } else if (peer.webinarUserType == "GUEST" && this.role == "MODERATOR") {
          this.selectedRoll = "MODERATOR";
        } else if (peer.webinarUserType == "VIEWER") {
          this.selectedRoll = "HOST";
        } else {
          this.selectedRoll = "HOST";
        }
        $("#changeRoleModal").modal("show");
      },
      async saveRoleForUser(peer) {
        var json = {
          email: this.changeRollData.email,
          webinarId: this.webinarId,
          webinarUserType:
            this.selectedRoll == "HOST"
              ? "Host"
              : this.selectedRoll == "MODERATOR"
              ? "Moderator"
              : this.selectedRoll == "GUEST"
              ? "Guest"
              : "Viewer",
        };
        console.log("____json_", json);
        await updateWebinarType(json);
  
        this.socket.emit("roleChanged", {
          currrentRoll: this.changeRollData.webinarUserType,
          mySocketId: this.changeRollData.socketId,
          webinarUserType: this.selectedRoll,
          socketId: this.changeRollData.socketId,
          roomId: this.roomId,
          lockRoom: this.lockRoom,
        });
        $("#changeRoleModal").modal("hide");
      },
      async changeRole(peer, socketId, webinarUserType) {
        console.log("_____2___", peer, socketId);
  
        var json = {
          email: peer.email,
          webinarId: this.webinarId,
          webinarUserType: webinarUserType,
        };
        console.log("____json_", json);
        await updateWebinarType(json);
  
        this.socket.emit("roleChanged", {
          webinarUserType: webinarUserType,
          socketId: socketId,
          roomId: this.roomId,
        });
  
        var webinarUseType = webinarUserType == "Host" ? "HOST" : "MODERATOR";
        // this.changeRoleOnPeers(socketId, webinarUseType);
      },
      async changeRoleOnPeers(socketId, webinarUserType) {
        if (this.peers.length > 0) {
          var objIndex = this.peers.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peers[objIndex].userDetails.webinarUserType = webinarUserType;
          }
          //Update object's name property.
          var objIndexScreen = this.peers.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == true
          );
          if (objIndexScreen >= 0) {
            console.log("objIndexScreen______", objIndexScreen);
            this.peers[objIndexScreen].userDetails.webinarUserType =
              webinarUserType;
          }
        }
  
        if (this.peerShow.length > 0) {
          var objIndex = this.peerShow.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peerShow[objIndex].userDetails.webinarUserType = webinarUserType;
          }
          //Update object's name property.
          var objIndexScreen = this.peerShow.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == true
          );
          if (objIndexScreen >= 0) {
            console.log("objIndexScreen______", objIndexScreen);
            this.peerShow[objIndexScreen].userDetails.webinarUserType =
              webinarUserType;
          }
        }
  
        if (this.mainScreenStreamRef) {
          console.log("changes ______", this.mainScreenStreamRef);
          if (this.mainScreenStreamRef.socketId == socketId) {
            this.mainScreenStreamRef.userDetails.webinarUserType =
              webinarUserType;
            console.log("changes DONE______", this.mainScreenStreamRef);
          }
        }
  
        if (this.peerShow.length != 0) {
          this.filterdParticipants = this.peers.filter(
            (a) => a.userDetails.webinarUserType == this.currentTabSelect
          );
        }
        console.log("peers----", this.peers);
        console.log("peerShow----", this.peerShow);
      },
      async renameUser(peer, socketId) {
        console.log("_____2___", peer, socketId);
        console.log("___this.edit_socketId_____", this.edit_socketId);
  
        if (socketId == this.socket.id) {
          this.edit_name = this.user_name;
          this.edit_socketId = this.socket.id;
        } else {
          this.edit_socketId = socketId;
          this.edit_name = peer.userDetails
            ? peer.userDetails.user_name
            : peer.user_name;
        }
  
        console.log("___3_____", peer, socketId);
        $("#RenamePopup").modal("show");
      },
      async closeRenameModal() {
        this.edit_socketId = "";
        this.edit_name = "";
        $("#RenamePopup").modal("hide");
      },
      async closeEditMessageModal() {
        this.editedMessage = "";
        this.editMessageId = "";
        this.editMessageUser = "";
        $("#editMessagePopup").modal("hide");
      },
      async clickToMiniMiseStream(peer) {
        // console.log("__", peer);
        // console.log("this.peerShow", this.peerShow);
  
        if (this.mainScreenStreamRef) {
          if (this.mainScreenStreamRef.screenShare == true) {
            if (this.peers.length > 0) {
              var objIndex = this.peers.findIndex(
                (user) => user?.storageId == this.mainScreenStreamRef.storageId
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                // this.peers[objIndex].is_show = false;
              } else {
                this.peers.push({ is_show: true, ...this.mainScreenStreamRef });
                // this.peerShow.push({ is_show: true, ...this.mainScreenStreamRef })
              }
            }
            if (this.peerShow.length > 0) {
              var objIndex1 = this.peerShow.findIndex(
                (user) => user?.storageId == this.mainScreenStreamRef.storageId
              );
  
              //console.log("objIndex______", objIndex1);
              if (objIndex1 >= 0) {
                //console.log("objIndex______", objIndex1);
                // this.peers[objIndex].is_show = false;
              } else {
                // this.peers.push({ is_show: true, ...this.mainScreenStreamRef })
                this.peerShow.push({
                  is_show: true,
                  ...this.mainScreenStreamRef,
                });
              }
            }
          }
        }
        this.updateIsShow(this.mainScreenStreamRef.storageId, true);
  
        // this.mainScreenStreamRef = {...peer, mediaStream: video.srcObject};
  
        if (peer.screenShare == false) {
          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) =>
                user?.socketId == peer.socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peers[objIndex].mediaStream =
                this.mainScreenStreamRef.mediaStream;
            }
          }
          if (this.peerShow.length > 0) {
            var objIndex = this.peerShow.findIndex(
              (user) =>
                user?.socketId == peer.socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peerShow[objIndex].mediaStream =
                this.mainScreenStreamRef.mediaStream;
            }
          }
          if (this.peersRef.length > 0) {
            var objIndex = this.peersRef.findIndex(
              (user) =>
                user?.socketId == peer.socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peersRef[objIndex].mediaStream =
                this.mainScreenStreamRef.mediaStream;
            }
          }
        }
        this.mainScreenStreamRef = null;
  
        // const peerFilter = this.peers.filter((peerData) => peerData?.is_show == true)
        // this.peerShow = peerFilter;
        // console.log("this.peerShow", this.peerShow);
  
        this.manageDiv();
        document.getElementById("Screen").style.display = "none";
  
        if (peer.screenShare == false) {
          if (peer.mutedVideo == true && peer.screenShare == false) {
            console.log("IFFFFFF", peer.socketId);
            // document.getElementById(peer.socketId + "STREAM_SRC") != null ? document.getElementById(peer.socketId + "STREAM_SRC").style.zIndex = "9" : '';
            // document.getElementById(peer.socketId + "_PEER_IMAGE") != null ? document.getElementById(peer.socketId + "_PEER_IMAGE").style.zIndex = "10" : '';
          } else {
            console.log("ELSEEEEEEE", peer.socketId);
  
            // document.getElementById(peer.socketId + "STREAM_SRC") != null ? document.getElementById(peer.socketId + "STREAM_SRC").style.zIndex = "10" : '';
            // document.getElementById(peer.socketId + "_PEER_IMAGE") != null ? document.getElementById(peer.socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
          }
        }
      },
      async clickToBigStream(peer) {
        this.manageDiv();
  
        if (peer.screenShare == true) {
          var video = document.getElementById(peer.socketId + "STREAM_SCR_SRC");
        } else {
          var video = document.getElementById(peer.socketId + "STREAM_SRC");
        }
        // console.log('video.srcObject----------', video.srcObject);
        //console.log("__this.mainScreenStreamRef_____", this.mainScreenStreamRef);
        //console.log("__", peer);
        if ($("#" + peer.storageId + "_VIDEO").css("visibility") == "hidden") {
          var visible = true;
          document.getElementById("Screen").style.background = "#black";
  
          // if (document.getElementById(peer.storageId + "_SCREEN_VIDEO") != null) {
          //   document.getElementById(peer.storageId + "_SCREEN_VIDEO").style.display = "none";
          // }
        } else {
          document.getElementById("Screen").style.background = "black";
          var visible = false;
          // if (document.getElementById(peer.storageId + "_SCREEN_VIDEO") != null) {
          //   document.getElementById(peer.storageId + "_SCREEN_VIDEO").style.display = "none";
          // }
        }
  
        if (
          document.getElementById(
            "localStream" + this.socket.id + "_STREAM_SCREEN_VIDEO"
          ) != null
        ) {
          document.getElementById(
            "localStream" + this.socket.id + "_STREAM_SCREEN_VIDEO"
          ).style.visibility = "visible";
        }
  
        if (this.mainScreenStreamRef) {
          if (visible == false) {
            if (
              document.getElementById(
                this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
              ) != null
            ) {
              document.getElementById(
                this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
              ).style.display = "flex";
            }
            // console.log("_____B____245____");
          } else {
            if (
              document.getElementById(
                this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
              ) != null
            ) {
              document.getElementById(
                this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
              ).style.display = "none";
            }
          }
          if (this.mainScreenStreamRef.screenShare == true) {
            if (this.peers.length > 0) {
              var objIndex = this.peers.findIndex(
                (user) => user?.storageId == this.mainScreenStreamRef.storageId
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                // this.peers[objIndex].is_show = false;
              } else {
                this.peers.push({ is_show: true, ...this.mainScreenStreamRef });
              }
            }
            if (this.peerShow.length > 0) {
              var objIndex1 = this.peerShow.findIndex(
                (user) => user?.storageId == this.mainScreenStreamRef.storageId
              );
  
              //console.log("objIndex______", objIndex1);
              if (objIndex1 >= 0) {
                //console.log("objIndex______", objIndex1);
                // this.peerShow[objIndex].is_show = false;
              } else {
                this.peerShow.push({
                  is_show: true,
                  ...this.mainScreenStreamRef,
                });
              }
            }
          }
          if (
            document.getElementById(
              this.mainScreenStreamRef.storageId + "_STREAM_SCREEN_VIDEO"
            ) != null
          ) {
            document.getElementById(
              this.mainScreenStreamRef.storageId + "_STREAM_SCREEN_VIDEO"
            ).style.visibility = "visible";
          }
          this.updateIsShow(this.mainScreenStreamRef.storageId, true);
          this.updateIsShow(peer.storageId, false);
  
          //console.log("AHIYA AAVYU 1");
        } else {
          if (document.getElementById(peer.storageId + "_SCREEN_VIDEO") != null) {
            document.getElementById(
              peer.storageId + "_SCREEN_VIDEO"
            ).style.display = "none";
          }
          //console.log("AHIYA AAVYU 2");
          if (
            document.getElementById(peer.storageId + "_STREAM_VIDEO_DIV") != null
          ) {
            //console.log("AHIYA AAVYU 3222");
            // document.getElementById(peer.storageId + "_STREAM_VIDEO_DIV").style.display = "none";
          }
        }
  
        this.updateIsShow(peer.storageId, false);
  
        // video.srcObject = mediaStream;
        this.mainScreenStreamRef = peer;
  
        if (
          document.getElementById(peer.storageId + "_STREAM_SCREEN_VIDEO") != null
        ) {
          //console.log("AHIYA AAVYU 33333222fff");
          console.log(" exist 2", $("#Screen").css("display"));
        } else {
          //console.log("AHIYA AAVYU 4rrr");
          document.getElementById("Screen").style.display = "flex";
          if (
            document.getElementById(peer.storageId + "_STREAM_VIDEO_DIV") != null
          ) {
            //console.log("AHIYA AAVYU 3rrrr");
          }
          //console.log(" offline slash does not exist! ", $("#Screen").css("display"));
        }
        if (document.getElementById("LOCAL_STREAM_VIDEO_DIV") != null) {
          document.getElementById("LOCAL_STREAM_VIDEO_DIV").style.display =
            "flex";
          document.getElementById("ALL_VIDEOS").style.display = "flex";
        }
        // this.screenShareStarted = true;
        // this.socket.emit('muteMic', socketId);
        this.manageDiv();
      },
      async updateIsShow(storageId, value) {
        //console.log("storageId,value______", storageId, value);
        if (this.peers.length > 0) {
          var objIndex = this.peers.findIndex(
            (user) => user?.storageId == storageId
          );
  
          //console.log("objIndex______", objIndex);
  
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peers[objIndex].is_show = value;
          }
        }
        const peerFilter = this.peers.filter(
          (peerData) => peerData?.is_show == true
        );
        //console.log("this.peers______", this.peers);
        // console.log("peerFilter______", peerFilter);
        this.peerShow = peerFilter;
        this.manageDiv();
      },
      async updateCamStream(socketId, stream) {
        if (this.mainScreenStreamRef) {
          if (
            this.mainScreenStreamRef.socketId == socketId &&
            !this.mainScreenStreamRef.screenShare
          ) {
            this.mainScreenStreamRef.mediaStream = stream;
          }
        }
        if (this.peers.length > 0) {
          var objIndex = this.peers.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          console.log("objIndex__::____", objIndex);
          if (objIndex >= 0) {
            console.log("objIndex__::2____", objIndex);
            this.peers[objIndex].mediaStream = stream;
          }
        }
        if (this.peerShow.length > 0) {
          var objIndex1 = this.peerShow.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          console.log("objIndex1__::____", objIndex1);
          if (objIndex1 >= 0) {
            console.log("objIndex1__::3____", objIndex1, stream);
            this.peerShow[objIndex1].mediaStream = stream;
          }
        }
        if (this.peersRef.length > 0) {
          var objIndex2 = this.peersRef.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          //console.log("objIndex2______", objIndex2);
          if (objIndex2 >= 0) {
            console.log("objIndex2__::4____", objIndex2, stream);
            this.peersRef[objIndex2].mediaStream = stream;
          }
        }
      },
      async updateCamStatus(socketId, status) {
        if (this.mainScreenStreamRef) {
          if (
            this.mainScreenStreamRef.socketId == socketId &&
            !this.mainScreenStreamRef.screenShare
          ) {
            this.mainScreenStreamRef.mutedVideo = status;
          }
        }
        if (this.peers.length > 0) {
          var objIndex = this.peers.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peers[objIndex].mutedVideo = status;
          }
        }
        if (this.peerShow.length > 0) {
          var objIndex1 = this.peerShow.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          //console.log("objIndex1______", objIndex1);
          if (objIndex1 >= 0) {
            //console.log("objIndex1______", objIndex1);
            this.peerShow[objIndex1].mutedVideo = status;
          }
        }
        if (this.peersRef.length > 0) {
          var objIndex2 = this.peersRef.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          //console.log("objIndex2______", objIndex2);
          if (objIndex2 >= 0) {
            //console.log("objIndex2______", objIndex2);
            this.peersRef[objIndex2].mutedVideo = status;
          }
        }
      },
      async muteMic(socketId, remote, action) {
        console.log("muteMic______", socketId, remote);
        const userName = this.user_name;
        if (socketId == this.socket.id) {
          this.handleMic();
        } else {
          this.socket.emit("muteMic", { socketId, remote, action, userName });
        }
      },
      async muteVideo(socketId, remote, action) {
        console.log("muteVideo______", socketId, remote, action);
  
        const userName = this.user_name;
        if (socketId == this.socket.id) {
          this.handleVideo();
        } else {
          this.socket.emit("muteVideo", { socketId, remote, action, userName });
        }
      },
      async stopMicVoice() {
        this.audio = false;
        this.publisher.publishAudio(false);
      },
      async startMicVoice() {
        this.audio = true;
        this.publisher.publishAudio(true);
      },
      async stopVideo() {
        this.video = false;
        this.publisher.publishVideo(false);
      },
      async startVideo() {
        console.log("startVideo---", this.video);
        this.publisher.publishVideo(true);
        this.video = true;
      },
      async changeMic(deviceId) {
        console.log("deviceData-------", this.deviceData);
        console.log("deviceId-------", deviceId);
        // const devices = await navigator.mediaDevices.enumerateDevices()
        //Here We Get The First Device's Device Id
        const device = deviceId.deviceId;
        try {
          //Get That Device's Stream
          if (deviceId.kind === "videoinput" || deviceId.kind === "video") {
            try {
              const stream = await navigator?.mediaDevices?.getUserMedia({
                video: {
                  // deviceId: device
                  //This Will Get The Device If Available, If Not Then It Will Get Some other Device
                  //To Get Only The Device With Device Id Use,
                  deviceId: { exact: device },
                },
              });
              this.selectedVideo = deviceId.deviceId;
              //Sending New Track To Mediasoup
              await this.videoProducer?.replaceTrack({
                track: stream.getVideoTracks()[0],
                // encodings: [
                //   {
                //     rid: "r0",
                //     scaleResolutionDownBy: 4,
                //     maxBitrate: 500000,
                //     scalabilityMode: "L1T3",
                //   },
                //   {
                //     rid: "r1",
                //     scaleResolutionDownBy: 2,
                //     maxBitrate: 750000,
                //     scalabilityMode: "L1T3",
                //   },
                //   {
                //     rid: "r2",
                //     scaleResolutionDownBy: 1,
                //     maxBitrate: 1000000,
                //     scalabilityMode: "L1T3",
                //   },
                // ],
              });
  
              //Stop And Remove Previous Track From Local Stream
              this.localStreamRef?.getVideoTracks()?.forEach((track) => {
                track?.stop();
                this.localStreamRef?.removeTrack(track);
              });
              //Add New Track In Local Stream
              this.localStreamRef?.addTrack(stream.getVideoTracks()[0]);
  
              //Set That New Track In Video Element
              // this.$refs.localStreamElemRef.srcObject = this.localStreamRef;
            } catch (e) {
              if (error.name == "TypeError") {
                document.getElementById("BtnTypeError").innerHTML = "Try again";
                $("#TypeError").modal("show");
              }
  
              if (error.name == "NotReadableError") {
                document.getElementById("BtnNotReadableError").innerHTML =
                  "Dismiss";
                $("#NotReadableError").modal("show");
                // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
              }
            }
          } else {
            try {
              const stream = await navigator?.mediaDevices?.getUserMedia({
                audio: {
                  noiseSuppression: true,
                  echoCancellation: true,
                },
                //This Will Get The Device If Available, If Not Then It Will Get Some other Device
                //To Get Only The Device With Device Id Use,
                //deviceId: {exact: device }
              });
  
              //Sending New Track To Mediasoup
              await this.audioProducer.replaceTrack({
                track: stream.getAudioTracks()[0],
              });
  
              //Stop And Remove Previous Track From Local Stream
              this.localStreamRef?.getAudioTracks()?.forEach((track) => {
                track?.stop();
                this.localStreamRef?.removeTrack(track);
              });
              //Add New Track In Local Stream
              this.localStreamRef?.addTrack(stream.getAudioTracks()[0]);
              this.selectedAudio = deviceId.deviceId;
              //Set That New Track In Video Element
              // this.$refs.localStreamElemRef.srcObject = this.localStreamRef;
            } catch (e) {
              if (error.name == "TypeError") {
                document.getElementById("BtnTypeError").innerHTML = "Try again";
                $("#TypeError").modal("show");
              }
  
              if (error.name == "NotReadableError") {
                document.getElementById("BtnNotReadableError").innerHTML =
                  "Dismiss";
                $("#NotReadableError").modal("show");
                // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
              }
            }
          }
        } catch (error) {
          this.running_error = error.name + ": " + error.message;
          console.log("error.name", error.name + ": " + error.message);
  
          console.log(error);
        }
      },
      async changeOuterSpeaker(deviceId) {
        console.log("deviceData-------", this.deviceData);
        console.log("deviceId-------", deviceId);
        try {
          navigator.mediaDevices
            .getUserMedia({
              audio: {
                noiseSuppression: true,
                echoCancellation: true,
              },
              video: false,
            })
            .then((s) => {
              console.log("s-------", s);
              navigator.mediaDevices.enumerateDevices().then((devices) => {
                devices.forEach((device) => {
                  console.log("device.label :", device);
                });
              });
            })
            .catch((error) => {
              console.log("Error :", error);
            });
  
          //Get That Device's Stream
          const sinkId = deviceId;
          if (!navigator.mediaDevices.selectAudioOutput) {
            console.log("selectAudioOutput() not supported.");
            // return;
          }
          // console.log('element-------', element);
          console.log("this.audioElement-------", this.audioElement);
          this.selectedOuterAudio = deviceId;
  
          if (this.audioElement.length > 0) {
            this.audioElement.forEach((item) => {
              const element = document.getElementById(item);
              console.log("element-------", element);
              console.log("element.sinkId-------", element.sinkId);
              if (typeof element.sinkId !== "undefined") {
                element
                  .setSinkId(sinkId)
                  .then(() => {
                    console.log(
                      `Success, audio output device attached: ${sinkId}`
                    );
                  })
                  .catch((error) => {
                    let errorMessage = error;
                    if (error.name === "SecurityError") {
                      errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    console.log(errorMessage);
                    // Jump back to first output device in the list as it's the default.
                    // audioOutputSelect.selectedIndex = 0;
                  });
              } else {
                console.warn("Browser does not support output device selection.");
              }
            });
          }
        } catch (error) {
          this.running_error = error.name + ": " + error.message;
          console.log("error.name", error.name + ": " + error.message);
  
          console.log(error);
        }
      },
      denyUser(socketId) {
        this.waitingUser = this.waitingUser.filter(
          (a) => a.socketId !== socketId
        );
  
        this.filterdWaitingUser = this.waitingUser.filter(
          (a) => a.userDetails.webinarUserType == this.currentTabSelect
        );
  
        this.socket.emit("denyUserRequest", {
          socketId: socketId,
          roomId: this.roomId,
        });
      },
      allowUser(socketId) {
        this.waitingUser = this.waitingUser.filter(
          (a) => a.socketId !== socketId
        );
        this.filterdWaitingUser = this.waitingUser.filter(
          (a) => a.socketId !== socketId
        );
  
        this.filterdWaitingUser = this.waitingUser.filter(
          (a) => a.userDetails.webinarUserType == this.currentTabSelect
        );
  
        this.socket.emit("allowJoinUser", {
          socketId: socketId,
          roomId: this.roomId,
        });
      },
      searchInputHandler(e) {
        console.error(`EEEEEEEEEEEEEEEEEEE: `, e);
        // e.preventDefault();
        return false;
      },
      async getFilterParticipate(type) {
        if (document.getElementById("myTabContent")) {
          document.getElementById("myTabContent").style.display = "unset";
        }
  
        if (document.getElementById("WaitedUser")) {
          document.getElementById("WaitedUser").style.display = "none";
        }
  
        if (document.getElementById("WaitedUserList")) {
          document.getElementById("WaitedUserList").style.display = "none";
        }
  
        this.currentTabSelect = type;
        if (type == "VIEWER") {
          this.socket.emit("fetchViewerUser", { room: this.roomId });
          this.filterdParticipants = this.viewers;
        } else {
          this.filterdParticipants = this.peers.filter(
            (a) => a.userDetails.webinarUserType == type
          );
        }
        this.filterdWaitingUser = this.waitingUser.filter(
          (a) => a.userDetails.webinarUserType == type
        );
        // if (this.waitingUser.length != 0) {
        // }
        console.log("type-------", type);
        console.log("this.peerShow-------", this.peerShow);
      },
      async MuteUser(email, mute, currentTab, userid) {
        document.getElementById("HANDLE_BUTTON_" + userid).style.display = "none";
        document.getElementById("UPDATING_" + userid).style.display = "flex";
  
        const body = {
          webinarId: this.webinarId,
          is_edit: 1,
          email: email,
          is_mute: mute,
        };
        await listupdateParticipants(body);
  
        var webinarParticipate = await webinarParticipateUserList(this.webinarId);
  
        if (webinarParticipate.data.code == 200) {
          this.participants = webinarParticipate.data.result.userId;
          this.filterdParticipants = webinarParticipate.data.result.userId.filter(
            (a) => a.webinarUserType == currentTab
          );
          document.getElementById("UPDATING_" + userid).style.display = "none";
          document.getElementById("HANDLE_BUTTON_" + userid).style.display =
            "block";
          // $('#UPDATING_' + userid).removeClass('box');
        }
      },
      async BannedUser(userid, email, ban) {
        $("#UPDATING_" + userid).addClass("box");
        // this.socket.emit("removeUser", socketId);
        document.getElementById("HANDLE_BUTTON_" + userid).style.display = "none";
        document.getElementById("UPDATING_" + userid).style.display = "flex";
        const body = {
          webinarId: this.webinarId,
          is_edit: 1,
          email: email,
          is_ban: ban,
        };
  
        await listupdateParticipants(body);
  
        var webinarParticipate = await webinarParticipateUserList(this.webinarId);
  
        if (webinarParticipate.data.code == 200) {
          this.participants = webinarParticipate.data.result.userId;
          this.filterdParticipants = webinarParticipate.data.result.userId.filter(
            (a) => a.webinarUserType == currentTab
          );
          // $('#UPDATING_' + userid).removeClass('box');
          document.getElementById("UPDATING_" + userid).style.display = "none";
          document.getElementById("HANDLE_BUTTON_" + userid).style.display =
            "block";
        }
      },
      chatInputHandler(e) {
        console.log("---", this.chatMessage);
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault();
          this.sendMessage();
        }
      },
      hideRaiseHand(socketId) {
        this.socket.emit("hideRaideHandForUser", socketId);
      },
      raiseHand() {
        if (this.isRaiseHand) {
          this.isRaiseHand = false;
          this.RaiseCount++;
        } else {
          this.isRaiseHand = true;
        }
  
        var daTa = {
          user: this.user_name,
          roomId: this.roomId,
          socketId: this.socket.id,
          is_show: this.isRaiseHand,
          is_showCount: this.RaiseCount,
        };
  
        this.socket.emit("raise_hand", daTa);
      },
      muteChatUser(userId, socketId, action) {
        console.log("MUTE CHAT");
        console.log(userId, socketId, action);
  
        var daTa = {
          userId: userId,
          socketId: socketId,
          action: action,
        };
  
        var objIndex = this.chatHistory.findIndex(
          (user) => user?.socketId == socketId
        );
  
        console.log("objIndex______", objIndex);
        if (objIndex >= 0) {
          //console.log("objIndex______", objIndex);
          this.chatHistory[objIndex].is_chat_mute = action;
        }
        this.socket.emit("muteChatUser", daTa);
      },
      banChatUser(userId, socketId, action) {
        console.log("BAN CHAT");
        console.log(userId, socketId, action);
        var daTa = {
          userId: userId,
          socketId: socketId,
          action: action,
        };
        this.socket.emit("banChatUser", daTa);
      },
      deleteMessage(data) {
        var daTaJson = {
          id: data.id,
          roomId: this.roomId,
        };
        console.log("deleteMessage----", daTaJson);
        this.socket.emit("deleteChat", daTaJson);
      },
      renameSubmitMessageUser(data) {
        // this.editedMessage = data.message;
        // this.editMessageId = data.id;
        var rexp =
          /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
        this.editedMessage = this.editedMessage.replace(
          rexp,
          "<a href='$1' target='_blank'>$1</a>"
        );
        var daTaJson = {
          id: this.editMessageId,
          message:
            "<span style='color:#2d8cff;margin-right: 5px'>" +
            this.editMessageUser +
            "</span>" +
            this.editedMessage,
          roomId: this.roomId,
        };
        this.socket.emit("editChatMessage", daTaJson);
        this.editedMessage = "";
        this.editMessageId = "";
        console.log("editMessage----", data);
        $("#editMessagePopup").modal("hide");
      },
      editMessage(data) {
        console.log("data----", data);
        // "<span style='color:#2d8cff;margin-right: 5px'>" + this.user_name + "</span>" + this.chatMessage
        // this.editedMessage = data.message;
        this.editMessageId = data.id;
        this.editMessageUser = data.user;
  
        $("#editMessagePopup").modal("show");
  
        // this.socket.emit("editChat", daTa);
        console.log("editMessage----", data);
      },
      randomString(length) {
        var chars =
          "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".split(
            ""
          );
  
        if (!length) {
          length = Math.floor(Math.random() * chars.length);
        }
  
        var str = "";
        for (var i = 0; i < length; i++) {
          str += chars[Math.floor(Math.random() * chars.length)];
        }
        return str;
      },
      sendMessage() {
        if (this.chatMessage != "") {
          var rexp =
            /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
          this.chatMessage = this.chatMessage.replace(
            rexp,
            "<a href='$1' target='_blank'>$1</a>"
          );
  
          var daTa = {
            id: this.randomString(6),
            message:
              "<span style='color:#2d8cff;margin-right: 5px'>" +
              this.user_name +
              "</span>" +
              this.chatMessage,
            senderRole: this.senderRole,
            profileImg: this.profileImg,
            user: this.user_name,
            roomId: this.roomId,
            userId: this.userId,
            is_chat_mute: this.is_chat_mute,
            socketId: this.socket.id,
          };
          this.socket.emit("chat", daTa);
  
          this.chatMessage = "";
        }
      },
      makeid(length) {
        let result = "";
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
          counter += 1;
        }
        return result;
      },
    },
  };
  </script>
  
  <style>
  /* Absolute Center Spinner */
  .dropdownStyle {
    position: absolute;
    top: 0 !important;
    left: -25% !important;
  
    @media (max-width: 1550px) {
      top: -0% !important;
      left: -45% !important;
    }
    @media (max-width: 1630px) {
      top: -5% !important;
      left: -42% !important;
    }
    @media (max-width: 1380px) {
      top: -5% !important;
      left: -50% !important;
    }
  
    @media (max-width: 985px) {
      top: -10% !important;
      left: -53% !important;
    }
  
    @media (max-width: 845px) {
      top: 0% !important;
      left: -58% !important;
    }
  
    @media (max-width: 695px) {
      top: 0% !important;
      left: -40% !important;
      min-width: 10rem !important;
    }
  
    @media (max-width: 500px) {
      top: 0% !important;
      left: -75% !important;
      min-width: 10rem !important;
    }
  }
  
  .dropdown5Style {
    position: absolute;
    top: 0 !important;
    left: -36% !important;
  }
  
  .dropdown6Style {
    position: absolute;
    top: 0 !important;
    left: -3% !important;
  }
  
  .dropdown7Or8Style {
    position: absolute;
    top: 0 !important;
    left: -10% !important;
  
    @media (max-width: 845px) {
      left: -4% !important;
    }
  }
  
  .lessThan2DropDown {
    top: 0% !important;
    left: -13% !important;
  
    @media (max-width: 1820px) {
      top: -4% !important;
      left: -19% !important;
    }
  
    @media (max-width: 1550px) {
      top: -4% !important;
      left: -20% !important;
    }
    @media (max-width: 1440px) {
      top: -4% !important;
      left: -22% !important;
    }
    @media (max-width: 1380px) {
      top: -5% !important;
      left: -25% !important;
    }
  
    @media (max-width: 985px) {
      top: -7% !important;
      left: -28% !important;
    }
  
    @media (max-width: 845px) {
      top: -1% !important;
      left: -5% !important;
    }
  
    @media (max-width: 695px) {
      top: -10% !important;
      left: -40% !important;
    }
  
    @media (max-width: 500px) {
      top: 0% !important;
      left: -8% !important;
      min-width: 10rem !important;
    }
  }
  
  .lessThan2MoreDropDown {
    top: 0% !important;
    left: -6% !important;
  
    @media (max-width: 1820px) {
      top: -4% !important;
      left: -24% !important;
    }
    @media (max-width: 1700px) {
      top: -4% !important;
      left: -27% !important;
    }
  
    @media (max-width: 1550px) {
      top: -5% !important;
      left: -29% !important;
    }
    @media (max-width: 1440px) {
      top: -6% !important;
      left: -30% !important;
    }
    @media (max-width: 1380px) {
      top: -2% !important;
      left: -7% !important;
    }
  
    @media (max-width: 985px) {
      top: -5% !important;
      left: -35% !important;
    }
  
    @media (max-width: 845px) {
      top: 0% !important;
      left: -5% !important;
    }
  
    @media (max-width: 695px) {
      top: -10% !important;
      left: -40% !important;
    }
  
    @media (max-width: 500px) {
      top: 0% !important;
      left: -8% !important;
      min-width: 10rem !important;
    }
  }
  
  .lessThan2HostDropDown {
    top: 0% !important;
    left: -19% !important;
  
    @media (max-width: 1820px) {
      top: -4% !important;
      left: -24% !important;
    }
    @media (max-width: 1700px) {
      top: -4% !important;
      left: -27% !important;
    }
  
    @media (max-width: 1550px) {
      top: -5% !important;
      left: -29% !important;
    }
    @media (max-width: 1440px) {
      top: -6% !important;
      left: -30% !important;
    }
    @media (max-width: 1380px) {
      top: -6% !important;
      left: -32% !important;
    }
  
    @media (max-width: 985px) {
      top: -5% !important;
      left: -35% !important;
    }
  
    @media (max-width: 845px) {
      top: -18% !important;
      left: -5% !important;
    }
  
    @media (max-width: 695px) {
      top: -10% !important;
      left: -40% !important;
    }
  
    @media (max-width: 500px) {
      top: -20% !important;
      left: -10% !important;
      min-width: 10rem !important;
    }
  }
  
  .mobileItemStyle {
    @media (max-width: 500px) {
      font-size: 12px !important;
      line-height: 20px !important;
    }
  }
  .usersOnly {
    overflow-y: auto;
    overflow-x: hidden;
    height: 78vh;
  
    /* iphone XR */
    @media only screen and (device-width: 414px) and (device-height: 896px) {
      height: 40vh !important;
    }
  
    /* iphone 12 pro to pro series */
    @media only screen and (min-device-width: 428px) and (max-device-width: 430px) and (-webkit-device-pixel-ratio: 3) {
      height: 42vh !important;
    }
  
    /* iPhone Mini series (12, 13, 14, 15) */
    @media only screen and (min-device-width: 360px) and (max-device-width: 375px) and (-webkit-device-pixel-ratio: 3) {
      height: 20vh !important;
    }
  
    /* iPhone Plus series (12, 13, 14, 15) */
    @media only screen and (min-device-width: 414px) and (max-device-width: 428px) and (-webkit-device-pixel-ratio: 3) {
      height: 42vh !important;
    }
  
    /* iphone 12 pro to pro series */
    @media only screen and (width: 390px) and (height: 844px) {
      height: 40vh !important;
    }
  
    /* Styles for most Android phones */
    @media only screen and (min-device-width: 360px) and (max-device-width: 411px) {
      height: 36vh !important;
    }
    @media only screen and (min-device-width: 412px) and (max-device-width: 460px) {
      height: 40vh !important;
    }
  
    /* Styles for Android tablets */
    /* @media only screen 
    and (min-device-width: 600px) 
    and (max-device-width: 768px) 
    and (-webkit-device-pixel-ratio: 2) {
      height: 40vh !important;
      background-color: turquoise !important;
  } */
  
    /* iPhone 5 (320x568) */
    @media (max-width: 320px) and (max-height: 568px) {
      height: 300px !important;
    }
  
    /* iPhone 6 (375x667) */
    @media (max-width: 375px) and (max-height: 667px) {
      height: 20vh !important;
    }
  
    /* iPad (1024x768) */
    @media (min-width: 768px) and (max-width: 1024px) {
      height: 55vh !important;
    }
  
    /* Laptop (1440x900) */
    @media (min-width: 1025px) and (max-width: 1440px) {
      height: 73vh !important;
    }
  
    /* Desktop (1680x1050) */
    @media (min-width: 1441px) and (max-width: 1680px) {
      height: 68vh !important;
    }
  
    /* Desktop (1920x1080) */
    @media (min-width: 1681px) and (max-width: 1920px) {
      height: 65vh !important;
    }
  }
  .outer-circle {
    margin-bottom: 13px;
    width: 80px;
    height: 80px;
    border: solid 5px white;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    animation: rotateCircle 1s linear infinite;
    padding: 2px;
    display: table;
  
    .inner-circle {
      height: 80px;
      width: 80px;
      border-style: solid;
      border-color: transparent;
      border-radius: 50%;
      border-width: 1px;
      animation: rotateCircle 1s linear infinite reverse;
      display: table;
      text-align: center;
    }
  
    .text {
      vertical-align: middle;
      display: table-cell;
      text-align: center;
    }
  }
  
  @keyframes rotateCircle {
    0% {
      transform: rotate(0);
    }
  
    25% {
      transform: rotate(90deg);
    }
  
    50% {
      transform: rotate(180deg);
    }
  
    75% {
      transform: rotate(270deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    color: white;
    border: 0;
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  .presentingScreen {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }
  
  #fixed_popup {
    position: absolute;
    transform: translate3d(404px, 536px, 0px) !important;
    top: 0px !important;
    left: 0px !important;
    will-change: transform !important;
  }
  
  #loader {
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #444444;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
  }
  
  .center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  
  .audio-icon-cont {
    position: relative;
    display: flex;
    align-self: flex-end;
    justify-content: space-between;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  
  .audio-icon {
    margin: auto 0;
    width: 100%;
    height: 100%;
    /* background-color: white; */
    transform-origin: bottom;
    border-radius: 10px;
  }
  
  .audio-icon:nth-of-type(2) {
    animation-delay: -1s;
  }
  
  .audio-icon:nth-of-type(3) {
    animation-delay: -1.5s;
  }
  
  .animate-audio-icon {
    height: 100% !important;
    animation: bounce 1.2s ease infinite alternate;
  }
  
  @keyframes bounce {
    10% {
      transform: scaleY(0.3);
    }
  
    30% {
      transform: scaleY(1);
    }
  
    60% {
      transform: scaleY(0.5);
    }
  
    80% {
      transform: scaleY(0.75);
    }
  
    100% {
      transform: scaleY(0.6);
    }
  }
  
  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @-o-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .animate-flicker {
    /* -webkit-animation: flickerAnimation 0.5s infinite;
      -moz-animation: flickerAnimation 0.5s infinite;
      -o-animation: flickerAnimation 0.5s infinite;
      animation: flickerAnimation 0.5s infinite; */
  
    border: 3px solid #2d8cff !important;
  }
  
  .RecordSec {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 7px;
  }
  
  .recordAnimation {
    animation: record 1.5s ease infinite normal;
  }
  
  @keyframes record {
    10% {
      opacity: 0.1;
    }
  
    30% {
      opacity: 0.3;
    }
  
    60% {
      opacity: 0.6;
    }
  
    80% {
      opacity: 0.8;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .disableTopIcon {
    pointer-events: none;
    cursor: no-drop;
    color: #9ca3af9c !important;
    background: #80808024;
    border-radius: 9px;
  }
  
  .disableTopIconMain {
    cursor: no-drop !important;
    /* background: #80808024; */
    border-radius: 9px;
  }
  
  .disableBottomIcon {
    pointer-events: none;
    cursor: no-drop;
  }
  
  .disableTopIconText {
    background: #9ca3af9c;
  }
  
  .disableTopIconSVG {
    fill: #9ca3af9c !important;
  }
  
  .play-button {
    cursor: pointer;
    z-index: 999999;
    background: black;
    border-radius: 50%;
  }
  
  .topRecordPanel {
    border-radius: 8px;
    margin: 5px;
    padding: 6px;
    border: 2px solid red;
    color: white;
  }
  
  .closeIconModal {
    border: 2px solid black;
    position: absolute;
    right: 12px;
    border-radius: 68px;
    padding: 4px 10px;
    margin: 0;
    cursor: pointer;
  }
  
  .modal-open {
    overflow: visible !important;
  }
  
  .mySpin {
    width: 20px;
    height: 20px;
    margin-left: 4px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #000;
    animation: spinner 0.6s linear infinite;
  }
  
  .flip-list-move {
    transition: transform 0.5s;
  }
  
  .no-move {
    transition: transform 0s;
  }
  
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  
  .list-group-item {
    cursor: move;
  }
  
  .list-group-item i {
    cursor: pointer;
  }
  
  .normal {
    background-color: grey;
  }
  
  .drag {
    background-color: green;
  }
  
  .dragArea {
    min-height: 10px;
  }
  
  .toggleP {
    background-color: #ddddde;
    border-radius: 60px;
    box-shadow: 0 1px 1px 0 rgba(255, 255, 255, 0.4),
      0 1px 0 0 rgba(0, 0, 0, 0.1) inset;
    cursor: pointer;
    width: 57px;
    height: 31px;
    overflow: hidden;
    top: 50px;
    left: 50px;
    transition: all 0.25s linear;
  }
  
  .toggleP .slide {
    color: #818283;
    color: rgba(0, 0, 0, 0.15);
    background: #efefef;
    border-radius: 50%;
    font-size: 30px;
    line-height: 68px;
    text-align: center;
    text-decoration: none;
    height: 22px;
    width: 23px;
    position: absolute;
    top: 5px;
    left: 5px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15),
      0 1px 1px 0 rgba(255, 255, 255, 0.8) inset;
    transition: all 0.3s cubic-bezier(0.43, 1.3, 0.86, 1);
  }
  
  .toggleP .slide span {
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.7), 0 0 1px rgba(0, 0, 0, 0.3);
  }
  
  .toggleP .slide:before,
  .toggleP .slide:after {
    color: #fff;
    content: "\f023";
    font-family: fontAwesome;
    font-size: 16px;
    font-weight: 400;
    text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.25);
    -webkit-font-smoothing: antialiased;
    position: absolute;
  }
  
  .toggleP .slide:before {
    right: -23px;
    color: #0b111b;
    top: -24px;
  }
  
  .toggleP .slide:after {
    content: "\f09c";
    left: -21px;
    color: #0b111b;
    top: -23px;
  }
  
  .toggleP.on {
    background: #2d8cff;
  }
  
  .toggleP.on .slide {
    left: 29px;
    color: #2d8cff;
  }
  
  .input {
    width: 2ch;
    background: transparent;
    border: none;
    border: 1px solid rgb(42 132 242);
    color: white;
    margin: 0 10px;
    text-align: center;
    font-size: 36px;
    cursor: not-allowed;
    border-radius: 9px;
    /* pointer-events: none; */
    cursor: pointer;
    pointer-events: all;
  }
  
  .input:focus {
    border: 3px solid rgb(255 255 255);
    outline: none;
  }
  
  .inputs1 {
    padding: 0 !important;
  }
  
  /* .input:nth-child(1) {
    } */
  
  @media screen and (min-width: 10px) and (max-width: 1099px) {
    .audience {
      position: unset !important;
    }
  }
  
  @media screen and (min-width: 1300px) and (max-width: 1399px) {
    .audience {
      position: unset !important;
    }
  }
  
  .waitingMain {
    display: flex;
    align-items: center;
    border: 1px solid #959595;
    background: #374151;
    padding: 8px;
  }
  
  .waitingRoomText {
    font-weight: bold;
    color: white;
    font-size: 16px;
    margin-left: 14px;
  }
  
  .hrLine {
    border-top: 1px solid rgb(55 65 81);
    margin: 1rem 5rem;
  }
  
  .rejectBtn {
    letter-spacing: 0.5px;
    cursor: pointer;
    color: #f44336;
    background: #374151;
    padding: 5px 8px;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid red;
  }
  
  .acceptBtn {
    cursor: pointer;
    color: #00ba00;
    background: #374151;
    padding: 5px 8px;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #00ba00;
  }
  
  .two-line-paragraph {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .chatBox {
    display: flex;
    margin-bottom: 10px;
    padding: 6px;
    position: relative;
    border-radius: 7px;
  }
  
  .chatBox:hover {
    background-color: #0b111b7a;
    transition: 0.3s;
  }
  
  .threeDots {
    position: absolute;
    margin-left: 10px;
    float: right;
    right: 21px;
    rotate: 90deg;
    top: 50%;
    display: none;
  }
  
  .chatBox:hover .threeDots {
    display: block !important;
  }
  
  .chatBox:hover .two-line-paragraph {
    width: 75% !important;
  }
  
  .nextBtn:hover {
    background-color: #2d8cff;
    color: white !important;
  }
  
  .loader {
    border: 1px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    margin-bottom: 10px;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loader_main {
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  </style>
  