<template>
  <div class="fluid container">
    <div class="form-group form-group-lg panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title">Sortable control</h3>
      </div>
    </div>

    <draggable :list="list" item-key="name" class="Dish" ghost-class="ghost" :move="checkMove"
      @start="dragging = true" @end="dragging = false">
      <template #item="{ element }" class="Dish">
        <div class="draggable">
          <video draggable="true" muted autoPlay playsInline :src="element.link"></video>
          {{ element.name }}
        </div>
      </template>
    </draggable>
    {{ list }}
    {{ enabled }}


  </div>
</template>

<script>

var _ratios = ["4:3"];
var _aspect = 0;
var _margin = 5;
var _ratio = ratio();

// resize event of window
window.addEventListener("resize", function () {
  // resize event to dimension cameras
  resize();
});

// split aspect ratio (format n:n)

function resize() {
  dimensions();

  // loop (i recommend you optimize this)
  let max = 0;
  let i = 1;
  while (i < 5000) {
    var area = areas(i);
    if (area === false) {
      max = i - 1;
      break;
    }
    i++;
  }

  // remove margins
  max = max - _margin * 1;

  // set dimensions to all cameras
  resizer(max);
}

function resizer(width) {
  var _dish = document.getElementsByClassName("list-group")[0];

  if (_dish) {
    for (var s = 0; s < _dish.children.length; s++) {
      // camera fron dish (div without class)
      const element = _dish.children[s];
      const pseudoElementStyle = getComputedStyle(element, "display");

      console.log('pseudoElementStyle___', pseudoElementStyle.display);
      if (pseudoElementStyle.display == "flex") {
      // custom margin
      element.style.margin = _margin + "px";

      // calculate dimensions
      element.style.width = width + "px";
      element.style.height = width * _ratio + "px";

      // to show the aspect ratio in demo (optional)
      element.setAttribute("data-aspect", _ratios[_aspect]);
      }
    }
  }
}

function ratio() {
  var ratio = _ratios[_aspect].split(":");
  return ratio[1] / ratio[0];
}

function dimensions() {
  var _dish = document.getElementsByClassName("list-group")[0];

  //   let _width = _dish ? _dish.offsetWidth : 0 - _margin * 2;
  //   let _height = _dish.offsetHeight - _margin * 2;
  // }
}

function areas(increment) {
  var _dish = document.getElementsByClassName("list-group")[0];

  if (_dish) {
    let i = 0;
    let w = 0;
    let _width = _dish ? _dish.offsetWidth : 0 - _margin * 2;
    let _height = _dish ? _dish.offsetHeight : 0 - _margin * 2;
    let h = increment * _ratio + _margin * 2;
    while (i < _dish.children.length) {
      if (w + increment > _width - _margin * 2) {
        w = 0;
        h = h + increment * _ratio + _margin * 2;
      }
      w = w + increment + _margin * 2;
      i++;
    }
    if (h > _height - _margin * 2 || increment > _width - _margin * 2) return false;
    else return increment;
  }
}

import draggable from 'vuedraggable'
export default {
  name: "hello",
  components: {
    draggable,
  },

  data() {
    return {
      enabled: true,
      list: [{ name: "John", id: 1, link: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4' },
      { name: "Joao", id: 2, link: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4' },
      { name: "Jean", id: 3, link: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4' }]
    }


  },
  methods: {
    handleChange() {
      console.log('changed');
    },
    inputChanged(value) {
      this.activeNames = value;
    },
    getComponentData() {
      return {
        on: {
          change: this.handleChange,
          input: this.inputChanged
        },
        attrs: {
          wrap: true
        },
        props: {
          value: this.activeNames
        }
      };
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };

      window.setInterval(() => {
        resize();
      }, 100);
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    list2String() {
      return JSON.stringify(this.list2, null, 2);
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
};
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}


.normal {
  background-color: grey;
}

.drag {
  background-color: green;
}

.dragArea {
  min-height: 10px;
}
</style>