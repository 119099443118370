<template>
    <div v-if="isOpen" class="modal-overlay">
        <div class="modal-container">
            <div class="modal-header">Upload file(s) to chat</div>
            <div class="modal-body">
                <div
          v-if="storageWarning"
          style="padding: 14px; background: #101826; border-radius: 10px"
          class="mb-2 d-flex"
        >
          <div class="mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              fill="red"
              version="1.1"
              width="24px"
              height="24px"
              viewBox="0 0 34.854 34.855"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M34.653,30.47L18.727,2.884c-0.269-0.464-0.764-0.75-1.299-0.75c-0.537,0-1.031,0.286-1.3,0.75L0.202,30.47   c-0.269,0.464-0.269,1.036,0,1.5s0.763,0.75,1.299,0.75h31.853c0.535,0,1.031-0.286,1.3-0.75   C34.921,31.506,34.921,30.934,34.653,30.47z M4.099,29.72L17.427,6.634L30.756,29.72H4.099z M15.427,11.677h4V23.51h-4V11.677z    M15.427,25.507h4v2.919h-4V25.507z"
                />
              </g>
            </svg>
          </div>
          <div
            style="display: flex; flex-direction: column; margin-left: 0.5rem"
          >
            <span class="text-white" style="font-size: large"
              >Your storage is almost full {{ getStorageGB }}%</span
            >
            <span class="text-white">
              Webinar requires storage to maintain your videos, files,
              recordings and more. To continue maintain your data please upgrade
              your storage or free-up some space from media manager.
            </span>
          </div>
        </div>
                <div  v-if="!storageFull" class="drop-zone" :class="{ 'drop-zone-active': isDragActive }" @dragover.prevent="onDragOver"
                    @dragleave="onDragLeave" @drop.prevent="onDrop">
                    <svg width="82" height="70" viewBox="0 0 82 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_4866_696)">
                                <path
                                    d="M68.5193 7V15.0001H37.5117L52.6601 5.31496C52.9818 5.10929 53.3556 5 53.7374 5H66.5193C67.6239 5 68.5193 5.89543 68.5193 7Z"
                                    fill="#0576FF" />
                                <rect x="14" y="11.333" width="54.5189" height="35.6669" rx="2" fill="#2D8CFF" />
                            </g>
                            <path
                                d="M42.236 19.3636C41.8845 19.0121 41.3147 19.0121 40.9632 19.3636L35.2356 25.0912C34.8842 25.4426 34.8842 26.0125 35.2356 26.364C35.5871 26.7154 36.157 26.7154 36.5084 26.364L41.5996 21.2728L46.6908 26.364C47.0423 26.7154 47.6121 26.7154 47.9636 26.364C48.315 26.0125 48.315 25.4426 47.9636 25.0912L42.236 19.3636ZM42.4996 39L42.4996 20L40.6996 20L40.6996 39L42.4996 39Z"
                                fill="white" />
                            <defs>
                                <filter id="filter0_d_4866_696" x="0.9" y="0.9" width="80.7195" height="68.2"
                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="9" />
                                    <feGaussianBlur stdDeviation="6.55" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix"
                                        result="effect1_dropShadow_4866_696" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4866_696"
                                        result="shape" />
                                </filter>
                            </defs>
                        </svg>

                    <div class="drop-zone-text">

                        <a href="#" @click.prevent="onClickSelect">Click</a> or drag &amp; drop your file(s) here
                    </div>
                    <button class="select-files-btn" @click="onSelectFilesClick">Select Files</button>
                </div>

                <input  v-if="!storageFull" ref="fileInput" type="file" multiple style="display: none" @change="onFileChange" />

                <div class="file-list">
                    <div v-for="(fileObj, index) in filesData" :key="fileObj.id" class="file-item">
                        <div class="file-top">
                            <div>
                                <div class="file-name">{{ fileObj.file.name }}</div>
                                <div class="file-meta">
                                    {{ (fileObj.size / 1024 / 1024).toFixed(2) }} mb
                                    <span class="file-meta">{{ fileObj.speedAndTime }}</span>
                                </div>
                            </div>
                            <div class="file-progress">
                                <div class="file-meta" :class="{ 'error-text': fileObj.error }">
                                    {{ fileObj.statusText }}
                                </div>
                                <button class="x-btn" @click="onCancelClick(fileObj.id)">&times;</button>
                            </div>
                        </div>
                        <div class="progress-bar-container">
                            <div class="progress-bar" :style="{ width: fileObj.progress + '%' }"></div>
                        </div>
                    </div>
                </div>

                <div  v-if="!storageFull" class="upload-controls">
                    <button class="discard-btn" @click="onDiscard">Discard</button>
                    <button :disabled="this.filesData.some((file) => file.error === false && file.progress !== 100)"
                        class="send-btn" @click="onSend">Ok</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { deleteFile, getAttachmentUploadURL } from '@/services/service';
import axios from 'axios'
import { v4 } from 'uuid';
import { mapGetters } from 'vuex';

export default {
    name: 'ChatUpload',
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        send: {
            type: Function,
            default: () => null
        }
    },
    emits: ['update:isOpen'],
    data() {
        return {
            isDragActive: false,
            filesData: [],
            s3: null,
            storageFull: false,
        }
    },
    computed: {
    ...mapGetters(["getStorageGB"]),

    storageWarning() {
      console.log("this.getStorageGB chat----------", this.getStorageGB);
      if(this.getStorageGB === 100) {
        this.storageFull = true
      }else{
        this.storageFull = false
      }
      return this.getStorageGB > 90;
    },
  },
  mounted: async function () {
    console.log("mounted--------------------")
  },
    methods: {
        onDragOver() {
            this.isDragActive = true
        },
        onDragLeave() {
            this.isDragActive = false
        },
        onDrop(e) {
            console.log("e chat drag--------", e.dataTransfer.files)
            this.isDragActive = false
            this.addFilesToList(e.dataTransfer.files)
        },
        onClickSelect() {
            if (this.$refs.fileInput) {
                this.$refs.fileInput.click()
            }
        },
        onSelectFilesClick() {
            if (this.$refs.fileInput) {
                this.$refs.fileInput.click()
            }
        },
        onFileChange(e) {
            this.addFilesToList(e.target.files)
        },

        updateFileAtIndex(fileId, patch) {
            const newList = [...this.filesData]
            const index = newList.findIndex((item) => item.id === fileId)
            if (index === -1) return;
            const oldObj = newList[index]
            newList[index] = { ...oldObj, ...patch }
            this.filesData = newList
        },

        addFilesToList(fileList) {
            let newItems = [...this.filesData]
            const startLength = newItems.length

            for (const file of fileList) {
                const id = v4()
                newItems.push({
                    id,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    file,
                    progress: 0,
                    statusText: '0%',
                    speedAndTime: '',
                    error: false,
                    cancelSource: null
                })
            }

            this.filesData = newItems

            for (let i = startLength; i < this.filesData.length; i++) {
                const fObj = this.filesData[i]
                if (fObj.size > 25 * 1024 * 1024) {
                    this.updateFileAtIndex(fObj.id, {
                        statusText: 'Size limit exceeded (Max 25 MB)',
                        error: true
                    })
                } else {
                    this.uploadFile(fObj.id)
                }
            }
        },
        onCancelClick(fileId) {
            const index = this.filesData.findIndex((item) => item.id === fileId)
            if (index === -1) return;
            const fileObj = this.filesData[index]
            if (!fileObj.error && fileObj?.progress !== 100) {
                fileObj.cancelSource.cancel('User canceled upload')
            }
            else if (fileObj?.progress === 100) {
                deleteFile({ attachments: [fileObj?.name] })
                const newList = [...this.filesData]
                newList.splice(index, 1)
                this.filesData = newList
            }
            else {
                const newList = [...this.filesData]
                newList.splice(index, 1)
                this.filesData = newList
            }
        },
        onDiscard() {
            let filesToDelete = []

            for (const file of this.filesData) {
                if (file.error) continue;

                if (file.progress === 100) {
                    filesToDelete.push(file)
                }
                else {
                    file?.cancelSource?.cancel('User cancelled upload')
                }
            }

            if (filesToDelete?.length > 0) {
                deleteFile({ attachments: filesToDelete.map((file) => file?.name) })
            }

            this.filesData = []
            if (this.$refs.fileInput) {
                this.$refs.fileInput = ''
            }
            this.$emit('update:isOpen', false)
        },
        onSend() {
            this.$emit('update:isOpen', false)
            this.send(this.filesData)
            this.filesData = []
            if (this.$refs.fileInput) {
                this.$refs.fileInput = ''
            }
        },

        async uploadFile(fileId) {
            const index = this.filesData.findIndex((item) => item.id === fileId)
            if (index === -1) return;
            const fileObj = this.filesData[index]
            if (!fileObj) return

            const cancelSource = axios.CancelToken.source()
            this.updateFileAtIndex(fileId, { cancelSource })

            let signedUrl
            let newName
            try {
                const req = await getAttachmentUploadURL({
                    fileName: fileObj.file.name,
                    fileType: fileObj.file.type,
                    fileSize: fileObj.file.size
                })

                signedUrl = req.data?.result?.url
                newName = req?.data?.result?.name

                this.updateFileAtIndex(fileId, { name: newName })

            } catch (e) {
                this.updateFileAtIndex(fileId, {
                    statusText: 'Error',
                    error: true
                })
                return
            }

            const startTime = Date.now()

            const newFile = new File([fileObj?.file], fileObj?.name, {
                type: fileObj.file.type,
                lastModified: fileObj.file.lastModified,
            });

            console.log("signedUrl---------", signedUrl);
            console.log("newFile---------", newFile);
            axios.put(signedUrl, newFile, {
                headers: { 'Content-Type': fileObj.file.type },
                cancelToken: cancelSource.token,
                onUploadProgress: (progressEvent) => {
                    if (progressEvent?.loaded) {
                        const rawPercent = (progressEvent.loaded / progressEvent.total) * 100;
                        const percent = Math.min(100, rawPercent);
                        const elapsed = (Date.now() - startTime) / 1000;
                        const speed = progressEvent.loaded / elapsed;
                        const remaining = progressEvent.total - progressEvent.loaded;
                        const seconds = remaining / speed;
                        const newPatch = {};
                        if (percent === 100) {
                            newPatch.progress = 100;
                            newPatch.statusText = 'Processing';
                        } else {
                            newPatch.progress = percent;
                            newPatch.statusText = percent.toFixed(2) + '%';
                        }
                        newPatch.speedAndTime = `${(speed / 1024).toFixed(2)} KB/s` + (isFinite(seconds) ? ` • ${this.formatRemainingTime(seconds)}` : '');
                        this.updateFileAtIndex(fileId, newPatch);
                    }
                }
            })
                .then(() => {
                    this.updateFileAtIndex(fileId, {
                        progress: 100,
                        statusText: 'Uploaded',
                        speedAndTime: ''
                    })
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        const newList = [...this.filesData]
                        const idx = newList.findIndex((item) => item.id === fileId)
                        newList.splice(idx, 1)
                        this.filesData = newList
                    } else {
                        this.updateFileAtIndex(fileId, {
                            statusText: 'Error',
                            error: true,
                            progress: 0,
                            speedAndTime: ''
                        })
                    }
                })
        },
        formatRemainingTime(seconds) {
            if (seconds <= 0) return 'a few seconds';
            const days = Math.floor(seconds / (24 * 60 * 60));
            const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
            const mins = Math.floor((seconds % (60 * 60)) / 60);
            const secs = Math.floor(seconds % 60);
            let result = '';

            if (days > 0) {
                result += `${days} ${days === 1 ? 'day' : 'days'}`;
                if (hours > 0) result += ` ${hours} ${hours === 1 ? 'hour' : 'hours'}`;
            } else if (hours > 0) {
                result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
                if (mins > 0) result += ` ${mins} ${mins === 1 ? 'min' : 'mins'}`;
            } else if (mins > 0) {
                result += `${mins} ${mins === 1 ? 'min' : 'mins'}`;
                if (secs > 0) result += ` ${secs} ${secs === 1 ? 'second' : 'seconds'}`;
            } else if (secs > 0) {
                result += `${secs} ${secs === 1 ? 'second' : 'seconds'}`;
            }

            return result.trim();
        }

    }
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal-container {
    width: min(600px,80%);
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.modal-header {
    padding: 20px;
    font-size: 18px;
    font-weight: 700;
    color: #333;
    border-bottom: 1px solid #E2E2E2;
}

.modal-body {
    padding: 20px;
}

.drop-zone {
    border: 2px dashed #D3D3D3;
    border-radius: 8px;
    padding: 40px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.drop-zone-active {
    border-color: #999999;
}

.drop-zone-text {
    color: #666666;
    margin-bottom: 10px;
}

.select-files-btn {
    padding: 8px 16px;
    background: white;
    color: #374151C2;
    border: 1px solid #374151C2;
    border-radius: 9px;
    cursor: pointer;
    font-weight: 600;
}

.file-list {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.file-item {
    background: #F9F9F9;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 12px;
    border: 1px solid #E2E2E2;
}

.file-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.file-name {
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.file-meta {
    font-size: 12px;
    color: #666;
}

.file-progress {
    display: flex;
    align-items: center;
    gap: 8px;
}

.progress-bar-container {
    background: #E7E7E7;
    border-radius: 4px;
    height: 6px;
    flex: 1;
    overflow: hidden;
}

.progress-bar {
    height: 6px;
    background: #4CAF50;
    width: 0%;
}

.x-btn {
    background: transparent;
    color: #666;
    border: none;
    font-size: 18px;
    line-height: 1;
    cursor: pointer;
}

.upload-controls {
    display: flex;
    justify-content: center;
    gap: 16px;
}

.discard-btn {
    padding: 8px 45px;
    background: white;
    color: #374151C2;
    border: 1px solid #374151C2;
    border-radius: 9px;
    cursor: pointer;
    font-weight: 600;
}

.send-btn {
    background: #007BFF;
    color: #FFFFFF;
    border: none;
    padding: 10px 50px;
    border-radius: 11px;
    cursor: pointer;
    font-weight: 600;
}

.send-btn:disabled {
    background: #CCCCCC;
    color: #b1b0b0;
    cursor: not-allowed;
    pointer-events: none;
}

.error-text {
    color: red;
}

a{
    text-decoration: underline;
}
</style>