<template>
    <transition name="fade">
      <div v-if="visible" class="dialog-backdrop">
        <div class="dialog">
          <div class="form-group">
            <label>First Name</label>
            <input v-model="localUser.firstName" placeholder="First Name" />
          </div>
          <div class="form-group">
            <label>Last Name</label>
            <input v-model="localUser.lastName" placeholder="Last Name" />
          </div>
          <div class="form-group">
            <label>Email</label>
            <input v-model="localUser.email" placeholder="Email" />
          </div>
          <div class="form-group">
            <label>User Role</label>
            <select v-model="localRole">
              <option value="Host">Host</option>
              <option value="Moderator">Moderator</option>
              <option value="Guest">Guest</option>
              <option value="Viewer">Viewer</option>
            </select>
          </div>
          <div v-if="error" class="error">{{ error }}</div>
          <div class="actions">
            <button @click="cancel" :disabled="loading">Cancel</button>
            <button style="background-color: #2D8CFF !important; color: white" @click="saveChanges" :disabled="loading">Save Changes</button>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'UserDialog',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      user: {
        type: Object,
        required: true
      },
      role: {
        type: String,
        required: true
      },
      onSave: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        localUser: { ...this.user },
        localRole: this.role,
        loading: false,
        error: ''
      }
    },
    watch: {
     user (newVal) {
        this.localUser = {...newVal};
     },
     role (newVal) {
        this.localRole = newVal;
     }
    },
    methods: {
      async saveChanges() {
        this.loading = true
        this.error = ''
        await this.onSave({ ...this.localUser, role: this.localRole })
          .then(() => {
            this.loading = false
            this.$emit('update:visible', false)
          })
          .catch(e => {
            this.loading = false
            this.error = e?.message || 'Error saving user'
          })
      },
      cancel() {
        this.$emit('update:visible', false)
      }
    }
  }
  </script>
  
  <style scoped>
  .dialog-backdrop {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  .dialog {
    background: #fff;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  label {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 14px;
  }
  input,
  select {
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
  button {
    border: none;
    outline: none;
    padding: 8px 16px;
    margin-left: 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  .error {
    color: red;
    font-size: 12px;
    margin-bottom: 8px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  @media screen and (max-width: 480px) {
    .dialog {
      margin: 16px;
      width: auto;
    }
  }
  </style>
  