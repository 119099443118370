<template>
  <div id="main_div"
    class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
    <div style="z-index: 9999" id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed">
      <div class="kt-header-mobile__logo">
        <a href="demo3/index.html">
          <img alt="Logo" src="../../assets/media/site-logo.png" />
        </a>
      </div>
      <div class="kt-header-mobile__toolbar">
        <button class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
          id="kt_aside_mobile_toggler">
          <span></span>
        </button>
        <button class="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler">
          <span></span>
        </button>
      </div>
    </div>
    <!-- end:: Header Mobile -->
    <div class="kt-grid kt-grid--hor kt-grid--root">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <SideBar ref="modal" />
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper responsiv__space" id="kt_wrapper"
          style="padding-top: 60px !important;">
          <div id="kt_content" style="
              padding-top: 0;
              padding-bottom: 0;
    margin: 17px;
              overflow: hidden;
              position: relative;
              justify-content: center;
            ">
            <div class="top-title resp-top-spacing mb-4">
              <h3 class="text-white" style="
    font-weight: bold;
    font-size: 24px;">Webinars Recordings ({{ record.length }})</h3>
              <div>
                <span @click="deleteIds.length ? deleteRecording() : ''" type="button"
                  :style="deleteIds.length == 0 ? 'opacity: 0.7;cursor: no-drop;' : ''" class="btn btn-blue">

                  {{ deleteIds.length ? 'Delete (' + deleteIds.length + ')' : 'Delete' }}
                </span>
                <span v-on:click="BackToDashboard()" class="btn btn-brand fw-normal text-white"
                  style="background: #b60808; border: #b60808;    margin-left: 10px; cursor: pointer;">
                  Back
                </span>
              </div>
            </div>
            <!-- begin:: Content -->
            <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid" style="
              border: 4px solid #2d8cff;
    border-radius: 12px;">



              <div class="webinar-recordings-list">
                <div class="table-responsive" style="
    overflow-y: scroll;
    height: calc(100vh - 20vh);
    padding: 16px;"
                  :style="data_loader ? 'display: flex; align-items: center; justify-content: center;' : record.length == 0 ? 'display: flex; align-items: center' : ''">
                  <div class="card-body" v-if="data_loader" style="
                      display: flex;
                      justify-content: center;
                      position: absolute;
                      margin-bottom: 35px;
                    ">
                    <span class="cutome-spinner"></span>
                  </div>

                  <div v-if="record.length == 0 && !data_loader" class="card-body" style="
                      display: flex;
                      justify-content: center;
                    ">
                    <span style="color: white;">No Recordings Available</span>
                  </div>

                  <table v-if="record.length" class="table no-border table-videos">
                    <tbody>
                      <tr>
                        <td>
                          <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand">
                            <input :checked="isAllChecked" type="checkbox" @click="addToDelete(0, 'ALL')">
                            <span></span>
                          </label>
                        </td>
                        <td class="weight-700 text-white" style="min-width: 125px;">Date Time</td>
                        <td class="weight-700 text-white" style="min-width: 400px;">Title</td>
                        <td class="weight-700 text-white" style="min-width: 130px;">File Size</td>
                        <td class="weight-700 text-white" style="min-width: 130px;">Duration</td>
                        <td class="weight-700 text-white" style="min-width: 150px;">Download</td>
                      </tr>


                      <tr v-for="records in record">
                        <td>
                          <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand">
                            <input :checked="deleteIds.some((data) => data === records._id) ? true : false"
                              type="checkbox" @click="addToDelete(records._id, 'SINGLE')">
                            <span></span>
                          </label>
                        </td>
                        <td class=" text-white">{{ new Date(records?.createdAt)?.toLocaleString() }}</td>
                        <td class=" text-white">{{ records.title }}</td>
                        <td class=" text-white" colspan="3" v-if="!records.fileSize && fileName == records.downloadLink"
                          style="text-align: center;"><span style="color: red;    font-size: medium;"> Processing video
                            {{ loaderVal }}</span> <span style="color: white;"> (*You don't have to stay on the
                            page)</span></td>

                        <td class=" text-white" v-if="records.fileSize">{{ records.fileSize }}</td>
                        <td class=" text-white" v-if="records.fileSize">{{ records.duration }}</td>
                        <td class=" text-white" v-if="records.fileSize">
                          <a style="width: 150px; position: relative" class="download-vido"
                            :class="downloadLoader.some((data) => data === records.downloadLink) ? 'recordAnimation' : ''"
                            @click="downloadRecording(records.downloadLink)">
                            <span v-if="downloadLoader.some((data) => data === records.downloadLink)"
                              class="dspinner spinner-white spinner-right"></span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="62" viewBox="0 0 70 62">
                              <g id="Group_434" data-name="Group 434" transform="translate(-1183 -637)">
                                <g id="Group_300" data-name="Group 300">
                                  <rect id="Rectangle_170" data-name="Rectangle 170" width="70" height="62" rx="9"
                                    transform="translate(1183 637)" fill="#2d8cff"></rect>
                                  <path v-if="!downloadLoader.some((data) => data === records.downloadLink)"
                                    id="Path_479" data-name="Path 479"
                                    d="M28,22.5v5.222a2.611,2.611,0,0,1-2.611,2.611H7.111A2.611,2.611,0,0,1,4.5,27.722V22.5"
                                    transform="translate(1201.5 649.167)" fill="none" stroke="#fff"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path>
                                  <path v-if="!downloadLoader.some((data) => data === records.downloadLink)"
                                    id="Path_480" data-name="Path 480" d="M10.5,15l6.528,6.528L23.556,15"
                                    transform="translate(1200.722 650.139)" fill="none" stroke="#fff"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path>
                                  <path v-if="!downloadLoader.some((data) => data === records.downloadLink)"
                                    id="Path_481" data-name="Path 481" d="M18,20.167V4.5"
                                    transform="translate(1199.75 651.5)" fill="none" stroke="#fff"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path>
                                </g>
                              </g>
                            </svg>
                          </a>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- end:: Content -->
          </div>

        </div>


        <div class="modal fade bd-example-modal-xl" id="warningModal" tabindex="-1" role="dialog" aria-hidden="true"
          style="background: #000000bf;">
          <div class="modal-dialog modal-dialog-centered modal" role="document">
            <div class="modal-content" style="background: #fff;">
              <div class="border-dotted">
                <div class="modal-body">
                  <div class="row" style="margin-top: 14px;">
                    <div class="col-md-12">
                      <h3 class="text-dark weight-700">
                        Are you sure you want to Delete Recordings?
                      </h3>
                      <hr style="margin-bottom: 0;" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 15px;">
                <span v-on:click="FinalDeleteRecords()" type="button" id="BtnNotReadableError"
                  class="btn white-btn mr-3" style="width: 150px; background: #b60808; color: #fff;position: relative"
                  data-toggle="modal" data-target="#add-new-webinar-calendar">
                  Yes
                  <span v-if="is_delete_loader" class="spinner spinner-white spinner-right"></span>
                </span>
                <span v-on:click="closewarningModal()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
                  style="width: 150px; background: green; color: #fff;" data-toggle="modal"
                  data-target="#add-new-webinar-calendar">
                  No
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
window.addEventListener("pageshow", function (event) {
  var historyTraversal = event.persisted,
    perf = window.performance,
    perfEntries = perf && perf.getEntriesByType && perf.getEntriesByType("navigation"),
    perfEntryType = perfEntries && perfEntries[0] && perfEntries[0].type,
    navigationType = perf && perf.navigation && perf.navigation.type;
  if (historyTraversal || perfEntryType === "back_forward" || navigationType === 2) {
    // Handle page restore.
    window.location.reload(true);
  }
});

import { ref, reactive } from "vue";
import axios from "axios";
import {
  getRecording,
  deleteRecording,
  unlinkRecording,
} from "../services/service";
import io from "socket.io-client";
import SideBar from "../WebinarModel/SideBar.vue";
axios.defaults.headers.post["Content-Type"] = "application/json";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import { v4 as uuidv4 } from "uuid";
import mod from "@/assets/vendors/general/moment/src/lib/utils/mod";
export default {
  components: {
    SideBar,
  },
  data() {
    Vue.nextTick(() => {
      document.title = "Unity Webinar";
    });
    return {
      chunks: ref([]),
      record: [],
      is_delete_loader: false,
      isAllChecked: false,
      deleteIds: [],
      downloadLoader: [],
      socket: null,
      namea: '',
      loaderVal: '',
      fileName: '',
      progress: 5,
      data_loader: true,
      date: new Date(),
      time: new Date().toLocaleString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      socket: null,
      BASE_URL:
        window.location.host == "unitywebinar.com" ? "https://api.unitywebinar.com/" : window.location.host == "dev.unitywebinar.com" ? "https://dev-api.unitywebinar.com/" : "https://api.unitywebinar.com/",
    };
  },
  computed: {
  },
  mounted: async function () {
  },

  async created() {
    if (!localStorage.getItem("isAuthenticated")) {
      this.$router.push("/login");
    } else {
      await this.loadData();
      this.socket = io(this.BASE_URL, { autoConnect: true });
      this.socket.on("connect", async () => {

        const obj = {
          email: JSON.parse(localStorage.getItem("userdetails")).email,
          userId: JSON.parse(localStorage.getItem("userdetails"))._id,
        };
        console.log("obj________", obj);
        this.socket.emit("addUserRecordCall", obj);
      });
      this.socket.on("sizeReducedFileDone", async (data) => {
        console.log("sizeReducedFileDone--", data); // undefined

        this.loaderVal = data.data.split(',')[1].split('(')[0].replaceAll(' ', '')
        this.fileName = data.fileName;
        await this.loadData();

        this.loaderVal = '';

        this.fileName = '';
      });
      this.socket.on("sizeReducedFile", async (data) => {
        console.log("sizeReducedFile--", data); // undefined

        if (this.fileName == '') {
          await this.loadData();
        }

        if (data.data != '\n') {
          this.fileName = data.fileName;
          var value = data.data.split(',')[1].split('(')[0].replaceAll(' ', '')
          var decimalPoint = value.toString().split('.')[0];
          this.loaderVal = decimalPoint + '%';
        }
        // if (this.loaderVal == '99.87 %') {
        //   await this.loadData();
        // }
      });
    }

  },

  methods: {
    async BackToDashboard() {
      var rr = { data: '\n', fileName: '049ec51b-6806-43bc-9c82-2b06fcf9d317-output.mp4' };
      const obj = {
        email: JSON.parse(localStorage.getItem("userdetails")).email,
        userId: JSON.parse(localStorage.getItem("userdetails"))._id,
      };
      this.socket.emit("disconnectManually", obj);
      // this.socket.disconnect();

      this.$router.push("/calendar");
    },
    async closewarningModal() {
      $("#warningModal").modal("hide");
    },
    async addToDelete(id, type) {
      if (type == 'ALL') {

        if (this.isAllChecked) {
          for (let r = 0; r < this.record.length; r++) {
            const element = this.record[r]._id;
            this.deleteIds = this.deleteIds.filter(item => item !== element)
          }
          this.isAllChecked = false;
        } else {
          for (let r = 0; r < this.record.length; r++) {
            const element = this.record[r]._id;
            if (this.deleteIds.some((ids) => ids === element)) {
            } else {
              this.deleteIds.push(element);
            }
          }
          this.isAllChecked = true;
        }
      } else {
        this.isAllChecked = false;
        if (this.deleteIds.some((ids) => ids === id)) {
          this.deleteIds = this.deleteIds.filter(item => item !== id)
        } else {
          this.deleteIds.push(id);
        }
      }

      if (this.deleteIds.length == this.record.length) {
        this.isAllChecked = true;
      } else {
        this.isAllChecked = false;
      }
    },
    async downloadRecording(link) {
      let modifiedLink = link;
      if(link){
        if(link.includes('-Final')){
          console.log("go inside this---------")
        }else{
          modifiedLink = modifiedLink.replace(/\.mp4$/, "-Final.mp4");
          console.log("Modified link with '-Final':", link);
        }
      }
      this.downloadLoader.push(link);

      this.socket.emit("generateUrlSingleStorj",
        {
          fileName: modifiedLink,
          socketId: this.socket.id
        },
        async (response) => {


        }
      );


      this.socket.on("recordingDownloadingUrl", async (data) => {
        console.log("data----------", data)

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = data + '?download=1';
        // the filename you want
        a.download = true;
        document.body.appendChild(a);
        a.click();

        this.downloadLoader = [];
      });
    },
    async FinalDeleteRecords() {
      this.is_delete_loader = true;
      const body = {
        id: this.deleteIds
      }
      const data = await deleteRecording(body);
      console.log(data)

      if (data.status == 200) {
        this.is_delete_loader = false;
        $("#warningModal").modal("hide");
        await this.loadData();
      }
      this.deleteIds = [];
    },
    async deleteRecording() {
      $("#warningModal").modal("show");
    },
    async loadData() {

      const getRecors = await getRecording();
      console.log("getRecors---22----", getRecors);

      if (getRecors.response) {
        if (getRecors.response.data.code == 401 || getRecors.response.status == 401) {
          localStorage.clear();
          swal(getRecors.response.data.message);
          this.$router.push("/login");
        }
      } else {

        this.record = getRecors.data.result.data;
        this.data_loader = false;

        this.socket = io(this.BASE_URL, { autoConnect: true });
        this.socket.on("connect", async () => {

        });

        this.socket.on('fileDetails', (name) => {
          console.log('file details')
          this.namea = name
        })

        this.socket.on('getChunk', (chunk) => {
          console.log('chunk', chunk)
          this.chunks.push(chunk)
        })

        this.socket.on('downloadFinished', () => {
          console.log('finished')
          const blob = new Blob(this.chunks, { type: 'video/mp4' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url;
          a.download = this.namea;
          a.click()
          URL.revokeObjectURL(url)
        })
      }
    },
  },
};
</script>
<style scoped>
/* Absolute Center Spinner */
tr:first-child {
  background: transparent;
  margin: 0 !important;
}

td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 15px !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 1em !important;
}

td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

tr {
  background: #373737;
}

td {
  vertical-align: middle !important;
}

.kt-checkbox>input {
  position: relative !important;
}

::-webkit-scrollbar {
  width: 10px;
  margin-right: 10px !important;
}

::-webkit-scrollbar-thumb {
  border: 2px solid rgb(45 140 255);
  background-clip: padding-box;
  border-radius: 9999px;
}

.kt-container {
  padding: 10px 10px !important;
}

.kt-checkbox.kt-checkbox--brand>span:after {
  border-color: white !important;
}

.dspinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 60%;
  right: 28%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
}

.recordAnimation {
  animation: record 1.5s ease infinite normal;
}

@keyframes record {
  10% {
    opacity: 0.1;
  }

  30% {
    opacity: 0.3;
  }

  60% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}


.shell {
  height: 20px;
  width: 250px;
  border: 1px solid #aaa;
  border-radius: 13px;
  padding: 3px;
}

.bar {
  background: linear-gradient(to right, #B993D6, #8CA6DB);
  height: 20px;
  width: 15px;
  border-radius: 9px;

  span {
    float: right;
    padding: 4px 5px;
    color: #fff;
    font-size: 0.7em
  }
}

button {
  margin: 10px;
  border: 1px solid #aaa;
  background: none;
  border-radius: 50%;
  padding: 5px 8px;
  outline: none;
  cursor: pointer;
}
</style>