<template>
    <div class="dropdown-container" @click.stop>
        <div class="input-wrapper">
            <input 
                type="text" 
                v-model="searchQuery" 
                :placeholder="placeholder" 
                @focus="handleFocus"
                @input="filterOptions" 
                @blur="handleBlur"
                class="styled-input" 
            />
            <button type="button" @click="toggleDropdown" class="dropdown-button">▼</button>
        </div>

        <ul v-if="showDropdown" class="dropdown-list">
            <li v-for="option in filteredOptions" :key="option.value" @click="selectOption(option)">
                {{ option.label }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: String,
        options: Array,
        placeholder: { type: String, default: "Select an option" },
    },
    mounted() {
        document.addEventListener("click", this.closeDropdown);
        this.setInitialSelection();
    },
    beforeUnmount() {
        document.removeEventListener("click", this.closeDropdown);
    },
    data() {
        return {
            searchQuery: "",
            showDropdown: false,
            filteredOptions: this.options,
            previousValue: ""
        };
    },
    watch: {
        modelValue() {
            this.setInitialSelection();
        }
    },
    methods: {
        setInitialSelection() {
            const selectedOption = this.options.find(option => option.value === this.modelValue);
            this.searchQuery = selectedOption ? selectedOption.label : "";
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
            if (this.showDropdown) {
                this.previousValue = this.searchQuery;
                this.searchQuery = "";
                this.filteredOptions = this.options;
            } else {
                this.searchQuery = this.previousValue;
            }
        },
        handleFocus() {
            this.previousValue = this.searchQuery;
            this.searchQuery = "";
            this.filteredOptions = this.options;
            this.showDropdown = true;
        },
        handleBlur() {
            if (!this.modelValue) {
                this.searchQuery = this.previousValue;
            }
        },
        filterOptions() {
            this.filteredOptions = this.options.filter(option =>
                option.label.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
        selectOption(option) {
            this.searchQuery = option.label;
            this.$emit("update:modelValue", option.value);
            this.showDropdown = false;
        },
        closeDropdown(event) {
            if (!this.$el.contains(event.target)) {
                this.showDropdown = false;
                this.searchQuery = this.modelValue ? this.options.find(opt => opt.value === this.modelValue)?.label || "" : this.previousValue;
            }
        }
    }
};
</script>

<style>
.dropdown-container {
    position: relative;
    display: inline-block;
}

.input-wrapper {
    position: relative;
    width: 100%;
}

.styled-input {
    width: 100%;
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #2D8CFF;
    color: white;
    outline: none;
}

.styled-input::placeholder {
    color: white;
    opacity: 0.8;
}

.dropdown-button {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: white;
    width: max-content !important;
}

.dropdown-list {
    position: absolute;
    background: #2D8CFF;
    color: white;
    border: 1px solid #ccc;
    width: 100%;
    max-height: 80px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: start;
    z-index: 10;
}

.dropdown-list li {
    padding: 8px;
    cursor: pointer;
}

.dropdown-list li:hover {
    background: #0f73ed;
    color: white;
}
</style>
