export class AudioCaptureStream {

    constructor(file) {
        this.fileNode = file;
        this.resolver = null;
        this.generatedStream = new Promise((resolve) => this.resolver = resolve);
        this.audioCtx = null;
        this.source = null;
        this.destination = null;
        this.processStream()
    }


    processStream() {
        if (typeof HTMLAudioElement?.prototype?.captureStream === 'function') {
            this.resolver(this.fileNode.captureStream())
        }
        else if (typeof HTMLAudioElement?.prototype?.mozCaptureStream === 'function') {
            this.resolver(this.fileNode.mozCaptureStream());
        }
        else {
            this.audioCtx = new AudioContext()
            this.source = this.audioCtx.createMediaElementSource(this.fileNode)
            this.destination = this.audioCtx.createMediaStreamDestination()
            this.source.connect(this.destination)
            this.source.connect(this.audioCtx.destination)
            this.resolver(this.destination.stream)
        }
    }

    async close() {
        this.resolver = null;
        const stream = await this.generatedStream;
        stream.getTracks()?.forEach((track) => {
            track?.stop()
        })
        this.generatedStream = null;
        this.source?.disconnect()
        this.source = null;
        this.destination = null;
        await this.audioCtx?.close()
        this.audioCtx = null;
    }

}