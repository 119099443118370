<template>
  <body
    class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
    <!-- <webinarInvitemodel ref="modal" /> -->
    <!-- Test -->
    <div class="modal fade bd-example-modal-xl" id="inviteusermodal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <h3 class="weight: 700;">
                        Participants ({{ users.length }})
                      </h3>
                    </div>
                  </div>
                  <div class="row mt-3 mb-3">
                    <div class="col-md-4">
                      <div class="input-group mb-3">
                        <input type="email" class="form-control bg-white text-black" v-model="invitefirstName"
                          placeholder="First Name" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="input-group mb-3">
                        <input type="email" class="form-control bg-white text-black" v-model="invitelastName"
                          placeholder="Last Name " />
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="input-group mb-3">
                        <input type="email" class="form-control bg-white text-black" v-model="inviteUser"
                          placeholder="Search..." />
                      </div>

                      <div :class="[isEmailValid(), disableBTN()]" style="color: #ff8b00; font-weight: bold">
                        <em>Please enter valid Email</em>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <button type="button" class="btn border-radius add-new-video-link width-100"
                        style="background: #2d8cff; color: #ffff" data-toggle="dropdown" @click="addParticipants()"
                        :disabled="!disable">
                        + Invite
                      </button>
                    </div>
                  </div>
                  <div style="max-height: 230px; overflow-y: auto">
                    <div v-for="(key, i) in this.users" :key="i" class="user-list-box mb-3">
                      <div class="">
                        <div class="">
                          <div class="" style="display: flex">
                            <input type="hidden" v-model="users[i].Name" />
                            <input type="hidden" v-model="users[i].email" />
                            <div class="w-100 d-flex justify-content-between">
                              <div style="color: #fff">{{ key.email }} ({{ key.firstName }} {{ key.lastName == 'lastName'
                                ? users[i].webinarUserType : key.lastName }})</div>
                              <button v-on:click="() => deleted(users[i])" type="button" class="btn white-btn"
                                style="background: #b60808; color: #fff" data-toggle="modal"
                                data-target="#add-new-webinar-calendar">
                                Delete
                              </button>
                            </div>
                          </div>
                          <div class="row mt-3 ml-1">
                            <label class="custom kt-radio kt-radio--bold kt-radio--brand">
                              <input type="radio" value="Host" v-model="users[i].webinarUserType" />
                              Host
                              <span></span>
                            </label>
                            <label class="custom kt-radio kt-radio--bold kt-radio--brand">
                              <input type="radio" value="Moderator" v-model="users[i].webinarUserType" />
                              Moderator
                              <span></span>
                            </label>
                            <label class="custom kt-radio kt-radio--bold kt-radio--brand">
                              <input type="radio" value="Guest" v-model="users[i].webinarUserType" />
                              Guest
                              <span></span>
                            </label>
                            <label class="custom kt-radio kt-radio--bold kt-radio--brand">
                              <input type="radio" value="Viewer" v-model="users[i].webinarUserType" />
                              Viewer
                              <span></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 text-center align-items-center justify-content-center">
                <button v-on:click="save()" type="button" class="btn white-btn mr-3"
                  style="width: 150px; position: relative" :disabled="disabled">
                  <span v-if="loader" class="spinner spinner-white spinner-right"></span>
                  Add Participants
                </button>
                <button type="button" class="btn transparent-btn border-blue" style="width: 150px" @click="closeModall()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade bd-example-modal-xl" id="editusermodal" tabindex="-1"
      style="z-index: 9999;background: rgb(0 0 0 / 92%);" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" style="width: fit-content;" role="document">
        <div class="modal-content">
          <div class="border-dotted">
            <div class="modal-body" style="    border: 1px solid;
        border-radius: 6px;">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <h1 class="text-white">
                        Edit Contact
                      </h1>
                    </div>
                  </div>
                  <div class="row mt-3 mb-3">
                    <div class="col-md-6">
                      <lable class="text-white mb-1">First Name</lable>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control bg-white text-black" v-model="editFirstName"
                          placeholder="First Name" />
                      </div>
                    </div>
                    <input type="hidden" class="form-control bg-white text-black" v-model="editUserId"
                      placeholder="Last Name " />
                    <div class="col-md-6">
                      <lable class="text-white mb-1">Last Name</lable>
                      <div class="input-group mb-3">

                        <input type="text" class="form-control bg-white text-black" v-model="editLastname"
                          placeholder="Last Name " />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <lable class="text-white mb-1">Email</lable>
                      <div class="input-group mb-3">

                        <input type="email" class="form-control bg-white text-black" v-model="editEmail"
                          placeholder="Search..." />
                      </div>

                      <div :class="[isEditEmailValid(), disableBTN()]" style="color: #ff8b00; font-weight: bold">
                        <em>{{ email_error }}</em>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 text-center align-items-center justify-content-center">
                <button v-on:click="EditSave()" type="button" class="btn white-btn mr-3"
                  style="width: 150px; position: relative" :disabled="s_enable">
                  <span v-if="edit_loader" class="spinner spinner-white spinner-right"></span>
                  Save
                </button>
                <button type="button" class="btn transparent-btn border-blue" style="width: 150px"
                  @click="closeEditModall()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" id="WebinarViewModel" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="closeIcon">
            <div class="d-flex align-items-center">
              <h2 class="text-white weight-700">View Webinar</h2>
              <svg v-if="webinarType != 'OPEN_ROOM' && is_edit_btn" v-on:click="editWebinar()"
                xmlns="http://www.w3.org/2000/svg" width="30" height="30" version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 401.523 401"
                style="enable-background:new 0 0 512 512;margin-left: 11px;cursor: pointer;" xml:space="preserve"
                class="">
                <g>
                  <path style="fill: white;"
                    d="M370.59 250.973c-5.524 0-10 4.476-10 10v88.789c-.02 16.562-13.438 29.984-30 30H50c-16.563-.016-29.98-13.438-30-30V89.172c.02-16.559 13.438-29.98 30-30h88.79c5.523 0 10-4.477 10-10 0-5.52-4.477-10-10-10H50c-27.602.031-49.969 22.398-50 50v260.594c.031 27.601 22.398 49.968 50 50h280.59c27.601-.032 49.969-22.399 50-50v-88.793c0-5.524-4.477-10-10-10zm0 0"
                    fill="#000000" opacity="1" data-original="#000000" class=""></path>
                  <path style="fill: white;"
                    d="M376.629 13.441c-17.574-17.574-46.067-17.574-63.64 0L134.581 191.848a9.997 9.997 0 0 0-2.566 4.402l-23.461 84.7a9.997 9.997 0 0 0 12.304 12.308l84.7-23.465a9.997 9.997 0 0 0 4.402-2.566l178.402-178.41c17.547-17.587 17.547-46.055 0-63.641zM156.37 198.348 302.383 52.332l47.09 47.09-146.016 146.016zm-9.406 18.875 37.62 37.625-52.038 14.418zM374.223 74.676 363.617 85.28l-47.094-47.094 10.61-10.605c9.762-9.762 25.59-9.762 35.351 0l11.739 11.734c9.746 9.774 9.746 25.59 0 35.36zm0 0"
                    fill="#000000" opacity="1" data-original="#000000" class=""></path>
                </g>
              </svg>
            </div>
            <svg @click="closeViewCloseModal()" width="35" height="35" xmlns="http://www.w3.org/2000/svg" version="1.1"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 320.591 320.591"
              style="enable-background:new 0 0 512 512;padding: 8px;cursor: pointer;" xml:space="preserve" class="">
              <g>
                <path
                  d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                  fill="#ffffff" opacity="1" data-original="#000000" class=""></path>
                <path
                  d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                  fill="#ffffff" opacity="1" data-original="#000000" class=""></path>
              </g>
            </svg>
          </div>
          <div class="border-dotted">
            <div class="modal-body">
          
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="one-time" role="tabpanel" aria-labelledby="kt_tab_pane_2">
                  <div class="row">
                    <div class="col-md-6 res-col">
                      <form>
                        <div class="form-group">
                          <div class="input-group">
                            <input type="text" class="text-center blue-border dark-bg form-control"
                              id="exampleInputPassword1" :style="webinarType != 'OPEN_ROOM' ? 'color: #ffffffa8;border: 1px solid #2d8cff82 !important;' : ''"
                              placeholder="Title" v-model="title" disabled v-if="is_edit_btn" />
                            <input type="text" class="text-center blue-border dark-bg form-control"
                              id="exampleInputPassword1" placeholder="Title" v-model="title" v-if="is_save_btn" />
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="input-group date">
                            <Datepicker style="width: 100%;" v-if="is_save_btn"
                              class="text-center form-control blue-border date-picker-custom" v-model="editWebinarDate"
                              inputFormat="MM/dd/yyyy" />
                            <input :style="webinarType != 'OPEN_ROOM' ? 'color: #ffffffa8;border: 1px solid #2d8cff82 !important;' : ''" type="text"
                              v-if="is_edit_btn" class="text-center blue-border dark-bg form-control"
                              id="exampleInputPassword1" placeholder="Time Zone" v-model="date1" disabled />

                          </div>
                        </div>

                        <div class="form-group" v-if="webinarType == 'OPEN_ROOM'">
                          <div class="input-group">
                            <input type="text" class="text-center blue-border dark-bg form-control" v-model="urls"
                            disabled />
                            <a class="btn btn-danger copyMyUrl" id="copyMyUrl"
                              style="margin-left: 10px; color: white;cursor: pointer;" @click="copyUrl()">
                              Copy Link
                            </a>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <h4 class="text-white">Time</h4>
                              <input type="text" :style="webinarType != 'OPEN_ROOM' ? 'color: #ffffffa8;border: 1px solid #2d8cff82 !important;' : ''"
                                v-if="is_edit_btn" class="text-center blue-border dark-bg form-control" id="exampleInputPassword1"
                                placeholder="Time Zone" v-model="time" disabled />

                              <vue-timepicker class="text-center" v-if="is_save_btn" id="vueTime" v-model="time"
                                hide-clear-button format="hh:mm A">
                              </vue-timepicker>

                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <h4 class="text-white">Timezone</h4>
                              <input :style="webinarType != 'OPEN_ROOM' ? 'color: #ffffffa8;border: 1px solid #2d8cff82 !important;' : ''" type="text"
                                v-if="is_edit_btn" class="text-center blue-border dark-bg form-control"
                                id="exampleInputPassword1" placeholder="Time Zone" v-model="timezone" disabled />
                              <v-select style="height: 37px;" v-model="timezone" v-if="is_save_btn" placeholder="Timezone"
                                class="select-picker blue-border dark-bg"
                                :options="Intl.supportedValuesOf('timeZone')"></v-select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <h4 class="text-white">Duration</h4>
                              <input type="text"
                              :style="webinarType != 'OPEN_ROOM' ? 'color: #ffffffa8;border: 1px solid #2d8cff82 !important;' : ''"
                                v-if="is_edit_btn" class="text-center blue-border dark-bg form-control"
                                id="exampleInputPassword1" placeholder="Duration" v-model="duration" disabled />
                              <vue-timepicker style="width: 100%;" id="vueTime" v-model="duration" v-if="is_save_btn"
                                hide-clear-button></vue-timepicker>

                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="col-md-6 res-col">
                      <div class="row" >

<div class="align-self-end col-md-12 text-right" style="display: flex">
  <input style="margin-right: 10px" type="text" v-model="search" class="form-control bg-white text-black"
    placeholder="Email" />
  <button v-if="webinarType == 'OPEN_ROOM'" style="max-width: 140px; width: 100%;" class="btn btn-danger"
    disabled v-on:click="deleteWebinar()">
    <span class="spinner" v-if="loader" style="position: relative"></span>Delete Webinar
  </button>
  <button v-if="webinarType != 'OPEN_ROOM'" style="max-width: 140px; width: 100%;" class="btn btn-danger"
    v-on:click="deleteWebinar()">
    <span class="spinner" v-if="loader" style="position: relative"></span>Delete Webinar
  </button>

</div>
</div>
                      <div class="d-flex justify-content-between" style="padding-top: 8px;align-items: center;">
                        <h3 class="weight: 700;">
                          Participants({{ filteredList.length }})
                        </h3>

                        <button class="btn btn-brand mt-2 invite-btn" style="    margin-bottom: 10px;" @click="handleclick()">
                          + Invite
                        </button>
                      </div>

                      <div style="max-height: 230px; overflow-y: auto;overflow-x: hidden;">
                        <div id="demo" v-for="(key, i) in filteredList" :key="i" class="user-list-box mb-3">
                          <div class="row" style="">
                            <div class="ml-2 col-12">
                              <div>
                                <input type="hidden" v-model="users[i].Name" />
                                <input type="hidden" v-model="users[i].email" />
                                <p style="color: #fff ;display: flex;margin: 0;"> {{ key.email }}&nbsp;
                                <p v-if="key.firstName"> ({{ key.firstName }} {{ key.lastName }}) -
                                  {{ key.webinarUserType }}</p>
                                </p>
                              </div> 
                            </div>
                            <div class="ml-2 col" style="display: flex; justify-content: flex-end">
                              <div class="user-list-box-info" style="
                                      display: flex;
                                      width: max-content;
                                      overflow: auto;
                                      padding: 0;
                                    ">
                                <button class="btn btn-danger mt-2" style="
                                            background: #008080;
                                            border:#008080;"
                                  :title="users[i].email == loggedUserEmail ? 'This user is logged and cannot be edited' : ''"
                                  :disabled="users[i].email == loggedUserEmail ? true : false" @click="
                                    openEditUserModal(users[i])" :id="'_EC_' + users[i].userid">
                                  Edit Contact
                                </button>
                                <span style="margin-left: 8px">
                                  <a href="javascript:void(0)" data-toggle="dropdown">
                                    <button class="btn btn-brand mt-2" style="
                                            background: #b60808;
                                            border: #b60808;
                                          " :id="'_ER_' + users[i].userid">
                                      Edit Role
                                    </button>
                                  </a>

                                  <div class="dropdown-menu dropdown-menu-right bg-gray">
                                    <div class="row mb-2 editRole" @click="
                                      editRole(
                                        'Host',
                                        key.email,
                                        users[i].userid
                                      )
                                      ">
                                      <div class="col-8">
                                        <a href="#" style="color: #fff">Host
                                          <i v-if="key.webinarUserType == 'Host'" style="color: white">✓
                                          </i></a>
                                      </div>
                                    </div>
                                    <div class="row mb-2 editRole" @click="
                                      editRole(
                                        'Moderator',
                                        key.email,
                                        users[i].userid
                                      )
                                      ">
                                      <div class="col-8">
                                        <a href="#" style="color: #fff">Moderator
                                          <i v-if="key.webinarUserType == 'Moderator'
                                            " style="color: white">✓
                                          </i></a>
                                      </div>
                                    </div>
                                    <div class="row mb-2 editRole" @click="
                                      editRole(
                                        'Guest',
                                        key.email,
                                        users[i].userid
                                      )
                                      ">
                                      <div class="col-8">
                                        <a href="#" style="color: #fff">Guest
                                          <i v-if="key.webinarUserType == 'Guest'
                                            " style="color: white">✓
                                          </i>
                                        </a>
                                      </div>
                                    </div>
                                    <div class="row" @click="
                                      editRole(
                                        'Viewer',
                                        key.email,
                                        users[i].userid
                                      )
                                      ">
                                      <div class="col-8">
                                        <a href="#" style="color: #fff">Viewer
                                          <i v-if="key.webinarUserType == 'Viewer'
                                            " style="color: white">✓
                                          </i></a>
                                      </div>
                                    </div>
                                  </div>
                                </span>

                                <button v-on:click="
                                  openRemoveParticipantsModel(key.email)
                                  " class="btn btn-brand mt-2"
                                  :title="users[i].email == loggedUserEmail ? 'This user is logged and cannot be remove' : ''"
                                  style="
                                        background: #b60808;
                                        border: #b60808;
                                        margin-left: 10px;
                                      " :disabled="users[i].email == loggedUserEmail ? true : false">
                                  Remove User
                                </button>

                                <button class="btn btn-brand mt-2" style="margin-left: 10px" :id="users[i].email"
                                  @click="resendInvite(users[i])">
                                  Resend Invite
                                </button>
                                <input :id="users[i].userid + '_COPYTEXT'" type="hidden" value="" />
                                <button v-if="webinarType != 'OPEN_ROOM'" class="btn btn-brand mt-2"
                                  style="margin-left: 10px; background: #ffb822; border: #ffb822; color:'black"
                                  :id="users[i].userid + '_COPY'" @click="copyLink(users[i])">
                                  Copy Link
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 text-center align-items-center justify-content-center justify-content-center">
                  <button v-on:click="(e) => Create(e)" type="button" class="btn white-btn mr-3" style="width: 150px"
                    data-toggle="modal" data-target="#add-new-webinar-calendar">
                    Join Meeting
                  </button>
                  <button type="button" class="btn transparent-btn border-blue" style="width: 150px" data-dismiss="modal"
                    @click="closeViewModal()">
                    Cancel
                  </button>

                  <button v-if="is_save_btn" style="max-width: 140px; width: 100%;align-items: center;
    justify-content: center;display: flex;" class="btn btn-info mx-3" v-on:click="SaveWebinar()">
                    Save <span class="spinner" v-if="save_loader" style="position: relative"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" id="ViewModel" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <h2 class="text-white weight-700">Delete Webinar</h2>
                </div>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="one-time" role="tabpanel" aria-labelledby="kt_tab_pane_2">
                  <div class="row">
                    <div class="col-md-6">
                      <form>
                        <div>
                          <div class="form-group" style="margin-top: 20px">
                            <div class>
                              <label class="kt-checkbox text-white kt-checkbox--brand">
                                <input type="checkbox" v-model="recurringStatus" />
                                Also delete all future webinars?
                                <span></span>
                              </label>
                            </div>
                          </div>

                          <div style="margin-top: 20px">
                            <div class>
                              <label class="kt-checkbox text-white kt-checkbox--brand">
                                <input type="checkbox" v-model="sendMailVerify" />
                                Notify All Users Subscribed To This Webinar
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 32px">
            <button v-on:click="deleteWebinar(true)" type="button" class="btn white-btn mr-3"
              style="width: 150px; background: #b60808; color: #fff" data-toggle="modal"
              data-target="#add-new-webinar-calendar">
              Delete
            </button>
            <button type="button" class="btn transparent-btn border-blue" style="width: 150px" data-dismiss="modal"
              @click="closeDeleteModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div v-if="show == 'true'"></div>
    </div>

    <div class="modal fade bd-example-modal-xl" id="deleteconformmodel" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <h2 class="text-white weight-700">
                    Are you sure you want to delete this webinar?
                  </h2>
                </div>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="one-time" role="tabpanel" aria-labelledby="kt_tab_pane_2">
                  <div class="row">
                    <div class="col-md-6">
                      <form>
                        <div>
                          <div style="margin-top: 20px">
                            <div class>
                              <label class="kt-checkbox text-white kt-checkbox--brand">
                                <input type="checkbox" v-model="sendMailVerify" />
                                Notify All Users Subscribed To This Webinar
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 32px">
            <button v-on:click="deleteWebinar(true)" type="button" class="btn white-btn mr-3"
              style="width: 150px; background: #b60808; color: #fff;position: relative;" data-toggle="modal"
              data-target="#add-new-webinar-calendar">
              Delete
              <span v-if="del_loader" class="spinner spinner-white spinner-right"></span>
            </button>
            <button type="button" class="btn transparent-btn border-blue" style="width: 150px" data-dismiss="modal"
              @click="closeConfDeleteModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div v-if="show == 'true'"></div>
    </div>


    <div class="modal fade bd-example-modal-xl" id="removeuser" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <h2 class="text-white weight-700">Remove user</h2>
                </div>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="one-time" role="tabpanel" aria-labelledby="kt_tab_pane_2">
                  <div class="row">
                    <div class="col-md-6">
                      <form>
                        <div>
                          <div style="margin-top: 20px">
                            <div class>
                              <label class="kt-checkbox text-white kt-checkbox--brand">
                                <input type="checkbox" v-model="userVerifyMail" />
                                Notify User Subcribed To This Webinar
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 32px">
            <button v-on:click="removeParticipants()" type="button" class="btn white-btn mr-3"
              style="width: 150px; background: #b60808; color: #fff" data-toggle="modal"
              data-target="#add-new-webinar-calendar">
              Delete
            </button>
            <button type="button" class="btn transparent-btn border-blue" style="width: 150px" data-dismiss="modal"
              @click="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div v-if="show == 'true'"></div>
    </div>

    <div class="modal fade bd-example-modal-xl" id="removeconformmodel" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <h2 class="text-white weight-700">
                    Are you sure you want to delete this webinar?
                  </h2>
                </div>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="one-time" role="tabpanel" aria-labelledby="kt_tab_pane_2">
                  <div class="row">
                    <div class="col-md-6">
                      <form>
                        <div>
                          <div style="margin-top: 20px">
                            <div class>
                              <label class="kt-checkbox text-white kt-checkbox--brand">
                                <input type="checkbox" v-model="sendMailVerify" />
                                Notify All Users Subscribed To This Webinar
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 32px">
            <button v-on:click="removeParticipants(true)" type="button" class="btn white-btn mr-3"
              style="width: 150px; background: #b60808; color: #fff" data-toggle="modal"
              data-target="#add-new-webinar-calendar">
              Delete
            </button>
            <button type="button" class="btn transparent-btn border-blue" style="width: 150px" data-dismiss="modal"
              @click="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div v-if="show == 'true'"></div>
    </div>
  </body>
</template>

<script>
import { RRule, RRuleSet, rrulestr } from "rrule";
import VueTimepicker from "vue3-timepicker";
import "vue3-timepicker/dist/VueTimepicker.css";
import axios from "axios";
import moment from "moment-timezone";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import { checkWebinarParticipantEmail, webinaroneTime, updateDateTimeForWebinars, deleteWebinars, resendInvite, participants, listupdateParticipants, removeParticipants, singlewebinardata } from "../services/service";
import Datepicker from "vue3-datepicker";

export default {
  name: "WebinarModel",
  components: {
    RRule,
    RRuleSet,
    rrulestr,
    Datepicker,
    vSelect,
    VueTimepicker,
  },
  props: {
    data: Object,
    mode: String,
  },
  data() {
    return {
      userVerifyMail: true,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      disabled: false,
      disable: "",
      disabled: false,
      del_loader: false,
      users: [],
      frequency: "day",
      webinarType: "RECURRING",
      mode: "edit",
      title: "",
      roomName: "",
      urls: "",
      date1: "",
      time: "",
      timezone: "",
      duration: "",
      timezone: "",
      loader: false,
      description: "",
      invitefirstName: "",
      invitelastName: "",
      inviteUser: "",
      webinars: [],
      users: [],
      link: "",
      webinarDetails: "",
      recurringStatus: false,
      s_enable: false,
      modal_open: false,
      edit_loader: false,
      is_edit_btn: true,
      is_save_btn: false,
      save_loader: false,
      sendMailVerify: true,
      mid: 0,
      search: "",
      filteredUsers: [],
      webinarId: "",
      email: "",
      removeEmail: "",
      editFirstName: "",
      editWebinarName: "",
      editWebinarId: "",
      editWebinarTime: "",
      editWebinarDate: "",
      editUserId: "",
      editLastname: "",
      oldDate: "",
      oldDuration: "",
      oldTime: "",
      oldTitle: "",
      oldTimeZone: "",
      email_error: "",
      editEmail: "",
      editUsersId: "",
      loggedUserEmail: JSON.parse(localStorage.getItem("userdetails")).email.replaceAll(" ", ""),
    };
  },

  computed: {
    filteredList() {
      if (this.search == "") {
        return this.users;
      } else {
        this.filteredUsers = this.users.filter((name) => {
          return name.email.toLowerCase().includes(this.search.toLowerCase());
        });
        return this.filteredUsers;
      }
    },
  },
  methods: {

    Create(e) {
      if (this.webinarType == 'OPEN_ROOM') {
        window.open(this.urls, "_blank");
      } else {
        window.open(this.link, "_blank");
      }

      // var old_url =
      //   window.location.origin +
      //   "/" +
      //   JSON.parse(localStorage.getItem("userdetails")).companyname +
      //   "/" +
      //   this.roomName;

      // window.open(old_url, "_blank");
    },

    handleclick: function () {
      this.users = [];
      $("#inviteusermodal").modal("show");
      $("#WebinarViewModel").modal("hide");
    },

    async closeConfDeleteModal() {
      this.sendMailVerify = true;
      $("#deleteconformmodel").modal("hide");
    },
    async closeDeleteModal(email) {
      this.sendMailVerify = false;
      $("#ViewModel").modal("hide");
    },
    async closeEditModall(email) {
      $("#editusermodal").modal("hide");
      this.email_error = "";
      this.modal_open = false;


    },
    async openEditUserModal(users) {
      console.log('users--------------', users)
      this.editUsersId = users.userid
      this.editFirstName = users.firstName
      this.editUserId = users.email;
      this.editLastname = users.lastName;
      this.editEmail = users.email;
      this.email_error = "";

      $("#editusermodal").modal("show");

      this.modal_open = true;

    },
    async openRemoveParticipantsModel(email) {
      this.removeEmail = email;

      $("#removeuser").modal("show");

      // window.location.reload();
    },
    closeModall() {

      $("#inviteusermodal").modal("hide");
    },
    async removeParticipants() {
      var i = this.users.length;
      let email = this.removeEmail;
      let body = {
        webinarId: this.webinarDetails,
        email: [email],
        userVerifyMail: this.userVerifyMail,
      };
      if (this.mid != 0) {
        body.childID = this.mid;
      }
      body = await removeParticipants(body);
      while (i != -1) {
        if (
          this.users[i] &&
          this.users[i].hasOwnProperty("email") &&
          this.users[i]["email"] === email
        ) {
          this.users.splice(i, 1);
        }
        console.log("u-->", i);
        i--;
      }
      $("#removeuser").modal("toggle");
    },
    updateWebinarDetails(webinarDetails, type, id, mid = 0) {

      console.log('Got it! aavyu bhai')
      this.save_loader = false;
      this.is_save_btn = false;
      this.is_edit_btn = true;
      var companyname = JSON.parse(
        localStorage.getItem("userdetails")
      ).companyname;

      console.log("webinarDetails-------------", webinarDetails);
      this.webinarDetails = id;
      this.title = webinarDetails.title;
      this.roomName = webinarDetails.roomName;
      this.duration = webinarDetails.duration;
      this.timezone = webinarDetails.timezone;
      this.users = webinarDetails.participate;
      this.urls =
        window.location.origin +
        "/" +
        companyname +
        "/" +
        webinarDetails.roomName;
      this.oldTitle = webinarDetails.title;
      this.oldDuration = webinarDetails.duration;
      this.oldTimeZone = webinarDetails.timezone;
      this.mid = mid;
      this.webinarType = type;
      // alert(moment.unix(webinarDetails.time).format("h:mm a"))
      if (type == "RECURRING") {
        this.date1 = moment.unix(webinarDetails.time).format("MM/DD/YYYY");
        this.time = moment.unix(webinarDetails.time).format("hh:mm A");
        this.oldDate = moment.unix(webinarDetails.time).format("MM/DD/YYYY");
        this.oldTime = moment.unix(webinarDetails.time).format("hh:mm A");
      } else {
        this.date1 = moment.unix(webinarDetails.time).format("MM/DD/YYYY");
        this.time = moment.unix(webinarDetails.time).format("hh:mm A");
        this.oldDate = moment.unix(webinarDetails.time).format("MM/DD/YYYY");
        this.oldTime = moment.unix(webinarDetails.time).format("hh:mm A");
      }


      var d = moment.unix(webinarDetails.time).format();
      console.log("d--->", d);
      console.log("test--->", webinarDetails.joinUrl);
      this.link =
        window.location.origin + "/join?webinarid=" +
        id +
        "&childId=" +
        this.mid;


      const specificTime = new Date(moment.unix(webinarDetails.time).format("hh:mm A"));
      const specificDate = new Date(moment.unix(webinarDetails.time).format());
      this.editWebinarDate = specificDate;
      this.editWebinarTime = specificDate.toLocaleString('en-US', { hour: "2-digit", minute: "2-digit" })

      // this.link = webinarDetails.joinUrl + "&childId=" + this.mid;
      console.log("v--->", this.editWebinarTime);
      console.log("test--->", webinarDetails.joinUrl);
    },
    copyUrl() {
      var copyText = this.urls;
      navigator.clipboard.writeText(this.urls);
      document.getElementById("copyMyUrl").classList.remove("btn-brand");
      document.getElementById("copyMyUrl").classList.add("btn-success");
      document.getElementById("copyMyUrl").innerHTML = "Copied";

      // Select the text field
      // copyText.select();
      // copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      // navigator.clipboard.writeText(this.urls);
      setInterval(function () {
        document.getElementById("copyMyUrl").classList.remove("btn-success");
        document.getElementById("copyMyUrl").classList.add("btn-danger");
        document.getElementById("copyMyUrl").innerHTML = "Copy Link";
      }, 5000);

      // Alert the copied text
    },
    async copyLink(users) {
      var id = users.userid + "_COPYTEXT";
      // var old_url =
      //   window.location.origin +
      //   "/" +
      //   JSON.parse(localStorage.getItem("userdetails")).companyname +
      //   "/" +
      //   this.roomName;
      // navigator.clipboard.writeText(old_url);
      // document
      //   .getElementById(users.userid + "_COPY")
      //   .classList.add("btn-danger");
      // document.getElementById(users.userid + "_COPY").innerHTML = "Copied";
      // alert("Copied");

      var getLink = document.getElementById(users.userid + "_COPYTEXT").value;
      if (getLink) {
        // dataRes.data.result.replace(/&amp;/g, "&")
        document.getElementById(id).value = getLink;

        let testingCodeToCopy = document.getElementById(id);
        testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
        testingCodeToCopy.select();

        try {
          var successful = document.execCommand("copy");
          var msg = successful ? "successful" : "unsuccessful";
        } catch (err) { }

        /* unselect the range */
        testingCodeToCopy.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();

        document
          .getElementById(users.userid + "_COPY")
          .classList.add("btn-success");

        document.getElementById(users.userid + "_COPY").innerHTML = "Copied";
        alert("Copied");
      } else {
        document.getElementById(users.userid + "_COPY").innerHTML =
          "Please wait..";
        var companyname = JSON.parse(
          localStorage.getItem("userdetails")
        ).companyname;

        var body = {
          email: users.email,
          forCopy: 1,
          webinarId: this.webinarDetails,
          companyname: companyname,
        }

        const dataRes = await resendInvite(body);

        console.log("-datap--", dataRes.data.result.replace(/&amp;/g, "&"));
        var id = users.userid + "_COPYTEXT";
        document.getElementById(id).value = dataRes.data.result.replace(
          /&amp;/g,
          "&"
        );

        document
          .getElementById(users.userid + "_COPY")
          .classList.add("btn-danger");

        document.getElementById(users.userid + "_COPY").innerHTML =
          "Click to Copy";
      }
    },
    async EditSave() {
      this.edit_loader = true;
      var users_id = this.editUsersId;
      var companyname = JSON.parse(
        localStorage.getItem("userdetails")
      ).companyname;
      const data = {
        is_edit: 1,
        email: this.editUserId,
        Editemail: this.editEmail,
        firstName: this.editFirstName,
        lastName: this.editLastname,
        webinarId: this.webinarDetails,
        companyname: companyname,
      };
      const participate = await this.editRoleForParticipant(data);

      let dataP = await singlewebinardata(this.webinarDetails, 0);

      this.users = dataP.data.result.participate;
      console.log("users_id-----------------", users_id);
      this.edit_loader = false;

      $("#editusermodal").modal("hide");
      this.modal_open = false;
    },
    async editRole(type, email, users_id) {
      document.getElementById("_ER_" + users_id).classList.remove("btn-brand");
      document.getElementById("_ER_" + users_id).classList.add("btn-success");
      document.getElementById("_ER_" + users_id).innerHTML = "Please wait...";
      var companyname = JSON.parse(
        localStorage.getItem("userdetails")
      ).companyname;
      const data = {
        email: email,
        webinarUserType: type,
        webinarId: this.webinarDetails,
        companyname: companyname,
      };
      const participate = await this.editRoleForParticipant(data);

      let dataP = await singlewebinardata(this.webinarDetails, 0);

      this.users = dataP.data.result.participate;
      console.log("users_id-----------------", users_id);
      document
        .getElementById("_ER_" + users_id)
        .classList.remove("btn-success");
      document.getElementById("_ER_" + users_id).classList.add("btn-brand");
      document.getElementById("_ER_" + users_id).innerHTML = "Edit Role";
    },
    async editRoleForParticipant(body) {
      console.log('body---', body)
      await listupdateParticipants(body);
    },
    async resendInvite(users) {
      var companyname = JSON.parse(
        localStorage.getItem("userdetails")
      ).companyname;
      var body = {
        email: users.email,
        webinarId: this.webinarDetails,
        companyname: companyname,
      }
      let data = await resendInvite(body)

      document.getElementById(users.email).classList.remove("btn-brand");
      document.getElementById(users.email).classList.add("btn-success");
      document.getElementById(users.email).innerHTML = "Invite Sent";
    },
    async closeEditWebianrModel() {
      $("#editWebinarModel").modal("hide");
    },
    async editWebinar() {

      this.save_loader = false;
      this.is_save_btn = true;
      this.is_edit_btn = false;
    },
    async SaveWebinar() {
      this.save_loader = true;

      console.log('**********************');
      console.log(this.oldTime);
      console.log(this.oldDate);
      console.log('**********************');

      const date = new Date(this.editWebinarDate);

      // Extract year, month, and day components
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1 and format it.
      const day = String(date.getDate()).padStart(2, '0');

      // Create the formatted date string in "MM/DD/YYYY" format
      const formattedDate = `${month}/${day}/${year}`;

      this.date1 = formattedDate;
      const unixTime1 = Date.parse(this.date1.toString());

      const unixTime = new Date(unixTime1).setHours(
        this.to24Hr(this.time).split(":")[0],
        this.to24Hr(this.time).split(":")[1]
      );
      console.log(unixTime);
      console.log(formattedDate);

      var body = {
        title: this.title,
        oldTitle: this.oldTitle,
        oldTime: this.oldTime,
        oldDuration: this.oldDuration,
        oldDate: this.oldDate,
        oldTimeZone: this.oldTimeZone,
        webinarId: this.webinarDetails,
        id: this.webinarDetails,
        webinarType: this.webinarType,
        newUser: this.users,
        oneTime: {
          time: unixTime.valueOf() / 1000,
          sendTime: this.time,
          date: this.date1,
          duration: this.duration,
          timezone: this.timezone,
        },
      };
      console.log(body);
      const body1 = await updateDateTimeForWebinars(body);

      this.save_loader = false;
      window.location.reload();
    },

    to24Hr(time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes;
    },
    async deleteWebinar(temp = false) {
      let body = {
        recurringStatus: this.recurringStatus.toString(),
        sendMailVerify: this.sendMailVerify,
        webinarDetails: this.webinarDetails,
        mid: this.mid,
      };
      let headers = {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      };
      // let url = BASE_URL + `api/users/v1/webinar/delete/${this.webinarDetails}`;
      // if (this.mid != 0) {
      //   url += `/${this.mid}`;
      // }
      if (temp) {
        this.del_loader = true;
        this.loader = true;

        await deleteWebinars(body);

        window.location.reload();
      } else {
        if (this.webinarType == "RECURRING") {
          $("#ViewModel").modal("show");
          $("#WebinarViewModel").modal("hide");
        } else {
          $("#deleteconformmodel").modal("show");
          $("#WebinarViewModel").modal("hide");
          // this.loader = true;
          // await axios
          //   .post(url, body, { headers: headers })
          //   .then(function (response) {
          //     console.log(response);
          //   })
          //   .catch(function (error) {
          //     console.log(error);
          //   });
          // window.location.reload();
        }
      }
    },
    closeViewCloseModal() {
      this.title = this.oldTitle;
      this.roomName = this.oldTitle;
      this.duration = this.oldDuration;
      this.timezone = this.oldTimeZone;
      this.date1 = this.oldDate;
      this.time = this.oldTime;
      this.is_save_btn = false;
      this.is_edit_btn = true;
      $("#WebinarViewModel").modal("hide");
    },
    closeViewModal() {
      if (this.is_save_btn) {

        this.title = this.oldTitle;
        this.roomName = this.oldTitle;
        this.duration = this.oldDuration;
        this.timezone = this.oldTimeZone;
        this.date1 = this.oldDate;
        this.time = this.oldTime;
        this.is_save_btn = false;
        this.is_edit_btn = true;
      } else {
        $("#WebinarViewModel").modal("hide");
      }
    },
    closeModal() {
      $("#WebinarViewModel").modal("hide");
      // window.location.reload();
      // $("#ViewModel").modal("show")

      // WebinarViewModel
    },
    isEmailValid: function () {
      return this.inviteUser == "" ?
        " d-none" :
        this.reg.test(this.inviteUser) ?
          "d-none" :
          " ";
    },


    disableBTN: function () {
      return this.inviteUser == "" ?
        (this.disable = false) :
        this.reg.test(this.inviteUser) ?
          (this.disable = true) :
          (this.disable = false);
    },
    deleted(id) {
      this.users = this.users.filter((name) => {
        return name.email != id.email;
      });
    },
    addParticipants() {
      this.users.push({
        email: this.inviteUser.toLocaleLowerCase(),
        firstName: this.invitefirstName ? this.invitefirstName : "UnityWebinar",
        lastName: this.invitelastName ? this.invitelastName : "lastName",
        webinarUserType: "Viewer",
      });
      console.log('this.users-------------', this.users)
      console.log('this.invitelastName-------------', this.invitelastName)
      this.invitefirstName = "";
      this.invitelastName = "";
      this.inviteUser = "";
      this.users = this.users.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.email == thing.email &&
              t.Name == thing.Name &&
              t.webinarUserType == thing.webinarUserType
          )
      );
    },
    async save() {
      this.disabled = true;
      this.loader = true;
      let headers = {
        "Content-type": "application/json",
        Authorization: localStorage.getItem("user"),
      };
      var companyname = JSON.parse(
        localStorage.getItem("userdetails")
      ).companyname;
      let body = {
        companyname: companyname,
        webinarId: this.webinarDetails,
        newUser: this.users,
      };
      console.log("***body***************");
      console.log(body);
      console.log("***body***************");
      if (this.mid != 0) {
        body.childID = this.mid;
      }
      let data = await participants(body);

      if (data.data.code == 200) {
        window.location.reload();
      }
      this.loader = false;
      this.disabled = false;
      this.loader = false;
      this.disabled = false;

      // window.location.reload()
      return data;
    },
    isEditEmailValid: async function () {
      console.log('this.editEmail------------', this.editEmail)
      console.log('this.edit_loader------------', this.edit_loader)
      console.log('this.reg.test(this.editEmail)------------', this.reg.test(this.editEmail))

      if (this.modal_open == true && this.edit_loader == false) {
        if (this.editEmail || this.editEmail == "") {
          console.log('this.email_error------------', this.email_error)
          if (this.reg.test(this.editEmail) == false) {
            this.email_error = "Please enter valid Email";
            this.s_enable = true;
            console.log('this.email_error------------', this.email_error)
          } else {
            var body_params = {
              webinarId: this.webinarDetails,
              email: this.editEmail
            }
            if (this.editEmail && this.webinarDetails) {

              var body = await checkWebinarParticipantEmail(body_params);

              if (this.editUserId != this.editEmail) {
                console.log('body.data.result------------', body.data.result)
                if (body.data.result == 1) {
                  this.email_error = "Email already exits.";
                  this.s_enable = true;
                } else {
                  this.email_error = "";
                  this.s_enable = false;
                }

              } else {
                this.email_error = "";
                this.s_enable = false;
              }

            }
          }
        } else {
          this.email_error = "";
        }
      }
      // return this.editEmail == "" ?
      //   " d-none" :
      //   this.reg.test(this.editEmail) ?
      //     "d-none" :
      //     " ";
    },
  },
};
</script>

<style>
.modal-backdrop.fade.show {
  z-index: -1;
}

.v3dp__datepicker,
.vue__time-picker {
  width: 100%;
}

#vueTime {
  text-align: center;
  width: 100%;
}

.vs__dropdown-toggle {
  height: inherit;
}

.closeIcon {
  text-align: end;
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0px;
}
</style>