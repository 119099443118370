<template>
  <div class="image-container">
    <div class="main-image-cont" v-if="imageUrl">

      <div v-if="isDeleteAvailable" class="internalMessage">
        <div v-if="receiverUserName !== null && receiverUserName !== 'Everyone'">[Message to {{ receiverUserName }}]
        </div>
      </div>


      <div class="main-image">
        <div v-if="isDeleteAvailable" class="optionsBox">
          <svg fill="black" @click="downloadImage()" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512">
          <path
            fill="black"
            d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 242.7-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7 288 32zM64 352c-35.3 0-64 28.7-64 64l0 32c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-32c0-35.3-28.7-64-64-64l-101.5 0-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352 64 352zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
        </svg>
        <svg class="deleteImage" @click="deleteMessage()" width="20" height="20" viewBox="0 0 14 15" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.65406 12.8289L1.43461 4.29407H12.5521L11.3327 12.8289C11.2853 13.16 11.1202 13.463 10.8674 13.6821C10.6147 13.9013 10.2914 14.0219 9.95688 14.0219H4.02986C3.69534 14.0219 3.37205 13.9013 3.11932 13.6821C2.86659 13.463 2.7014 13.16 2.65406 12.8289ZM13.247 1.51469H9.77275V0.819845C9.77275 0.635561 9.69954 0.458824 9.56924 0.328515C9.43893 0.198207 9.26219 0.125 9.07791 0.125H4.90884C4.72455 0.125 4.54782 0.198207 4.41751 0.328515C4.2872 0.458824 4.21399 0.635561 4.21399 0.819845V1.51469H0.739767C0.555482 1.51469 0.378746 1.5879 0.248437 1.71821C0.118129 1.84851 0.0449219 2.02525 0.0449219 2.20953C0.0449219 2.39382 0.118129 2.57056 0.248437 2.70086C0.378746 2.83117 0.555482 2.90438 0.739767 2.90438H13.247C13.4313 2.90438 13.608 2.83117 13.7383 2.70086C13.8686 2.57056 13.9418 2.39382 13.9418 2.20953C13.9418 2.02525 13.8686 1.84851 13.7383 1.71821C13.608 1.5879 13.4313 1.51469 13.247 1.51469Z"
            fill="#EF4444" />
        </svg>
        </div>

        <img class="galleryViewImage" crossorigin="anonymous" :src="imageUrl" alt="Fetched Image" />
      </div>

    </div>

    <div v-else-if="loading" class="skeleton-box"></div>

    <div v-else class="error-box">
      <p>Error fetching image</p>
      <button @click="fetchImageUrl" class="retry-button">Retry</button>
    </div>
  </div>
</template>

<script>
import { getAttachmentURL } from "@/services/service";

export default {
  name: "ImageFetcher",
  props: {
    fileName: {
      type: String,
      required: true,
    },
    receiverUserName: {
      type: String,
      required: true,
    },
    isDeleteAvailable: {
      type: String,
      default: false
    },
    deleteMessage: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      imageUrl: "",
      loading: true,
      error: false,
    };
  },
  async mounted() {
    await this.fetchImageUrl();
  },
  watch: {
    fileName: {
      immediate: false,
      handler() {
        this.fetchImageUrl();
      },
    },
  },
  methods: {
    async fetchImageUrl() {
      this.loading = true;
      this.error = false;

      try {
        const url = await getAttachmentURL(this.fileName);
        if (url) {
          this.imageUrl = `${url}?download=1`;
        } else {
          throw new Error("Invalid response");
        }
      } catch (error) {
        console.error("Error fetching image URL:", error);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    truncateFileName(fileName, maxLength = 40) {
      const extension = this.getFileExtension(fileName)
      let baseName = this.formatBaseName(fileName)
      if (baseName.length > maxLength) {
        return baseName.slice(0, maxLength - 3 - extension.length) + '...' + extension;
      }
      return baseName;
    },
    getFileExtension(fileName) {
      return fileName.split('.').pop();
    },
    formatBaseName(name) {
      return name?.replace(/-U-[^\.]+(?=\.\w+$)/, '')
    },
    downloadImage() {
      window.open(this.imageUrl, '_blank')
    }
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  overflow: hidden;
  position: relative;
}

.galleryViewImage {
  border-radius: 9px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* width: 150px;
  height: 150px; */
}

.skeleton-box {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #ddd 25%, #e0e0e0 50%, #ddd 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 9px;
}

.main-image-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
  width: 100%;
}

.main-image {
  position: relative;
  background-color: #c3c3c3c3;
  width: 100%;
}

.optionsBox {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  padding: 5px 7px;
  top: 5%;
  right: 5%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
}


@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.error-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffcccc;
  color: #b00020;
  font-weight: bold;
  border-radius: 9px;
  text-align: center;
  padding: 10px;
}

.retry-button {
  margin-top: 8px;
  padding: 6px 12px;
  background: #b00020;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  width: max-content;
  height: max-content;
}

.retry-button:hover {
  background: #90001a;
}

.internalMessage {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: flex-start;
}
</style>
