<template>
  <div class="mention-container">
    <textarea
      ref="textareaRef"
      :value="message"
      :disabled="disabled"
      :placeholder="placeholder"
      spellcheck="false"
      @input="onInput"
      @keydown="onKeyDown"
      style="background: transparent; border: none; resize: none; width: 100%; outline: none; color: black;"
    ></textarea>
    <div v-if="showSuggestions && filteredPeers.length" class="suggestions">
      <ul>
        <li
          v-for="(peer, index) in filteredPeers"
          :key="peer.socketId"
          :class="{ active: index === selectedIndex }"
          @click="selectPeer(peer)"
          @mouseover="selectedIndex = index"
          class="main-tag"
        >
          <span class="first-name">{{ peer.userDetails.user_name.split(' ')[0] }}</span>
          <span class="last-name" v-if="peer.userDetails.user_name.split(' ').length > 1">
            {{ " " + peer.userDetails.user_name.split(' ').slice(1).join(' ') }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicTextArea',
  props: {
    modelValue: { type: String, default: '' },
    users: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    ownUserId: { type: [String, Number], default: '' }
  },
  data() {
    return {
      message: this.modelValue,
      showSuggestions: false,
      suggestionQuery: '',
      selectedIndex: 0
    }
  },
  computed: {
    filteredPeers() {
      return this.users
        .filter(peer => peer.userDetails.user_id !== this.ownUserId)
        .filter(peer =>
          !this.suggestionQuery ||
          peer.userDetails.user_name.toLowerCase().includes(this.suggestionQuery.toLowerCase())
        )
    }
  },
  watch: {
    modelValue(newVal) {
      this.message = newVal
    },
    message(newVal) {
      this.$emit('update:modelValue', newVal)
    }
  },
  methods: {
    onInput(e) {
      this.message = e.target.value
      const pos = e.target.selectionStart
      const textBeforeCursor = this.message.slice(0, pos)
      const match = /@(\w*)$/.exec(textBeforeCursor)
      if (match) {
        this.suggestionQuery = match[1]
        this.showSuggestions = this.filteredPeers.length > 0
        this.selectedIndex = 0
      } else {
        this.showSuggestions = false
      }
    },
    onKeyDown(e) {
      if (this.showSuggestions && this.filteredPeers.length > 0) {
        if (e.key === 'ArrowDown') {
          e.preventDefault()
          e.stopPropagation()
          this.selectedIndex = (this.selectedIndex + 1) % this.filteredPeers.length
          return
        } else if (e.key === 'ArrowUp') {
          e.preventDefault()
          e.stopPropagation()
          this.selectedIndex = (this.selectedIndex - 1 + this.filteredPeers.length) % this.filteredPeers.length
          return
        } else if (e.key === 'Enter') {
          e.preventDefault()
          e.stopPropagation()
          const peer = this.filteredPeers[this.selectedIndex]
          if (peer) this.selectPeer(peer)
          return
        } else if (e.key === 'Escape') {
          e.preventDefault()
          e.stopPropagation()
          this.showSuggestions = false
          return
        }
      }
      this.$emit('keydown', e)
    },
    selectPeer(peer) {
      const textarea = this.$refs.textareaRef
      const pos = textarea.selectionStart
      const textBeforeCursor = this.message.slice(0, pos)
      const match = /@(\w*)$/.exec(textBeforeCursor)
      if (match) {
        const startPos = pos - match[0].length
        const firstName = peer.userDetails.user_name.split(' ')[0]
        const before = this.message.slice(0, startPos)
        const after = this.message.slice(pos)
        this.message = before + '@' + firstName + ' ' + after
        this.$nextTick(() => {
          const newPos = startPos + firstName.length + 2
          textarea.setSelectionRange(newPos, newPos)
        })
      }
      this.showSuggestions = false
    }
  }
}
</script>

<style scoped>
.mention-container {
  position: relative;
  flex: 0.75;
}
.suggestions {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 0;
  background: #2D8CFF;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 80px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  z-index: 10;
}
.suggestions ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.suggestions li {
  padding: 8px;
  cursor: pointer;
}
.suggestions li.active,
.suggestions li:hover {
  background: #0f73ed;
}
.first-name {
  color: white;
}
.last-name {
  color: #e7e1e1;
}
.main-tag{
  text-align: start;
}
</style>
